import React from 'react';
import { Datagrid, List, ReferenceField, TextField } from 'react-admin';
import { CustomDateField } from '../../../../commonComponents/DateField';
import CustomReportListActions from '../../../../commonComponents/ListActions/CustomReportListActions';
import { APP_TYPES } from '../../../../config/constants';
import UsersKnowledgeBankArticlesFilter from './UsersKnowledgeBankArticlesFilter';

const UsersKnowledgeBankArticlesList = (props) => (
  <List
    title="Users Knowledge Bank Read Articles"
    actions={<CustomReportListActions />}
    filters={<UsersKnowledgeBankArticlesFilter />}
    bulkActionButtons={false}
    sort={{}}
    {...props}
  >
    <Datagrid>
      <TextField label="Article ID" source="article_id" sortable={false} />
      <TextField label="User ID" source="user_id" sortable={false} />
      <TextField label="User Phone" source="phone" sortable={false} />
      {process.env.REACT_APP_APP_TYPE === APP_TYPES.IRELAND && (
        <ReferenceField source="tier_id" reference="admin/tiers">
          <TextField source="name" />
        </ReferenceField>
      )}
      <TextField label="Article Name" source="name" sortable={false} />
      <TextField label="Credits" source="credits" sortable={false} />
      <TextField label="Kudos" source="points" sortable={false} />
      <TextField label="Transaction ID" source="transaction_id" sortable={false} />
      <CustomDateField label="Date created" source="created_at" sortable={false} />
      {process.env.REACT_APP_APP_TYPE === APP_TYPES.BELARUS && (
        <TextField label="Внешний код торговой точки" source="account_code" />
      )}
    </Datagrid>
  </List>
);

export default UsersKnowledgeBankArticlesList;

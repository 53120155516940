import React from 'react';
import ImageField from './ImageField';

const AttachmentImages = (props) => (
  <>
    {props.record.attachments.map((imageUrl) => (
      <ImageField key={imageUrl} src={imageUrl} />
    ))}
  </>
);

export default AttachmentImages;

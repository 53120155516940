import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useNotify, useTranslate } from 'react-admin';
import {
  APP_TYPES,
  CAUCASUS_TYPES,
  linearDiagramFields,
  serverApiUrl,
  usersDiagramsColorScheme,
} from '../../../../../config/constants';
import httpClient from '../../../../../core/httpClient';

import CardWithDiagram from '../CardWithDiagram';

const ENV_APP_TYPE = process.env.REACT_APP_APP_TYPE;
const isCaucasus = CAUCASUS_TYPES.includes(ENV_APP_TYPE);

const useStyles = makeStyles({
  cardsContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
});

const getAdminData = async () => {
  const url = `${serverApiUrl}/admin/users/admin/stats`;
  const options = { method: 'GET' };

  return httpClient(url, options);
};

const AdminCardSet = () => {
  const notify = useNotify();
  const classes = useStyles();
  const translate = useTranslate();
  const [selectedItems, setSelectedItems] = useState([]);
  const [nationalData, setNationalData] = useState({ data: [] });
  const [regionsData, setRegionsData] = useState([]);

  useEffect(() => {
    getAdminData()
      .then((response) => {
        setNationalData(response.json.national_data);
        !isCaucasus && setRegionsData(response.json.regions_data);
      })
      .catch((e) => {
        notify(`GET Admin data error: ${e.message}`, 'warning');
      });
  }, [notify]);

  const handleClick = (node, nodeIndex) => {
    const index = selectedItems.findIndex((x) => x.name === node.name);
    if (index === -1) {
      setSelectedItems(selectedItems.slice().concat({ name: node.name, value: node.value, index: nodeIndex }));
    } else {
      setSelectedItems(selectedItems.slice(0, index).concat(selectedItems.slice(index + 1)));
    }
  };

  const handleClickOutside = () => {
    setSelectedItems([]);
  };

  if ((ENV_APP_TYPE === APP_TYPES.HUNGARY || CAUCASUS_TYPES.includes(ENV_APP_TYPE)) && nationalData.data.length) {
    nationalData.data = nationalData.data.filter((item) => item.name !== linearDiagramFields.activeUsers);
  }

  if ((ENV_APP_TYPE === APP_TYPES.HUNGARY || CAUCASUS_TYPES.includes(ENV_APP_TYPE)) && regionsData.length) {
    regionsData.forEach((region) => {
      region.data = region.data.filter((item) => item.name !== linearDiagramFields.activeUsers);
    });
  }

  return (
    <div className={classes.cardsContainer}>
      <CardWithDiagram
        title={translate('root.statistics.national')}
        data={nationalData.data}
        colors={usersDiagramsColorScheme}
        selectedItems={selectedItems}
        handleClick={handleClick}
        handleClickOutside={handleClickOutside}
      />
      {regionsData.map((region, index) => (
        <CardWithDiagram
          key={`${region.name}-${index}`}
          title={`${region.name} ${translate('root.statistics.region')}`}
          data={region.data}
          colors={usersDiagramsColorScheme}
          selectedItems={selectedItems}
          handleClick={handleClick}
          handleClickOutside={handleClickOutside}
        />
      ))}
    </div>
  );
};

export default AdminCardSet;

import React from 'react';
import { SimpleForm, Edit, NumberInput, minValue, maxValue } from 'react-admin';
import ProductForm from './ProductForm';

const StepEdit = (props) => (
  <Edit title="Edit Quiz" undoable={false} {...props}>
    <SimpleForm redirect="list">
      <NumberInput label="Step Number" source="step_number" validate={[minValue(1), maxValue(7)]} />
      <ProductForm name="products" formType="edit" />
    </SimpleForm>
  </Edit>
);

export default StepEdit;

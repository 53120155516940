import ChatIcon from '@material-ui/icons/Chat';
import Chat from './components/Chat';
import { roles, APP_TYPES } from '../../config/constants';
import { isHungary } from '../../helpers/appTypeHelper';

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: [roles.ADMIN, isHungary() && roles.BA],
  label: 'Chats',
};

export default {
  list: Chat,
  icon: ChatIcon,
  name: 'admin/chats',
  options,
};

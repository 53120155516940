import React from 'react';
import { Filter } from 'react-admin';
import TargetGroupInput from '../../../commonComponents/TargetGroupInput';

const LimitationFilter = (props) => (
  <Filter {...props}>
    <TargetGroupInput alwaysOn />
  </Filter>
);

export default LimitationFilter;

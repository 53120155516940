import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { ClickAwayListener, Tooltip, tooltipClasses } from '@mui/material';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const stopPropagation = (e) => e.stopPropagation();

const CustomTooltip = styled(({ className, ...props }) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div
        onClick={(e) => {
          handleTooltipOpen();
          stopPropagation(e);
        }}
        style={{ display: 'flex', marginLeft: '8px' }}
      >
        <Tooltip {...props} onClose={handleTooltipClose} open={open} placement="right">
          <HelpOutlineIcon />
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
})({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

export const CellWithTooltip = ({ showTooltip, tooltipTitle, children }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    {children}
    {showTooltip && <CustomTooltip title={tooltipTitle} />}
  </div>
);

import React from 'react';
import { BooleanInput, Create, SimpleForm } from 'react-admin';
import IndependentItemCreateFields from './independentItemCreateFields';
import { isIreland } from '../../../helpers/appTypeHelper';

const IndependentItemCreate = (props) => (
  <Create title="Create Independent Item" {...props}>
    <SimpleForm redirect="list">
      <IndependentItemCreateFields />
      {isIreland() && (
        <BooleanInput
          style={{ width: '300px' }}
          label="Included in the Progress bar"
          source="progress_bar_enable"
          defaultValue={true}
        />
      )}
    </SimpleForm>
  </Create>
);

export default IndependentItemCreate;

import { TextField } from '@mui/material';
import React from 'react';

export const TiersPricesList = (props) => {
  const { prices_by_discount_tiers } = props.record;
  return (
    <>
      {prices_by_discount_tiers &&
        prices_by_discount_tiers.map((tier) => (
          <div key={tier.tier_id} style={{ marginBottom: 5 }}>
            <TextField
              label={tier.tier_name}
              defaultValue={tier.discount_price}
              variant="filled"
              type="number"
              disabled
            />
          </div>
        ))}
    </>
  );
};

import HomeIcon from '@material-ui/icons/Home';
import OutletsList from './components/OutletsList';
import OutletEdit from './components/OutletEdit';
import { APP_TYPES, CAUCASUS_TYPES, roles } from '../../../config/constants';
import OutletCreate from './components/OutletCreate';

const appType = process.env.REACT_APP_APP_TYPE;
const isBelarus = appType === APP_TYPES.BELARUS;
const isCaucasus = CAUCASUS_TYPES.includes(appType);

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: isBelarus || isCaucasus ? [roles.ADMIN, roles.JTI_TEAM] : [roles.ADMIN],
  subMenuName: 'outlets',
  subMenuLabel: 'Outlets',
};

export default {
  list: OutletsList,
  create: OutletCreate,
  edit: OutletEdit,
  icon: HomeIcon,
  name: 'admin/outlets',
  options,
};

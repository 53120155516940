import React from 'react';
import { Datagrid, List, ReferenceField, TextField } from 'react-admin';
import { CustomDateField } from '../../../../commonComponents/DateField';
import CustomReportListActions from '../../../../commonComponents/ListActions/CustomReportListActions';

import SalesTipsReportsFilter from './SalesTipsReportsFilter';

const SalesTipsReportsList = (props) => (
  <List
    title="Sales tips user steps"
    actions={<CustomReportListActions />}
    filters={<SalesTipsReportsFilter />}
    bulkActionButtons={false}
    sort={{ field: 'user_id', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField label="User ID" source="user_id" />
      <ReferenceField label="Tip name" source="tip.id" reference="admin/sales-tips">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Step type"
        source="step.transaction_type_id"
        reference="admin/transactions/types"
        perPage={100}
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField label="Points" source="transaction.coins" />
      <CustomDateField label="Created at" source="transaction.created_at" />
    </Datagrid>
  </List>
);

export default SalesTipsReportsList;

import React from 'react';
import { Edit, NumberInput, required, SimpleForm, TextInput } from 'react-admin';
import ArrayField from '../../../commonComponents/ArrayField';
import UploadFileInput from '../../../commonComponents/UploadInputs/UploadFileInput';

const TierEdit = (props) => (
  <Edit title="Tiers Edit" undoable={false} {...props}>
    <SimpleForm redirect="list">
      <TextInput label="Name" source="name" validate={required()} />
      <NumberInput source="reward_coefficient" label="Coefficient per content" validate={required()} />
      <NumberInput source="coins_per_currency" label="1 euro convertation" validate={required()} />
      <UploadFileInput fileInputSource="account_codes_file" label="Account codes" />
      <ArrayField label="Account codes" source="account_codes" />
    </SimpleForm>
  </Edit>
);

export default TierEdit;

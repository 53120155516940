import ColorLens from '@material-ui/icons/ColorLens';
import { Create, Edit } from 'react-admin';
import { APP_TYPES, roles } from '../../config/constants';
import UpsertThemeForm from './components/UpsertThemeForm';
import ThemeList from './components/ThemeList';

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: [roles.ADMIN],
  subMenuName: 'manage',
  label: 'Themes',
};

const CreateTheme = (props) => (
  <Create {...props}>
    <UpsertThemeForm {...props} type={'create'} />
  </Create>
);

const EditTheme = (props) => (
  <Edit {...props}>
    <UpsertThemeForm {...props} type={'edit'} />
  </Edit>
);

export default {
  create: CreateTheme,
  edit: EditTheme,
  list: ThemeList,
  icon: ColorLens,
  name: 'admin/themes',
  options,
};

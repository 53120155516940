import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, Paper, Typography } from '@material-ui/core';
import { CustomDateField } from './DateField';
import ImageField from './ImageField';
import { APP_TYPES } from '../config/constants';

const isIreland = process.env.REACT_APP_APP_TYPE === APP_TYPES.IRELAND;

const useStyles = makeStyles({
  chatMessageContainer: {
    width: '70%',
    marginTop: '20px',
  },
  incomingMessageContainer: {
    alignSelf: 'flex-start',
  },
  chatMessageHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: '0.8em',
  },
  incomingMessageHeader: {
    justifyContent: 'flex-start',
  },
  chatMessageHeaderDateField: {
    fontSize: '0.75rem',
    lineHeight: '1.66',
  },
  chatMessage: {
    whiteSpace: 'pre-line',
    borderRadius: '10px',
    padding: 15,
    backgroundColor: '#01c1b6',
    color: '#fff',
    textAlign: 'right',
    wordBreak: 'break-all',
  },
  caption: {
    color: '#inherit',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  incomingChatMessage: {
    textAlign: 'left',
    color: '#000',
    backgroundColor: '#dfdfdf',
  },
  typographyFont: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  },
});

const getSendToLabel = (chat_id) => {
  if (chat_id === '1-1') {
    return '#ALL';
  }
  if (chat_id.includes('TG-')) {
    return `#${chat_id}`;
  }
  return '#USER';
};

const ChatMessage = ({ record, companionFullName }) => {
  const classes = useStyles();
  const { message, user_id, chat_id } = record;
  const isUserMessage = user_id === 1;
  const dateFormat = {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };

  const formatChatMessage = (message) => {
    const { link, image, caption, text } = message;

    const imageField = image ? <ImageField label="Image" src={message.image} height="5" /> : null;
    const linkField = link ? (
      <Link href={link} color="inherit" underline="always" target="_blank" rel="noopener">
        {link}
      </Link>
    ) : null;

    return (
      <>
        <Typography component={'span'} color="inherit">
          {caption && <Typography className={classes.caption}>{caption}</Typography>}
          <div>
            {text} {linkField}
          </div>
        </Typography>
        {imageField}
      </>
    );
  };

  return (
    <div className={`${classes.chatMessageContainer} ${!isUserMessage ? classes.incomingMessageContainer : ''}`}>
      <div className={`${classes.chatMessageHeader} ${!isUserMessage ? classes.incomingMessageHeader : ''}`}>
        {isIreland && (
          <>
            <Typography variant="caption">{getSendToLabel(chat_id)}</Typography>
            &nbsp;|&nbsp;
          </>
        )}
        <CustomDateField
          className={classes.chatMessageHeaderDateField}
          record={record}
          source="created_at"
          options={dateFormat}
        />
        &nbsp;|&nbsp;
        <Typography variant="caption">{isUserMessage ? 'You' : companionFullName}</Typography>
      </div>
      <Paper className={`${classes.chatMessage} ${!isUserMessage ? classes.incomingChatMessage : ''}`}>
        {formatChatMessage(message)}
      </Paper>
    </div>
  );
};

export default ChatMessage;

import KnowledgeBankBrandsIcon from '@material-ui/icons/Style';
import BrandsList from './components/BrandsList';
import BrandCreate from './components/BrandCreate';
import BrandEdit from './components/BrandEdit';
import { roles, APP_TYPES } from '../../../config/constants';

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: [roles.ADMIN],
  label: 'Brands',
  subMenuName: 'knowledgeBank',
};

export default {
  list: BrandsList,
  create: BrandCreate,
  edit: BrandEdit,
  icon: KnowledgeBankBrandsIcon,
  name: 'admin/knowledge-bank/brands',
  options,
};

import React from 'react';
import { TextInput, required, maxLength, minLength, regex } from 'react-admin';
import { phoneCodePrepender } from '../helpers/phoneCodePrepender';
import { APP_TYPES } from '../config/constants';

const isIreland = process.env.REACT_APP_APP_TYPE === APP_TYPES.IRELAND;
const isDemo = process.env.REACT_APP_APP_TYPE === APP_TYPES.DEMO;

const PhoneInput = ({ label, source = 'phone', isRequired = true, ...rest }) => (
  <TextInput
    label={label}
    source={source}
    onSelect={phoneCodePrepender}
    validate={[
      isRequired && required(),
      minLength(12),
      isIreland || isDemo ? maxLength(16) : maxLength(13),
      regex(/^\+[0-9]*(\([0-9]\))?[0-9]*$/, 'Must contain only numbers and +'),
    ]}
    {...rest}
  />
);

export default PhoneInput;

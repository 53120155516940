import React from 'react';
import { BooleanField, Datagrid, List, TextField } from 'react-admin';
import { CustomDateField } from '../../../../commonComponents/DateField';
import CustomReportListActions from '../../../../commonComponents/ListActions/CustomReportListActions';

import NewDownloadsFilter from './NewDownloadsFilter';

const NewDownloadsList = (props) => (
  <List
    title="New downloads"
    actions={<CustomReportListActions />}
    filters={<NewDownloadsFilter />}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField label="User ID" source="id" />
      <TextField label="Account code" source="traffic_code" />
      <BooleanField label="Downloaded" source="downloaded" sortable={false} />
      <CustomDateField label="Download Date" source="download_date" />
      <CustomDateField label="Last Transaction Date" source="last_activity_date" />
    </Datagrid>
  </List>
);

export default NewDownloadsList;

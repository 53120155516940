import React from 'react';
import { Edit, NumberInput, SimpleForm, TextInput } from 'react-admin';

const ProviderProductsEdit = (props) => {
  const { state } = props.location;
  const redirect = (basePath, id, data) =>
    data.provider_id ? `/admin/products/providers/${data.provider_id}/products` : '/admin/products/providers';

  return (
    <Edit
      title="Edit Product"
      undoable={false}
      transform={(data) => ({ ...data, provider_id: state && state.provider_id })}
      {...props}
    >
      <SimpleForm redirect={redirect}>
        <TextInput label="ID" source="id" disalbed />
        <TextInput source="provider_id" disabled defaultValue={state && state.provider_id} />
        <TextInput source="name" defaultValue={state && state.name} disabled />
        <NumberInput source="price" />
        <NumberInput source="nominal_price" />
        <TextInput source="description" multiline />
      </SimpleForm>
    </Edit>
  );
};

export default ProviderProductsEdit;

import ChallengeIcon from '@material-ui/icons/FormatAlignJustify';
import ChallengesList from './components/ChallengesList';
import ChallengeCreate from './components/ChallengeCreate';
import ChallengeEdit from './components/ChallengeEdit';
import { roles, APP_TYPES } from '../../config/constants';

const ENV_APP_TYPE = process.env.REACT_APP_APP_TYPE;

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: [APP_TYPES.HUNGARY, APP_TYPES.BELARUS].includes(ENV_APP_TYPE)
    ? [roles.ADMIN]
    : [roles.ADMIN, roles.JTI_TEAM],
  label: 'Challenge',
  subMenuName: 'content',
};

export default {
  list: ChallengesList,
  create: ChallengeCreate,
  edit: ChallengeEdit,
  icon: ChallengeIcon,
  name: 'admin/quests',
  options,
};

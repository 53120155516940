import React from 'react';
import { List, Datagrid, TextField, EditButton, DeleteButton } from 'react-admin';
import StatusField from '../../../commonComponents/StatusField';
import AdministratorsFilter from './AdministratorsFilter';

const AdministratorsList = (props) => (
  <List
    title="Administrators"
    filters={<AdministratorsFilter />}
    exporter={false}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <TextField label="Username" source="username" sortable={false} />
      <TextField label="Name" source="name" sortable={false} />
      <StatusField label="Status" source="status" sortable={false} />
      <EditButton />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
);

export default AdministratorsList;

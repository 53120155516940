import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormWithRedirect, required, TextInput } from 'react-admin';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Button } from '@mui/material';
import React from 'react';

export const CustomModal = ({ showDialog, handleCloseClick, handleSubmit }) => (
  <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Create post">
    <DialogTitle>
      <span style={{ fontWeight: 700 }}>You didn’t choose any users, please describe the reason</span>
    </DialogTitle>

    <FormWithRedirect
      resource="posts"
      save={handleSubmit}
      render={({ handleSubmitWithRedirect }) => (
        <>
          <DialogContent>
            <TextInput source="reason" validate={required()} fullWidth />
          </DialogContent>
          <DialogActions style={{ padding: '0px 26px 40px 0px' }}>
            <Button onClick={handleCloseClick} variant="outlined" color="error">
              CANCEL
            </Button>
            <Button
              onClick={handleSubmitWithRedirect}
              variant="contained"
              style={{ color: '#FFFF', marginLeft: '20px' }}
            >
              SAVE
            </Button>
          </DialogActions>
        </>
      )}
    />
  </Dialog>
);

import decodeToken from 'jwt-decode';
import { serverApiUrl } from '../config/constants';

export default {
  login: async ({ username, password }) => {
    const response = await fetch(`${serverApiUrl}/admin/auth`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, password }),
    });
    if (!response.ok) {
      throw new Error('The user name or password is incorrect');
    }
    const { token, id, name, surname, photo } = await response.json();
    localStorage.setItem('token', token);
    localStorage.setItem('auth', JSON.stringify({ id, fullName: `${name} ${surname || ''}`.trim(), avatar: photo }));

    await fetch(`${serverApiUrl}/admin/logs`, {
      method: 'POST',
      headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
      body: JSON.stringify({ action: 'login' }),
    });

    return Promise.resolve('/admin/users');
  },
  logout: () => {
    localStorage.removeItem('token');
    return Promise.resolve();
  },
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      localStorage.removeItem('auth');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => (localStorage.getItem('token') ? Promise.resolve() : Promise.reject()),
  getPermissions: () => {
    const token = localStorage.getItem('token');

    if (!token) {
      return Promise.reject();
    }

    const { roles } = decodeToken(token);

    return roles ? Promise.resolve(roles) : Promise.reject();
  },
  getIdentity: localStorage.getItem('auth')
    ? () => {
        const { id, fullName, avatar } = JSON.parse(localStorage.getItem('auth'));

        return { id, fullName, avatar };
      }
    : undefined,
};

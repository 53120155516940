import React from 'react';
import { BooleanInput, NumberInput, ReferenceInput, required, SelectInput, TextInput } from 'react-admin';
import { ColorInput } from 'react-admin-color-input';

import { mainScreenModuleComponentTypes, s3FilePaths } from '../../../../config/constants';
import ImageInput from '../../../../commonComponents/UploadInputs/ImageInput';
import withCardWrapper from '../../hocs/withCardWrapper';
import BrandSelector from '../../../../commonComponents/BrandSelector';
import CategorySelector from '../../../../commonComponents/CategorySelector';

const getFieldName = (member, name) => {
  if (!member) {
    return name;
  }

  return `${member}.${name}`;
};

const getReferenceInput = (member, type) => {
  let fieldLabel = '';
  let fieldSourceName;
  let fieldRequired = true;
  let fieldReference = '';

  switch (type) {
    case mainScreenModuleComponentTypes.solo_quiz:
      fieldLabel = 'Solo Quiz Brand ID';
      fieldSourceName = 'brand_id';
      fieldRequired = false;
      break;
    case mainScreenModuleComponentTypes.quiz_with_user:
      fieldLabel = 'Quiz with user Brand ID';
      fieldSourceName = 'brand_id';
      fieldRequired = false;
      break;
    case mainScreenModuleComponentTypes.special_quiz:
      fieldLabel = 'Special quiz ID';
      fieldSourceName = 'pack_id';
      break;
    case mainScreenModuleComponentTypes.kb_article:
      fieldLabel = 'KB Article ID';
      fieldSourceName = 'knowledge_bank_article_id';
      break;
    case mainScreenModuleComponentTypes.kb_game:
      fieldLabel = 'KB Game ID';
      fieldSourceName = 'knowledge_bank_article_id';
      break;
    case mainScreenModuleComponentTypes.survey:
      fieldLabel = 'KB Survey ID';
      fieldSourceName = 'knowledge_bank_article_id';
      break;
    case mainScreenModuleComponentTypes.kb_category:
      fieldLabel = 'KB Category ID';
      fieldSourceName = 'knowledge_bank_category_id';
      fieldReference = 'admin/knowledge-bank/categories';
      break;
    case mainScreenModuleComponentTypes.loyalty_program:
      fieldLabel = 'Loyalty program ID';
      fieldSourceName = 'program_id';
      break;
    case mainScreenModuleComponentTypes.achievement:
      fieldLabel = 'Achievement';
      fieldSourceName = 'achievement_id';
      fieldReference = 'admin/achievements';
      break;
    case mainScreenModuleComponentTypes.daily_game:
      fieldLabel = 'Daily Game ID';
      fieldSourceName = 'daily_game_id';
      break;
    case mainScreenModuleComponentTypes.kb:
      fieldLabel = 'KB Section of Category';
      fieldSourceName = 'knowledge_bank_category_section_id';
      fieldReference = 'admin/knowledge-bank/categories/sections';
      break;
    case mainScreenModuleComponentTypes.subscreen:
      fieldLabel = 'Subscreen ID';
      fieldSourceName = 'subscreen_id';
      fieldRequired = true;
      break;
    case mainScreenModuleComponentTypes.bonus_shop:
    case mainScreenModuleComponentTypes.general_quiz:
    case mainScreenModuleComponentTypes.lottery:
    case mainScreenModuleComponentTypes.question_of_the_day:
    case mainScreenModuleComponentTypes.event_mode:
    case mainScreenModuleComponentTypes.single_mode:
    case mainScreenModuleComponentTypes.check_in:
    case mainScreenModuleComponentTypes.invite_code:
    case mainScreenModuleComponentTypes.achievements:
    case mainScreenModuleComponentTypes.lep:
    case mainScreenModuleComponentTypes.pack_recognition:
    case mainScreenModuleComponentTypes.brand_information:
      return null;
    default:
      throw new Error(`Unsupported type for Module component(TypeId = ${type}).`);
  }

  return fieldReference ? (
    <ReferenceInput
      label={fieldLabel}
      source={getFieldName(member, fieldSourceName)}
      reference={fieldReference}
      validate={fieldRequired && required()}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  ) : (
    <NumberInput
      label={fieldLabel}
      source={getFieldName(member, fieldSourceName)}
      validate={fieldRequired && required()}
    />
  );
};

const ModuleComponent = ({ member, type }) => (
  <>
    <TextInput label="Name" source={getFieldName(member, 'name')} validate={required()} />
    <TextInput source={getFieldName(member, 'navigation_title')} label={'Menu title'} />
    <ImageInput label="Image" imagePath={s3FilePaths.allUploads} imageInputSource={getFieldName(member, 'image')} />
    <ColorInput label="Background color" source={getFieldName(member, 'background_color')} validate={required()} />
    <ColorInput label="Text color" source={getFieldName(member, 'text_color')} validate={required()} />
    <ColorInput label="Border color" source={getFieldName(member, 'border_color')} />
    {getReferenceInput(member, type)}
    {type === mainScreenModuleComponentTypes.brand_information && (
      <>
        <BrandSelector source={getFieldName(member, 'brand_id')} />
        <BrandSelector label="Secondary brand" source={getFieldName(member, 'secondary_brand_id')} />
        <CategorySelector source={getFieldName(member, 'knowledge_bank_category_id')} />
      </>
    )}
    <NumberInput label="Priority" source={getFieldName(member, 'priority')} />
    <BooleanInput label="Visible" source={getFieldName(member, 'visible')} />
    <BooleanInput label="Double size" source={getFieldName(member, 'double_size')} />
  </>
);

export default withCardWrapper(ModuleComponent);

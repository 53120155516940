import React from 'react';
import { Filter, TextInput } from 'react-admin';
import styles from './TradeProgram.module.scss';
import ManagerAutocompleteInput from '../../../../commonComponents/ManagerAutocompleteInput';
import { roles } from '../../../../config/constants';
import checkUserRole from '../../../../helpers/checkUserRole';

export const CustomFilter = ({ setFilters }) => {
  // Fix bug https://github.com/marmelab/react-admin/issues/3551
  const identity = (value) => value;

  return (
    <div className={styles.filterContainer}>
      <Filter
        setFilters={({ manager_id, rsm_id, outlet_code }) => {
          setFilters({ manager_id, rsm_id, outlet_code });
        }}
      >
        <TextInput source={'outlet_code'} parse={identity} alwaysOn />
        {!checkUserRole(roles.BA) && <ManagerAutocompleteInput alwaysOn label={'BA'} />}
        {!checkUserRole(roles.RSM, roles.BA) && (
          <ManagerAutocompleteInput label="RSM" source="rsm_id" alwaysOn filter={{ roles: [roles.RSM] }} />
        )}
      </Filter>
    </div>
  );
};

import React from 'react';
import { FormDataConsumer, SimpleForm } from 'react-admin';

import CustomArrayInput from '../../../commonComponents/CustomArrayInput';
import FormIterator from '../../../core/FormIterator';
import Task from './Task';
import Reward from './Reward';
import { challengesRewardTypes, challengesTaskTypes, challengeTypes } from '../config/constants';
import { isHungary, isIreland } from '../../../helpers/appTypeHelper';

const regionsWithTeamChallenge = isHungary() || isIreland();

const tasksFormTypeTogglerOptions = [
  { value: challengesTaskTypes.playedQuizzesNumber, label: 'Number of played quizzes for the period' },
  { value: challengesTaskTypes.quizzesOnTopicNumber, label: 'Number of quizzes on some topic for the period' },
  { value: challengesTaskTypes.wonQuizzesNumber, label: 'Number of quizzes won for the period' },
  { value: challengesTaskTypes.kudosNumber, label: 'Number of kudos for the period' },
  { value: challengesTaskTypes.purchasedGiftsNumber, label: 'Number of gifts purchased for the period' },
  {
    value: challengesTaskTypes.takenPartLotteriesNumber,
    label: 'Number of lotteries are taken part in for the period',
  },
  { value: challengesTaskTypes.readArticlesNumber, label: 'Number of articles are read for the period' },
  { value: challengesTaskTypes.specialQuizzesNumber, label: 'Number of special quizzes for the period' },
  { value: challengesTaskTypes.spentCoinsNumber, label: 'Number of coins are spent for the period' },
  { value: challengesTaskTypes.multibrandQuizzesNumber, label: 'Number of multibrand quizzes for the period' },
  { value: challengesTaskTypes.custom, label: 'Custom task' },
  { value: challengesTaskTypes.dailyQuestionsPlayedNumber, label: 'Number of played question of the day' },
  { value: challengesTaskTypes.dailyQuestionsWonNumber, label: 'Number of won quizzes in question of the day' },
  { value: challengesTaskTypes.kbGamesWonNumber, label: 'Number of won games in KB' },
  { value: challengesTaskTypes.singleModePlayedNumber, label: 'Number of played Single Mode' },
  { value: challengesTaskTypes.singleModePlayedInRowNumber, label: 'Number of played Single Mode in a row' },
  { value: challengesTaskTypes.wonKnowledgeBaseEntity, label: 'Won KB entry' },
  { value: challengesTaskTypes.knowledgeBaseEntity, label: 'Complete KB entry' },
  { value: challengesTaskTypes.wonDailyQuestion, label: 'Won daily question' },
  { value: challengesTaskTypes.dailyQuestion, label: 'Take part in daily question' },
  { value: challengesTaskTypes.specialQuiz, label: 'Complete special quiz' },
  { value: challengesTaskTypes.lottery, label: 'Take part in lottery' },
  { value: challengesTaskTypes.salesTips, label: 'Complete sales tips' },
  { value: challengesTaskTypes.playedQuickTipsNumber, label: 'Number of played Quick Tips' },
  { value: challengesTaskTypes.wonQuickTipsNumber, label: 'Number of won quick Tips' },
];

const customTasksForHungaryAndIreland = [
  { value: challengesTaskTypes.customTextManual, label: 'Custom text (manual)' },
  { value: challengesTaskTypes.customTextAuto, label: 'Custom text (auto)' },
];

const irelandTasks = [{ value: challengesTaskTypes.linkToChat, label: 'Link to Chat' }];

const tasksList = [
  ...tasksFormTypeTogglerOptions,
  ...(regionsWithTeamChallenge ? customTasksForHungaryAndIreland : []),
  ...(isIreland() ? irelandTasks : []),
];

const rewardsFormTypeTogglerOptions = [
  { value: challengesRewardTypes.completedQuestsNumber, label: 'Number of completed quests tasks' },
  { value: challengesRewardTypes.draw, label: 'Draw' },
];

const pushNewFieldToTasks = ({ fields, nextFormType: taskTypeId }) => {
  fields.push({ type_id: taskTypeId });
};

const pushNewFieldToRewards = ({ fields, nextFormType: rewardTypeId }) => {
  fields.push({ type: rewardTypeId });
};

const TaskForm = ({ classes, children, type, ...rest }) => {
  const renderTasksForm = ({ field, member, index }) => {
    const fieldTypeId = field.type_id;
    const itemCountFieldLabel = tasksList
      .find((item) => item.value === fieldTypeId)
      .label.replace(' for the period', '');
    return <Task member={member} taskFormType={fieldTypeId} itemCountFieldLabel={itemCountFieldLabel} index={index} />;
  };

  const renderRewardsForm = ({ field, member, record }) => {
    const fieldTypeId = field.type;
    return (
      <FormDataConsumer>
        {({ formData }) => {
          const { type: challengeType } = formData;

          return (
            <Reward
              member={member}
              rewardFormType={fieldTypeId}
              formType={type}
              record={record}
              isUploadBackgroundButtonShown={challengeType !== challengeTypes.team}
            />
          );
        }}
      </FormDataConsumer>
    );
  };

  return (
    <SimpleForm {...rest}>
      {children}
      <CustomArrayInput source="tasks">
        <FormIterator
          formTypeTogglerOptions={tasksList}
          renderForm={renderTasksForm}
          pushNewField={pushNewFieldToTasks}
          formTypeTogglerLabel="Task type"
        />
      </CustomArrayInput>
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          const { type } = formData;

          if (type === challengeTypes.daily_game) return null;

          return (
            <CustomArrayInput source="rewards" {...rest}>
              <FormIterator
                formTypeTogglerOptions={rewardsFormTypeTogglerOptions}
                renderForm={renderRewardsForm}
                pushNewField={pushNewFieldToRewards}
                formTypeTogglerLabel="Reward type"
              />
            </CustomArrayInput>
          );
        }}
      </FormDataConsumer>
    </SimpleForm>
  );
};

export default TaskForm;

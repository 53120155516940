import React from 'react';
import { Datagrid, List, TextField } from 'react-admin';
import { CustomDateField } from '../../../../commonComponents/DateField';
import CustomReportListActions from '../../../../commonComponents/ListActions/CustomReportListActions';

import SurveyReportsFilter from './SurveyReportsFilter';

const SurveyReportsList = (props) => (
  <List
    title="Survey answers"
    actions={<CustomReportListActions />}
    filters={<SurveyReportsFilter />}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField label="Survey ID" source="pack_id" />
      <TextField label="Survey name" source="pack.name" />
      <TextField label="User ID" source="user.id" />
      <TextField label="Question" source="question.text" />
      <TextField label="Answer" source="answer.answer" />
      <TextField label="Points" source="pack.survey_article[0].coins" sortable={false} />
      <CustomDateField label="Created at" source="created_at" />
    </Datagrid>
  </List>
);

export default SurveyReportsList;

import { Datagrid, List, TextField, useRefresh } from 'react-admin';

import { attachmentsStatusChoices } from '../../config/constants';

import { CustomDateField } from '../../../../commonComponents/DateField';
import AttachmentsFilter from './AttachmentsFilter';
import ChangeStatusButton from './ChangeStatusButton';
import StatusField from '../../../../commonComponents/StatusField';
import AttachmentImages from '../../../../commonComponents/AttachmentImages';
import CustomListActions from '../../../../commonComponents/ListActions/CustomListActions';

const AttachmentsList = (props) => {
  const refresh = useRefresh();

  return (
    <List
      title="Mystery Shopper Attachments"
      filters={<AttachmentsFilter />}
      sort={{ field: 'id', order: 'DESC' }}
      bulkActionButtons={false}
      actions={<CustomListActions importer={false} creation={false} />}
      {...props}
    >
      <Datagrid>
        <TextField label="ID" source="id" sortable={false} />
        <TextField label="Name" source="name" sortable={false} />
        <CustomDateField label="End Data" source="end_date" sortable={false} />
        <TextField label="User ID" source="user_id" sortable={false} />
        <StatusField label="Status" source="status" statuses={attachmentsStatusChoices} />
        <TextField label="Answer" source="open_question_answer" sortable={false} />
        <TextField label="Photo question" source="question" sortable={false} />
        <AttachmentImages label="Photos" source="attachments" sortable={false} />
        <ChangeStatusButton refreshListCallback={refresh} />
      </Datagrid>
    </List>
  );
};

export default AttachmentsList;

import PresentIcon from '@material-ui/icons/CardGiftcard';
import PresentsList from './components/PresentsList';
import PresentCreate from './components/PresentCreate';
import PresentEdit from './components/PresentEdit';
import { roles, APP_TYPES, ALL_WITHOUT_IRELAND } from '../../../config/constants';

const ENV_APP_TYPE = process.env.REACT_APP_APP_TYPE;

const options = {
  appTypes: ALL_WITHOUT_IRELAND,
  allowedRoles: [APP_TYPES.HUNGARY, APP_TYPES.BELARUS].includes(ENV_APP_TYPE)
    ? [roles.ADMIN]
    : [roles.ADMIN, roles.JTI_TEAM],
  label: 'Presents',
  subMenuName: 'bonusShop',
};

export default {
  list: PresentsList,
  create: PresentCreate,
  edit: PresentEdit,
  icon: PresentIcon,
  name: 'admin/bonus-shop/presents',
  options,
};

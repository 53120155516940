import React from 'react';
import {
  Create,
  NumberInput,
  ReferenceArrayInput,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

import { CustomDateInput } from '../../../commonComponents/CustomDateInput';
import ImageInput from '../../../commonComponents/UploadInputs/ImageInput';
import TargetGroupInput from '../../../commonComponents/TargetGroupInput';
import { s3FilePaths, statuses } from '../../../config/constants';

const BannerCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <NumberInput label="ID" source="id" disabled />
      <TextInput label="Name" source="name" validate={required()} />
      <SelectInput source="status" optionText="name" choices={statuses.slice(0, 2)} validate={required()} />
      <CustomDateInput label="Publish date" source="published_at" validate={required()} />
      <TargetGroupInput />
      <TextInput label="URL" source="url" validate={required()} />
      <ReferenceArrayInput
        label="KB categories"
        source="kb_categories"
        reference="admin/knowledge-bank/categories"
        validate={required()}
      >
        <SelectArrayInput source="id" />
      </ReferenceArrayInput>
      <ImageInput
        label="Upload banner image"
        imageInputSource="image"
        validate={required()}
        imagePath={s3FilePaths.allUploads}
      />
    </SimpleForm>
  </Create>
);

export default BannerCreate;

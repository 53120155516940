import React from 'react';
import { List, Datagrid, TextField, EditButton, DeleteButton, DateField } from 'react-admin';

import PromoCodeFilter from './PromoCodeFilter';
import { CustomDateField } from '../../../commonComponents/DateField';
import StatusField from '../../../commonComponents/StatusField';
import CustomListActions from '../../../commonComponents/ListActions/CustomListActions';

const PromoCodesList = (props) => (
  <List
    title="Promo codes"
    filters={<PromoCodeFilter />}
    bulkActionButtons={false}
    actions={<CustomListActions />}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <TextField label="Code" source="code" />
      <CustomDateField label="Created at" source="created_at" />
      <DateField label="Expires at" source="expires_at" />
      <TextField label="Coins" source="coins" />
      <StatusField label="Status" source="status" />
      <EditButton />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
);

export default PromoCodesList;

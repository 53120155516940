import React from 'react';
import { Filter, NumberInput, ReferenceInput, SelectInput } from 'react-admin';
import { CustomDateInput } from '../../../../commonComponents/CustomDateInput';
import UserAutocompleteInput from '../../../../commonComponents/UserAutocompleteInput';

const AchievementsReportsFilter = (props) => (
  <Filter {...props}>
    <UserAutocompleteInput label="User ID" source="user_id" alwaysOn />
    <ReferenceInput label="Achievement" source="achievement_id" reference="admin/achievements" alwaysOn>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <CustomDateInput label="Min completed date" source="start_date" start alwaysOn />
    <CustomDateInput label="Max completed date" source="end_date" end alwaysOn />
    <NumberInput source="min_level" label="Min level" alwaysOn />
    <NumberInput source="max_level" label="Max level" alwaysOn />
  </Filter>
);

export default AchievementsReportsFilter;

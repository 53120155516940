import React from 'react';
import { Datagrid, DeleteButton, List, EditButton, TextField } from 'react-admin';
import { CustomDateField } from '../../../commonComponents/DateField';
import StatusField from '../../../commonComponents/StatusField';
import LinkToAttachments from '../../../commonComponents/LinkToAttachments';

const LoyaltyProgramsList = (props) => (
  <List
    title="Loyalty programs"
    exporter={false}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField label="ID" source="id" />
      <TextField label="name" source="name" />
      <CustomDateField source="start_date" />
      <CustomDateField source="end_date" />
      <StatusField label="Status" source="status" />
      <EditButton />
      <LinkToAttachments pathname="/admin/loyalty-programs/attachments" recordName="program_id" />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
);

export default LoyaltyProgramsList;

import React from 'react';

import CustomArrayInput from '../../../../commonComponents/CustomArrayInput';
import FormIterator from '../../../../core/FormIterator';
import { mainScreenFadingBlockComponentToggleOptions } from '../../../../config/constants';
import FadingBlockComponent from './FadingBlockComponent';

const pushNewField = ({ fields, nextFormType }) => {
  fields.push({ type: { id: nextFormType } });
};

const renderForm = ({ field, member }) => <FadingBlockComponent member={member} type={field.type.id} />;

const FadingBlockComponentForm = React.memo(({ member }) => (
  <CustomArrayInput source={`${member}.fading_block_components`} label="Fading block components" defaultValue={[]}>
    <FormIterator
      formTypeTogglerLabel="Fading block component type"
      formTypeTogglerOptions={mainScreenFadingBlockComponentToggleOptions}
      pushNewField={pushNewField}
      renderForm={renderForm}
      maxLength={2}
    />
  </CustomArrayInput>
));

export default FadingBlockComponentForm;

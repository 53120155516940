import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

function withCardWrapper(WrappedComponent) {
  class WithCardWrapper extends React.Component {
    render() {
      return (
        <Card raised>
          <CardContent>
            <WrappedComponent {...this.props} />
          </CardContent>
        </Card>
      );
    }
  }

  return WithCardWrapper;
}

export default withCardWrapper;

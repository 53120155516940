import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import AdminLinearDiagram from '../AdminLinearDiagram';
import BALinearDiagram from '../BALinearDiagram';
import { roles } from '../../../../../config/constants';
import checkUserRole from '../../../../../helpers/checkUserRole';

const useStyles = makeStyles({
  container: {
    width: '100%',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});

const LinearDiagramPicker = (props) => {
  const classes = useStyles();
  const {
    onPeriodChange,
    onCustomFilterChange,
    onCheckboxChange,
    selectedFilters,
    selectedCustomFilterOption,
    selectedPeriod,
  } = props;

  const isJTIStaff = checkUserRole(roles.ADMIN, roles.JTI_TEAM, roles.RSM);
  const isBA = checkUserRole(roles.BA);

  const handlePeriodChange = (event) => {
    if (onPeriodChange) {
      onPeriodChange(event);
    }
  };

  const handleCustomFilterChange = (data) => {
    if (onCustomFilterChange) {
      onCustomFilterChange(data);
    }
  };

  const handleCheckboxChange = (event) => {
    if (onCheckboxChange) {
      onCheckboxChange(event);
    }
  };

  return (
    <div className={classes.container}>
      {/* eslint-disable-next-line no-nested-ternary */}
      {isJTIStaff ? (
        <AdminLinearDiagram
          onPeriodChange={handlePeriodChange}
          onCustomFilterChange={handleCustomFilterChange}
          onCheckboxChange={handleCheckboxChange}
          selectedFilters={selectedFilters}
          selectedCustomFilterOption={selectedCustomFilterOption}
          selectedPeriod={selectedPeriod}
        />
      ) : isBA ? (
        <BALinearDiagram
          onPeriodChange={handlePeriodChange}
          onCheckboxChange={handleCheckboxChange}
          selectedFilters={selectedFilters}
          selectedPeriod={selectedPeriod}
        />
      ) : null}
    </div>
  );
};

export default LinearDiagramPicker;

import { stringify } from 'query-string';
import { fetchUtils } from 'react-admin';

export default (apiUrl, httpClient = fetchUtils.fetchJson) => ({
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      ...params.filter,
      sort: field,
      order,
      offset: (page - 1) * perPage,
      limit: perPage,
    };

    let url;

    if (resource === 'users-answers') {
      url = `${apiUrl}/admin/interactive-articles/${resource}?${stringify(query)}`;
    } else {
      url = `${apiUrl}/${resource}?${stringify(query)}`;
    }

    return httpClient(url).then(({ json }) => {
      let data;
      let total;
      if (resource.includes('settings')) {
        data = [json];
        total = 1;
      } else {
        const dataProperty = resource.split('/').pop();
        data = Array.isArray(json) ? json : json[dataProperty] || json.data;
        total = json.total !== undefined ? json.total : json.length;
      }

      return { data, total };
    });
  },

  getMany: (resource, params) => {
    const url =
      resource.includes('autocomplete') && params.ids && params.ids.length === 1
        ? `${apiUrl}/${resource}?q=${params.ids[0]}`
        : `${apiUrl}/${resource}`;

    return httpClient(url).then(({ json }) => {
      const dataProperty = resource.split('/').pop();
      const data = Array.isArray(json) ? json : json[dataProperty] || json.data;
      return { data };
    });
  },

  getOne: (resource, params) => {
    const url = `${apiUrl}/${resource}/${params.id}`;

    return httpClient(url).then(({ json }) => ({ data: json }));
  },

  getManyReference: (resource, params) => {
    const url = resource.includes('products')
      ? `${apiUrl}/${resource}?providerId=${params.id}`
      : `${apiUrl}/${resource}`;

    return httpClient(url).then(({ json }) => {
      const data = Array.isArray(json) ? json : json[resource.split('/').pop()];
      const total = json.total !== undefined ? json.total : json.length;
      return { data, total };
    });
  },

  create: (resource, params) => {
    let url = `${apiUrl}/${resource}`;
    const options = { method: 'POST' };

    if (resource === 'admin/transactions') {
      const { amountOfUsers, ...body } = params.data;
      if (amountOfUsers === 'custom') {
        const { file_key, type, shouldIncreaseMonthlyEarned } = body;
        const formData = new FormData();
        formData.append('file_key', file_key);
        formData.append('type', type);
        formData.append('has_custom_coins', 'true');
        if (shouldIncreaseMonthlyEarned) {
          formData.append('shouldIncreaseMonthlyEarned', 'true');
        }
        url += '/group';
        options.body = formData;
      } else if (amountOfUsers === 'group') {
        const { file_key, type, coins, shouldIncreaseMonthlyEarned } = body;
        const formData = new FormData();
        formData.append('file_key', file_key);
        formData.append('type', type);
        formData.append('coins', coins);
        if (shouldIncreaseMonthlyEarned) {
          formData.append('shouldIncreaseMonthlyEarned', 'true');
        }
        url += '/group';
        options.body = formData;
      } else {
        const isForAllUsers = amountOfUsers === 'all';
        options.body = JSON.stringify({
          ...body,
          forAll: isForAllUsers,
          id: isForAllUsers ? null : body.id,
        });
      }
    } else {
      options.body = params.data instanceof FormData ? params.data : JSON.stringify(params.data);
    }

    return httpClient(url, options).then(({ json }) => ({ data: { ...params.data, id: json.id } }));
  },

  update: (resource, params) => {
    const url = `${apiUrl}/${resource}`;
    const options = {
      method: 'PUT',
      body: JSON.stringify(params.data),
    };

    return httpClient(url, options).then(({ json }) => ({ data: { ...params.data, id: json.id } }));
  },

  delete: (resource, params) => {
    const url = `${apiUrl}/${resource}`;
    const options = {
      method: 'DELETE',
      body: JSON.stringify({ id: params.id }),
    };

    return httpClient(url, options).then(() => ({ data: { id: params.id } }));
  },
});

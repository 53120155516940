import TargetGroupIcon from '@material-ui/icons/Group';
import SimulatorReactionsList from './components/SimulatorReactionsList';
import simulatorReactionsCreate from './components/SimulatorReactionsCreate';
import SimulatorReactionsEdit from './components/SimulatorReactionsEdit';
import { roles, APP_TYPES } from '../../config/constants';

const options = {
  appTypes: [APP_TYPES.BELARUS],
  allowedRoles: [roles.ADMIN],
  label: 'Simulator Reactions',
  subMenuName: 'manage',
};

export default {
  list: SimulatorReactionsList,
  create: simulatorReactionsCreate,
  edit: SimulatorReactionsEdit,
  icon: TargetGroupIcon,
  name: 'admin/reactions',
  options,
};

import React from 'react';
import { Edit } from 'react-admin';
import LepForm from './LepForm';

const LepEdit = (props) => (
  <Edit title="Edit LEP" undoable={false} {...props}>
    <LepForm />
  </Edit>
);

export default LepEdit;

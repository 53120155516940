import React from 'react';
import {
  ArrayInput,
  Edit,
  FormDataConsumer,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  ReferenceInput,
  TextInput,
  required,
} from 'react-admin';
import ImageInput from '../../../commonComponents/UploadInputs/ImageInput';
import { s3FilePaths } from '../../../config/constants';

const SimulatorReactionsEdit = (props) => (
  <Edit title="Edit reaction pack" undoable={false} {...props}>
    <SimpleForm redirect="list">
      <TextInput label="Name" source="name" validate={required()} />
      <ArrayInput source="images">
        <SimpleFormIterator>
          <FormDataConsumer>
            {({ getSource }) => (
              <ImageInput
                label="Image"
                imageInputSource={getSource('image')}
                imagePath={s3FilePaths.allUploads}
                validate={required()}
              />
            )}
          </FormDataConsumer>
          <ReferenceInput label="Type" source="type_id" reference="admin/reactions/types">
            <SelectInput optionText="id" />
          </ReferenceInput>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);

export default SimulatorReactionsEdit;

import React from 'react';
import { Cell, Pie, PieChart } from 'recharts';

const CircularDiagram = ({ data, colors, selectedItems, handleClick }) => {
  const handlePieClick = (node, index, event) => {
    if (handleClick) {
      handleClick(node, index, event);
    }
    event.stopPropagation();
  };

  return (
    <PieChart width={174} height={174}>
      <Pie
        data={data}
        dataKey="value"
        outerRadius={87}
        innerRadius={63}
        startAngle={90}
        endAngle={-270}
        isAnimationActive={false}
        onClick={handlePieClick}
      >
        {data.map((item, index) => {
          const isItemSelected = !selectedItems.length || selectedItems.findIndex((x) => x.name === item.name) !== -1;

          return <Cell key={`${item.name}-${index}`} fill={colors[item.name]} fillOpacity={isItemSelected ? 1 : 0.2} />;
        })}
      </Pie>
    </PieChart>
  );
};

export default CircularDiagram;

import React from 'react';
import { Edit, SimpleForm, TextInput, SelectInput } from 'react-admin';
import { confirmedPasswordValidation } from '../../../helpers/validators';
import { statuses } from '../../../config/constants';

const AdministratorEdit = (props) => (
  <Edit title="Edit Administrator" undoable={false} {...props}>
    <SimpleForm>
      <TextInput label="ID" source="id" disabled />
      <TextInput label="Username" source="username" />
      <TextInput label="Name" source="name" />
      <TextInput label="Password" source="password" type="password" />
      <TextInput
        label="Confirm Password"
        source="confirm_password"
        validate={confirmedPasswordValidation}
        type="password"
      />
      <SelectInput source="status" optionText="name" choices={statuses.slice(0, 3)} />
    </SimpleForm>
  </Edit>
);

export default AdministratorEdit;

import React from 'react';
import {
  Create,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';
import TargetGroupInput from '../../../../commonComponents/TargetGroupInput';
import ImageInput from '../../../../commonComponents/UploadInputs/ImageInput';
import { bonusShopCategoryTypes, s3FilePaths } from '../../../../config/constants';

const CategoryCreate = (props) => (
  <Create title="Add Category" {...props}>
    <SimpleForm redirect="list">
      <TextInput label="Name" source="name" validate={required()} />
      <ImageInput imageInputSource="photo" validate={required()} imagePath={s3FilePaths.allUploads} />
      <NumberInput label="Priority" source="priority" />
      <ReferenceInput
        label="Transaction Type"
        source="transaction_type_id"
        reference="admin/transactions/types"
        perPage={100}
      >
        <SelectInput validate={required()} />
      </ReferenceInput>
      <TargetGroupInput />
      <ReferenceInput label="Type" source="type" reference="admin/bonus-shop/category-types">
        <SelectInput validate={required()} />
      </ReferenceInput>
      <FormDataConsumer>
        {({ formData }) => {
          const { type } = formData;
          return type === bonusShopCategoryTypes.TRANSFER ? (
            <ImageInput
              label="Header Image"
              imageInputSource="header_image"
              imagePath={s3FilePaths.allUploads}
              validate={required()}
            />
          ) : null;
        }}
      </FormDataConsumer>
    </SimpleForm>
  </Create>
);

export default CategoryCreate;

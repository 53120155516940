import React from 'react';
import { BooleanInput, Create, required, SelectInput, SimpleForm, TextInput, useTranslate } from 'react-admin';
import { CustomDateInput } from '../../../commonComponents/CustomDateInput';
import { cpaProgramSelectStatuses } from '../../../config/constants';
import UploadFileInput from '../../../commonComponents/UploadInputs/UploadFileInput';

const ProgramCreate = (props) => {
  const translate = useTranslate();

  return (
    <Create title={translate('root.cpa.create.title')} {...props}>
      <SimpleForm redirect="list">
        <TextInput label="Name" source="name" validate={required()} />
        <CustomDateInput label="Moderation end date" source="end_date" />

        <SelectInput source="status" optionText="name" choices={cpaProgramSelectStatuses} />

        <UploadFileInput
          label={translate('root.cpa.create.importLabel')}
          fileInputSource="file_link"
          buttonText={translate('root.cpa.create.importButton')}
          validate={required()}
        />

        <BooleanInput label={translate('root.cpa.create.toggle')} source="allow_grade" />
      </SimpleForm>
    </Create>
  );
};

export default ProgramCreate;

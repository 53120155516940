import { Box, Toolbar, Typography } from '@material-ui/core';
import React from 'react';
import {
  BooleanInput,
  DeleteButton,
  FormWithRedirect,
  NumberInput,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  TextInput,
} from 'react-admin';
import { ColorInput } from 'react-admin-color-input';
import { CustomDateInput } from '../../../commonComponents/CustomDateInput';
import ImageInput from '../../../commonComponents/UploadInputs/ImageInput';
import { s3FilePaths } from '../../../config/constants';
import { isIreland } from '../../../helpers/appTypeHelper';

const LepForm = (props) => (
  <FormWithRedirect
    {...props}
    render={(formProps) => (
      <form>
        <Box p="1em" display={{ md: 'block', lg: 'flex' }}>
          <Box flex={1} mr={{ md: 0, lg: '1em' }}>
            <Typography variant="h6" gutterBottom>
              LEP
            </Typography>
            <Box display="flex">
              <Box flex={2} mr="0.5em">
                <TextInput source="name" validate={required()} fullWidth />
              </Box>
              <Box flex={1} ml="0.5em">
                <NumberInput source="priority" fullWidth />
              </Box>
            </Box>
            <TextInput source="rules_link" validate={required()} fullWidth />
            <TextInput source="help_link" validate={required()} fullWidth />
            <Box display="flex">
              <Box flex={1} mr="0.5em">
                <CustomDateInput label="Start date (00:00)" source="start_date" validate={required()} fullWidth />
              </Box>
              <Box flex={1} ml="0.5em">
                <CustomDateInput label="End date (12:00)" source="end_date" validate={required()} fullWidth />
              </Box>
            </Box>
          </Box>

          <Box flex={1} mr={{ md: 0, lg: '1em' }}>
            <Typography variant="h6" gutterBottom>
              Colors
            </Typography>

            <Box display="flex">
              <Box mr="0.5em">
                <ColorInput source="accent_color" validate={required()} />
              </Box>
              <Box ml="0.5em">
                <ColorInput source="background_color" validate={required()} />
              </Box>
            </Box>
            <Box display="flex">
              <Box mr="0.5em">
                <ColorInput source="text_color" validate={required()} defaultValue="#000000" />
              </Box>
              <Box ml="0.5em">
                <ColorInput source="button_text_color" validate={required()} defaultValue="#ffffff" />
              </Box>
            </Box>

            <Typography variant="h6" gutterBottom>
              Images
            </Typography>

            <Box display="flex">
              <Box mr="0.5em">
                <ImageInput imageInputSource="main_image" imagePath={s3FilePaths.allUploads} validate={required()} />
              </Box>
              <Box ml="0.5em">
                <ImageInput
                  imageInputSource="background_image"
                  imagePath={s3FilePaths.allUploads}
                  validate={required()}
                />
              </Box>
              <Box ml="0.5em">
                <ImageInput
                  imageInputSource="input_background_image"
                  imagePath={s3FilePaths.allUploads}
                  validate={required()}
                />
              </Box>
            </Box>
          </Box>

          <Box flex={1}>
            <Typography variant="h6" gutterBottom>
              Transaction
            </Typography>

            <NumberInput source="coins" validate={required()} fullWidth />
            <ReferenceInput
              label="Transaction Type"
              source="transaction_type_id"
              reference="admin/transactions/types"
              perPage={100}
              fullWidth
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
            {isIreland() && (
              <BooleanInput
                style={{ width: '300px' }}
                label="Included in the Progress bar"
                source="progress_bar_enable"
              />
            )}
          </Box>
        </Box>

        <Toolbar>
          <Box display="flex" justifyContent="space-between" width="100%">
            <SaveButton
              saving={formProps.saving}
              disabled={formProps.submitting || formProps.pristine}
              handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              redirect="list"
            />
            <DeleteButton record={formProps.record} />
          </Box>
        </Toolbar>
      </form>
    )}
  />
);

export default LepForm;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';
import { Link } from 'react-router-dom';
import AttachmentIcon from '@material-ui/icons/AttachFile';
import { stringify } from 'query-string';

const useStyles = makeStyles({
  link: {
    display: 'inline-flex',
    alignItems: 'center',
  },
});

const LintToAttachments = ({ label, record, pathname, recordName }) => {
  const classes = useStyles();

  return (
    <Button
      label={label}
      className={classes.link}
      component={Link}
      to={{
        pathname,
        search: stringify({ page: 1, perPage: 10, filter: JSON.stringify({ [recordName]: record.id }) }),
      }}
    >
      <AttachmentIcon />
    </Button>
  );
};

export default LintToAttachments;

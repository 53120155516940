import React from 'react';
import { AutocompleteInput, ReferenceInput } from 'react-admin';

const formatText = (user) => {
  if (!user?.surname) {
    return '';
  }
  const fullName = `${user.name} ${user.surname}`;

  if (user.id) {
    return `(${user.id}) ${fullName}`;
  }

  return fullName;
};

const UserAutocompleteInput = ({
  label,
  source = 'user_id',
  reference = 'admin/users/autocomplete',
  ...otherProps
}) => (
  <ReferenceInput label={label} source={source} reference={reference} {...otherProps}>
    <AutocompleteInput
      optionText={formatText}
      options={{
        suggestionsContainerProps: {
          style: { zIndex: 1301 },
        },
      }}
    />
  </ReferenceInput>
);

export default UserAutocompleteInput;

import React from 'react';
import { Datagrid, DeleteButton, List, TextField } from 'react-admin';
import { CustomDateField } from '../../../../commonComponents/DateField';
import StatusField from '../../../../commonComponents/StatusField';
import { ordersStatuses } from '../../../../config/constants';
import OrderRevertButton from './OrderRevertButton';

import OrdersFilter from './OrdersFilter';

const OrdersList = (props) => (
  <List
    title="Orders"
    filters={<OrdersFilter />}
    exporter={false}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <TextField label="Name of the gift" source="name" sortable={false} />
      <TextField label="Username" source="user_name" sortable={false} />
      <TextField label="Phone" source="phone" sortable={false} />
      <TextField label="Price" source="price" />
      <StatusField source="status" statuses={ordersStatuses} />
      <CustomDateField label="Date" source="created_at" />
      <OrderRevertButton />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
);

export default OrdersList;

import React from 'react';
import { Filter, TextInput, SelectArrayInput } from 'react-admin';
import { CustomDateInput } from '../../../../commonComponents/CustomDateInput';
import UserAutocompleteInput from '../../../../commonComponents/UserAutocompleteInput';

const gameTypes = [
  {
    id: 3,
    name: 'Game with user',
  },
  {
    id: 2,
    name: 'Game with bot',
  },
  {
    id: 8,
    name: 'Solo mode games',
  },
  {
    id: 5,
    name: 'Teaser game',
  },
  {
    id: 10003,
    name: 'No bonus games',
  },
  {
    id: -1,
    name: 'Solo mode bonus games',
  },
  {
    id: -2,
    name: 'Solo mode not bonus games',
  },
];

const QuizReportsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="User phone" source="user_phone" alwaysOn />
    <UserAutocompleteInput label="User ID" source="user_id" alwaysOn />
    <CustomDateInput label="Choose start date" source="start_date" start alwaysOn />
    <CustomDateInput label="Choose end date" source="end_date" end alwaysOn />
    <SelectArrayInput
      label="Type"
      source="type_ids"
      optionText="name"
      choices={gameTypes}
      allowEmpty={false}
      alwaysOn
    />
  </Filter>
);

export default QuizReportsFilter;

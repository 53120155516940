import React from 'react';
import { BooleanInput, maxLength, required, regex, SimpleFormIterator, TextInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import { s3FilePaths } from '../../../config/constants';
import { mysteryShopperQuestionTypes } from '../config/constants';

import {
  mysteryShopperMultipleQuestionValidate,
  mysteryShopperSingleQuestionValidate,
} from '../../../helpers/validators';

import ImageInput from '../../../commonComponents/UploadInputs/ImageInput';
import CustomArrayInput from '../../../commonComponents/CustomArrayInput';

const useStyles = makeStyles({
  customFormField: {
    width: 256,
  },
});

const Question = React.memo(({ member, taskFormType }) => {
  const classes = useStyles();

  const defaultFields = [
    { answer_text: '', is_correct: false },
    { answer_text: '', is_correct: false },
    { answer_text: '', is_correct: false },
  ];

  let label = 'Question text';

  if (taskFormType === mysteryShopperQuestionTypes.multiplyChoiceQuestion) {
    label = 'Question Multiple choice';
  } else if (taskFormType === mysteryShopperQuestionTypes.singleChoiceQuestion) {
    label = 'Question Single choice';
  }

  return (
    <>
      <TextInput
        source={`${member}.question_text`}
        label={label}
        className={classes.customFormField}
        validate={[required(), maxLength(65)]}
      />
      {taskFormType === mysteryShopperQuestionTypes.multiplyChoiceQuestion && (
        <CustomArrayInput
          label="Answers"
          source={`${member}.answers`}
          validate={mysteryShopperMultipleQuestionValidate}
          defaultValue={defaultFields}
        >
          <SimpleFormIterator>
            <TextInput label="Answer" source="answer_text" validate={[required(), maxLength(85)]} />
            <BooleanInput label="Is correct" source="is_correct" />
          </SimpleFormIterator>
        </CustomArrayInput>
      )}
      {taskFormType === mysteryShopperQuestionTypes.singleChoiceQuestion && (
        <CustomArrayInput
          label="Answers"
          source={`${member}.answers`}
          validate={[mysteryShopperSingleQuestionValidate]}
          defaultValue={defaultFields}
        >
          <SimpleFormIterator>
            <TextInput label="Answer" source="answer_text" validate={[required(), maxLength(60)]} />
            <BooleanInput label="Is correct" source="is_correct" />
          </SimpleFormIterator>
        </CustomArrayInput>
      )}
      {taskFormType === mysteryShopperQuestionTypes.priceQuestion && (
        <>
          <ImageInput
            label="Question image"
            imageInputSource={`${member}.question_image`}
            imagePath={s3FilePaths.mysteryShopper}
          />
          <TextInput
            source={`${member}.answers[0].answer_text`}
            label={'Correct answer'}
            className={classes.customFormField}
            validate={[required(), regex(/^[0-9]{1,2}([.][0-9]{2})?$/, 'Must be 1.11 or 11.11 format')]}
          />
          <BooleanInput label="Is correct" source={`${member}.answers[0].is_correct`} defaultValue={true} disabled />
          <BooleanInput source={`${member}.show_price_disclaimer`} label="Show price disclaimer" />
        </>
      )}
    </>
  );
});

export default Question;

import React, { useEffect } from 'react';
import { SelectInput, TextInput } from 'react-admin';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import ImageInput from '../../../commonComponents/UploadInputs/ImageInput';
import { quizzesQuestionTypes, s3FilePaths } from '../../../config/constants';

const useStyles = makeStyles({
  questionFormWrapper: {
    width: 276,
    marginTop: 20,
    padding: 10,
    border: '1px solid #0000001f',
  },
});

const correctAnswers = [
  { id: 1, name: '1' },
  { id: 2, name: '2' },
  { id: 3, name: '3' },
  { id: 4, name: '4' },
];

const textAnswersList = ({ fields }) => (
  <>
    {fields.map((member) => (
      <div key={`${member}.answer`}>
        <TextInput key={member} source={`${member}.answer`} label="Answer for question" />
      </div>
    ))}
  </>
);

const QuestionForm = ({ name, populate, validate, ...rest }) => {
  const form = useForm();
  const classes = useStyles();

  useEffect(() => {
    if (!populate) return;
    console.log('triggere');
    for (let i = 0; i < 15; i += 1) {
      form.mutators.push(name, { type_id: quizzesQuestionTypes.commonText });
      for (let j = 0; j < 4; j += 1) {
        form.mutators.push(`${name}[${i}].answers`, {});
      }
    }
  }, [form.mutators, name, populate]);

  return (
    <FieldArray name={name} validate={validate}>
      {({ fields, meta: { error, submitFailed } }) =>
        fields.map((field, index) => (
          <div key={field} className={classes.questionFormWrapper}>
            <Typography variant="h6">Question {index + 1}</Typography>
            <TextInput source={`${name}[${index}].text`} label="Question" {...rest} />
            {submitFailed && error && error.questions && error.questions[index] && error.questions[index].text && (
              <Typography color="error">{error.questions[index].text}</Typography>
            )}
            <FieldArray name={`${name}[${index}].answers`} component={textAnswersList} />
            <SelectInput
              source={`${name}[${index}].correct_answers`}
              label="Correct answer"
              choices={correctAnswers}
              format={(value) => (value ? value[0] : '')}
              parse={(value) => [value]}
            />
            {submitFailed &&
              error &&
              error.questions &&
              error.questions[index] &&
              error.questions[index].correct_answers &&
              error.questions[index].correct_answers._error && (
                <Typography color="error">{error.questions[index].correct_answers._error}</Typography>
              )}
            <ImageInput imagePath={s3FilePaths.quiz} imageInputSource={`${name}[${index}].image`} />
            {submitFailed && error && error.questions && error.questions[index] && error.questions[index].image && (
              <Typography color="error">{error.questions[index].image}</Typography>
            )}
          </div>
        ))
      }
    </FieldArray>
  );
};

export default QuestionForm;

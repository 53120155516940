import React from 'react';
import { Typography } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import formatNumber from '../../../helpers/numberFormatter';

const useStyles = makeStyles({
  numbers: {
    fontSize: '22px',
  },

  description: {
    fontSize: '12px',
    color: '#B1B1B1',
  },

  title: {
    paddingTop: '10px',
    lineHeight: '14px',
    fontSize: '12px',
  },
});

const StatisticsItem = ({ data, title, description }) => {
  const classes = useStyles();

  return (
    <div>
      <Typography className={classes.numbers}>{formatNumber(data)}</Typography>
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.description}>{description}</Typography>
    </div>
  );
};

export default StatisticsItem;

import React from 'react';
import { Datagrid, TextField, List, EditButton, DeleteButton } from 'react-admin';
import CustomListActions from '../../../commonComponents/ListActions/CustomListActions';

const ButtonsWrapper = (props) => {
  const { id } = props.record;
  const isDefaultTier = id === 1;
  return <DeleteButton mutationMode="optimistic" {...props} disabled={isDefaultTier} />;
};

const TiersList = (props) => (
  <List
    title="Tiers"
    bulkActionButtons={false}
    actions={<CustomListActions importer={false} />}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField label="ID" source="id" />
      <TextField label="Name" source="name" />
      <TextField label="Discount" source="discount" />
      <EditButton />
      <ButtonsWrapper />
    </Datagrid>
  </List>
);

export default TiersList;

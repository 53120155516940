import React from 'react';
import { Create, SelectInput, TextInput } from 'react-admin';
import { statuses } from '../../../config/constants';
import QuizForm from '../../../commonComponents/QuizForm';

const SurveyCreate = (props) => (
  <Create title="Create survey" {...props}>
    <QuizForm redirect="list" quizType="survey">
      <TextInput label="Name" source="name" />
      <SelectInput source="status" optionText="name" choices={statuses.slice(0, 2)} />
    </QuizForm>
  </Create>
);

export default SurveyCreate;

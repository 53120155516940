import React from 'react';
import {
  ArrayInput,
  BooleanInput,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';
import { useForm } from 'react-final-form';
import { ColorInput } from 'react-admin-color-input';
import ImageInput from '../../../commonComponents/UploadInputs/ImageInput';
import {
  tasksTypesWithKBCategory,
  taskTypeChoices,
  taskTypesColdBeCompletedOnlyInRowByDays,
  taskTypesWithBrand,
  taskTypesWithInRow,
  taskTypesWithoutByDays,
} from '../config/constants';
import { s3FilePaths } from '../../../config/constants';

const TaskForm = () => {
  const form = useForm();

  return (
    <ArrayInput source="tasks">
      <SimpleFormIterator>
        <TextInput label="Name" source="name" validate={required()} />
        <SelectInput
          label="Task type"
          optionText="name"
          choices={taskTypeChoices}
          source="type_id"
          validate={required()}
        />

        <FormDataConsumer>
          {({ scopedFormData, getSource }) => {
            if (!scopedFormData) {
              return null;
            }

            if (tasksTypesWithKBCategory.includes(scopedFormData?.type_id)) {
              return (
                <ReferenceInput
                  label="KB Category"
                  source={getSource('kb_category_id')}
                  reference="admin/knowledge-bank/categories"
                >
                  <SelectInput optionText="name" validate={required()} />
                </ReferenceInput>
              );
            }

            form.change(getSource('kb_category_id'), null);
            return null;
          }}
        </FormDataConsumer>
        <NumberInput label="Number of actions" source="number_of_actions" validate={required()} min={1} />
        <FormDataConsumer>
          {({ scopedFormData, getSource }) => {
            if (!scopedFormData) {
              return null;
            }

            const showToggler = taskTypesWithInRow.includes(scopedFormData?.type_id);

            if (showToggler) {
              return <BooleanInput label="In row" source={getSource('in_row')} />;
            }

            form.change(getSource('in_row'), false);
            return null;
          }}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ scopedFormData, getSource }) => {
            if (!scopedFormData) {
              return null;
            }

            const taskType = scopedFormData?.type_id;

            if (!taskType) {
              return null;
            }

            const onlyByDays = taskTypesColdBeCompletedOnlyInRowByDays.includes(taskType);
            const isInRowEnabled = scopedFormData?.in_row;

            if (isInRowEnabled && onlyByDays) {
              form.change(getSource('in_row_by_days'), true);
              return (
                <BooleanInput
                  label="Row by Days"
                  source={getSource('in_row_by_days')}
                  defaultValue="true"
                  disabled={true}
                />
              );
            }

            const isDaysEnabled = !taskTypesWithoutByDays.includes(taskType);

            if (isInRowEnabled && isDaysEnabled) {
              return <BooleanInput label="Row by Days" source={getSource('in_row_by_days')} defaultValue="true" />;
            }

            form.change(getSource('in_row_by_days'), false);
            return null;
          }}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ getSource, scopedFormData }) =>
            taskTypesWithBrand.includes(scopedFormData?.type_id) && (
              <ReferenceInput
                label="Brand"
                source={getSource('kb_brand_id')}
                reference="admin/knowledge-bank/brands"
                allowEmpty
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ getSource }) => (
            <ImageInput
              label="Task Image"
              imageInputSource={getSource('task_image')}
              imagePath={s3FilePaths.achievementTasks}
              validate={required()}
            />
          )}
        </FormDataConsumer>
        <ColorInput label="Text color" source="text_color" validate={required()} />
        <ColorInput label="Card color" source="card_color" validate={required()} />
        <ColorInput label="Progress color" source="progress_color" validate={required()} />
        <ColorInput label="Get reward color" source="reward_color" validate={required()} />
        <FormDataConsumer>
          {({ getSource }) => (
            <ImageInput
              label="Reward Image"
              imageInputSource={getSource('reward_image')}
              imagePath={s3FilePaths.achievementTasks}
              validate={required()}
            />
          )}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export default TaskForm;

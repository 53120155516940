import React from 'react';
import { Datagrid, DeleteButton, List, NumberField, ReferenceField, TextField } from 'react-admin';
import AccountNumberFilter from './AccountNumberFilter';
import CustomListActions from '../../../commonComponents/ListActions/CustomListActions';

const AccountNumberList = (props) => (
  <List
    title="Account numbers"
    actions={<CustomListActions />}
    exporter={false}
    filters={<AccountNumberFilter />}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid rowClick="edit">
      <NumberField label="ID" source="id" />
      <TextField label="Account number" source="account_codes" />
      <TextField label="Tier" source="tier.name" />
      <NumberField label="BA ID" source="user_id" />
      <ReferenceField label="BA" reference="admin/managers" source="user_id">
        <TextField source="username" />
      </ReferenceField>
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
);

export default AccountNumberList;

export const achievementTaskTypes = {
  kBEntitiesCompleteNumber: 1,
  kBEntitiesWonNumber: 2,
  quizzesPlayedNumber: 3,
  quizzesWonNumber: 4,
  soloQuizzesPlayedNumber: 5,
  soloQuizzesWonNumber: 6,
  dailyQuestionsPlayedNumber: 7,
  dailyQuestionsWonNumber: 8,
  singleModePlayedNumber: 9,
  singleModeWonNumber: 10,
  eventModePlayedNumber: 11,
  eventModeWonNumber: 12,
  multibrandQuizzesWonNumber: 13,
  multibrandQuizzesPlayedNumber: 14,
  specialQuizzesWonNumber: 15,
  specialQuizzesPlayedNumber: 16,
  lottery: 17,
  quests: 18,
  sales_tips: 19,
  promoCode: 20,
  inviteCode: 21,
  leaderBord: 22,
  bonusShopСoins: 23,
  bonusShopOrders: 24,
};

export const taskTypesWithBrand = [
  achievementTaskTypes.kBEntitiesCompleteNumber,
  achievementTaskTypes.kBEntitiesWonNumber,
  achievementTaskTypes.quizzesPlayedNumber,
  achievementTaskTypes.quizzesWonNumber,
  achievementTaskTypes.soloQuizzesPlayedNumber,
  achievementTaskTypes.soloQuizzesWonNumber,
  achievementTaskTypes.specialQuizzesWonNumber,
  achievementTaskTypes.specialQuizzesPlayedNumber,
];

export const taskTypesWithInRow = [
  achievementTaskTypes.kBEntitiesCompleteNumber,
  achievementTaskTypes.kBEntitiesWonNumber,
  achievementTaskTypes.quizzesPlayedNumber,
  achievementTaskTypes.quizzesWonNumber,
  achievementTaskTypes.soloQuizzesPlayedNumber,
  achievementTaskTypes.soloQuizzesWonNumber,
  achievementTaskTypes.dailyQuestionsPlayedNumber,
  achievementTaskTypes.dailyQuestionsWonNumber,
  achievementTaskTypes.eventModePlayedNumber,
  achievementTaskTypes.eventModeWonNumber,
  achievementTaskTypes.specialQuizzesWonNumber,
  achievementTaskTypes.specialQuizzesPlayedNumber,
  achievementTaskTypes.lottery,
];

export const taskTypesColdBeCompletedOnlyInRowByDays = [
  achievementTaskTypes.quizzesPlayedNumber,
  achievementTaskTypes.soloQuizzesPlayedNumber,
  achievementTaskTypes.dailyQuestionsPlayedNumber,
  achievementTaskTypes.dailyQuestionsWonNumber,
  achievementTaskTypes.eventModePlayedNumber,
  achievementTaskTypes.eventModeWonNumber,
  achievementTaskTypes.specialQuizzesWonNumber,
  achievementTaskTypes.specialQuizzesPlayedNumber,
  achievementTaskTypes.lottery,
];

export const taskTypesWithoutByDays = [achievementTaskTypes.kBEntitiesWonNumber];

export const tasksTypesWithKBCategory = [
  achievementTaskTypes.kBEntitiesWonNumber,
  achievementTaskTypes.kBEntitiesCompleteNumber,
];

export const taskTypeChoices = [
  { id: achievementTaskTypes.kBEntitiesCompleteNumber, name: 'Number of completed KB entities' },
  { id: achievementTaskTypes.kBEntitiesWonNumber, name: 'Number of won KB entities' },
  { id: achievementTaskTypes.quizzesPlayedNumber, name: 'Number of played quizzes' },
  { id: achievementTaskTypes.quizzesWonNumber, name: 'Number of won quizzes' },
  { id: achievementTaskTypes.soloQuizzesPlayedNumber, name: 'Number of solo quizzes played' },
  { id: achievementTaskTypes.soloQuizzesWonNumber, name: 'Number of won solo quizzes' },
  { id: achievementTaskTypes.dailyQuestionsPlayedNumber, name: 'Number of QoD completed' },
  { id: achievementTaskTypes.dailyQuestionsWonNumber, name: 'Number of won QoD' },
  { id: achievementTaskTypes.singleModePlayedNumber, name: 'Number of played single mod' },
  { id: achievementTaskTypes.singleModeWonNumber, name: 'Number of won single mod' },
  { id: achievementTaskTypes.eventModePlayedNumber, name: 'Number of played event mod' },
  { id: achievementTaskTypes.eventModeWonNumber, name: 'Number of won event mod' },
  { id: achievementTaskTypes.multibrandQuizzesPlayedNumber, name: 'Number of played multibrand quizzes' },
  { id: achievementTaskTypes.multibrandQuizzesWonNumber, name: 'Number of won multibrand quizzes' },
  { id: achievementTaskTypes.specialQuizzesWonNumber, name: 'Number of won special quizzes' },
  { id: achievementTaskTypes.specialQuizzesPlayedNumber, name: 'Number of played special quizzes' },
  { id: achievementTaskTypes.lottery, name: 'Number of lotteries' },
  { id: achievementTaskTypes.quests, name: 'Number of challenges' },
  { id: achievementTaskTypes.sales_tips, name: 'Number of sales tips' },
  { id: achievementTaskTypes.promoCode, name: 'Number of activated promo codes' },
  { id: achievementTaskTypes.inviteCode, name: 'Number of activated invite codes' },
  { id: achievementTaskTypes.leaderBord, name: 'Top 3 in leader bord' },
  { id: achievementTaskTypes.bonusShopСoins, name: 'Number of spent coins' },
  { id: achievementTaskTypes.bonusShopOrders, name: 'Number of orders in BS' },
];

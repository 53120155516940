import React from 'react';
import { Filter, SelectInput } from 'react-admin';
import UserAutocompleteInput from '../../../../commonComponents/UserAutocompleteInput';

const AttachmentsFilter = ({ tasks, ...props }) => (
  <Filter {...props}>
    <UserAutocompleteInput label="User" source="user_id" reference="admin/users/autocomplete" alwaysOn />

    {tasks && <SelectInput label="Tasks" source="task" choices={tasks} reference="admin/quests/attachments" alwaysOn />}
  </Filter>
);

export default AttachmentsFilter;

import React from 'react';
import { useNotify } from 'react-admin';
import httpClient from '../../../../core/httpClient';
import { serverApiUrl } from '../../../../config/constants';

export const OutletNewSyncTest = () => {
  const notify = useNotify();
  const onClick = () => {
    const endpointPathName = '';
    const url = `${serverApiUrl}${endpointPathName}/admin/outlets/sync-test`;
    const options = { method: 'GET' };

    httpClient(url, options)
      .then((res) => {
        window.location.href = res.json.url;
      })
      .catch((err) => {
        console.error(err);
        notify("Error: file link wasn't loaded", 'warning');
      });
  };

  return (
    <button style={{ height: 50 }} onClick={onClick}>
      Download
    </button>
  );
};

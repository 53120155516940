import MultibrandQuizIcon from '@material-ui/icons/List';
import { APP_TYPES, roles } from '../../../config/constants';
import QuizRatesReportsList from './components/QuizRatesReportsList';

const options = {
  appTypes: [APP_TYPES.HUNGARY],
  allowedRoles: [roles.ADMIN],
  label: 'Quiz rates',
  subMenuName: 'reports',
};

export default {
  list: QuizRatesReportsList,
  icon: MultibrandQuizIcon,
  name: 'admin/reports/quizzes-rates',
  options,
};

import DailyRewardsIcon from '@material-ui/icons/DateRange';
import StepsList from './components/StepsList';
import StepCreate from './components/StepCreate';
import StepEdit from './components/StepEdit';
import { roles, APP_TYPES } from '../../config/constants';

const options = {
  appTypes: [APP_TYPES.HUNGARY],
  allowedRoles: [roles.ADMIN],
  label: 'Daily Rewards',
  subMenuName: 'content',
};

export default {
  list: StepsList,
  create: StepCreate,
  edit: StepEdit,
  icon: DailyRewardsIcon,
  name: 'admin/daily-rewards/steps',
  options,
};

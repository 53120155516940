import ChallengeIcon from '@material-ui/icons/FormatAlignJustify';
import InteractivesReportsList from './components/InteractivesReportsList';
import { APP_TYPES, roles } from '../../../config/constants';

const options = {
  appTypes: [APP_TYPES.HUNGARY, APP_TYPES.IRELAND],
  allowedRoles: [roles.ADMIN],
  label: 'Interactives',
  subMenuName: 'reports',
};

export default {
  list: InteractivesReportsList,
  icon: ChallengeIcon,
  name: 'admin/reports/interactive-articles',
  options,
};

import React from 'react';
import { Checkbox } from '@mui/material';
import classNames from 'classnames';
import styles from './Checkbox.module.scss';

export const RewardCheckbox = ({ params, isDisabled, handleClick }) => {
  const { id, value } = params;

  const handleChange = () => {
    handleClick(id);
  };

  return (
    <Checkbox
      disabled={isDisabled}
      className={classNames(styles.checkbox, { [styles.disabled]: isDisabled })}
      checked={value}
      onChange={handleChange}
    />
  );
};

import React from 'react';
import { Filter } from 'react-admin';
import { CustomDateInput } from '../../../commonComponents/CustomDateInput';

const ArUnrecognizedPacksFilter = (props) => (
  <Filter {...props}>
    <CustomDateInput label="Start date" source="created_at_from" start alwaysOn />
    <CustomDateInput label="End date" source="created_at_to" end alwaysOn />
  </Filter>
);

export default ArUnrecognizedPacksFilter;

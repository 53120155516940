import ChanceIcon from '@material-ui/icons/Star';
import ChanceList from './components/ChanceList';
import ChanceCreate from './components/ChanceCreate';
import ChanceEdit from './components/ChanceEdit';
import IrelandChanceCreate from './components/IrelandChanceCreate';
import IrelandChanceEdit from './components/IrelandChanceEdit';

import { roles, APP_TYPES, CAUCASUS_TYPES } from '../../config/constants';
import { isIreland } from '../../helpers/appTypeHelper';

const ENV_APP_TYPE = process.env.REACT_APP_APP_TYPE;

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: [APP_TYPES.HUNGARY, APP_TYPES.BELARUS, ...CAUCASUS_TYPES].includes(ENV_APP_TYPE)
    ? [roles.ADMIN]
    : [roles.ADMIN, roles.JTI_TEAM],
  label: 'Chance',
  subMenuName: 'content',
};

export default {
  list: ChanceList,
  create: isIreland() ? IrelandChanceCreate : ChanceCreate,
  edit: isIreland() ? IrelandChanceEdit : ChanceEdit,
  icon: ChanceIcon,
  name: 'admin/lotteries',
  options,
};

import React from 'react';
import { Datagrid, List, TextField } from 'react-admin';
import CustomReportListActions from '../../../../commonComponents/ListActions/CustomReportListActions';
import StatusField from '../../../../commonComponents/StatusField';
import CurrentProductsReportsFilter from './CurrentProductsReportsFilter';

const CurrentProductsReportsList = (props) => (
  <List
    title="Order reports"
    actions={<CustomReportListActions />}
    filters={<CurrentProductsReportsFilter />}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField label="ID" source="id" sortable={false} />
      <TextField label="Name/Description" source="name" sortable={false} />
      <TextField label="Price (points)" source="price_points" sortable={false} />
      <TextField label="Nominal price" source="product_price" sortable={false} />
      <TextField label="Count" source="count" sortable={false} />
      <TextField label="Total cost" source="cost" sortable={false} />
      <StatusField label="Status" source="status" sortable={false} />
    </Datagrid>
  </List>
);

export default CurrentProductsReportsList;

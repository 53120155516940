import DailyRewardsIcon from '@material-ui/icons/DateRange';
import RewardsList from './components/RewardsList';
import RewardCreate from './components/RewardCreate';
import RewardEdit from './components/RewardEdit';
import { roles, APP_TYPES } from '../../../config/constants';

const ENV_APP_TYPE = process.env.REACT_APP_APP_TYPE;

const options = {
  appTypes: [APP_TYPES.BELARUS, APP_TYPES.HUNGARY],
  allowedRoles: [APP_TYPES.HUNGARY, APP_TYPES.BELARUS].includes(ENV_APP_TYPE)
    ? [roles.ADMIN]
    : [roles.ADMIN, roles.JTI_TEAM],
  label: 'Event Mode: Rewards',
  subMenuName: 'content',
};

export default {
  list: RewardsList,
  create: RewardCreate,
  edit: RewardEdit,
  icon: DailyRewardsIcon,
  name: 'admin/event-mode/rewards',
  options,
};

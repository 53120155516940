import React from 'react';
import { Edit, NumberInput, required, SelectInput, SimpleForm, TextInput } from 'react-admin';
import RewardInput from '../../../commonComponents/RewardInput';
import TargetGroupInput from '../../../commonComponents/TargetGroupInput';
import ImageInput from '../../../commonComponents/UploadInputs/ImageInput';
import { s3FilePaths, statuses } from '../../../config/constants';

const ChanceEdit = (props) => {
  const rewardInputFields = [
    { inputLabel: 'Prize', inputType: 'string', radioLabel: 'Prizes', source: 'product_name' },
    { inputLabel: 'Coins', inputType: 'number', radioLabel: 'Coins', source: 'coins' },
  ];

  return (
    <Edit title="Edit Chance" undoable={false} {...props}>
      <SimpleForm>
        <TextInput label="ID" source="id" disabled />
        <TextInput label="Name" source="name" />
        <TextInput label="Description" source="description" />
        <NumberInput label="Total" source="total" />
        <TextInput label="Cost" source="cost" />
        <RewardInput formType="edit" inputFields={rewardInputFields} />
        <SelectInput source="status" optionText="name" choices={statuses.slice(0, 2)} />
        <ImageInput imageInputSource="photo" validate={required()} imagePath={s3FilePaths.allUploads} />
        <TargetGroupInput />
      </SimpleForm>
    </Edit>
  );
};

export default ChanceEdit;

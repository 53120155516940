import React from 'react';
import { Datagrid, DeleteButton, FunctionField, List, TextField } from 'react-admin';
import { CustomDateField } from '../../../commonComponents/DateField';

const PopupList = (props) => (
  <List title="Popup" exporter={false} bulkActionButtons={false} sort={{ field: 'id', order: 'ASC' }} {...props}>
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" sortable={false} />
      <TextField label="Title" source="title" sortable={false} />
      <FunctionField label="Message" render={(record) => record.message.substring(0, 30)} sortable={false} />
      <CustomDateField source="date_from" showTime={false} sortable={false} />
      <CustomDateField source="date_to" showTime={false} sortable={false} />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
);

export default PopupList;

import { APP_TYPES, roles } from '../../../config/constants';
import AttachmentsList from './components/AttachmentsList';

const options = {
  appTypes: [APP_TYPES.IRELAND],
  allowedRoles: [roles.JTI_TEAM, roles.ADMIN],
  label: 'Attachments',
};

export default {
  list: AttachmentsList,
  name: 'admin/mystery-shopper/attachments',
  options,
};

import React, { useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import ChatMessage from '../../../commonComponents/ChatMessage';
import { ChatMessagesContext } from '../context';

const useStyles = makeStyles({
  messagesContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: '0 10px 10px 10px',
    alignItems: 'flex-end',
    overflowY: 'auto',
  },
  chatMessage: {
    width: '70%',
    borderRadius: '10px',
    padding: '15px 5px',
    backgroundColor: '#01c1b6',
    color: '#fff',
    marginTop: '20px',
    textAlign: 'right',
  },
});

const ChatMessagesSection = ({ selectedChatCompanion }) => {
  const classes = useStyles();
  const messagesEnd = useRef();
  const { messages } = React.useContext(ChatMessagesContext);

  useEffect(() => {
    if (messagesEnd.current) {
      messagesEnd.current.scrollIntoView();
    }
  });

  const companionFullName = `${selectedChatCompanion.name} ${selectedChatCompanion.surname}`;

  return (
    <div className={classes.messagesContainer}>
      {messages.map((message) => (
        <ChatMessage key={message.created_at} record={message} companionFullName={companionFullName} />
      ))}
      <div ref={messagesEnd} />
    </div>
  );
};

export default ChatMessagesSection;

import React from 'react';
import { Filter, TextInput } from 'react-admin';

const ProductsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Product description" source="description" alwaysOn />
  </Filter>
);

export default ProductsFilter;

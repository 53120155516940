import { BooleanInput, required, TextInput, regex } from 'react-admin';
import React from 'react';

const PriceQuestion = () => (
  <>
    <TextInput
      label="Correct answer"
      source="answers[0].answer"
      validate={[required(), regex(/^[0-9]{1,2}([.][0-9]{2})?$/, 'Must be 1.11 or 11.11 format')]}
    />
    <BooleanInput label="Correct" source="answers[0].correct" defaultValue disabled />
  </>
);

export default PriceQuestion;

import Looks1Icon from '@material-ui/icons/LooksOne';
import Looks2Icon from '@material-ui/icons/LooksTwo';
import Looks3Icon from '@material-ui/icons/Looks3';

import { roles, APP_TYPES, PROBLEM_LEVELS } from '../../config/constants';
import { ProblemCreate, ProblemEdit, ProblemList } from './components';

const iconsByLevel = {
  1: Looks1Icon,
  2: Looks2Icon,
  3: Looks3Icon,
};

const getResource = (level) => {
  const options = {
    appTypes: [APP_TYPES.IRELAND],
    allowedRoles: [roles.ADMIN],
    subMenuName: 'problems',
    label: `${level} Level`,
  };

  return {
    list: (props) => <ProblemList level={level} {...props} />,
    create: (props) => <ProblemCreate level={level} {...props} />,
    edit: (props) => <ProblemEdit level={level} {...props} />,
    icon: iconsByLevel[level],
    name: `admin/problems/${level}/problems`,
    options,
  };
};

const resources = PROBLEM_LEVELS.map(getResource);

export default resources;

import React from 'react';
import { Filter, SelectInput } from 'react-admin';
import { CustomDateInput } from '../../../commonComponents/CustomDateInput';
import { challengeTypesChoices } from '../config/constants';

const ChallengesFilter = (props) => (
  <Filter {...props}>
    <SelectInput optionText="name" choices={challengeTypesChoices} source="type" alwaysOn />
    <CustomDateInput label="Choose start date" source="start_date" start alwaysOn />
    <CustomDateInput label="Choose end date" source="end_date" end alwaysOn />
  </Filter>
);

export default ChallengesFilter;

import InviteCodesIcon from '@material-ui/icons/DeviceHub';
import InviteCodesList from './components/InviteCodesList';
import { APP_TYPES, roles } from '../../../config/constants';

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: [roles.ADMIN, roles.JTI_TEAM],
  label: 'Invite Codes',
  subMenuName: 'reports',
};

export default {
  list: InviteCodesList,
  icon: InviteCodesIcon,
  name: 'admin/reports/invite-codes',
  options,
};

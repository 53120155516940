import React from 'react';
import { Edit, required, SelectInput, SimpleForm, TextInput } from 'react-admin';
import { ordersStatuses } from '../../../../config/constants';

const OrderEdit = (props) => (
  <Edit title="Edit order status" undoable={false} {...props}>
    <SimpleForm>
      <TextInput label="ID" source="id" disabled />
      <SelectInput source="status" optionText="name" choices={ordersStatuses} validate={required()} />
    </SimpleForm>
  </Edit>
);

export default OrderEdit;

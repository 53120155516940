import React from 'react';
import {
  ArrayInput,
  Create,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';
import { ColorInput } from 'react-admin-color-input';
import { makeStyles } from '@material-ui/core/styles';
import { CustomDateInput } from '../../../commonComponents/CustomDateInput';
import TargetGroupInput from '../../../commonComponents/TargetGroupInput';
import ImageInput from '../../../commonComponents/UploadInputs/ImageInput';
import { activityTypes, s3FilePaths } from '../../../config/constants';

const useStyles = makeStyles({
  hideFormLabel: {
    '& li > p': {
      display: 'none',
    },
  },
});

const LimitationsCreate = (props) => {
  const classes = useStyles();
  return (
    <Create title="Create monthly limitation" undoable="false" {...props}>
      <SimpleForm redirect="list">
        <TextInput label="Hint" source="hint_text" validate={required()} />
        <NumberInput label="Coins limit" source="limit" defaultValue={10000} validate={required()} />
        <ColorInput source="first_half_color" defaultValue="#FFC163" validate={required()} />
        <ColorInput source="second_half_color" defaultValue="#67F6BA" validate={required()} />
        <CustomDateInput label="Activated at" source="activated_at" />
        <TargetGroupInput />
        <ArrayInput source="activities" className={classes.hideFormLabel}>
          <SimpleFormIterator>
            <TextInput label="Name" source="name" validate={required()} />
            <NumberInput label="Priority" source="priority" />
            <ReferenceInput
              label="Activity type"
              source="type_id"
              reference="admin/monthly-limitations/activity-types"
              sort={{ field: 'name', order: 'ASC' }}
            >
              <SelectInput />
            </ReferenceInput>
            <FormDataConsumer>
              {({ scopedFormData, getSource }) => {
                if (!scopedFormData) return null;
                let fieldLabel = '';
                let fieldSourceName = '';
                switch (scopedFormData.type_id) {
                  case activityTypes.special_quiz:
                    fieldLabel = 'Special quiz ID';
                    fieldSourceName = 'pack_id';
                    break;
                  case activityTypes.multibrand_quiz:
                    fieldLabel = 'Multibrand quiz ID';
                    fieldSourceName = 'pack_id';
                    break;
                  case activityTypes.kb_article:
                    fieldLabel = 'KB Article ID';
                    fieldSourceName = 'knowledge_bank_article_id';
                    break;
                  case activityTypes.kb_game:
                    fieldLabel = 'KB Game ID';
                    fieldSourceName = 'knowledge_bank_article_id';
                    break;
                  default:
                    return null;
                }
                return <NumberInput label={fieldLabel} source={getSource(fieldSourceName)} validate={required()} />;
              }}
            </FormDataConsumer>
            <FormDataConsumer>
              {({ getSource }) => (
                <ImageInput
                  label="Image"
                  imageInputSource={getSource('image')}
                  imagePath={s3FilePaths.progressBar}
                  validate={required()}
                />
              )}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

export default LimitationsCreate;

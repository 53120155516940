import LoyaltyProgramIcon from '@material-ui/icons/Extension';
import LoyaltyProgramsList from './components/LoyaltyProgramsList';
import LoyaltyProgramsEdit from './components/LoyaltyProgramsEdit';
import LoyaltyProgramsCreate from './components/LoyaltyProgramsCreate';
import { roles, APP_TYPES } from '../../config/constants';

const options = {
  appTypes: [APP_TYPES.IRELAND],
  allowedRoles: [roles.ADMIN],
  label: 'Loyalty programs',
  subMenuName: 'content',
};

export default {
  list: LoyaltyProgramsList,
  create: LoyaltyProgramsCreate,
  edit: LoyaltyProgramsEdit,
  icon: LoyaltyProgramIcon,
  name: 'admin/loyalty-programs',
  options,
};

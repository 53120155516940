import React from 'react';
import { List, Datagrid, TextField, EditButton, DeleteButton, SelectField } from 'react-admin';
import StatusField from '../../../commonComponents/StatusField';
import DuplicateButton from '../../../commonComponents/DuplicateButton';
import { CustomDateField } from '../../../commonComponents/DateField';
import LinkToAttachments from '../../../commonComponents/LinkToAttachments';
import CustomListActions from '../../../commonComponents/ListActions/CustomListActions';
import { isCaucasus, isHungary, isIreland } from '../../../helpers/appTypeHelper';
import { challengeTypesChoices } from '../config/constants';
import { roles } from '../../../config/constants';
import checkUserRole from '../../../helpers/checkUserRole';
import ChallengesFilter from './ChallengesFilter';

const ChallengesList = (props) => {
  const duplicateRecord = (record) => {
    const { id, created_at, ...mappedRecord } = record;
    const mappedRewards = mappedRecord.rewards.map((reward) => {
      const { id, product_id, quest_id, tasks_to_complete, winners_ids, ...mappedReward } = reward;
      return mappedReward;
    });
    const mappedTasks = mappedRecord.tasks.map((task) => {
      const { brand_id, id, quest_id, type, ...mappedTask } = task;
      return mappedTask;
    });
    mappedRecord.status = 0;
    mappedRecord.tasks = mappedTasks;
    mappedRecord.rewards = mappedRewards;

    return mappedRecord;
  };

  const hasTeamChallenge = isHungary() || isIreland();
  const isButtonHidden = checkUserRole(roles.JTI_TEAM) && isCaucasus;

  return (
    <List
      title="Challenge"
      exporter={false}
      bulkActionButtons={false}
      actions={!isButtonHidden && <CustomListActions />}
      filters={<ChallengesFilter />}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}
    >
      <Datagrid>
        <TextField label="ID" source="id" />
        {hasTeamChallenge && (
          <SelectField label="Type" source="type" choices={challengeTypesChoices} sortable={false} />
        )}
        <TextField label="Admin Panel Name" source="admin_panel_name" />
        <TextField label="Name" source="name" />
        <CustomDateField label="Start Date" source="start_date" />
        <CustomDateField label="End Date" source="end_date" />
        <StatusField label="Status" source="status" sortable={false} />
        {!isButtonHidden && <EditButton />}
        {!isButtonHidden && <DuplicateButton copyRecord={duplicateRecord} />}
        <LinkToAttachments pathname="/admin/quests/attachments" recordName="quest_id" />
        {!isButtonHidden && <DeleteButton undoable={false} />}
      </Datagrid>
    </List>
  );
};

export default ChallengesList;

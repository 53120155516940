import React from 'react';
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  Edit,
  NumberInput,
  ReferenceInput,
  required,
  SimpleForm,
} from 'react-admin';
import { ColorInput } from 'react-admin-color-input';

import Section from './Section';
import { isIreland } from '../../../helpers/appTypeHelper';

const InteractiveCreate = (props) => (
  <Edit title="Edit Interactive" undoable={false} {...props}>
    <SimpleForm redirect="list">
      <ReferenceInput
        source="article_id"
        label="Article"
        reference={`admin/knowledge-bank/articles/autocomplete`}
        perPage={10000}
        validate={required()}
      >
        <AutocompleteInput />
      </ReferenceInput>

      <ColorInput label="Background color" source="background_color" validate={required()} />

      <NumberInput label="Coins" source="coins" min={0} />
      {isIreland() && (
        <BooleanInput style={{ width: '300px' }} label="Included in the Progress bar" source="progress_bar_enable" />
      )}
      <ArrayInput source="sections">
        <Section />
      </ArrayInput>
    </SimpleForm>
  </Edit>
);

export default InteractiveCreate;

import React from 'react';
import { ArrayInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  arrayInputSectionLabel: {
    '& > label': {
      fontSize: '1.6rem',
      color: '#000',
      fontWeight: 600,
      marginTop: 15,
      position: 'static',
    },
  },
});

const CustomArrayInput = ({ children, className, ...rest }) => {
  const classes = useStyles();

  return (
    <ArrayInput className={`${classes.arrayInputSectionLabel} ${className}`} {...rest}>
      {children}
    </ArrayInput>
  );
};

export default CustomArrayInput;

import React from 'react';
import { Create, maxValue, minValue, NumberInput, SimpleForm } from 'react-admin';

import ProductForm from './ProductForm';

const StepCreate = (props) => (
  <Create title="Create Step" {...props}>
    <SimpleForm redirect="list">
      <NumberInput label="Step Number" source="step_number" validate={[minValue(1), maxValue(7)]} />
      <ProductForm name="products" formType="create" populate />
    </SimpleForm>
  </Create>
);

export default StepCreate;

import React, { Suspense } from 'react';
import { Loading } from 'react-admin';
import { APP_TYPES } from '../../../config/constants';

const ArmeniaUserEdit = React.lazy(() => import('./EditForms/ArmeniaUserEdit'));
const AzerbaijanUserEdit = React.lazy(() => import('./EditForms/AzerbaijanUserEdit'));
const BelarusUserEdit = React.lazy(() => import('./EditForms/BelarusUserEdit'));
const FallbackUserEdit = React.lazy(() => import('./EditForms/FallbackUserEdit'));
const GeorgiaUserEdit = React.lazy(() => import('./EditForms/GeorgiaUserEdit'));
const HungaryUserEdit = React.lazy(() => import('./EditForms/HungaryUserEdit'));
const IrelandUserEdit = React.lazy(() => import('./EditForms/IrelandUserEdit'));

const UserEdit = (props) => {
  const forms = {
    [APP_TYPES.HUNGARY]: <HungaryUserEdit {...props} />,
    [APP_TYPES.IRELAND]: <IrelandUserEdit {...props} />,
    [APP_TYPES.BELARUS]: <BelarusUserEdit {...props} />,
    [APP_TYPES.GEORGIA]: <GeorgiaUserEdit {...props} />,
    [APP_TYPES.ARMENIA]: <ArmeniaUserEdit {...props} />,
    [APP_TYPES.AZERBAIJAN]: <AzerbaijanUserEdit {...props} />,
  };

  return (
    <Suspense fallback={<Loading />}>
      {forms[process.env.REACT_APP_APP_TYPE] || <FallbackUserEdit {...props} />}
    </Suspense>
  );
};

export default UserEdit;

import SpecialQuizIcon from '@material-ui/icons/FormatAlignCenter';
import SpecialQuizReportsList from './components/SpecialQuizReportsList';
import { APP_TYPES, CAUCASUS_TYPES, roles } from '../../../config/constants';

const appType = process.env.REACT_APP_APP_TYPE;

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: [APP_TYPES.BELARUS, ...CAUCASUS_TYPES].includes(appType)
    ? [roles.ADMIN, roles.JTI_TEAM]
    : [roles.ADMIN],
  label: 'Special quiz',
  subMenuName: 'reports',
};

export default {
  list: SpecialQuizReportsList,
  icon: SpecialQuizIcon,
  name: 'admin/reports/special',
  options,
};

import React from 'react';
import { Filter, SelectInput, TextInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { chatStatuses, roles } from '../../../config/constants';
import UserAutocompleteInput from '../../../commonComponents/UserAutocompleteInput';
import SendCustomMessagesToUsers from '../../../commonComponents/SendMessageActions/SendCustomMessagesToUsers/SendCustomMessagesToUsers';
import checkUserRole from '../../../helpers/checkUserRole';
import { isCaucasus } from '../../../helpers/appTypeHelper';

const isAdmin = checkUserRole(roles.ADMIN);

const useStyles = makeStyles({
  filterForm: {
    paddingLeft: '1em',
    marginTop: 0,
    overflow: 'hidden',
    '& > :last-child': {
      height: '1em',
      width: '100%',
    },
    '& > :nth-child(3), & > :nth-child(4)': {
      width: '100%',
    },
  },
  halvedInput: {
    minWidth: '88px',
    marginBottom: '0px',
  },
  uploadButton: {
    marginTop: '1em',
    marginLeft: '1em',
    minWidth: '88px',
  },
  filterFormInput: {
    width: '100%',
    marginBottom: '0px',
  },
});

const ChatsFilter = (props) => {
  const classes = useStyles();

  return (
    <>
      {isAdmin && isCaucasus && (
        <div className={classes.uploadButton}>
          <SendCustomMessagesToUsers endpointUrl="/admin/messages/send/custom" />
        </div>
      )}
      <Filter classes={{ form: classes.filterForm }} {...props}>
        <SelectInput
          source="filter"
          className={classes.halvedInput}
          optionValue="value"
          choices={chatStatuses}
          allowEmpty={false}
          alwaysOn
        />
        <UserAutocompleteInput className={classes.halvedInput} label="User ID or Name" source="user_id" alwaysOn />
        <TextInput className={classes.filterFormInput} label="User phone" source="search" alwaysOn />
        <TextInput className={classes.filterFormInput} label="Word search" source="word_search" alwaysOn />
      </Filter>
    </>
  );
};

export default ChatsFilter;

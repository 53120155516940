import React, { useCallback, useEffect, useState } from 'react';
import { useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import httpClient from '../../../core/httpClient';

const useStyles = makeStyles({
  serviceCodeContainer: {
    maxWidth: '41%',
    padding: '16px 24px',
  },
});

const ServiceCode = () => {
  const classes = useStyles();
  const notify = useNotify();
  const [serviceCode, setServiceCode] = useState();

  const getServiceCode = useCallback(() => {
    const url = `${process.env.REACT_APP_API_URL}/v3/admin/code`;
    const options = { method: 'GET' };

    httpClient(url, options)
      .then((res) => {
        setServiceCode(res.json.code);
      })
      .catch((err) => {
        notify(err.message, 'warning');
      });
  }, []);

  useEffect(() => {
    getServiceCode();
  }, []);

  return (
    <div className={classes.serviceCodeContainer}>
      <Typography variant="h6" gutterBottom>
        Service code: {serviceCode}
      </Typography>
    </div>
  );
};

export default ServiceCode;

import React from 'react';
import {
  ArrayInput,
  FormDataConsumer,
  ReferenceInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';

import SingleAnswerFields from './SingleAnswerFields';
import MultipleAnswersFields from './MultipleAnswersFields';

const Section = (props) => (
  <SimpleFormIterator {...props}>
    <ReferenceInput label="Type" source="type_id" reference="admin/interactive-articles/types">
      <SelectInput source="name" />
    </ReferenceInput>

    <TextInput label="Title" source="title" validate={required()} />
    <FormDataConsumer>
      {({ scopedFormData, getSource }) => (
        <>
          {scopedFormData?.type_id === 4 && (
            <>
              <TextInput label="Text Area" source={getSource('textarea')} />
              <div>Use [Dropdown Index] inside a question to bind it with inputs: Text [1] text [2] text.</div>
            </>
          )}
          <ArrayInput label="" source={getSource('answers')}>
            {(() => {
              switch (scopedFormData?.type_id) {
                case 1:
                  return <SingleAnswerFields getSource={getSource} />;
                case 2:
                case 3:
                  return <MultipleAnswersFields getSource={getSource} />;
                case 4:
                  return <MultipleAnswersFields getSource={getSource} isDropdownIndexShown />;
                default:
                  return <></>;
              }
            })()}
          </ArrayInput>
        </>
      )}
    </FormDataConsumer>
  </SimpleFormIterator>
);

export default Section;

import CallToActionIcon from '@material-ui/icons/CallToAction';
import { APP_TYPES, roles } from '../../../config/constants';
import PopupReportList from './components/PopupReportList';

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: [roles.ADMIN, roles.JTI_TEAM],
  label: 'Popups',
  subMenuName: 'reports',
};

export default {
  list: PopupReportList,
  icon: CallToActionIcon,
  name: 'admin/reports/popup',
  options,
};

import ProviderIcon from '@material-ui/icons/Inbox';
import ProvidersList from './components/ProvidersList';
import ProviderCreate from './components/ProviderCreate';
import ProviderEdit from './components/ProviderEdit';
import { roles, ALL_WITHOUT_IRELAND } from '../../../config/constants';

const options = {
  appTypes: ALL_WITHOUT_IRELAND,
  allowedRoles: [roles.ADMIN],
  label: 'Providers',
  subMenuName: 'bonusShop',
};

export default {
  list: ProvidersList,
  edit: ProviderEdit,
  create: ProviderCreate,
  icon: ProviderIcon,
  name: 'admin/bonus-shop/providers',
  options,
};

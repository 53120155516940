import React from 'react';
import { Datagrid, List, TextField, useRefresh } from 'react-admin';
import { CustomDateField } from '../../../../commonComponents/DateField';
import StatusField from '../../../../commonComponents/StatusField';
import { lepCodeStatuses } from '../../../../config/constants';
import LepCodeCheckbox from './LepCodeCheckbox';

import LepCodesFilter from './LepCodeFilter';

const LepCodeList = (props) => {
  const refresh = useRefresh();

  return (
    <List
      title="LEP Codes"
      filters={<LepCodesFilter />}
      bulkActionButtons={false}
      exporter={false}
      sort={{ field: 'code', order: 'DESC' }}
      {...props}
    >
      <Datagrid>
        <TextField label="User phone" source="user.phone" />
        <TextField source="code" />
        <StatusField source="status" statuses={lepCodeStatuses} />
        <CustomDateField source="assigned_at" />
        <CustomDateField source="won_at" />
        <LepCodeCheckbox refresh={refresh} />
      </Datagrid>
    </List>
  );
};

export default LepCodeList;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import AdminCardSet from '../AdminCardSet';
import BAOverviewCardSet from '../BAOverviewCardSet';
import BACardSet from '../BACardSet';
import { APP_TYPES, roles } from '../../../../../config/constants';
import checkUserRole from '../../../../../helpers/checkUserRole';

const useStyles = makeStyles({
  adminCardSetContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  managerCardSetContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    width: '100%',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});

const ENV_APP_TYPE = process.env.REACT_APP_APP_TYPE;

const CardSetPicker = () => {
  const classes = useStyles();
  const isJTIStaff = checkUserRole(roles.ADMIN, roles.JTI_TEAM, roles.RSM);
  const isBA = checkUserRole(roles.BA);

  return (
    <div className={classes.container}>
      {/* eslint-disable-next-line no-nested-ternary */}
      {isJTIStaff && ENV_APP_TYPE !== APP_TYPES.BELARUS ? (
        <div className={classes.adminCardSetContainer}>
          <AdminCardSet />
          <BAOverviewCardSet />
        </div>
      ) : isBA || (isJTIStaff && ENV_APP_TYPE === APP_TYPES.BELARUS) ? (
        <div className={classes.managerCardSetContainer}>
          <BACardSet />
        </div>
      ) : null}
    </div>
  );
};

export default CardSetPicker;

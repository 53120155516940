import UserIcon from '@material-ui/icons/Group';
import UsersList from './components/UsersList';
import UserCreate from './components/UserCreate';
import UserEdit from './components/UserEdit';
import { allRoles, APP_TYPES } from '../../config/constants';

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: allRoles,
};

export default {
  list: UsersList,
  create: UserCreate,
  edit: UserEdit,
  icon: UserIcon,
  name: 'admin/users',
  options,
};

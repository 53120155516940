import { Typography } from '@material-ui/core';
import { get } from 'lodash';

const ArrayField = ({ record, source, label }) => {
  const values = get(record, source);
  if (!values) return null;

  return (
    <Typography component="div">
      {label}: {values.join(', ')}
    </Typography>
  );
};

export default ArrayField;

import { Filter, TextInput } from 'react-admin';
import { CustomDateInput } from '../../../commonComponents/CustomDateInput';

const MysteryShopperFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name" source="name" alwaysOn />
    <CustomDateInput label="Choose start date" source="start_date" start alwaysOn />
    <CustomDateInput label="Choose end date" source="end_date" end alwaysOn />
  </Filter>
);

export default MysteryShopperFilter;

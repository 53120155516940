import MainScreenIcon from '@material-ui/icons/Airplay';
import ScreensList from './components/ScreensList';
import ScreenCreate from './components/ScreenCreate';
import ScreenEdit from './components/ScreenEdit';
import { roles, APP_TYPES } from '../../config/constants';

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: [roles.ADMIN],
  label: 'Main screen',
  subMenuName: 'manage',
};

export default {
  list: ScreensList,
  create: ScreenCreate,
  edit: ScreenEdit,
  icon: MainScreenIcon,
  name: 'admin/main-screen',
  options,
};

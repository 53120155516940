import React, { useState, useEffect } from 'react';
import { useNotify, useRefresh } from 'react-admin';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';

import httpClient from '../../../../../core/httpClient';
import { serverApiUrl } from '../../../../../config/constants';
import { taskStatus } from '../../../config/constants';

const TaskStatusForUser = ({ record, ...props }) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const { tasks } = record;

  const [checked, setChecked] = useState(tasks);

  const handleCheckBoxOnClick = (task) => {
    const questId = record.quest.id;
    const { userId } = record;

    const body = { userId, questId, task: { id: task.id, status: taskStatus.completed } };
    const url = `${serverApiUrl}/admin/quests/tasks/mark`;
    const options = { method: 'POST', body: JSON.stringify(body) };

    httpClient(url, options)
      .then(() => {
        notify('Task was marked successfully!', 'success');
      })
      .then(() => {
        refresh();
      })
      .catch((e) => {
        notify("Task wasn't marked successfully!", 'warning');
      });
  };

  useEffect(() => {
    setChecked(tasks);
  }, [tasks]);

  if (!props.tasks) {
    const tasks = [];
    checked.forEach((task) => {
      tasks.push({
        id: task.id,
        name: task.description,
        type: task.type_id,
      });
    });
    props.setTasksForFilter([...tasks]);
  }

  return (
    <>
      {checked.map((task, i) => (
        <Tooltip title={`(${task.id}) ${task.description}`} key={`Task${task.id}`} placement="top">
          <Checkbox
            checked={task.done}
            onClick={() => handleCheckBoxOnClick(task)}
            id={String(i)}
            disabled={task.done}
          />
        </Tooltip>
      ))}
    </>
  );
};

export default TaskStatusForUser;

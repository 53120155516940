import BatteryChargingFullIcon from '@material-ui/icons/BatteryChargingFull';
import { APP_TYPES, roles } from '../../config/constants';
import BoosterEdit from './components/BoosterEdit';
import BoosterList from './components/BoosterList';

const options = {
  appTypes: [APP_TYPES.HUNGARY],
  allowedRoles: [roles.ADMIN],
  label: 'Boosters',
  subMenuName: 'manage',
};

export default {
  list: BoosterList,
  edit: BoosterEdit,
  icon: BatteryChargingFullIcon,
  name: 'admin/boosters',
  options,
};

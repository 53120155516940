import SalesTipsIcon from '@material-ui/icons/Help';
import SalesTipsList from './components/SalesTipsList';
import SalesTipCreate from './components/SalesTipCreate';
import SalesTipEdit from './components/SalesTipEdit';
import { APP_TYPES, roles } from '../../config/constants';

const options = {
  appTypes: [APP_TYPES.BELARUS, APP_TYPES.HUNGARY, APP_TYPES.DEMO, APP_TYPES.ALL],
  allowedRoles: [roles.ADMIN],
  label: 'Sales tips',
  subMenuName: 'content',
};

export default {
  list: SalesTipsList,
  create: SalesTipCreate,
  edit: SalesTipEdit,
  icon: SalesTipsIcon,
  name: 'admin/sales-tips',
  options,
};

import React from 'react';
import { Filter, NumberInput, TextInput } from 'react-admin';

const QuizFilter = (props) => (
  <Filter {...props}>
    <NumberInput label="Price" source="price" alwaysOn />
    <TextInput source="search" label="Search" alwaysOn />
  </Filter>
);

export default QuizFilter;

import UserIcon from '@material-ui/icons/Group';
import UserReportsList from './components/UserReportsList';
import { APP_TYPES, CAUCASUS_TYPES, roles } from '../../../config/constants';

const appType = process.env.REACT_APP_APP_TYPE;

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: CAUCASUS_TYPES.includes(appType) ? [roles.ADMIN, roles.JTI_TEAM] : [roles.ADMIN],
  label: 'Users',
  subMenuName: 'reports',
};

export default {
  list: UserReportsList,
  icon: UserIcon,
  name: 'admin/reports/users',
  options,
};

import React from 'react';
import { Edit, SimpleForm, TextInput, SelectInput, required, BooleanInput, useTranslate } from 'react-admin';
import { cpaProgramSelectStatuses } from '../../../config/constants';
import { CustomDateInput } from '../../../commonComponents/CustomDateInput';
import UploadFileInput from '../../../commonComponents/UploadInputs/UploadFileInput';

const ProgramsEdit = (props) => {
  const translate = useTranslate();

  return (
    <Edit title={translate('root.cpa.edit.title')} undoable={false} {...props}>
      <SimpleForm redirect="list">
        <TextInput label="ID" source="id" validate={required()} disabled />
        <TextInput label="Name" source="name" validate={required()} />
        <CustomDateInput label="Moderation end date" source="end_date" />

        <SelectInput source="status" optionText="name" choices={cpaProgramSelectStatuses} />

        <UploadFileInput
          label={translate('root.cpa.create.importLabel')}
          fileInputSource="file_link"
          buttonText={translate('root.cpa.create.importButton')}
        />

        <BooleanInput label={translate('root.cpa.create.toggle')} source="allow_grade" />
      </SimpleForm>
    </Edit>
  );
};

export default ProgramsEdit;

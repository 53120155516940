import React from 'react';
import { Filter, SelectArrayInput } from 'react-admin';
import UserAutocompleteInput from '../../../../commonComponents/UserAutocompleteInput';
import { CustomDateInput } from '../../../../commonComponents/CustomDateInput';

const gameTypes = [
  {
    id: 2,
    name: 'Game with bot',
  },
  {
    id: 3,
    name: 'Game with user',
  },
  {
    id: 8,
    name: 'Solo game',
  },
];

const ProductQuestionsReportsFilter = (props) => (
  <Filter {...props}>
    <UserAutocompleteInput label="User ID" source="user_id" alwaysOn />
    <SelectArrayInput
      label="Type"
      source="type_ids"
      optionText="name"
      choices={gameTypes}
      allowEmpty={false}
      alwaysOn
    />
    <CustomDateInput label="Choose start date" source="start_date" start alwaysOn />
    <CustomDateInput label="Choose end date" source="end_date" end alwaysOn />
  </Filter>
);

export default ProductQuestionsReportsFilter;

import React from 'react';
import { Create } from 'react-admin';
import LepForm from './LepForm';

const LepCreate = (props) => (
  <Create title="Create LEP" {...props}>
    <LepForm />
  </Create>
);

export default LepCreate;

import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';

const CategoryNameField = ({ record, changeCategory }) => {
  useEffect(() => {
    record && changeCategory(record);
  }, [record, changeCategory]);

  return <Typography>{record.name}</Typography>;
};

export default CategoryNameField;

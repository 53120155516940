import React from 'react';
import { Datagrid, DeleteButton, List, ReferenceField, TextField } from 'react-admin';

import ImageField from '../../../../commonComponents/ImageField';

const ProvidersList = (props) => (
  <List title="Providers" bulkActionButtons={false} exporter={false} sort={{ field: 'id', order: 'DESC' }} {...props}>
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <TextField label="Name" source="name" />
      <ImageField label="Image" source="photo" />
      <TextField label="Priority" source="priority" />
      <ReferenceField label="Category" source="category_id" reference="admin/bonus-shop/categories">
        <TextField source="name" />
      </ReferenceField>
      <TextField label="Template URL" source="template_url" />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
);

export default ProvidersList;

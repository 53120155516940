import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import { CustomDateField } from '../../../commonComponents/DateField';
import ImageField from '../../../commonComponents/ImageField';
import ArUnrecognizedPacksFilter from './ArUnrecognizedPacksFilter';
import CustomReportListActions from '../../../commonComponents/ListActions/CustomReportListActions';

const ARUnrecognizedPacksList = (props) => (
  <List
    title="Unrecognized packs"
    actions={<CustomReportListActions />}
    filters={<ArUnrecognizedPacksFilter />}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" sortable={false} />
      <TextField label="Barcode" source="barcode" sortable={false} />
      <TextField label="User ID" source="user_id" sortable={false} />
      <CustomDateField label="Created at" source="created_at" sortable={false} />
      <ImageField label="Photo" source="photo" sortable={false} />
    </Datagrid>
  </List>
);

export default ARUnrecognizedPacksList;

import React from 'react';
import { Datagrid, List, NumberField, ReferenceField, TextField } from 'react-admin';
import { CustomDateField } from '../../../../commonComponents/DateField';
import CustomReportListActions from '../../../../commonComponents/ListActions/CustomReportListActions';
import StatusField from '../../../../commonComponents/StatusField';
import { lepCodeStatuses } from '../../../../config/constants';

import LepReportsFilter from './LepReportsFilter';

const LepReportsList = (props) => (
  <List
    title="LEP reports"
    actions={<CustomReportListActions />}
    filters={<LepReportsFilter />}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <ReferenceField label="Competition" source="competition_id" reference="admin/lep">
        <TextField source="name" />
      </ReferenceField>
      <NumberField label="User ID" source="user.id" />
      <TextField label="User ID" source="user.register_type" />
      <TextField label="User Name" source="user.name" />
      <TextField label="User Surname" source="user.surname" />
      <TextField label="User Email" source="user.email" />
      <TextField label="User Phone" source="user.phone" />
      <TextField label="User Account Code" source="user.traffic_code" />
      <TextField source="code" />
      <CustomDateField label="Input date" source="assigned_at" />
      <StatusField source="status" statuses={lepCodeStatuses} />
    </Datagrid>
  </List>
);

export default LepReportsList;

import DailyRewardsIcon from '@material-ui/icons/DateRange';
import DailyQuestionsList from './components/DailyQuestionList';
import DailyQuestionCreate from './components/DailyQuestionCreate';
import DailyQuestionEdit from './components/DailyQuestionEdit';
import { roles, APP_TYPES } from '../../config/constants';

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: [roles.ADMIN],
  label: 'Questions of the day',
  subMenuName: 'content',
};

export default {
  list: DailyQuestionsList,
  create: DailyQuestionCreate,
  edit: DailyQuestionEdit,
  icon: DailyRewardsIcon,
  name: 'admin/daily-questions',
  options,
};

import React from 'react';
import { BooleanInput, Create, required, TextInput } from 'react-admin';

import ScreenForm from './ScreenForm';
import TargetGroupInput from '../../../commonComponents/TargetGroupInput';
import ThemeSelector from '../../../commonComponents/ThemeSelector';

const ScreenCreate = (props) => (
  <Create title="Create screen" {...props}>
    <ScreenForm redirect="list">
      <TextInput label="Name" source="name" validate={required()} />
      <TextInput label="Description" source="description" validate={required()} />
      <TargetGroupInput />
      <BooleanInput label="Active" source="active" />
      <ThemeSelector />
      <TextInput source={'navigation_title'} label={'Menu title'} />
    </ScreenForm>
  </Create>
);

export default ScreenCreate;

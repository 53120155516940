import React from 'react';
import { TopToolbar } from 'react-admin';
import CustomExportButton from './CustomExportButton';

const CustomListActions = ({ displayedFilters, exporter, filters, filterValues, resource, showFilter }) => (
  <TopToolbar>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    {exporter && <CustomExportButton filter={filterValues} />}
  </TopToolbar>
);

export default CustomListActions;

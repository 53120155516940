import React from 'react';
import { ColorInput } from 'react-admin-color-input';
import {
  BooleanInput,
  Edit,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import TargetGroupInput from '../../../commonComponents/TargetGroupInput';
import { CustomDateInput } from '../../../commonComponents/CustomDateInput';
import ImageInput from '../../../commonComponents/UploadInputs/ImageInput';
import TaskForm from './TasksForm';
import { s3FilePaths } from '../../../config/constants';

const AchievementEdit = (props) => (
  <Edit title="Edit Achievement" undoable={false} {...props}>
    <SimpleForm redirect="list">
      <TextInput label="ID" source="id" disabled />
      <TextInput label="Name" source="name" validate={required()} />
      <TextInput label="Description" source="description" />
      <TargetGroupInput />
      <ReferenceInput label="Level package name" reference="admin/levels-packages" source="levels_package_id">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <NumberInput label="Max level" source="max_level" />
      <CustomDateInput label="Choose Start date" source="start_date" />
      <CustomDateInput label="Choose End date" source="end_date" />
      <ColorInput label="Text color" source="text_color" />
      <ColorInput label="Status color" source="status_color" />
      <NumberInput label="Priority" source="priority" />
      <BooleanInput label="Visible" source="visible" />
      <ImageInput
        label="Upload background image"
        imageInputSource="background_image"
        imagePath={s3FilePaths.achievements}
        validate={required()}
      />
      <ImageInput
        label="Upload icon image"
        imageInputSource="icon_image"
        imagePath={s3FilePaths.achievements}
        validate={required()}
      />
      <TaskForm />
    </SimpleForm>
  </Edit>
);

export default AchievementEdit;

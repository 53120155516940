import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';

const LegalEntityCreate = (props) => (
  <Create title="Create Legal Entity" {...props}>
    <SimpleForm redirect="list">
      <TextInput label="Name" source="name" />
      <TextInput label="Type" source="type" />
    </SimpleForm>
  </Create>
);

export default LegalEntityCreate;

import React from 'react';
import { Filter, TextInput } from 'react-admin';
import { CustomDateInput } from '../../../../commonComponents/CustomDateInput';
import UserAutocompleteInput from '../../../../commonComponents/UserAutocompleteInput';

const TaxesReportsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Tax rate" source="formula" alwaysOn />
    <UserAutocompleteInput label="User ID" source="user_id" alwaysOn />
    <CustomDateInput label="Choose start date" source="start_date" start alwaysOn />
    <CustomDateInput label="Choose end date" source="end_date" end alwaysOn />
  </Filter>
);

export default TaxesReportsFilter;

import React from 'react';
import { Filter, TextInput } from 'react-admin';

const PromoCodeFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Code" source="code" alwaysOn />
  </Filter>
);

export default PromoCodeFilter;

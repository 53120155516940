import React from 'react';
import { Datagrid, List, NumberField, ReferenceField, TextField } from 'react-admin';
import { CustomDateField } from '../../../../commonComponents/DateField';
import CustomReportListActions from '../../../../commonComponents/ListActions/CustomReportListActions';

import BoostersReportsFilter from './BoostersReportsFilter';

const BoostersReportsList = (props) => (
  <List
    title="Autolight order reports"
    actions={<CustomReportListActions />}
    filters={<BoostersReportsFilter />}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField source="user_id" />
      <TextField label="Booster type" source="type" sortable={false} />
      <CustomDateField label="Activated at" source="created_at" />
      <ReferenceField label="Pack ID" reference="admin/quizzes" source="pack_id">
        <TextField source="id" />
      </ReferenceField>
      <NumberField label="Question Number" source="question_number" />
    </Datagrid>
  </List>
);

export default BoostersReportsList;

import BotsReportsIcon from '@material-ui/icons/Casino';
import BotsReportsList from './components/BotsReportsList';
import { APP_TYPES, roles } from '../../../config/constants';

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: [roles.ADMIN, roles.JTI_TEAM],
  label: 'Bots Statistics',
  subMenuName: 'reports',
};

export default {
  list: BotsReportsList,
  icon: BotsReportsIcon,
  name: 'admin/reports/bots',
  options,
};

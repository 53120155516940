import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { BooleanInput, required, TextInput } from 'react-admin';
import { useForm } from 'react-final-form';
import UploadFileInput from '../../../../commonComponents/UploadInputs/UploadFileInput';
import { isBelarus } from '../../../../helpers/appTypeHelper';
import { s3FilePaths } from '../../../../config/constants';

const pdfType = 'pdf';
const pdfTypeSource = 'pdf_link';
const codeType = 'code';
const codeTypeSource = 'code';

const useStyles = makeStyles({
  couponRadioGroup: {
    display: 'flex',
    flexDirection: 'row',
  },
});

const CouponForm = () => {
  const form = useForm();
  const classes = useStyles();
  const [couponType, setCouponType] = useState(pdfType);

  const handleChange = (event) => {
    setCouponType(event.target.value);
    if (event.target.value === pdfType) {
      form.change(pdfTypeSource, null);
    } else {
      form.change(codeTypeSource, null);
    }
  };

  return (
    <>
      <Typography>Coupon type</Typography>
      <RadioGroup value={couponType} onChange={handleChange} className={classes.couponRadioGroup}>
        <FormControlLabel value={pdfType} control={<Radio />} label="PDF" />
        <FormControlLabel value={codeType} control={<Radio />} label="Code" />
      </RadioGroup>
      {couponType === pdfType ? (
        <div>
          <TextInput label="PDF link" source={pdfTypeSource} />
          <UploadFileInput
            label="PDF file"
            fileInputSource="pdf_link"
            filePath={s3FilePaths.pdfCoupons}
            buttonText="Upload PDF file"
          />
        </div>
      ) : (
        <div>
          <TextInput label="Nominal value" source="nominal_value" validate={required()} />
          <UploadFileInput
            label="File with codes"
            fileInputSource="coupon_codes_link"
            buttonText="Upload XLSX file with codes"
            filePath={s3FilePaths.xlsxCoupons}
            validate={required()}
          />
          {isBelarus() && <BooleanInput label="Generate barcode" source={'has_barcode'} />}
        </div>
      )}
    </>
  );
};

export default CouponForm;

import React, { useEffect } from 'react';
import { TextInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import RewardInput from '../../../commonComponents/RewardInput';
import ImageInput from '../../../commonComponents/UploadInputs/ImageInput';
import { s3FilePaths } from '../../../config/constants';

const useStyles = makeStyles({
  productFormWrapper: {
    width: 276,
    marginTop: 20,
    padding: 10,
    border: '1px solid #0000001f',
  },
});

const ProductForm = ({ fields, formType, name, populate, ...rest }) => {
  const form = useForm();
  const classes = useStyles();

  useEffect(() => {
    if (!populate) return;
    for (let i = 0; i < 4; i += 1) {
      form.mutators.push(name, {});
    }
  });

  const rewardInputFields = (member) => [
    { inputLabel: 'Count', inputType: 'number', radioLabel: 'Product', source: `${member}.count` },
    { inputLabel: 'Coins', inputType: 'number', radioLabel: 'Coins', source: `${member}.coins` },
  ];

  return (
    <FieldArray name={name}>
      {({ fields }) =>
        fields.map((field, index) => (
          <div key={field} className={classes.productFormWrapper}>
            <Typography>{`Product ${index + 1}`}</Typography>
            <TextInput source={`${field}.name`} label="Name" {...rest} />
            <ImageInput
              imagePath={s3FilePaths.allUploads}
              imageInputSource={`${field}.photo`}
              label="Photo for roll"
              {...rest}
            />
            <ImageInput
              imagePath={s3FilePaths.allUploads}
              imageInputSource={`${field}.withdraw_photo`}
              label="Photo for withdrawal"
              {...rest}
            />
            <RewardInput formType={formType} inputFields={rewardInputFields(field)} {...rest} />
          </div>
        ))
      }
    </FieldArray>
  );
};

export default ProductForm;

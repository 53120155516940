import React from 'react';
import { Datagrid, List, ReferenceField, TextField } from 'react-admin';
import CustomReportListActions from '../../../../commonComponents/ListActions/CustomReportListActions';
import { APP_TYPES } from '../../../../config/constants';

import LeaderBoardReportsFilter from './LeaderBoardReportsFilter';

const LeaderBoardReportsList = (props) => (
  <List
    title="Leaderboard reports"
    actions={<CustomReportListActions />}
    filters={<LeaderBoardReportsFilter />}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField label="User ID" source="user.id" sortable={false} />
      <TextField label="Name" source="user.name" sortable={false} />
      <TextField label="Surname" source="user.surname" sortable={false} />
      <TextField label="Profile name" source="user.username" sortable={false} />
      {process.env.REACT_APP_APP_TYPE === APP_TYPES.IRELAND && (
        <ReferenceField source="tier_id" reference="admin/tiers">
          <TextField source="name" />
        </ReferenceField>
      )}
      <TextField label="City" source="user.city" sortable={false} />
      <TextField label="Kudos" source="points" sortable={false} />
      {process.env.REACT_APP_APP_TYPE === APP_TYPES.BELARUS && (
        <TextField label="Внешний код торговой точки" source="user.account_code" />
      )}
    </Datagrid>
  </List>
);

export default LeaderBoardReportsList;

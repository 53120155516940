import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';

const CityEdit = (props) => (
  <Edit titile="Edit City" undoable={false} {...props}>
    <SimpleForm>
      <TextInput label="ID" source="id" disabled />
      <TextInput label="Name" source="name" />
    </SimpleForm>
  </Edit>
);

export default CityEdit;

import SpecialQuizIcon from '@material-ui/icons/FormatAlignCenter';
import SpecialQuizzesList from './components/SpecialQuizzesList';
import SpecialQuizCreate from './components/SpecialQuizCreate';
import SpecialQuizEdit from './components/SpecialQuizEdit';
import { APP_TYPES, CAUCASUS_TYPES, roles } from '../../config/constants';

const ENV_APP_TYPE = process.env.REACT_APP_APP_TYPE;

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: [APP_TYPES.HUNGARY, APP_TYPES.BELARUS, ...CAUCASUS_TYPES].includes(ENV_APP_TYPE)
    ? [roles.ADMIN]
    : [roles.ADMIN, roles.JTI_TEAM],
  label: 'Special quizzes',
  subMenuName: 'content',
};

export default {
  list: SpecialQuizzesList,
  create: SpecialQuizCreate,
  edit: SpecialQuizEdit,
  icon: SpecialQuizIcon,
  name: 'admin/special-quizzes',
  options,
};

import CategoryIcon from '@material-ui/icons/Bookmark';
import CouponsList from './components/CouponsList';
import CouponCreate from './components/CouponCreate';
import CouponEdit from './components/CouponEdit';
import { roles, ALL_WITHOUT_IRELAND } from '../../../config/constants';

const options = {
  appTypes: ALL_WITHOUT_IRELAND,
  allowedRoles: [roles.ADMIN],
  label: 'Coupons',
  subMenuName: 'bonusShop',
};

export default {
  list: CouponsList,
  create: CouponCreate,
  edit: CouponEdit,
  icon: CategoryIcon,
  name: 'admin/bonus-shop/coupons',
  options,
};

import React from 'react';
import { TextInput, Edit, FormDataConsumer, SimpleForm, required, BooleanInput } from 'react-admin';
import Question from '../../../commonComponents/Question';
import { CustomDateInput } from '../../../commonComponents/CustomDateInput';
import { isIreland } from '../../../helpers/appTypeHelper';

const DailyQuestionEdit = (props) => (
  <Edit title="Edit question of the day" undoable={false} {...props}>
    <SimpleForm>
      <TextInput label="ID" source="id" disabled />
      <FormDataConsumer>{({ formData }) => <Question questionFormType={formData.type_id} />}</FormDataConsumer>
      {isIreland() && (
        <BooleanInput style={{ width: '300px' }} label="Included in the Progress bar" source="progress_bar_enable" />
      )}
      <CustomDateInput label="Publish date" source="active_at" validate={required()} />
    </SimpleForm>
  </Edit>
);

export default DailyQuestionEdit;

import { Card } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';
import { loggedInOrActiveInfoLabel } from '../../../users/components/UserLabelConsts';
import { usersDiagramsColorScheme } from '../../../../config/constants';
import { linearDiagramPeriodOptions, registeredUsersLinearDiagramFields } from '../../config/constants';
import LinearDiagram from './LinearDiagram';
import Select from '../../../../commonComponents/Select';

const useStyles = makeStyles({
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    height: 250,
    paddingLeft: 12,
    paddingRight: 60,
    marginBottom: 70,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  diagrams: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 50,
  },
  title: {
    marginBottom: 30,
    marginLeft: 48,
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: '19px',
    display: 'flex',
    alignItems: 'center',
  },
  periodPicker: {
    marginLeft: 20,
  },
  linearDiagramDownloadedUsers: {
    backgroundColor: usersDiagramsColorScheme[registeredUsersLinearDiagramFields.downloadedUsers],
  },
  linearDiagramNotDownloadedUsers: {
    backgroundColor: usersDiagramsColorScheme[registeredUsersLinearDiagramFields.notDownloadedUsers],
  },
});

const RegisteredUsersLinearDiagram = ({ categories, handlePeriodChange }) => {
  const classes = useStyles();
  const translate = useTranslate();

  const getTotalRegisteredUsers = (categories) =>
    categories.length ? categories.reduce((acc, cur) => acc + cur.value, 0) : 0;

  const getTotalValue = (categories, fieldName) => {
    const item = categories.find((category) => category.name === fieldName);
    if (item) {
      return item.value || 0;
    }
    return 0;
  };

  const getPercentage = (categories, fieldName) => {
    const total = getTotalRegisteredUsers(categories);

    const item = categories.find((x) => x.name === fieldName);
    if (item && item.value) {
      return ((100 * item.value) / total).toFixed(2);
    }
    return 0;
  };

  const getCategoryTitle = (fieldName) =>
    `${getTotalValue(categories, fieldName)} (${getPercentage(categories, fieldName)}%)`;

  const downloadedTitle = `${translate(registeredUsersLinearDiagramFields.downloadedUsers)}: ${getCategoryTitle(
    registeredUsersLinearDiagramFields.downloadedUsers,
  )}`;
  const downloadedValue = getPercentage(categories, registeredUsersLinearDiagramFields.downloadedUsers);

  const notDownloadedTitle = `${translate(registeredUsersLinearDiagramFields.notDownloadedUsers)}: ${getCategoryTitle(
    registeredUsersLinearDiagramFields.notDownloadedUsers,
  )}`;
  const notDownloadedValue = getPercentage(categories, registeredUsersLinearDiagramFields.notDownloadedUsers);

  const title = translate('Registered Users');
  return (
    <Card className={classes.cardContainer}>
      <div className={classes.title}>
        <div>
          {title}: {getTotalRegisteredUsers(categories)}
        </div>
        <Select
          options={linearDiagramPeriodOptions}
          defaultValue={linearDiagramPeriodOptions[0].id}
          onChange={handlePeriodChange}
          passedValue={linearDiagramPeriodOptions[0].id}
          customClassName={classes.periodPicker}
        />
      </div>
      <div>
        <LinearDiagram
          id={registeredUsersLinearDiagramFields.downloadedUsers}
          title={downloadedTitle}
          value={downloadedValue}
          barColorClass={classes.linearDiagramDownloadedUsers}
          infoTitle={loggedInOrActiveInfoLabel}
        />
        <LinearDiagram
          id={registeredUsersLinearDiagramFields.notDownloadedUsers}
          title={notDownloadedTitle}
          value={notDownloadedValue}
          barColorClass={classes.linearDiagramNotDownloadedUsers}
          infoTitle={loggedInOrActiveInfoLabel}
        />
      </div>
    </Card>
  );
};

export default RegisteredUsersLinearDiagram;

import React from 'react';
import { SimpleForm } from 'react-admin';

import CustomArrayInput from './CustomArrayInput';
import FormIterator from '../core/FormIterator';
import Question from './Question';
import { formTypeTogglerOptions, quizzesQuestionTypes as questionTypes } from '../config/constants';

const pushNewField = ({ fields, nextFormType: questionTypeId }) => {
  let counter;
  switch (questionTypeId) {
    case questionTypes.commonText:
    case questionTypes.picture:
      counter = 4;
      break;
    case questionTypes.trueFalse:
      counter = 2;
      break;
    default:
      counter = 0;
  }

  fields.push({ type_id: questionTypeId, answers: Array(counter).fill({}) });
};

const renderForm = ({ field, member, quizType, index }) => {
  const component = <Question member={member} questionFormType={field.type_id} quizType={quizType} index={index} />;

  return component;
};

const QuizForm = ({ children, quizType, ...rest }) => (
  <SimpleForm {...rest}>
    {children}
    <CustomArrayInput source="questions">
      <FormIterator
        formTypeTogglerOptions={formTypeTogglerOptions}
        renderForm={renderForm}
        pushNewField={pushNewField}
        formTypeTogglerLabel="Question type"
        quizType={quizType}
      />
    </CustomArrayInput>
  </SimpleForm>
);

export default QuizForm;

import TypesIcon from '@material-ui/icons/Title';
import TypesList from './components/TypesList';
import TypeCreate from './components/TypeCreate';
import TypeEdit from './components/TypeEdit';
import { roles, APP_TYPES } from '../../../config/constants';

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: [roles.ADMIN],
  label: 'Transactions Types',
  subMenuName: 'manage',
};

export default {
  list: TypesList,
  create: TypeCreate,
  edit: TypeEdit,
  icon: TypesIcon,
  name: 'admin/transactions/types',
  options,
};

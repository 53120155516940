import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import { APP_TYPES, roles } from '../../../config/constants';

import ActivitiesList from './components/activitiesList';

const allCountries = {
  [APP_TYPES.IRELAND]: [roles.ADMIN, roles.JTI_TEAM, roles.RSM],
  [APP_TYPES.GEORGIA]: [roles.ADMIN, roles.JTI_TEAM],
  [APP_TYPES.ARMENIA]: [roles.ADMIN, roles.JTI_TEAM],
  [APP_TYPES.AZERBAIJAN]: [roles.ADMIN, roles.JTI_TEAM],
  [APP_TYPES.BELARUS]: [roles.ADMIN],
  [APP_TYPES.DEMO]: [roles.ADMIN],
  [APP_TYPES.HUNGARY]: [roles.ADMIN],
};

const ENV_APP_TYPE = process.env.REACT_APP_APP_TYPE;
const rolesForCountries = allCountries[ENV_APP_TYPE];

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: rolesForCountries,
  label: 'Activity',
  subMenuName: 'reports',
};

export default {
  list: ActivitiesList,
  icon: LocalActivityIcon,
  name: 'admin/reports/activities',
  options,
};

import React from 'react';
import {
  BooleanInput,
  Edit,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { isHungary } from '../../../../helpers/appTypeHelper';
import { TiersPricesList } from '../../commonComponents/TiersPricesList';

const ProductEdit = (props) => (
  <Edit title="Edit Product" {...props}>
    <SimpleForm>
      <TextInput label="ID" source="id" disabled />
      <TextInput label="Description" source="description" validate={required()} />
      <NumberInput label="Price" source="price" validate={required()} />
      {isHungary() && <TiersPricesList {...props} />}
      <NumberInput label="Promo price" source="promo_price" />
      <TextInput label="Nominal price" source="nominal_price" validate={required()} />
      <ReferenceInput label="Provider" source="provider_id" reference="admin/bonus-shop/providers">
        <SelectInput validate={required()} />
      </ReferenceInput>
      <ReferenceInput label="Type" source="type" reference="admin/bonus-shop/presents/types">
        <SelectInput optionText="name" validate={required()} />
      </ReferenceInput>
      <BooleanInput label="Active" source="active" />
    </SimpleForm>
  </Edit>
);

export default ProductEdit;

import React from 'react';
import { List, Datagrid, TextField, EditButton, DeleteButton } from 'react-admin';
import StatusField from '../../../../commonComponents/StatusField';
import PromoPriceField from './PromoPriceField';
import ImageField from '../../../../commonComponents/ImageField';
import PresentsFilter from './PresentsFilter';

const PresentsList = (props) => (
  <List
    title="Presents"
    filters={<PresentsFilter />}
    exporter={false}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <TextField label="Name" source="name" />
      <ImageField source="photo" />
      <TextField label="Price" source="price" sortable={false} />
      <PromoPriceField label="Promo price" />
      <TextField label="Priority" source="priority" />
      <StatusField label="Status" source="status" />
      <TextField label="Count" source="count" />
      <EditButton />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
);

export default PresentsList;

import { SimpleFormIterator, TextInput, FormDataConsumer, required, maxLength } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import QuestionForm from './QuestionForm';
import AddButton from '../../../commonComponents/AddButton';

const useStyles = makeStyles({
  customTextField: {
    color: '#000',
    fontSize: '1.2rem',
    marginTop: '17px',
    marginBottom: '15px',
    fontWeight: '600',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});

const Criteria = (props) => {
  const classes = useStyles();

  return (
    <SimpleFormIterator addButton={<AddButton label="Add criteria" />} {...props}>
      <div className={classes.customTextField}>Criteria</div>
      <TextInput label="Criteria name" source="name" validate={[required(), maxLength(60)]} />
      <TextInput label="Good answer" source="good_answer" validate={[required(), maxLength(255)]} />
      <TextInput label="Normal answer" source="normal_answer" validate={[required(), maxLength(255)]} />
      <TextInput label="Bad answer" source="bad_answer" validate={[required(), maxLength(255)]} />
      <FormDataConsumer>
        {({ getSource, formData, scopedFormData, ...rest }) => (
          <QuestionForm source={getSource('questions')} {...rest} />
        )}
      </FormDataConsumer>
    </SimpleFormIterator>
  );
};

export default Criteria;

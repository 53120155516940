import React from 'react';
import { Datagrid, List, TextField } from 'react-admin';
import { CustomDateField } from '../../../../commonComponents/DateField';
import CustomReportListActions from '../../../../commonComponents/ListActions/CustomReportListActions';

import ManagersActivityReportsFilter from './ManagersActivityReportsFilter';

const ManagersActivityReportsList = (props) => (
  <List
    title="Activity managers reports"
    actions={<CustomReportListActions />}
    filters={<ManagersActivityReportsFilter />}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField label="ID" source="id" />
      <CustomDateField label="Date" source="login_date" />
      <TextField label="Position" source="roles" sortable={false} />
      <TextField label="Name" source="name" sortable={false} />
      <TextField label="Surname" source="surname" sortable={false} />
      <TextField label="Email" source="email" sortable={false} />
      <TextField label="Region" source="city" sortable={false} />
    </Datagrid>
  </List>
);

export default ManagersActivityReportsList;

import { useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import { serverApiUrl } from '../../../../config/constants';
import httpClient from '../../../../core/httpClient';
import OverviewBlock from '../OverviewBlock';

const MonthlyActiveUsers = () => {
  const translate = useTranslate();
  const [generalInfo, setGeneralInfo] = useState();
  const [additionalInfo, setAdditionalInfo] = useState();

  const updateProgramOutlets = () => {
    const url = `${serverApiUrl}/admin/outlets-users/monthly-active-users`;
    const options = { method: 'GET' };

    httpClient(url, options)
      .then(({ json: { monthly_active_users, new_monthly_active_users } }) => {
        setGeneralInfo(monthly_active_users);
        setAdditionalInfo(new_monthly_active_users);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    updateProgramOutlets();
  }, []);

  return (
    <OverviewBlock
      generalInfo={generalInfo}
      additionalInfo={additionalInfo}
      description={translate('Monthly active users')}
      tip={translate('root.statistics.Users who have been active in the last 30 days')}
    />
  );
};

export default MonthlyActiveUsers;

import MultibrandQuizIcon from '@material-ui/icons/List';
import MultibrandQuizReportsList from './components/MultibrandQuizReportsList';
import { APP_TYPES, roles } from '../../../config/constants';

const options = {
  appTypes: [APP_TYPES.BELARUS, APP_TYPES.HUNGARY],
  allowedRoles: [roles.ADMIN, roles.JTI_TEAM],
  label: 'Multibrand quiz',
  subMenuName: 'reports',
};

export default {
  list: MultibrandQuizReportsList,
  icon: MultibrandQuizIcon,
  name: 'admin/reports/multibrand-quizzes',
  options,
};

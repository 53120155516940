import SimulatorScenarioList from './components/SimulatorScenarioList';
import SimulatorScenarioCreate from './components/SimulatorScenarioCreate';
import SimulatorScenarioEdit from './components/SimulatorScenarioEdit';
import { roles, APP_TYPES } from '../../../config/constants';

const options = {
  appTypes: [APP_TYPES.BELARUS],
  allowedRoles: [roles.ADMIN],
  label: 'Sales simulator scenarios',
};

export default {
  list: SimulatorScenarioList,
  create: SimulatorScenarioCreate,
  edit: SimulatorScenarioEdit,
  name: 'admin/sales-simulators/scenarios',
  options,
};

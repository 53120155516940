import React from 'react';
import { Filter, ReferenceInput, SelectInput, TextInput } from 'react-admin';
import { CustomDateInput } from '../../../../commonComponents/CustomDateInput';
import { APP_TYPES } from '../../../../config/constants';

const UsersKnowledgeBankArticlesFilter = (props) => (
  <Filter {...props}>
    <TextInput label="User ID" source="user_id" alwaysOn />
    <TextInput label="Article ID" source="article_id" alwaysOn />
    {process.env.REACT_APP_APP_TYPE === APP_TYPES.IRELAND && (
      <ReferenceInput source="tier_id" reference="admin/tiers" alwaysOn>
        <SelectInput source="name" />
      </ReferenceInput>
    )}
    <CustomDateInput label="Choose start date" source="start_date" start alwaysOn />
    <CustomDateInput label="Choose end date" source="end_date" end alwaysOn />
  </Filter>
);

export default UsersKnowledgeBankArticlesFilter;

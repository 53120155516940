import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import { Button, crudGetMatching, FormWithRedirect, SaveButton, TextInput, useNotify } from 'react-admin';
import { ColorInput } from 'react-admin-color-input';
import { serverApiUrl } from '../../../../config/constants';

import httpClient from '../../../../core/httpClient';

const ColorCreate = () => {
  const notify = useNotify();
  const dispatch = useDispatch();
  const [isOpen, setOpenStatus] = useState(false);

  const handleOpenModal = () => {
    setOpenStatus(true);
  };

  const handleClose = () => {
    setOpenStatus(false);
  };

  // Renamed field name due to DOM id duplication
  const handleSubmit = ({ colorName, color }) => {
    const url = `${serverApiUrl}/admin/colors`;
    const options = { method: 'POST', body: JSON.stringify({ name: colorName, color }) };

    httpClient(url, options)
      .then(() => {
        dispatch(
          crudGetMatching(
            'admin/colors',
            'admin/bonus-shop/presents@colors',
            { page: 1, perPage: 100 },
            { field: 'id', order: 'DESC' },
            {},
          ),
        );
        handleClose();
      })
      .catch(() => {
        notify("Error: Color wasn't created", 'warning');
      });
  };

  return (
    <>
      <Button onClick={handleOpenModal} label="Create color">
        <IconContentAdd />
      </Button>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>Add color</DialogTitle>
        <FormWithRedirect
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, submitting, pristine }) => (
            <>
              <DialogContent dividers>
                <TextInput label="Color Name" source="colorName" />
                <ColorInput source="color" />
              </DialogContent>
              <DialogActions>
                <SaveButton disabled={submitting || pristine} handleSubmitWithRedirect={handleSubmitWithRedirect} />
                <Button label="ra.action.cancel" onClick={handleClose}>
                  <IconCancel />
                </Button>
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

export default ColorCreate;

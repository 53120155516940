import RewardsIcon from '@material-ui/icons/Cake';
import RewardCreate from './components/RewardCreate';
import RewardEdit from './components/RewardEdit';
import RewardsList from './components/RewardsList';
import { APP_TYPES, roles } from '../../config/constants';

const options = {
  appTypes: [APP_TYPES.HUNGARY],
  allowedRoles: [roles.ADMIN],
  label: 'Rewards',
  subMenuName: 'content',
};

export default {
  list: RewardsList,
  create: RewardCreate,
  edit: RewardEdit,
  icon: RewardsIcon,
  name: 'admin/unexpected-rewards',
  options,
};

import { FormatAlignJustify } from '@material-ui/icons';
import { APP_TYPES, roles } from '../../config/constants';
import LepCreate from './components/LepCreate';
import LepEdit from './components/LepEdit';
import LepList from './components/LepList';

const options = {
  appTypes: [APP_TYPES.IRELAND],
  allowedRoles: [roles.ADMIN],
  label: 'LEP',
  subMenuName: 'content',
};

export default {
  list: LepList,
  create: LepCreate,
  edit: LepEdit,
  icon: FormatAlignJustify,
  name: 'admin/lep',
  options,
};

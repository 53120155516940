import React from 'react';
import { Datagrid, DeleteButton, EditButton, List, TextField } from 'react-admin';
import { CustomDateField } from '../../../commonComponents/DateField';
import LinkToAttachments from '../../../commonComponents/LinkToAttachments';
import CustomImportButton from '../../../commonComponents/ListActions/CustomImportButton';
import CustomListActions from '../../../commonComponents/ListActions/CustomListActions';

const LepList = (props) => (
  <List
    title="LEP"
    exporter={false}
    bulkActionButtons={false}
    actions={<CustomListActions />}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField label="ID" source="id" />
      <TextField label="Name" source="name" />
      <CustomDateField label="Start Date" source="start_date" />
      <CustomDateField label="End Date" source="end_date" />
      <LinkToAttachments label="User codes" pathname="/admin/lep/codes" recordName="competition_id" />
      <CustomImportButton label="Import codes" />
      <EditButton />
      <DeleteButton mutationMode="optimistic" />
    </Datagrid>
  </List>
);

export default LepList;

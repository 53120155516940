import React, { useState, useEffect } from 'react';
import { NumberInput, ReferenceInput, SelectInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core';
import { useForm } from 'react-final-form';

import ImageInput from '../../../../commonComponents/UploadInputs/ImageInput';
import { s3FilePaths } from '../../../../config/constants';

const coinsType = 1;
const physicalProductType = 2;
const digitalProductType = 3;

const useStyles = makeStyles({
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '15rem',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '15rem',
  },
  fileUploadContainer: {
    display: 'flex',
  },
});

const RewardForm = ({ resource, record }) => {
  const classes = useStyles();
  const form = useForm();
  // type can be either number or object, idk why but parsing both
  const initialType = record && record.type ? record.type.id || record.type : coinsType;
  const [rewardType, setRewardType] = useState(initialType);
  useEffect(() => {
    form.change('reward_type_id', rewardType);
  }, []); // eslint-disable-line

  const handleChange = (event) => {
    setRewardType(event.target.value);

    form.change('coins', null);
    form.change('image', null);
    form.change('digital_product_id', null);
    form.change('physical_product_id', null);

    form.change('reward_type_id', Number(event.target.value));
  };

  const renderCoinsFields = () => (
    <div className={classes.column}>
      <NumberInput label="Coins" source="coins" />
      <NumberInput label="Count" source="count" />
      <ImageInput imageInputSource="image" />
    </div>
  );

  const renderPhysicalProductFields = () => (
    <div className={classes.column}>
      <ReferenceInput
        label="Physical product"
        source="physical_product_id"
        reference="admin/event-mode/physical-products"
        resource={resource}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </div>
  );

  const renderDigitalProductFields = () => (
    <div className={classes.column}>
      <ReferenceInput
        label="Digital product"
        source="digital_product_id"
        reference="admin/event-mode/digital-products"
        resource={resource}
      >
        <SelectInput optionText="description" />
      </ReferenceInput>
      <ImageInput imageInputSource="image" imagePath={s3FilePaths.rewards} />
    </div>
  );

  const renderReward = (type) => {
    switch (type.toString()) {
      case coinsType.toString():
        return renderCoinsFields();
      case physicalProductType.toString():
        return renderPhysicalProductFields();
      case digitalProductType.toString():
        return renderDigitalProductFields();
      default:
        return null;
    }
  };

  return (
    <>
      {/* Because react-final-form won't accept random values without field */}
      <NumberInput source="reward_type_id" style={{ display: 'none' }} />
      <Typography>Coupon type</Typography>
      <RadioGroup value={`${rewardType}`} onChange={handleChange} className={classes.row}>
        <FormControlLabel value={`${coinsType}`} control={<Radio />} label="Coins" />
        <FormControlLabel value={`${physicalProductType}`} control={<Radio />} label="Physical Product" />
        <FormControlLabel value={`${digitalProductType}`} control={<Radio />} label="Digital Product" />
      </RadioGroup>
      {renderReward(rewardType)}
    </>
  );
};

export default RewardForm;

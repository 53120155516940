import React from 'react';
import { Datagrid, EditButton, List, NumberField, ReferenceField, TextField } from 'react-admin';

import ImageField from '../../../../commonComponents/ImageField';
import exporter from '../../../../helpers/xlsxExporter';

const CategoriesList = (props) => (
  <List
    title="Categories"
    exporter={exporter(null, '/admin/products/csv')}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <TextField label="Name" source="name" />
      <ImageField label="Image" source="photo" />
      <ReferenceField
        label="Transaction Type"
        source="transaction_type_id"
        reference="admin/transactions/types"
        link={false}
        sortable={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <NumberField label="Priority" source="priority" />
      <EditButton />
    </Datagrid>
  </List>
);

export default CategoriesList;

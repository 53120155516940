import { BooleanInput, required, TextInput } from 'react-admin';
import React from 'react';

const QuizQuestion = () => (
  <>
    <TextInput label="Answer" source="answers[0].answer" validate={required()} />
    <BooleanInput label="Correct" source="answers[0].correct" />

    <TextInput label="Answer" source="answers[1].answer" validate={required()} />
    <BooleanInput label="Correct" source="answers[1].correct" />

    <TextInput label="Answer" source="answers[2].answer" validate={required()} />
    <BooleanInput label="Correct" source="answers[2].correct" />
  </>
);

export default QuizQuestion;

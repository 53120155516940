export const duplicateRecord = (record) => {
  const mappedRecord = { ...record };
  const mappedQuestions = mappedRecord.questions.map((question) => {
    const { id, number, type, ...mappedQuestion } = question;

    mappedQuestion.answers = mappedQuestion.answers.map((answer) => ({ answer: answer.answer }));

    return mappedQuestion;
  });

  mappedRecord.status = 0;
  mappedRecord.questions = mappedQuestions;

  return mappedRecord;
};

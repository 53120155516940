import React from 'react';
import { Button } from '@mui/material';
import OpenFolder from '../icons/OpenFolder.svg';

const OpenButton = ({ handleClick, ...props }) => (
  <Button
    onClick={(e) => {
      handleClick(props);
      stopPropagation(e);
    }}
    startIcon={<img src={OpenFolder} alt="" />}
    label={'OPEN'}
    style={{ color: '#01c1b6' }}
  >
    OPEN
  </Button>
);

const stopPropagation = (e) => e.stopPropagation();

export default OpenButton;

import React from 'react';
import isEmpty from 'is-empty-typed';

import styles from './TicketsLayout.module.scss';
import { TicketsPreviews } from '../ticketPreviews/TicketsPreviews';
import { TicketsMessages } from '../ticketMessages/TicketsMessages';

export const TicketsLayout = (props) => {
  const { messages } = props;

  return (
    <div className={styles.ticketsLayout}>
      <TicketsPreviews {...props} />
      {!isEmpty(messages) && <TicketsMessages {...props} />}
    </div>
  );
};

import React from 'react';
import { Filter, ReferenceInput, SelectInput, TextInput } from 'react-admin';
import { CustomDateInput } from '../../../../commonComponents/CustomDateInput';

const SalesSimulatorReportsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="User ID" source="user_id" alwaysOn />
    <ReferenceInput label="Simulator" source="simulator_id" reference="admin/sales-simulators" alwaysOn>
      <SelectInput optionText={(s) => `(${s.id}) ${s.name}`} />
    </ReferenceInput>
    <CustomDateInput label="Choose start date" source="start_date" start alwaysOn />
    <CustomDateInput label="Choose end date" source="end_date" end alwaysOn />
  </Filter>
);

export default SalesSimulatorReportsFilter;

import React from 'react';
import { Datagrid, DeleteButton, List, NumberField, TextField } from 'react-admin';
import { CustomDateField } from '../../../commonComponents/DateField';
import CustomListActions from '../../../commonComponents/ListActions/CustomListActions';
import LimitationFilter from './LimitationFilter';

const LimitationsList = (props) => (
  <List
    title="Monthly limitations"
    actions={<CustomListActions importer={false} />}
    filters={<LimitationFilter />}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <TextField label="Hint" source="hint_text" />
      <NumberField label="Coin limit" source="limit" />
      <CustomDateField source="activated_at" showTime={false} />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
);

export default LimitationsList;

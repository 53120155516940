import { required, maxLength, TextInput } from 'react-admin';
import { ColorInput } from 'react-admin-color-input';

import ImageInput from '../../../../commonComponents/UploadInputs/ImageInput';
import withCardWrapper from '../../hocs/withCardWrapper';
import { mainScreenFadingBlockTypes, s3FilePaths } from '../../../../config/constants';

const getFieldName = (member, name) => {
  if (!member) {
    return name;
  }

  return `${member}.${name}`;
};

const FadingBlockComponent = ({ member, type }) => (
  <>
    {type === mainScreenFadingBlockTypes.mystery_shopper && (
      <>
        <TextInput label="Name" source={getFieldName(member, 'name')} validate={[required(), maxLength(60)]} />
        <ColorInput label="Text color" source={getFieldName(member, 'text_color')} validate={required()} />
      </>
    )}

    <ImageInput
      label="Image"
      imagePath={s3FilePaths.allUploads}
      imageInputSource={getFieldName(member, 'image')}
      validate={required()}
    />
  </>
);

export default withCardWrapper(FadingBlockComponent);

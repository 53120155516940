import React from 'react';
import { Datagrid, FunctionField, List, NumberField, TextField } from 'react-admin';
import { CustomDateField } from '../../../../commonComponents/DateField';
import CustomReportListActions from '../../../../commonComponents/ListActions/CustomReportListActions';

const PopupReportList = (props) => (
  <List
    title="Popup reports"
    actions={<CustomReportListActions exporter />}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'ASC' }}
    {...props}
  >
    <Datagrid isRowSelectable={false}>
      <TextField label="ID" source="id" />
      <TextField label="Title" source="title" sortable={false} />
      <FunctionField label="Message" render={(record) => record.message.substring(0, 30)} sortable={false} />
      <CustomDateField source="date_from" showTime={false} sortable={false} />
      <CustomDateField source="date_to" showTime={false} sortable={false} />
      <NumberField label="Users viewed" source="seen_count" emptyText="0" />
      <NumberField label="Clicked action" source="interacted_count" emptyText="0" />
      <NumberField label="Clicked close" source="closed_count" emptyText="0" />
    </Datagrid>
  </List>
);

export default PopupReportList;

import React from 'react';
import { Filter, NumberInput, TextInput, SelectInput, ReferenceInput } from 'react-admin';
import { CustomDateInput } from '../../../../commonComponents/CustomDateInput';
import UserAutocompleteInput from '../../../../commonComponents/UserAutocompleteInput';
import { booleanFilterChoices } from '../../../../config/constants';

const activityTypesChoices = [
  { id: 'Quizzes', name: 'Quizzes' },
  { id: 'Special quizzes', name: 'Special quizzes' },
  { id: 'Articles', name: 'Articles' },
  { id: 'Games', name: 'Games' },
  { id: 'Challenge', name: 'Challenge' },
  { id: 'Daily Question', name: 'Daily Question' },
  { id: 'Promo Code', name: 'Promo Code' },
  { id: 'Invite Code', name: 'Invite Code' },
];

const ActivitiesFilter = (props) => (
  <Filter {...props}>
    <NumberInput label="Week number" source="week_number" alwaysOn />
    <CustomDateInput
      label="Year"
      source="year"
      views={['year']}
      labelFunc={(date) => (date ? date.year() : '')}
      alwaysOn
    />
    <TextInput label="Account code" source="traffic_code" alwaysOn />
    <UserAutocompleteInput label="User ID" source="user_id" alwaysOn />
    <SelectInput source="downloaded" choices={booleanFilterChoices} allowNull alwaysOn />
    <SelectInput source="activity_type" choices={activityTypesChoices} allowNull alwaysOn />
    <ReferenceInput
      label="Brand"
      source="brand_id"
      reference="admin/knowledge-bank/brands"
      filter={{ for_activity_report: true }}
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export default ActivitiesFilter;

import { List, Datagrid, TextField, EditButton, DeleteButton } from 'react-admin';
import StatusField from '../../../commonComponents/StatusField';
import { CustomDateField } from '../../../commonComponents/DateField';
import LinkToAttachments from '../../../commonComponents/LinkToAttachments';
import MysteryShopperFilter from './MysteryShopperFilter';
import CustomListActions from '../../../commonComponents/ListActions/CustomListActions';
import ExportMysteryShopperButton from '../attachments/components/Export';

const MysteryShopperList = (props) => (
  <List
    title="Mystery Shopper"
    exporter={false}
    actions={<CustomListActions importer={false} />}
    filters={<MysteryShopperFilter />}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField label="Id" source="id" />
      <TextField label="Name" source="name" />
      <StatusField label="Status" source="status" />
      <CustomDateField label="Start date" source="start_date" />
      <CustomDateField label="End date" source="end_date" />
      <LinkToAttachments pathname="/admin/mystery-shopper/attachments" recordName="mystery_shopper_id" />
      <EditButton />
      <ExportMysteryShopperButton />
      <DeleteButton />
    </Datagrid>
  </List>
);

export default MysteryShopperList;

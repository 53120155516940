import React, { useEffect, useState } from 'react';
import { CircularProgress, TablePagination, Typography, ThemeProvider } from '@mui/material';
import isEmpty from 'is-empty-typed';
import { DataGrid } from '@mui/x-data-grid';
import { createTheme } from '@mui/material/styles';
import { useNotify } from 'react-admin';
import {
  serverApiUrl,
  tradeProgramAddressesStatus,
  tradeProgramAddressesStatusOptions,
} from '../../../../config/constants';
import httpClient from '../../../../core/httpClient';
import styles from './TradeProgram.module.scss';
import { CustomFilter } from './CustomFilter';
import { CustomActions } from './CustomActions';
import { CellWithTooltip } from '../../../../commonComponents/CellWithTooltip';

const INITIAL_ROWS_PER_PAGE = 10;

const theme = createTheme({
  palette: {
    primary: {
      main: '#01c1b6',
    },
  },
});

const TradeProgramAddresses = ({ history, match }) => {
  const [filters, setFilters] = useState('');
  const [AddressesTradeProgram, setAddressesTradeProgram] = useState(null);
  const [tradeProgram, setTradeProgram] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(INITIAL_ROWS_PER_PAGE);

  const [total, setTotal] = useState(0);
  const tradeProgramId = match.params.id;

  const notify = useNotify();

  useEffect(() => {
    updateProgramOutlets(0, rowsPerPage);
  }, [filters]);

  const toTradeProgramUsers = (address_id) => {
    history.push(`addresses/${address_id}`);
  };

  const updateProgramOutlets = (offset, limit) => {
    setAddressesTradeProgram(null);
    const { manager_id, rsm_id, outlet_code } = filters;

    const url = new URL(
      `${serverApiUrl}/admin/trade-program/${tradeProgramId}/addresses?offset=${offset}&limit=${limit}`,
    );

    !isEmpty(manager_id) && url.searchParams.append('manager_id', manager_id);
    !isEmpty(rsm_id) && url.searchParams.append('rsm_id', rsm_id);
    !isEmpty(outlet_code) && url.searchParams.append('outlet_code', outlet_code);

    const options = { method: 'GET' };

    httpClient(url, options)
      .then(({ json: { trade_program, data, total } }) => {
        setAddressesTradeProgram(data);
        setTradeProgram(trade_program);
        setTotal(total);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const exportButtonHandler = () => {
    const { manager_id, rsm_id, outlet_code } = filters;
    const url = new URL(`${serverApiUrl}/admin/trade-program/${tradeProgramId}/xlsx`);
    const options = { method: 'GET' };

    !isEmpty(manager_id) && url.searchParams.append('manager_id', manager_id);
    !isEmpty(rsm_id) && url.searchParams.append('rsm_id', rsm_id);
    !isEmpty(outlet_code) && url.searchParams.append('outlet_code', outlet_code);

    httpClient(url, options)
      .then((res) => {
        window.location.href = res.json.url;
      })
      .catch(() => {
        notify("Error: file link wasn't loaded", 'warning');
      });
  };

  const editButtonHandler = () => {
    history.push(`admin/trade_program/${tradeProgramId}`);
  };

  const columns = [
    { field: 'address_id', headerName: 'Outlet ID', flex: 1, sortable: false },
    { field: 'addresses.outlet_code', headerName: 'Outlet', flex: 1, sortable: false },
    { field: 'addresses.legal_entities.name', headerName: 'Legal name', flex: 1, sortable: false },
    { field: 'addresses.street', headerName: 'Address', flex: 1, sortable: false },
    { field: 'sell_out_plan', headerName: 'Sell out plan', flex: 1, sortable: false },
    { field: 'sell_in_plan', headerName: 'Sell in plan', flex: 1, sortable: false },
    {
      field: 'status',
      headerName: 'Program status',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const { status, reason_denied } = params.row;
        const isDeniedStatus = status === tradeProgramAddressesStatus.Denied;
        const addressStatusOptions = tradeProgramAddressesStatusOptions.find(
          (tradeProgramStatus) => status === tradeProgramStatus.id,
        );

        return (
          <CellWithTooltip showTooltip={isDeniedStatus} tooltipTitle={reason_denied}>
            <span style={{ color: addressStatusOptions.color }}>{addressStatusOptions.name}</span>
          </CellWithTooltip>
        );
      },
    },
    { field: 'accepted_users', headerName: 'Accepted Users', flex: 1, sortable: false },
    { field: 'declined_users', headerName: 'Declined Users', flex: 1, sortable: false },
  ];

  return (
    <div className={styles.viewContainer}>
      <ThemeProvider theme={theme}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5" style={{ fontWeight: 700 }}>{`Trade programs / ${tradeProgram?.name}`}</Typography>
          <CustomActions editButtonHandler={editButtonHandler} exportButtonHandler={exportButtonHandler} />
        </div>
        <CustomFilter filters={filters} setFilters={setFilters} className={styles.filterContainer} />
        {!AddressesTradeProgram ? (
          <div className={styles.spinnerContainer}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <DataGrid
              rows={AddressesTradeProgram}
              columns={columns}
              onRowClick={(props) => {
                toTradeProgramUsers(props.row.address_id);
              }}
              hideFooterSelectedRowCount
              disableColumnMenu
              autoHeight
              hideFooterPagination
            />

            <TablePagination
              component="div"
              count={total}
              page={page}
              onPageChange={(event, newPage) => {
                updateProgramOutlets(newPage * rowsPerPage, rowsPerPage);
                setPage(newPage);
              }}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={({ target }) => {
                updateProgramOutlets(0, target.value);
                setRowsPerPage(target.value);
                setPage(0);
              }}
            />
          </>
        )}
      </ThemeProvider>
    </div>
  );
};

export default TradeProgramAddresses;

import arUnrecognizedPacksIcon from '@material-ui/icons/LiveHelp';
import ARUnrecognizedPacksList from './components/ARUnrecognizedPacksList';
import { roles, APP_TYPES } from '../../config/constants';

const options = {
  appTypes: [APP_TYPES.HUNGARY],
  allowedRoles: [roles.ADMIN],
  label: 'AR Unrecognized Packs',
  subMenuName: 'content',
};

export default {
  list: ARUnrecognizedPacksList,
  icon: arUnrecognizedPacksIcon,
  name: 'admin/unrecognized-packs',
  options,
};

import React from 'react';
import { Edit, SelectInput, SimpleForm, TextInput } from 'react-admin';
import { CustomDateInput } from '../../../../commonComponents/CustomDateInput';
import { statuses } from '../../../../config/constants';
import QuestionForm from './QuestionForm';

const QuizEdit = (props) => (
  <Edit title="Edit Quiz" undoable={false} {...props}>
    <SimpleForm redirect="list">
      <TextInput label="Name" source="name" />
      <CustomDateInput label="Activates at" source="activated_at" />
      <SelectInput source="status" optionText="name" choices={statuses.slice(0, 2)} />
      <QuestionForm name="questions" />
    </SimpleForm>
  </Edit>
);

export default QuizEdit;

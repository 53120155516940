import NewDownloadsIcon from '@material-ui/icons/SystemUpdate';
import NewDownloadsList from './components/NewDownloadsList';
import { APP_TYPES, roles } from '../../../config/constants';

const allCountries = {
  [APP_TYPES.IRELAND]: [roles.ADMIN, roles.JTI_TEAM, roles.RSM],
  [APP_TYPES.GEORGIA]: [roles.ADMIN, roles.JTI_TEAM],
  [APP_TYPES.ARMENIA]: [roles.ADMIN, roles.JTI_TEAM],
  [APP_TYPES.AZERBAIJAN]: [roles.ADMIN, roles.JTI_TEAM],
  [APP_TYPES.BELARUS]: [roles.ADMIN],
  [APP_TYPES.DEMO]: [roles.ADMIN],
  [APP_TYPES.HUNGARY]: [roles.ADMIN],
};

const ENV_APP_TYPE = process.env.REACT_APP_APP_TYPE;
const rolesForCountries = allCountries[ENV_APP_TYPE];

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: rolesForCountries,
  label: 'New downloads',
  subMenuName: 'reports',
};

export default {
  list: NewDownloadsList,
  icon: NewDownloadsIcon,
  name: 'admin/reports/new-downloads',
  options,
};

import KnowledgeBankCategoryIcon from '@material-ui/icons/Dashboard';
import CategoriesList from './components/CategoriesList';
import CategoriesCreate from './components/CategoriesCreate';
import CategoriesEdit from './components/CategoriesEdit';
import { roles, APP_TYPES } from '../../../config/constants';

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: [roles.ADMIN],
  label: 'Categories',
  subMenuName: 'knowledgeBank',
};

export default {
  list: CategoriesList,
  create: CategoriesCreate,
  edit: CategoriesEdit,
  icon: KnowledgeBankCategoryIcon,
  name: 'admin/knowledge-bank/categories',
  options,
};

import LoyaltyProgramIcon from '@material-ui/icons/Extension';
import LoyaltyProgramsReportsList from './components/LoyaltyProgramsReportsList';
import { APP_TYPES, roles } from '../../../config/constants';

const options = {
  appTypes: [APP_TYPES.IRELAND],
  allowedRoles: [roles.ADMIN, roles.JTI_TEAM],
  label: 'Loyalty programs',
  subMenuName: 'reports',
};

export default {
  list: LoyaltyProgramsReportsList,
  icon: LoyaltyProgramIcon,
  name: 'admin/reports/loyalty-programs',
  options,
};

import React from 'react';
import { Create, NumberInput, ReferenceInput, SelectInput, SimpleForm, TextInput } from 'react-admin';
import ImageInput from '../../../../commonComponents/UploadInputs/ImageInput';
import { s3FilePaths } from '../../../../config/constants';

const CategoryCreate = (props) => (
  <Create title="Add Category" {...props}>
    <SimpleForm redirect="list">
      <TextInput label="ID" source="id" />
      <TextInput label="Name" source="name" />
      <ImageInput imageInputSource="photo" imagePath={s3FilePaths.allUploads} />
      <ReferenceInput
        label="Transaction Type"
        source="transaction_type_id"
        reference="admin/transactions/types"
        perPage={100}
      >
        <SelectInput />
      </ReferenceInput>
      <NumberInput label="Priority" source="priority" />
    </SimpleForm>
  </Create>
);

export default CategoryCreate;

import React from 'react';
import { Datagrid, DeleteButton, List, TextField } from 'react-admin';
import DuplicateButton from '../../../commonComponents/DuplicateButton';
import PrizeField from '../../../commonComponents/PrizeField';
import StatusField from '../../../commonComponents/StatusField';

const ChanceList = (props) => {
  const duplicateRecord = (record) => {
    const { win_number, id, ...mappedRecord } = record;
    mappedRecord.status = 0;
    mappedRecord.winner_count = record.win_number.length;

    return mappedRecord;
  };

  return (
    <List title="Chance" exporter={false} bulkActionButtons={false} sort={{ field: 'id', order: 'DESC' }} {...props}>
      <Datagrid rowClick="edit">
        <TextField label="ID" source="id" />
        <TextField label="Name" source="name" sortable={false} />
        <TextField label="Total" source="total" sortable={false} />
        <StatusField label="Status" source="status" />
        <TextField label="Cost" source="cost" sortable={false} />
        <PrizeField label="Prize" />
        <DuplicateButton copyRecord={duplicateRecord} />
        <DeleteButton undoable={false} />
      </Datagrid>
    </List>
  );
};

export default ChanceList;

import LevelPackage from '@material-ui/icons/CardGiftcard';
import { APP_TYPES, roles } from '../../config/constants';
import LevelsPackagesList from './components/LevelsPackagesList';
import LevelsPackageCreate from './components/LevelsPackageCreate';
import LevelsPackageEdit from './components/LevelsPackageEdit';

const options = {
  appTypes: [APP_TYPES.HUNGARY],
  allowedRoles: [roles.ADMIN],
  label: 'Levels Packages',
  subMenuName: 'manage',
};

export default {
  create: LevelsPackageCreate,
  list: LevelsPackagesList,
  edit: LevelsPackageEdit,
  icon: LevelPackage,
  name: 'admin/levels-packages',
  options,
};

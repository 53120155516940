import {
  APP_TYPES,
  statuses,
  belarusUserInputStatuses,
  customStatuses,
  caucasusInputStatuses,
} from '../config/constants';
import { isBelarus, isCaucasus } from './appTypeHelper';

export const getUserStatusesForAppType = () => {
  const appType = process.env.REACT_APP_APP_TYPE;
  if (isBelarus()) return belarusUserInputStatuses;
  if (isCaucasus) return caucasusInputStatuses;
  if ([APP_TYPES.IRELAND, APP_TYPES.HUNGARY].includes(appType)) return customStatuses;
  return statuses;
};

import { makeStyles } from '@material-ui/core/styles';
import { Tooltip } from '@mui/material';
import { Typography } from '@material-ui/core';
import formatNumber from '../../../helpers/numberFormatter';

const useStyles = makeStyles({
  block: {
    height: '124px',
    width: '90%',
    minWidth: 254,
    marginRight: '10px',
    border: '1px solid #F1F1F1',
    borderRadius: 8,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    display: 'flex',
    justifyContent: 'space-between',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20,
    marginLeft: 20,
  },
  tip: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 8,
    minWidth: 8,
    border: '2px solid #01C1B6',
    borderRadius: 50,
    padding: 4,
    color: '#01C1B6',
    margin: 15,
  },
  info: {
    display: 'flex',
    marginBottom: 10,
  },
  generalInfo: {
    fontSize: 36,
    fontWeight: 400,
    marginRight: 15,
  },
  additionalInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 16,
    border: '1px solid',
    borderRadius: 20,
    padding: '3px 10px',
    fontSize: 12,
    fontWeight: 400,
  },
});

const OverviewBlock = ({ generalInfo, additionalInfo, description, tip }) => {
  const classes = useStyles();

  const showAdditionalInfo = Number.isInteger(additionalInfo) && additionalInfo !== 0;

  const formattedAdditionalInfo = additionalInfo > 0 ? `+${additionalInfo}` : additionalInfo;

  return (
    <div className={classes.block}>
      <div className={classes.content}>
        <div className={classes.info}>
          <Typography className={classes.generalInfo}>{formatNumber(generalInfo)}</Typography>
          {showAdditionalInfo && (
            <Typography
              className={classes.additionalInfo}
              style={{ borderColor: additionalInfo < 0 ? '#FF6666' : '#4CBF8F' }}
            >
              {formattedAdditionalInfo}
            </Typography>
          )}
        </div>
        <Typography>{description}</Typography>
      </div>
      <Tooltip title={tip} placement="top">
        <div className={classes.tip}>i</div>
      </Tooltip>
    </div>
  );
};

export default OverviewBlock;

import BannerIcon from '@material-ui/icons/Image';
import BannerCreate from './components/BannerCreate';
import BannerEdit from './components/BannerEdit';
import BannerList from './components/BannerList';
import { APP_TYPES, roles } from '../../config/constants';

const options = {
  appTypes: [APP_TYPES.ARMENIA, APP_TYPES.GEORGIA, APP_TYPES.AZERBAIJAN, APP_TYPES.BELARUS],
  allowedRoles: [roles.ADMIN],
  label: 'Category Banners',
  subMenuName: 'content',
};

export default {
  create: BannerCreate,
  list: BannerList,
  edit: BannerEdit,
  icon: BannerIcon,
  name: 'admin/banners',
  options,
};

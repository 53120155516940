import React from 'react';
import { BooleanInput, Filter } from 'react-admin';

const ModerationFilter = (props) => (
  <Filter {...props}>
    <BooleanInput source="correct" />
    <BooleanInput source="moderation" />
  </Filter>
);

export default ModerationFilter;

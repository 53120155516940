import React, { useEffect, useState } from 'react';
import { Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useNotify, useTranslate } from 'react-admin';
import {
  APP_TYPES,
  CAUCASUS_TYPES,
  linearDiagramFields,
  serverApiUrl,
  usersDiagramsColorScheme,
} from '../../../../../config/constants';
import httpClient from '../../../../../core/httpClient';

import CardWithDiagram from '../CardWithDiagram';
import './BAOverviewCardSet.css';

const useStyles = makeStyles({
  cardsContainer: {
    display: 'flex',
    width: 'calc(100% - 40px)',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    margin: '0 20px',
  },
  header: {
    height: 40,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  overviewContainer: {
    width: '100%',
    marginBottom: 20,
  },
});

const getBAsOverviewData = async () => {
  const url = `${serverApiUrl}/admin/users/manager/overview`;
  const options = { method: 'GET' };

  return httpClient(url, options);
};

const ENV_APP_TYPE = process.env.REACT_APP_APP_TYPE;

const BAOverviewCardSet = () => {
  const notify = useNotify();
  const classes = useStyles();
  const translate = useTranslate();
  const [selectedItems, setSelectedItems] = useState([]);
  const [isComponentCollapsed, setComponentCollapse] = useState(true);
  const [managersData, setManagersData] = useState([]);

  if ((ENV_APP_TYPE === APP_TYPES.HUNGARY || CAUCASUS_TYPES.includes(ENV_APP_TYPE)) && managersData.length) {
    managersData.forEach((manager) => {
      // eslint-disable-next-line no-param-reassign
      manager.data = manager.data.filter((item) => item.name !== linearDiagramFields.activeUsers);
    });
  }

  useEffect(() => {
    getBAsOverviewData()
      .then((response) => {
        setManagersData(response.json);
      })
      .catch((e) => {
        notify(`GET BAs overview data error: ${e.message}`, 'warning');
      });
  }, [notify]);

  const handleClick = (node, nodeIndex) => {
    const index = selectedItems.findIndex((x) => x.name === node.name);
    if (index === -1) {
      setSelectedItems(selectedItems.slice().concat({ name: node.name, value: node.value, index: nodeIndex }));
    } else {
      setSelectedItems(selectedItems.slice(0, index).concat(selectedItems.slice(index + 1)));
    }
  };

  const handleClickOutside = () => {
    setSelectedItems([]);
  };

  const toggleOverviewSection = () => {
    setComponentCollapse(!isComponentCollapsed);
  };

  return (
    <Card className={classes.overviewContainer}>
      <div className={classes.header} onClick={toggleOverviewSection}>
        {translate('root.statistics.tmOverview')}
        <div className={`arrow ${isComponentCollapsed ? '' : 'open'}`}>
          <span className="arrow-top" />
          <span className="arrow-bottom" />
        </div>
      </div>
      <div className={classes.cardsContainer}>
        {!isComponentCollapsed
          ? managersData.map((manager, index) => (
              <CardWithDiagram
                key={`${manager.name}-${index}`}
                title={manager.name}
                data={manager.data}
                colors={usersDiagramsColorScheme}
                selectedItems={selectedItems}
                handleClick={handleClick}
                handleClickOutside={handleClickOutside}
              />
            ))
          : null}
      </div>
    </Card>
  );
};

export default BAOverviewCardSet;

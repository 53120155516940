import SalesTipsIcon from '@material-ui/icons/Help';
import SalesSimulatorList from './components/SalesSimulatorList';
import SalesSimulatorCreate from './components/SalesSimulatorCreate';
import SalesSimulatorEdit from './components/SalesSimulatorEdit';
import { roles, APP_TYPES } from '../../config/constants';

const options = {
  appTypes: [APP_TYPES.BELARUS],
  allowedRoles: [roles.ADMIN],
  label: 'Sales simulator',
  subMenuName: 'content',
};

export default {
  list: SalesSimulatorList,
  create: SalesSimulatorCreate,
  edit: SalesSimulatorEdit,
  icon: SalesTipsIcon,
  name: 'admin/sales-simulators',
  options,
};

import React from 'react';
import { Datagrid, List, TextField } from 'react-admin';
import { CustomDateField } from '../../../../commonComponents/DateField';
import CustomReportListActions from '../../../../commonComponents/ListActions/CustomReportListActions';

import ProductOrderReportsFilter from './ProductOrderReportsFilter';

const ProductOrderReportsList = (props) => (
  <List
    title="Order reports"
    actions={<CustomReportListActions />}
    filters={<ProductOrderReportsFilter />}
    bulkActionButtons={false}
    sort={{ field: 'created_at', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField label="ID" source="id" sortable={false} />
      <TextField label="User ID" source="user_id" sortable={false} />
      <TextField label="Product ID" source="product_id" sortable={false} />
      <TextField label="Product Name" source="product_description" sortable={false} />
      <TextField label="User Name" source="username" sortable={false} />
      <TextField label="Phone" source="phone" sortable={false} />
      <TextField label="Price" source="product_price" sortable={false} />
      <CustomDateField label="Date created" source="created_at" sortable={false} />
      <TextField label="Внешний код торговой точки" source="outlet_code" sortable={false} />
    </Datagrid>
  </List>
);

export default ProductOrderReportsList;

import React from 'react';
import { AutocompleteInput, Filter, ReferenceInput, SelectInput, TextInput } from 'react-admin';
import { CustomDateInput } from '../../../../commonComponents/CustomDateInput';
import { ticketStatusesForComponent } from '../../../../config/constants';

const TicketsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="User ID" source="user_id" alwaysOn />
    <CustomDateInput label="Last message: Start" source="last_message_date_from" start alwaysOn />
    <CustomDateInput label="Last message: End" source="last_message_date_to" end alwaysOn />
    <CustomDateInput label="Created at: Start" source="created_at_from" start alwaysOn />
    <CustomDateInput label="Created at: End" source="created_at_to" end alwaysOn />

    <ReferenceInput source="problem_id" reference="admin/problems/2/problems/autocomplete" perPage={10000} alwaysOn>
      <AutocompleteInput />
    </ReferenceInput>

    <SelectInput source="status" optionText="name" choices={ticketStatusesForComponent} alwaysOn />
    <TextInput label="Word search" source="word_search" alwaysOn />
  </Filter>
);

export default TicketsFilter;

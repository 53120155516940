import React from 'react';
import { Datagrid, List, ReferenceField, TextField } from 'react-admin';

import OrderReportsFilter from './OrderReportsFilter';
import CustomReportListActions from '../../../../commonComponents/ListActions/CustomReportListActions';
import { CustomDateField } from '../../../../commonComponents/DateField';
import { APP_TYPES, CAUCASUS_TYPES } from '../../../../config/constants';

const OrderReportsList = (props) => (
  <List
    title="Order reports"
    actions={<CustomReportListActions />}
    filters={<OrderReportsFilter />}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField label="User ID" source="user_id" sortable={false} />
      <TextField label="Status" source="status" sortable={false} />
      <TextField label="Product ID" source="product_id" />
      <TextField label="Product Name" source="product_name" sortable={false} />
      <TextField label="Name" source="user_name" sortable={false} />
      {!CAUCASUS_TYPES.includes(process.env.REACT_APP_APP_TYPE) && (
        <TextField label="Email" source="user_email" sortable={false} />
      )}
      <TextField label="Phone" source="user_phone" sortable={false} />
      <CustomDateField label="Date created" source="created_at" sortable={false} />
      <ReferenceField label="Type" source="type" reference="admin/reports/orders/types" link={false}>
        <TextField source="name" />
      </ReferenceField>
      {process.env.REACT_APP_APP_TYPE === APP_TYPES.BELARUS && (
        <TextField label="Внешний код торговой точки" source="account_code" />
      )}
    </Datagrid>
  </List>
);

export default OrderReportsList;

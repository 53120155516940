import { Button, Card, CardContent, Link, TextField, ThemeProvider } from '@mui/material';
import React, { useState } from 'react';
import { Title, UrlField } from 'react-admin';

import styles from './MediaView.module.scss';
import { serverApiUrl } from '../../config/constants';
import customTheme from '../../core/customTheme';
import httpClient from '../../core/httpClient';

export const MediaView = () => {
  const [fileKey, setFileKey] = useState('');
  const [imageUrl, setImageUrl] = useState(null);

  const onInputChange = (e) => setFileKey(e.target.value);

  const getImageUrl = async () => {
    const { json } = await httpClient(`${serverApiUrl}/services/file?file_key=${fileKey}`);
    const { url } = json;
    setImageUrl(url);
  };

  return (
    <Card className={styles.mediaView}>
      <ThemeProvider theme={customTheme}>
        <Title title="Media View" />
        <CardContent>
          <TextField
            onChange={onInputChange}
            value={fileKey}
            sx={{ width: '100%' }}
            InputProps={{ className: styles.input }}
            label="File Key"
            variant="filled"
          />
          <Button className={styles.button} variant="contained" onClick={getImageUrl} disabled={!fileKey}>
            Search
          </Button>
          {imageUrl && (
            <>
              <UrlField label="ID Card Photo Url" source={imageUrl} target="_blank" />
              <img className={styles.image} src={imageUrl} alt="Media File" />
              <Link variant="body2" href={imageUrl}>
                Download File
              </Link>
            </>
          )}
        </CardContent>
      </ThemeProvider>
    </Card>
  );
};

import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card } from '@mui/material';
import { useTranslate } from 'react-admin';

import httpClient from '../../../../../core/httpClient';

import { serverApiUrl } from '../../../../../config/constants';
import OverviewBlock from '../../OverviewBlock';

const useStyles = makeStyles({
  overview: {
    height: '289px',
    padding: '0 60px',
    marginBottom: '30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  title: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '22px',
    fontWeight: 700,
  },
  blocks: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

const UsersOverview = ({ outletId }) => {
  const translate = useTranslate();
  const classes = useStyles();

  const [legalEntityName, setLegalEntityName] = useState('');
  const [activeStatistic, setActiveStatistic] = useState({});
  const [downloadStatistic, setDownloadStatistic] = useState({});
  const [notActiveStatistic, setNotActiveStatistic] = useState({});
  const [registeredStatistic, setRegisteredStatistic] = useState({});

  const getOutletStatistic = () => {
    const url = `${serverApiUrl}/admin/outlets-users/outlet/statistic?outlet_address_id=${outletId}`;
    const options = { method: 'GET' };

    httpClient(url, options)
      .then(({ json }) => {
        const { active_statistic, download_statistic, legal_entity_name, not_active_statistic, registered_statistic } =
          json;

        setLegalEntityName(legal_entity_name);
        setActiveStatistic(active_statistic);
        setDownloadStatistic(download_statistic);
        setNotActiveStatistic(not_active_statistic);
        setRegisteredStatistic(registered_statistic);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOutletStatistic();
  }, []);

  return (
    <Card className={classes.overview}>
      <div className={classes.title}>{legalEntityName}</div>
      <div className={classes.blocks}>
        <OverviewBlock
          generalInfo={registeredStatistic.registered_users}
          additionalInfo={registeredStatistic.new_registered_users}
          description={translate('Registered users')}
          tip={translate('root.statistics.The total number of users at the outlet')}
        />
        <OverviewBlock
          generalInfo={downloadStatistic.download_users}
          additionalInfo={registeredStatistic.new_download_users}
          description={translate('Downloaded user')}
          tip={translate('root.statistics.The number of users who have logged in to the App at the outlet')}
        />
        <OverviewBlock
          generalInfo={activeStatistic.active_users}
          additionalInfo={activeStatistic.new_active_users}
          description={translate('Active users')}
          tip={translate('root.statistics.Users who have been active in the last 30 days at the outlet')}
        />
        <OverviewBlock
          generalInfo={notActiveStatistic.not_active_users}
          additionalInfo={notActiveStatistic.new_not_active_users}
          description={translate('Not active users')}
          tip={translate("root.statistics.Users who have't been active in the last 30 days  at the outlet")}
        />
      </div>
    </Card>
  );
};

export default UsersOverview;

import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import CustomReportListActions from '../../../../commonComponents/ListActions/CustomReportListActions';

import QuizReportsFilter from './QuizReportsFilter';
import { CustomDateField } from '../../../../commonComponents/DateField';
import { APP_TYPES } from '../../../../config/constants';

const QuizReportsList = (props) => (
  <List
    title="Quiz reports"
    actions={<CustomReportListActions />}
    filters={<QuizReportsFilter />}
    filterDefaultValues={{ type_ids: [3] }}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField label="Quiz ID" source="game_id" sortable={false} />
      <TextField label="Winner ID" source="winner_id" sortable={false} />
      <TextField label="User ID" source="user_id" sortable={false} />
      <TextField label="User phone" source="user_phone" sortable={false} />
      <TextField label="User points" source="user_coins" sortable={false} />
      <TextField label="User kudos" source="user_points" sortable={false} />
      <TextField label="Opponent ID" source="opponent_id" sortable={false} />
      <TextField label="Opponent phone" source="opponent_phone" sortable={false} />
      <TextField label="Opponent points" source="opponent_coins" sortable={false} />
      <TextField label="Opponent kudos" source="opponent_points" sortable={false} />
      <CustomDateField label="Date" source="date" sortable={false} />
      {process.env.REACT_APP_APP_TYPE === APP_TYPES.BELARUS && (
        <TextField label="Внешний код торговой точки" source="account_code" />
      )}
    </Datagrid>
  </List>
);

export default QuizReportsList;

import { ReferenceInput, SelectInput } from 'react-admin';
import React from 'react';

function BrandSelector(props) {
  return (
    <ReferenceInput
      label={props.label || 'Brand'}
      source={props.source || 'brand_id'}
      reference="admin/brands"
      perPage={5000}
      allowEmpty
      emptyValue={null}
      {...props}
    >
      <SelectInput optionText={(brand) => brand.name} />
    </ReferenceInput>
  );
}

export default BrandSelector;

import React from 'react';
import { List, Datagrid, TextField, BooleanField, FunctionField } from 'react-admin';
import ArrayField from '../../../commonComponents/ArrayField';
import ModerationButton from './ModerationButton';
import ModerationFilter from './ModerationFilter';

const InteractiveModerationList = (props) => (
  <List
    title="Interactive Moderation"
    exporter={false}
    filters={<ModerationFilter />}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField label="Article ID" source="interactive.article.id" />
      <TextField label="User ID" source="user.id" />

      <ArrayField source="user_answers" />

      <BooleanField label="Correct" source="correct" />
      <BooleanField label="Moderation" source="moderation" />

      <FunctionField
        render={(record) =>
          !record.moderation && (
            <>
              <ModerationButton record={record} statusToSet={true} />
              <ModerationButton record={record} statusToSet={false} />
            </>
          )
        }
      />
    </Datagrid>
  </List>
);

export default InteractiveModerationList;

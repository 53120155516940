import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

import { APP_TYPES, roles } from '../../config/constants';
import OutletsAndUsersList from './components/OutletsAndUsersList';

const options = {
  appTypes: [APP_TYPES.HUNGARY],
  allowedRoles: [roles.BA, roles.RSM, roles.JTI_TEAM],
};

export default {
  list: OutletsAndUsersList,
  icon: AssignmentIndIcon,
  name: 'admin/outlets-users',
  options,
};

import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';

import CustomReportListActions from '../../../../commonComponents/ListActions/CustomReportListActions';
import InteractivesReportsFilter from './InteractivesReportsFilter';
import { CustomDateField } from '../../../../commonComponents/DateField';

const InteractivesReportsList = (props) => (
  <List
    title="Interactives reports"
    actions={<CustomReportListActions />}
    filters={<InteractivesReportsFilter />}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField label="ID" source="id" />
      <TextField label="User ID" source="user_id" />
      <TextField label="Category Type" source="category_type" sortable={false} />
      <TextField label="Article Name" source="article_name" sortable={false} />
      <TextField label="Question ID" source="question_id" sortable={false} />
      <TextField label="Question Name" source="question" sortable={false} />
      <TextField source="user_answers" sortable={false} />
      <TextField source="coins" sortable={false} />
      <CustomDateField source="transaction_time" sortable={false} />
      <TextField source="transaction_id" sortable={false} />
    </Datagrid>
  </List>
);

export default InteractivesReportsList;

import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  activeButton: {
    padding: '0 25px',
    height: '41px',
    background: '#01C1B6',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    color: 'white',
    fontWeight: '700',
    fontSize: '18px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    marginRight: '20px',
    cursor: 'pointer',
  },
  button: {
    padding: '0 25px',
    height: '41px',
    background: 'transparent',
    border: '1px dashed #01C1B6',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    color: 'black',
    fontWeight: '400',
    fontSize: '18px',
    marginRight: '20px',
    cursor: 'pointer',
  },
  arrowUp: {
    marginLeft: '15px',
    borderBottom: '14px solid white',
    borderLeft: '8px solid transparent',
    borderRight: '8px solid transparent',
  },
  arrowDown: {
    marginLeft: '15px',
    borderTop: '14px solid white',
    borderLeft: '8px solid transparent',
    borderRight: '8px solid transparent',
  },
});

const SortButton = ({ label, field, sortBy, setSortBy }) => {
  const classes = useStyles();
  const [toggle, setToggle] = useState(false);

  const isActive = field === sortBy.field;

  const order = {
    0: 'DESC',
    1: 'ASC',
  };

  const onClick = () => {
    setSortBy({ field, order: order[Number(toggle)] });
    setToggle(!toggle);
  };

  return (
    <div className={isActive ? classes.activeButton : classes.button} onClick={onClick}>
      <span>{label}</span>
      {isActive && (toggle ? <span className={classes.arrowUp} /> : <span className={classes.arrowDown} />)}
    </div>
  );
};

export default SortButton;

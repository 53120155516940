import React from 'react';
import { Filter, TextInput } from 'react-admin';

const AdministratorsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name" source="name" alwaysOn />
    <TextInput label="Username" source="username" alwaysOn />
  </Filter>
);

export default AdministratorsFilter;

import TransferIcon from '@material-ui/icons/Receipt';
import { APP_TYPES, roles } from '../../../config/constants';
import TransfersReportsList from './components/TransfersReportsList';

const options = {
  appTypes: [APP_TYPES.GEORGIA],
  allowedRoles: [roles.ADMIN, roles.JTI_TEAM],
  label: 'Transfers',
  subMenuName: 'reports',
};

export default {
  list: TransfersReportsList,
  icon: TransferIcon,
  name: 'admin/reports/transfers',
  options,
};

import { Card } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useTranslate } from 'react-admin';

import UserStatus from './UserStatus';

const backgroundColors = ['#29C8BF', '#1F968F', '#146460', '#0A3230'];

const useStyles = makeStyles({
  overview: {
    height: '198px',
    padding: '0 60px',
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  upperInfo: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  avatarBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  name: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '18px',
    fontWeight: 700,
    marginLeft: '15px',
  },
  avatar: {
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontWeight: 700,
    fontSize: '25px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  info: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  divider: {
    margin: '0 10px',
    opacity: 0.3,
  },
  infoBlock: {
    width: '143px',
    display: 'flex',
    flexDirection: 'column',
  },
  infoValue: {
    fontWeight: 400,
    fontSize: '22px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    marginBottom: '12px',
  },
  infoDescription: {
    fontWeight: 400,
    fontSize: '14px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  title: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '22px',
    fontWeight: 700,
  },
  blocks: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

const UserCard = ({
  name,
  surname,
  balance,
  brandKnowledge,
  earnedCredits,
  lastActivity,
  spentCredits,
  status,
  leaderboardPosition,
  photo,
}) => {
  const classes = useStyles();
  const translate = useTranslate();

  const formatValue = (value, sign) => (value === 0 ? value : sign + value);

  function getRandomAvatarIndex() {
    return Math.floor(Math.random() * 4);
  }

  const userAvatar = photo
    ? { background: `url(${photo})`, backgroundSize: 'cover' }
    : { backgroundColor: photo || `${backgroundColors[getRandomAvatarIndex()]}` };

  return (
    <Card className={classes.overview}>
      <div className={classes.upperInfo}>
        <div className={classes.avatarBlock}>
          <div className={classes.avatar} style={userAvatar}>
            {!photo && name[0]}
          </div>
          <div className={classes.name}>{`${name} ${surname}`}</div>
        </div>
        {status !== null ? <UserStatus status={status} /> : 'N/A'}
      </div>
      <div className={classes.info}>
        <div className={classes.infoBlock}>
          <div className={classes.infoValue}>{lastActivity ? moment(lastActivity).format('DD.MM.YYYY') : 'N/A'}</div>
          <div className={classes.infoDescription}>{translate('Last activity')}</div>
        </div>
        <hr className={classes.divider} />
        <div className={classes.infoBlock}>
          <div className={classes.infoValue}>{balance}</div>
          <div>{translate('Balance')}</div>
        </div>
        <hr className={classes.divider} />
        <div className={classes.infoBlock}>
          <div className={classes.infoValue}>{formatValue(earnedCredits, '+')}</div>
          <div>{translate('Earned credits')}</div>
        </div>
        <hr className={classes.divider} />
        <div className={classes.infoBlock}>
          <div className={classes.infoValue}>{spentCredits}</div>
          <div>{translate('Spent credits')}</div>
        </div>
        <hr className={classes.divider} />
        <div className={classes.infoBlock}>
          <div className={classes.infoValue}>#{leaderboardPosition}</div>
          <div>{translate('Leaderboard')}</div>
        </div>
        <hr className={classes.divider} />
        <div className={classes.infoBlock}>
          <div className={classes.infoValue}>{brandKnowledge || 'N/A'}</div>
          <div>{translate('Brand knowledge')}</div>
        </div>
      </div>
    </Card>
  );
};

export default UserCard;

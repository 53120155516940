import React from 'react';
import { Datagrid, List, ReferenceField, TextField } from 'react-admin';
import { CustomDateField } from '../../../../commonComponents/DateField';
import CustomReportListActions from '../../../../commonComponents/ListActions/CustomReportListActions';
import { APP_TYPES } from '../../../../config/constants';

import SpecialQuizReportsFilter from './SpecialQuizReportsFilter';

const SpecialQuizReportsList = (props) => {
  const ENV_APP_TYPE = process.env.REACT_APP_APP_TYPE;
  return (
    <List
      title="Special quiz reports"
      actions={<CustomReportListActions />}
      filters={<SpecialQuizReportsFilter />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}
    >
      <Datagrid>
        <TextField label="Game ID" source="id" />
        <TextField label="Quiz ID" source="pack.id" />
        <ReferenceField label="Topic" reference="admin/brands" source="pack.brand.id">
          <TextField source="name" />
        </ReferenceField>
        <TextField label="Quiz name" source="pack.name" />
        <TextField label="User ID" source="user_id" />
        <TextField label="User name" source="user.name" />
        <TextField label="User surname" source="user.surname" />
        <TextField label="User phone" source="user.phone" />
        {ENV_APP_TYPE === APP_TYPES.HUNGARY && <TextField label="City" source="user.city.name" sortable={false} />}
        {ENV_APP_TYPE === APP_TYPES.HUNGARY && <TextField label="Shop ID" source="user.shop_id" />}
        <TextField
          label={ENV_APP_TYPE === APP_TYPES.BELARUS ? 'Внешний код торговой точки' : 'Account code'}
          source="user.traffic_code"
        />
        <CustomDateField label="Date" source="created_at" />
        <TextField label="Points" source="transactions[0].coins" sortable={false} />
      </Datagrid>
    </List>
  );
};

export default SpecialQuizReportsList;

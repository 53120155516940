import SingleModeIcon from '@material-ui/icons/VideogameAsset';
import SingleModeList from './components/SingleModeList';
import SingleModeCreate from './components/SingleModeCreate';
import SingleModeEdit from './components/SingleModeEdit';
import { roles, APP_TYPES } from '../../config/constants';

const options = {
  appTypes: [APP_TYPES.HUNGARY],
  allowedRoles: [roles.ADMIN],
  label: 'Single mode',
  subMenuName: 'content',
};

export default {
  list: SingleModeList,
  create: SingleModeCreate,
  edit: SingleModeEdit,
  icon: SingleModeIcon,
  name: 'admin/single-mode',
  options,
};

import StatisticsIcon from '@material-ui/icons/Description';
import StatisticsList from './components/StatisticsList';
import { APP_TYPES, STATISTICS_ALLOWED_ROLES, roles } from '../../config/constants';

const options = {
  appTypes: [
    APP_TYPES.IRELAND,
    APP_TYPES.HUNGARY,
    APP_TYPES.GEORGIA,
    APP_TYPES.ARMENIA,
    APP_TYPES.AZERBAIJAN,
    APP_TYPES.DEMO,
  ],
  allowedRoles: STATISTICS_ALLOWED_ROLES[process.env.REACT_APP_APP_TYPE] || [roles.ADMIN],
};

export default {
  list: StatisticsList,
  icon: StatisticsIcon,
  name: 'admin/statistics',
  options,
};

import React from 'react';
import { useTranslate } from 'react-admin';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';

import LinearDiagramWithCheckbox from '../LinearDiagramWithCheckbox';
import Select from '../../../../../commonComponents/Select';
import {
  linearDiagramFields,
  usersDiagramsColorScheme,
  linearDiagramPeriodOptions,
  APP_TYPES,
  CAUCASUS_TYPES,
} from '../../../../../config/constants';
import { loggedInOrActiveInfoLabel } from '../../UserLabelConsts';

const ENV_APP_TYPE = process.env.REACT_APP_APP_TYPE;

const useStyles = makeStyles({
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 12,
    paddingRight: 60,
    marginBottom: 20,
  },
  header: {
    margin: '60px 0 70px 48px',
    fontSize: 22,
    fontWeight: 'bold',
    lineHeight: '26px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  downloadedContactsSection: {
    flexDirection: 'column',
    marginBottom: 50,
  },
  contactsNotOnAppSection: {
    flexDirection: 'column',
    margin: '50px 0',
  },
  sectionTitle: {
    marginBottom: 30,
    marginLeft: 48,
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: '19px',
    display: 'flex',
    alignItems: 'center',
  },
  transparent: {
    opacity: 0.2,
  },
  periodPicker: {
    marginLeft: 20,
  },
  linearDiagramActiveUsers: {
    backgroundColor: usersDiagramsColorScheme[linearDiagramFields.activeUsers],
  },
  linearDiagramDeletedUsers: {
    backgroundColor: usersDiagramsColorScheme[linearDiagramFields.deletedUsers],
  },
  linearDiagramLiveContacts: {
    backgroundColor: usersDiagramsColorScheme[linearDiagramFields.liveContacts],
  },
  linearDiagramAgedContacts: {
    backgroundColor: usersDiagramsColorScheme[linearDiagramFields.agedContacts],
  },
  linearDiagramColdContacts: {
    backgroundColor: usersDiagramsColorScheme[linearDiagramFields.coldContacts],
  },
  linearDiagramWaitingContacts: {
    backgroundColor: usersDiagramsColorScheme[linearDiagramFields.waitingContacts],
  },
  linearDiagramBlockedContacts: {
    backgroundColor: usersDiagramsColorScheme[linearDiagramFields.blockedContacts],
  },
  linearDiagramLoggedInUsers: {
    backgroundColor: usersDiagramsColorScheme[linearDiagramFields.loggedIn],
  },
  checkboxActiveUsers: {
    color: usersDiagramsColorScheme[linearDiagramFields.activeUsers],
  },
  checkboxDeletedUsers: {
    color: usersDiagramsColorScheme[linearDiagramFields.deletedUsers],
  },
  checkboxLiveContacts: {
    color: usersDiagramsColorScheme[linearDiagramFields.liveContacts],
  },
  checkboxAgedContacts: {
    color: usersDiagramsColorScheme[linearDiagramFields.agedContacts],
  },
  checkboxColdContacts: {
    color: usersDiagramsColorScheme[linearDiagramFields.coldContacts],
  },
  checkboxWaitingContacts: {
    color: usersDiagramsColorScheme[linearDiagramFields.waitingContacts],
  },
  checkboxBlockedContacts: {
    color: usersDiagramsColorScheme[linearDiagramFields.blockedContacts],
  },
  checkboxLoggedInUsers: {
    color: usersDiagramsColorScheme[linearDiagramFields.loggedIn],
  },
});

const getTotalValue = (data, fieldName) => {
  const item = data.find((x) => x.name === fieldName);
  if (item) {
    return item.value || 0;
  }
  return 0;
};

const getPercentage = (data, fieldName) => {
  let total;
  if (
    (ENV_APP_TYPE === APP_TYPES.HUNGARY || CAUCASUS_TYPES.includes(ENV_APP_TYPE)) &&
    fieldName === linearDiagramFields.activeUsers
  ) {
    const loggedInUsers = data.find((x) => x.name === linearDiagramFields.loggedIn);
    total = (loggedInUsers && loggedInUsers.value) || 0;
  } else {
    total = data.length
      ? data.reduce((acc, cur) => {
          if (
            (ENV_APP_TYPE === APP_TYPES.HUNGARY || CAUCASUS_TYPES.includes(ENV_APP_TYPE)) &&
            cur.name === linearDiagramFields.activeUsers
          ) {
            return acc;
          }

          return acc + cur.value;
        }, 0)
      : 0;
  }

  const item = data.find((x) => x.name === fieldName);
  if (item && item.value) {
    return ((100 * item.value) / total).toFixed(2);
  }
  return 0;
};

const CardWithDiagram = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const {
    data = [],
    onPeriodChange,
    onCustomFilterChange,
    onCheckboxChange,
    regionsOptions,
    showRegionFilter,
    title,
    selectedFilters,
    selectedCustomFilterOptionId,
    selectedPeriod,
  } = props;
  const linearDiagramPeriodOptionsL10n = linearDiagramPeriodOptions.map((o) => ({
    ...o,
    name: translate(o.name),
  }));

  const handlePeriodChange = (event) => {
    if (onPeriodChange) {
      onPeriodChange(event);
    }
  };

  const handleCustomFilterChange = (event) => {
    if (onCustomFilterChange) {
      onCustomFilterChange(event);
    }
  };

  const handleCheckboxChange = (event) => {
    if (onCheckboxChange) {
      onCheckboxChange(event);
    }
  };

  const getDiagramValueForTitle = (fieldName) =>
    `${getTotalValue(data, fieldName)} (${getPercentage(data, fieldName)}%)`;

  const getDownloadedSectionValueForTitle = () => {
    const total = data.length
      ? data.reduce((acc, cur) => {
          if (
            (ENV_APP_TYPE === APP_TYPES.HUNGARY || CAUCASUS_TYPES.includes(ENV_APP_TYPE)) &&
            cur.name === linearDiagramFields.activeUsers
          ) {
            return acc;
          }

          return acc + cur.value;
        }, 0)
      : 0;

    const activeUsersTotal = getTotalValue(
      data,
      ENV_APP_TYPE === APP_TYPES.HUNGARY || CAUCASUS_TYPES.includes(ENV_APP_TYPE)
        ? linearDiagramFields.loggedIn
        : linearDiagramFields.activeUsers,
    );
    const deletedUsersTotal = getTotalValue(data, linearDiagramFields.deletedUsers);

    const downloadedTotal = activeUsersTotal + deletedUsersTotal;
    const downloadedPercentage = ((100 * downloadedTotal) / total).toFixed(2);

    return `${downloadedTotal} ${Number(downloadedPercentage) ? `(${downloadedPercentage} %)` : ''}`;
  };

  const getNotOnAppSectionValueForTitle = () => {
    const total = data.length
      ? data.reduce((acc, cur) => {
          if (
            (ENV_APP_TYPE === APP_TYPES.HUNGARY || CAUCASUS_TYPES.includes(ENV_APP_TYPE)) &&
            cur.name === linearDiagramFields.activeUsers
          ) {
            return acc;
          }

          return acc + cur.value;
        }, 0)
      : 0;

    const liveUsersTotal = getTotalValue(data, linearDiagramFields.liveContacts);
    const agedUsersTotal = getTotalValue(data, linearDiagramFields.agedContacts);
    const coldUsersTotal = getTotalValue(data, linearDiagramFields.coldContacts);
    const waitingUsersTotal = getTotalValue(data, linearDiagramFields.waitingContacts);
    const blockedUsersTotal = getTotalValue(data, linearDiagramFields.blockedContacts);

    const notOnAppTotal = liveUsersTotal + agedUsersTotal + coldUsersTotal + waitingUsersTotal + blockedUsersTotal;
    const notOnAppPercentage = ((100 * notOnAppTotal) / total).toFixed(2);

    return `${notOnAppTotal} ${Number(notOnAppPercentage) ? `(${notOnAppPercentage} %)` : ''}`;
  };

  const isFieldTransparent = (fieldName) => {
    if (!selectedFilters.length) {
      return false;
    }
    return !selectedFilters.find((x) => x === fieldName);
  };

  const getCheckboxProps = (id) => ({ checked: !!selectedFilters.find((x) => x === id) });

  const loggedInOrActiveTitle =
    ENV_APP_TYPE === APP_TYPES.HUNGARY || CAUCASUS_TYPES.includes(ENV_APP_TYPE)
      ? `${translate(`root.statistics.Logged in`)}: ${getDiagramValueForTitle(linearDiagramFields.loggedIn)}`
      : `${translate(`root.statistics.Active users`)}: ${getDiagramValueForTitle(linearDiagramFields.activeUsers)}`;
  const loggedInOrActiveColor =
    ENV_APP_TYPE === APP_TYPES.HUNGARY || CAUCASUS_TYPES.includes(ENV_APP_TYPE)
      ? classes.linearDiagramLoggedInUsers
      : classes.linearDiagramActiveUsers;
  const checkboxLoggedInOrActiveClass =
    ENV_APP_TYPE === APP_TYPES.HUNGARY || CAUCASUS_TYPES.includes(ENV_APP_TYPE)
      ? classes.checkboxLoggedInUsers
      : classes.checkboxActiveUsers;
  const checkboxLoggedInOrActive =
    ENV_APP_TYPE === APP_TYPES.HUNGARY || CAUCASUS_TYPES.includes(ENV_APP_TYPE)
      ? linearDiagramFields.loggedIn
      : linearDiagramFields.activeUsers;

  const loggedInOrActivePercentage = Number(getPercentage(data, checkboxLoggedInOrActive));
  const waitingModerationTitle =
    ENV_APP_TYPE === APP_TYPES.HUNGARY || CAUCASUS_TYPES.includes(ENV_APP_TYPE)
      ? `${translate(`root.statistics.Waiting moderation`)}: ${getDiagramValueForTitle(
          linearDiagramFields.waitingContacts,
        )}`
      : `${translate(`root.statistics.Waiting in moderation`)}: ${getDiagramValueForTitle(
          linearDiagramFields.waitingContacts,
        )}`;

  return (
    <Card className={classes.cardContainer}>
      <div className={classes.header}>
        <div>{title}</div>
        {showRegionFilter && (
          <div>
            <Select
              options={regionsOptions}
              defaultValue={selectedCustomFilterOptionId}
              onChange={handleCustomFilterChange}
              passedValue={selectedCustomFilterOptionId}
            />
          </div>
        )}
      </div>
      <div className={classes.downloadedContactsSection}>
        <div className={classes.sectionTitle}>
          <div>
            {translate(`root.statistics.Downloaded contacts`)}: {getDownloadedSectionValueForTitle()}
          </div>
          <Select
            options={linearDiagramPeriodOptionsL10n}
            defaultValue={linearDiagramPeriodOptionsL10n[0].id}
            onChange={handlePeriodChange}
            passedValue={selectedPeriod}
            customClassName={classes.periodPicker}
          />
        </div>
        <LinearDiagramWithCheckbox
          id={checkboxLoggedInOrActive}
          title={loggedInOrActiveTitle}
          value={loggedInOrActivePercentage}
          onCheckboxChange={handleCheckboxChange}
          barColorClass={loggedInOrActiveColor}
          checkboxCheckedClass={checkboxLoggedInOrActiveClass}
          customClassName={isFieldTransparent(checkboxLoggedInOrActive) ? classes.transparent : ''}
          checkboxProps={getCheckboxProps(checkboxLoggedInOrActive)}
          infoTitle={loggedInOrActiveInfoLabel}
        />
        {(ENV_APP_TYPE === APP_TYPES.HUNGARY || CAUCASUS_TYPES.includes(ENV_APP_TYPE)) && (
          <LinearDiagramWithCheckbox
            id={linearDiagramFields.activeUsers}
            title={`${translate(`root.statistics.Active users`)}: ${getDiagramValueForTitle(
              linearDiagramFields.activeUsers,
            )}`}
            value={Number(getPercentage(data, linearDiagramFields.activeUsers))}
            onCheckboxChange={handleCheckboxChange}
            barColorClass={classes.linearDiagramActiveUsers}
            checkboxCheckedClass={classes.checkboxActiveUsers}
            customClassName={isFieldTransparent(linearDiagramFields.activeUsers) ? classes.transparent : ''}
            checkboxProps={getCheckboxProps(linearDiagramFields.activeUsers)}
            infoTitle={'Had at least one transaction for the reported period'}
          />
        )}
        <LinearDiagramWithCheckbox
          id={linearDiagramFields.deletedUsers}
          title={`${translate(`root.statistics.Deleted users`)}: ${getDiagramValueForTitle(
            linearDiagramFields.deletedUsers,
          )}`}
          value={Number(getPercentage(data, linearDiagramFields.deletedUsers))}
          onCheckboxChange={handleCheckboxChange}
          barColorClass={classes.linearDiagramDeletedUsers}
          checkboxCheckedClass={classes.checkboxDeletedUsers}
          customClassName={isFieldTransparent(linearDiagramFields.deletedUsers) ? classes.transparent : ''}
          checkboxProps={getCheckboxProps(linearDiagramFields.deletedUsers)}
          infoTitle={'Sent a delete request'}
        />
      </div>
      <div className={classes.contactsNotOnAppSection}>
        <div className={classes.sectionTitle}>
          {translate(`root.statistics.Contacts not on App`)}: {getNotOnAppSectionValueForTitle()}
        </div>
        <LinearDiagramWithCheckbox
          id={linearDiagramFields.liveContacts}
          title={`${translate(`root.statistics.Live contacts`)}: ${getDiagramValueForTitle(
            linearDiagramFields.liveContacts,
          )}`}
          value={Number(getPercentage(data, linearDiagramFields.liveContacts))}
          onCheckboxChange={handleCheckboxChange}
          barColorClass={classes.linearDiagramLiveContacts}
          checkboxCheckedClass={classes.checkboxLiveContacts}
          customClassName={isFieldTransparent(linearDiagramFields.liveContacts) ? classes.transparent : ''}
          checkboxProps={getCheckboxProps(linearDiagramFields.liveContacts)}
          infoTitle={'Added to database within last 4 weeks'}
        />
        <LinearDiagramWithCheckbox
          id={linearDiagramFields.agedContacts}
          title={`${translate(`root.statistics.Aged contacts`)}: ${getDiagramValueForTitle(
            linearDiagramFields.agedContacts,
          )}`}
          value={Number(getPercentage(data, linearDiagramFields.agedContacts))}
          onCheckboxChange={handleCheckboxChange}
          barColorClass={classes.linearDiagramAgedContacts}
          checkboxCheckedClass={classes.checkboxAgedContacts}
          customClassName={isFieldTransparent(linearDiagramFields.agedContacts) ? classes.transparent : ''}
          checkboxProps={getCheckboxProps(linearDiagramFields.agedContacts)}
          infoTitle={'Added to database between 4-12 weeks ago'}
        />
        <LinearDiagramWithCheckbox
          id={linearDiagramFields.coldContacts}
          title={`${translate(`root.statistics.Cold contacts`)}: ${getDiagramValueForTitle(
            linearDiagramFields.coldContacts,
          )}`}
          value={Number(getPercentage(data, linearDiagramFields.coldContacts))}
          onCheckboxChange={handleCheckboxChange}
          barColorClass={classes.linearDiagramColdContacts}
          checkboxCheckedClass={classes.checkboxColdContacts}
          customClassName={isFieldTransparent(linearDiagramFields.coldContacts) ? classes.transparent : ''}
          checkboxProps={getCheckboxProps(linearDiagramFields.coldContacts)}
          infoTitle={'Added to database 12+ weeks ago'}
        />
        <LinearDiagramWithCheckbox
          id={linearDiagramFields.waitingContacts}
          title={waitingModerationTitle}
          value={Number(getPercentage(data, linearDiagramFields.waitingContacts))}
          onCheckboxChange={handleCheckboxChange}
          barColorClass={classes.linearDiagramWaitingContacts}
          checkboxCheckedClass={classes.checkboxWaitingContacts}
          customClassName={isFieldTransparent(linearDiagramFields.waitingContacts) ? classes.transparent : ''}
          checkboxProps={getCheckboxProps(linearDiagramFields.waitingContacts)}
          infoTitle={'Applied to gain access'}
        />
        <LinearDiagramWithCheckbox
          id={linearDiagramFields.blockedContacts}
          title={`${translate(`root.statistics.Blocked`)}: ${getDiagramValueForTitle(
            linearDiagramFields.blockedContacts,
          )}`}
          value={Number(getPercentage(data, linearDiagramFields.blockedContacts))}
          onCheckboxChange={handleCheckboxChange}
          barColorClass={classes.linearDiagramBlockedContacts}
          checkboxCheckedClass={classes.checkboxBlockedContacts}
          customClassName={isFieldTransparent(linearDiagramFields.blockedContacts) ? classes.transparent : ''}
          checkboxProps={getCheckboxProps(linearDiagramFields.blockedContacts)}
          infoTitle={`Don't have access to the app`}
        />
      </div>
    </Card>
  );
};

export default CardWithDiagram;

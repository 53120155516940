import React from 'react';
import { Datagrid, DeleteButton, EditButton, List, TextField, DateField } from 'react-admin';
import CustomListActions from '../../../../commonComponents/ListActions/CustomListActions';
import StatusField from '../../../../commonComponents/StatusField';

const QuizzesList = (props) => (
  <List
    title="Quizzes"
    exporter={false}
    bulkActionButtons={false}
    actions={<CustomListActions />}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" sortable={false} />
      <TextField label="Name" source="name" sortable={false} />
      <DateField label="Activates at" source="activated_at" sortable={false} />
      <StatusField label="Status" source="status" sortable={false} />
      <EditButton />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
);

export default QuizzesList;

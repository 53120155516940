import React from 'react';
import { Edit, NumberInput, ReferenceInput, SelectInput, TextInput } from 'react-admin';
import { CustomDateInput } from '../../../commonComponents/CustomDateInput';
import QuizForm from '../../../commonComponents/QuizForm';
import ImageInput from '../../../commonComponents/UploadInputs/ImageInput';
import { s3FilePaths, statuses } from '../../../config/constants';

const SpecialQuizEdit = (props) => (
  <Edit title="Edit Special Quiz" undoable={false} {...props}>
    <QuizForm>
      <TextInput label="ID" source="id" disabled />
      <TextInput label="Name" source="name" />
      <ReferenceInput label="Topic" source="brand_id" reference="services/brands">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <SelectInput source="status" optionText="name" choices={statuses.slice(0, 2)} />
      <NumberInput label="Price" source="price" />
      <CustomDateInput label="Publish date" source="published_at" />
      <ImageInput imageInputSource="image" imagePath={s3FilePaths.quiz} />
    </QuizForm>
  </Edit>
);

export default SpecialQuizEdit;

import SalesSimulatorIcon from '@material-ui/icons/Help';
import SalesSimulatorReportsList from './components/SalesSimulatorReportsList';
import { APP_TYPES, roles } from '../../../config/constants';

const options = {
  appTypes: [APP_TYPES.BELARUS],
  allowedRoles: [roles.ADMIN, roles.JTI_TEAM],
  label: 'Sales simulators',
  subMenuName: 'reports',
};

export default {
  list: SalesSimulatorReportsList,
  icon: SalesSimulatorIcon,
  name: 'admin/reports/sales-simulators',
  options,
};

import OrderIcon from '@material-ui/icons/Note';
import OrderReportsList from './components/OrderReportsList';
import { APP_TYPES, CAUCASUS_TYPES, roles } from '../../../config/constants';

const appType = process.env.REACT_APP_APP_TYPE;

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: CAUCASUS_TYPES.includes(appType) ? [roles.ADMIN, roles.JTI_TEAM] : [roles.ADMIN],
  label: 'Orders',
  subMenuName: 'reports',
};

export default {
  list: OrderReportsList,
  icon: OrderIcon,
  name: 'admin/reports/orders',
  options,
};

import React from 'react';
import { Filter, SelectInput } from 'react-admin';
import { knowledgeBankCategorySections } from '../../../../config/constants';

const CategoriesFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      label="Section of Category"
      source="section"
      choices={knowledgeBankCategorySections}
      allowEmpty={false}
      alwaysOn
    />
  </Filter>
);

export default CategoriesFilter;

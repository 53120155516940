import React from 'react';
import { BooleanField, Datagrid, List, TextField } from 'react-admin';
import { CustomDateField } from '../../../../commonComponents/DateField';
import CustomReportListActions from '../../../../commonComponents/ListActions/CustomReportListActions';
import QuickTipsReportsFilter from './QuickTipsReportsFilter';

const QuickTipsReportsList = (props) => (
  <List
    title="Quick Tips reports"
    actions={<CustomReportListActions />}
    filters={<QuickTipsReportsFilter />}
    bulkActionButtons={false}
    {...props}
  >
    <Datagrid>
      <TextField label="Question ID" source="question_id" />
      <TextField label="User ID" source="user_id" />
      <TextField label="Question Text" source="question" />
      <TextField label="Correct answer" source="correct_answer" />
      <TextField label="User answer" source="user_answer" />
      <BooleanField label="Correct" source="correct" sortable={false} />
      <TextField label="Brand" source="brand" />
      <CustomDateField label="Date" source="created_at" sortable={false} />
    </Datagrid>
  </List>
);

export default QuickTipsReportsList;

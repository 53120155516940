import { makeStyles } from '@material-ui/core/styles';
import { Card } from '@mui/material';
import { useTranslate } from 'react-admin';
import MonthlyActiveUsers from './overviewBlocks/MonthlyActiveUsers';
import OutletsCount from './overviewBlocks/OutletsCount';
import RegisteredUsers from './overviewBlocks/RegisteredUsers';
import WaitingModerationUsers from './overviewBlocks/WaitingModerationUsers';

const useStyles = makeStyles({
  overview: {
    height: '289px',
    padding: '0 60px',
    marginBottom: '30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  title: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '22px',
    fontWeight: 700,
  },
  blocks: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

const OutletsOverview = () => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Card className={classes.overview}>
      <div className={classes.title}>{translate('Your outlets overview')}</div>
      <div className={classes.blocks}>
        <OutletsCount />
        <RegisteredUsers />
        <WaitingModerationUsers />
        <MonthlyActiveUsers />
      </div>
    </Card>
  );
};

export default OutletsOverview;

import TransactionsIcon from '@material-ui/icons/SwapHoriz';
import TransactionsList from './components/TransactionsList';
import TransactionCreate from './components/TransactionCreate';
import { roles, APP_TYPES } from '../../../config/constants';

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: [roles.ADMIN],
  label: 'Transactions',
  subMenuName: 'manage',
};

export default {
  list: TransactionsList,
  create: TransactionCreate,
  icon: TransactionsIcon,
  name: 'admin/transactions',
  options,
};

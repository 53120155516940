import React from 'react';
import { Filter, ReferenceInput, SelectInput, TextInput } from 'react-admin';
// import { CustomDateInput } from '../../../../commonComponents/CustomDateInput';
import UserAutocompleteInput from '../../../../commonComponents/UserAutocompleteInput';

const LoyaltyProgramsReportsFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput label="Loyalty program" source="program_id" reference="admin/loyalty-programs" alwaysOn>
      <SelectInput optionText={(s) => `(${s.id}) ${s.name}`} />
    </ReferenceInput>
    <UserAutocompleteInput label="User ID" source="user_id" reference="admin/users/autocomplete" alwaysOn />
    <TextInput label="Account code" source="traffic_code" alwaysOn />
    {/* <CustomDateInput label="Choose start date" source="start_date" start alwaysOn /> */}
    {/* <CustomDateInput label="Choose end date" source="end_date" end alwaysOn /> */}
  </Filter>
);

export default LoyaltyProgramsReportsFilter;

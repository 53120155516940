import React from 'react';
import { Link } from '@mui/material';
import { serverApiUrl } from '../config/constants';
import httpClient from '../core/httpClient';

const PreSignedUrlField = ({ record = {}, source }) => {
  const handleClick = (e) => {
    e.stopPropagation();
    const url = `${serverApiUrl}/services/file?file_key=${record[source]}`;
    const options = { method: 'GET' };

    httpClient(url, options).then((res) => {
      window.open(res.json.url, '_blank');
    });
  };

  return (
    <Link style={{ color: '#01c1b6' }} onClick={handleClick}>
      {record[source]}
    </Link>
  );
};

export default PreSignedUrlField;

import React from 'react';
import { BooleanField, Datagrid, List, ReferenceField, TextField } from 'react-admin';
import { CustomDateField } from '../../../../commonComponents/DateField';
import CustomReportListActions from '../../../../commonComponents/ListActions/CustomReportListActions';

import AutolightReportsFilter from './AutolightReportsFilter';

const AutolightReportsList = (props) => (
  <List
    title="Autolight order reports"
    actions={<CustomReportListActions />}
    filters={<AutolightReportsFilter />}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField label="User ID" source="user_id" sortable={false} />
      <CustomDateField label="Date created" source="created_at" sortable={false} />
      <TextField label="Product ID" source="product_id" />
      <TextField label="Product Name" source="product_name" sortable={false} />
      <TextField label="Внешний код продавца" source="user_shop_id" />
      <TextField label="ФИО" source="user_fullname" />
      <TextField label="Внешний код торговой точки" source="account_code" />
      <TextField label="Область" source="area" />
      <TextField label="City" source="city" />
      <TextField label="Street" source="street" />
      <TextField label="Building number" source="building_number" />
      <BooleanField label="Gas station" source="is_gas_station" />
      <TextField label="Phone" source="user_phone" sortable={false} />
      <TextField label="Status" source="status" sortable={false} />
      <ReferenceField label="Type" source="type" reference="admin/reports/orders/types" link={false}>
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export default AutolightReportsList;

import { SelectInput } from 'react-admin';
import React from 'react';
import { CATEGORY_TYPES } from '../../../../config/constants';

function CategoryTypeSelector(props) {
  const options = Object.entries(CATEGORY_TYPES).map(([key, value]) => ({ id: value, name: key }));

  return <SelectInput source={props.source || 'type'} choices={options} {...props} />;
}

export default CategoryTypeSelector;

import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import {
  Edit,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  BooleanInput,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin';
import { CustomDateInput } from '../../../../commonComponents/CustomDateInput';
import RewardInput from '../../../../commonComponents/RewardInput';
import TargetGroupInput from '../../../../commonComponents/TargetGroupInput';
import ImageInput from '../../../../commonComponents/UploadInputs/ImageInput';

import {
  APP_TYPES,
  kbCategoryTypes,
  statuses,
  productStatusSelectStatuses,
  knowledgeBankCategorySections,
  s3FilePaths,
} from '../../../../config/constants';
import CategoryNameField from './CategoryNameField';
import { isHungary, isIreland } from '../../../../helpers/appTypeHelper';

const getCategorySectionName = (section_in_category) =>
  knowledgeBankCategorySections.find((section) => section_in_category === section.id).name;

const CustomCategoryNameField = ({ record, changeCategory }) => {
  useEffect(() => {
    record && changeCategory(record);
  }, [record, changeCategory]);

  return (
    <Typography>
      ({getCategorySectionName(record.section)}) {record.name}
    </Typography>
  );
};

const ArticleEdit = (props) => {
  const [category, changeCategory] = useState(null);
  const isSurvey = category && category.type === kbCategoryTypes.Survey;
  const rewardInputFields = [
    { inputLabel: 'URL', inputType: 'string', radioLabel: 'URL', source: 'url' },
    {
      inputLabel: 'Multibrand or Special Quiz ID',
      inputType: 'number',
      radioLabel: 'Multibrand or Special Quiz ID',
      source: 'special_quiz_id',
    },
  ];
  const sendPushNotification =
    category &&
    (process.env.REACT_APP_APP_TYPE === APP_TYPES.IRELAND || process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY) &&
    category.send_push_notification;

  return (
    <Edit title="Edit article" undoable={false} {...props}>
      <SimpleForm initialValues={{ push: false, message: '' }}>
        <ReferenceField label="Category" source="category_id" reference="admin/knowledge-bank/categories" link={false}>
          {isHungary() ? (
            <CustomCategoryNameField source="name" changeCategory={changeCategory} />
          ) : (
            <CategoryNameField source="name" changeCategory={changeCategory} />
          )}
        </ReferenceField>
        <TextInput label="ID" source="id" disabled />
        <SelectInput source="status" optionText="name" choices={statuses.slice(0, 2)} validate={required()} />
        {isIreland() && (
          <BooleanInput style={{ width: '300px' }} label="Included in the Progress bar" source="progress_bar_enable" />
        )}
        <TextInput label="Article name" source="name" validate={required()} />
        {/* eslint-disable-next-line no-nested-ternary */}
        {category && category.show_quiz ? (
          <RewardInput formType="edit" inputFields={rewardInputFields} />
        ) : isSurvey ? (
          <ReferenceInput label="Survey" source="survey_id" reference="admin/surveys">
            <SelectInput optionText="name" validate={required()} />
          </ReferenceInput>
        ) : (
          <TextInput label="URL" source="url" validate={required()} />
        )}
        {category && category.show_minutes_to_read && <NumberInput label="Minutes to read" source="minutes_to_read" />}
        {category && category.show_retail_price && <TextInput label="Retail price" source="retail_price" />}
        {category && (category.show_brand || category.show_choose_brand) && (
          <ReferenceInput label="Brands" source="brand_id" reference="admin/knowledge-bank/brands">
            <SelectInput optionText="name" />
          </ReferenceInput>
        )}
        <CustomDateInput label="Choose publication date" source="published_at" />
        <TargetGroupInput />
        <NumberInput label="Priority" source="priority" />
        {!isSurvey && <NumberInput label="Count of bonus reads" source="count" validate={required()} />}
        <NumberInput
          label={category && !category.show_quiz ? 'Coins' : 'Coins (not for special quizzes)'}
          source="coins"
          validate={required()}
        />
        {!isSurvey && <NumberInput label="Points of non-bonus reads" source="points" validate={required()} />}
        {isHungary() && <SelectInput source="product_status" optionText="name" choices={productStatusSelectStatuses} />}
        <ImageInput
          label={isHungary() ? 'Preview Image' : 'Chat Preview Image'}
          imagePath={s3FilePaths.articles}
          imageInputSource="preview_image"
        />
        {sendPushNotification && (
          <>
            <BooleanInput label="Push notification" source="push" />
            <FormDataConsumer>
              {({ formData, ...rest }) => formData && formData.push && <TextInput source="message" {...rest} />}
            </FormDataConsumer>
          </>
        )}
        {(!isHungary() || !isIreland()) && (
          <ArrayInput label="Reward coefficients" source="reward_coefficients">
            <SimpleFormIterator>
              <NumberInput label="Coefficient" source="coefficient" validate={required()} />
              <CustomDateInput label="Start date" source="start_date" validate={required()} start />
              <CustomDateInput label="End date" source="end_date" end />
            </SimpleFormIterator>
          </ArrayInput>
        )}
      </SimpleForm>
    </Edit>
  );
};

export default ArticleEdit;

import React from 'react';
import { Datagrid, List, TextField } from 'react-admin';
import CustomReportListActions from '../../../../commonComponents/ListActions/CustomReportListActions';

import BotsReportsFilter from './BotsReportsFilter';

const BotsReportsList = (props) => (
  <List
    title="Bots Statistics"
    actions={<CustomReportListActions />}
    filters={<BotsReportsFilter />}
    bulkActionButtons={false}
    sort={{ field: 'percentage_min', order: 'ASC' }}
    {...props}
  >
    <Datagrid>
      <TextField label="Bot ID" source="id" />
      <TextField label="Min win rate (%)" source="percentage_min" sortable={true} />
      <TextField label="Max win rate (%)" source="percentage_max" sortable={true} />
      <TextField label="Users" source="users_count" sortable={false} />
      <TextField label="Games" source="games_count" sortable={false} />
      <TextField label="Win" source="win_count" sortable={false} />
      <TextField label="Lose" source="lose_count" sortable={false} />
      <TextField label="Draw" source="draw_count" sortable={false} />
    </Datagrid>
  </List>
);

export default BotsReportsList;

export const challengesTaskTypes = {
  playedQuizzesNumber: 1,
  quizzesOnTopicNumber: 2,
  wonQuizzesNumber: 3,
  kudosNumber: 4,
  purchasedGiftsNumber: 5,
  custom: 6,
  takenPartLotteriesNumber: 7,
  readArticlesNumber: 8,
  specialQuizzesNumber: 9,
  spentCoinsNumber: 10,
  multibrandQuizzesNumber: 11,
  dailyQuestionsPlayedNumber: 12,
  dailyQuestionsWonNumber: 13,
  kbGamesWonNumber: 14,
  singleModePlayedNumber: 15,
  singleModePlayedInRowNumber: 16,
  wonKnowledgeBaseEntity: 17,
  knowledgeBaseEntity: 18,
  wonDailyQuestion: 19,
  dailyQuestion: 20,
  specialQuiz: 21,
  lottery: 22,
  salesTips: 23,
  customTextManual: 24,
  customTextAuto: 25,
  linkToChat: 26,
  wonQuickTipsNumber: 27,
  playedQuickTipsNumber: 28,
};

export const challengesRewardTypes = {
  completedQuestsNumber: 1,
  draw: 2,
};

export const challengeTypes = {
  personal: 0,
  team: 1,
  daily_game: 2,
};

export const challengeTypesChoices = [
  { id: challengeTypes.personal, name: 'Personal' },
  { id: challengeTypes.team, name: 'Team' },
  { id: challengeTypes.daily_game, name: 'Daily Game' },
];

export const taskStatus = {
  uncompleted: 0,
  completed: 1,
};

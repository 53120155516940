import React from 'react';
import { Create } from 'react-admin';

import BotChancesForm from './BotChancesForm';

const BotChancesCreate = (props) => (
  <Create {...props}>
    <BotChancesForm {...props} redirect="list" />
  </Create>
);

export default BotChancesCreate;

import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';
import ImageInput from '../../../../commonComponents/UploadInputs/ImageInput';
import EditViewToolbarWithoutDelete from '../../../../commonComponents/EditViewToolbarWithoutDelete';
import { s3FilePaths } from '../../../../config/constants';

const TypeEdit = (props) => (
  <Edit title="Edit Type" undoable={false} {...props}>
    <SimpleForm toolbar={<EditViewToolbarWithoutDelete />}>
      <TextInput label="ID" source="id" disabled />
      <TextInput label="Name" source="name" />
      <TextInput label="Title" source="title" />
      <ImageInput imageInputSource="photo" imagePath={s3FilePaths.bonusShop} />
    </SimpleForm>
  </Edit>
);

export default TypeEdit;

import React from 'react';
import { required } from 'react-admin';

import CustomArrayInput from '../../../commonComponents/CustomArrayInput';
import FormIterator from '../../../core/FormIterator';
import { mysteryShopperFormTypeTogglerOptions } from '../config/constants';
import Question from './Question';

const addQuestion = ({ fields, nextFormType: taskTypeId }) => {
  fields.push({ type_id: taskTypeId });
};

const QuestionForm = ({ label, ...props }) => {
  const renderQuestionForm = ({ field, member, index }) => {
    const fieldTypeId = field.type_id;

    return (
      <>
        <Question member={member} taskFormType={fieldTypeId} index={index} />
        <hr style={{ borderTop: '3px double #333' }} />
      </>
    );
  };

  return (
    <CustomArrayInput label="Question type" validate={required()} {...props}>
      <FormIterator
        formTypeTogglerOptions={mysteryShopperFormTypeTogglerOptions}
        renderForm={renderQuestionForm}
        pushNewField={addQuestion}
        formTypeTogglerLabel="Question type"
      />
    </CustomArrayInput>
  );
};

export default QuestionForm;

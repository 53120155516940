import React from 'react';

import CustomArrayInput from '../../../../commonComponents/CustomArrayInput';
import { mainScreenBoxComponentTypeToggleOptions } from '../../../../config/constants';
import FormIterator from '../../../../core/FormIterator';
import BoxComponent from './BoxComponent';

const pushNewField = ({ fields, nextFormType }) => {
  console.log('pushNewField', fields, nextFormType);
  fields.push({ type: { id: nextFormType } });
};

const renderForm = ({ field, member }) => <BoxComponent member={member} type={field.type.id} />;

const BoxComponentForm = React.memo(({ member }) => (
  <CustomArrayInput source={`${member}.box_components`} label="Box components" defaultValue={[]}>
    <FormIterator
      formTypeTogglerLabel="Box component type"
      formTypeTogglerOptions={mainScreenBoxComponentTypeToggleOptions}
      pushNewField={pushNewField}
      renderForm={renderForm}
      maxLength={2}
    />
  </CustomArrayInput>
));

export default BoxComponentForm;

import React from 'react';
import { Paper, Tab, Tabs } from '@material-ui/core';
import { APP_TYPES } from '../../../config/constants';
import CollectingStats from './CollectingStats';
import InviteCode from './InviteCode';
import LepSettings from './Lep';
import SettingsForm from './SettingsForm';
import ServiceCode from './ServiceCode';

const SettingsLayout = (props) => {
  const [currentTab, setCurrentTab] = React.useState(0);
  const tabs = [
    <SettingsForm {...props} />,
    <CollectingStats />,
    <ServiceCode />,
    <InviteCode {...props} />,
    <LepSettings {...props} />,
  ];

  const handleChange = (_, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <>
      <Paper>
        <Tabs value={currentTab} onChange={handleChange} indicatorColor="primary">
          <Tab label="Settings" />
          <Tab label="Collecting stats" />
          <Tab label="Service Code" />
          {[APP_TYPES.IRELAND, APP_TYPES.HUNGARY].includes(process.env.REACT_APP_APP_TYPE) && (
            <Tab label="Invite Code" />
          )}
          {process.env.REACT_APP_APP_TYPE === APP_TYPES.IRELAND && <Tab label="LEP" />}
        </Tabs>
      </Paper>
      {tabs[currentTab]}
    </>
  );
};

export default SettingsLayout;

import React from 'react';
import { Create, SimpleForm, TextInput, SelectInput, required } from 'react-admin';
import { confirmedPasswordValidation } from '../../../helpers/validators';
import { statuses } from '../../../config/constants';

const AdministratorCreate = (props) => (
  <Create title="Create Administrator" {...props}>
    <SimpleForm redirect="list">
      <TextInput label="Username" source="username" />
      <TextInput label="Name" source="name" />
      <TextInput label="Password" source="password" type="password" validate={required()} />
      <TextInput
        label="Confirm Password"
        source="confirm_password"
        validate={confirmedPasswordValidation}
        type="password"
      />
      <SelectInput source="status" optionText="name" choices={statuses.slice(0, 3)} />
    </SimpleForm>
  </Create>
);

export default AdministratorCreate;

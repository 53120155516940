import React from 'react';
import { Edit, NumberInput, SelectInput, TextInput } from 'react-admin';
import { quizPackTypes, statuses } from '../../../config/constants';
import QuizForm from '../../../commonComponents/QuizForm';
import { CustomDateInput } from '../../../commonComponents/CustomDateInput';

const MultibrandQuizEdit = (props) => (
  <Edit title="Edit Multibrand Quiz" undoable={false} {...props}>
    <QuizForm quizType={quizPackTypes.multibrand}>
      <TextInput label="ID" source="id" disabled />
      <TextInput label="Name" source="name" />
      <SelectInput source="status" optionText="name" choices={statuses.slice(0, 2)} />
      <NumberInput label="Price" source="price" />
      <CustomDateInput label="Publish date" source="published_at" />
    </QuizForm>
  </Edit>
);

export default MultibrandQuizEdit;

import React from 'react';
import Typography from '@material-ui/core/Typography';
import { unexpectedRewardsTypes } from '../../../config/constants';

const RewardTypeField = ({ record = {}, source }) => {
  const status = record[source]
    ? unexpectedRewardsTypes.find((i) => i.id === record[source]).name
    : unexpectedRewardsTypes[0].name;
  return <Typography>{status}</Typography>;
};

export default RewardTypeField;

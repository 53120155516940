import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';

import SortButton from './SortButton';

const useStyles = makeStyles({
  sortBlock: {
    height: '130px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    color: 'white',
    fontWeight: '700',
    fontSize: '18px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  sortButtons: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
});

const OutletUsersSorting = ({ sortBy, setSortBy }) => {
  const classes = useStyles();
  const translate = useTranslate();

  const sortFields = [
    { field: 'last_transaction_date', label: 'Last activity' },
    { field: 'coins', label: 'Balance' },
    { field: 'earned_credits', label: 'Credits earned' },
    { field: 'spent_credits', label: 'Credits spent' },
  ];

  return (
    <div className={classes.sortBlock}>
      <span style={{ color: 'black' }}>{translate('Sort by:')}</span>
      <div className={classes.sortButtons}>
        {sortFields.map((field) => (
          <SortButton
            key={field.label}
            sortBy={sortBy}
            setSortBy={setSortBy}
            label={translate(field.label)}
            field={field.field}
          />
        ))}
      </div>
    </div>
  );
};

export default OutletUsersSorting;

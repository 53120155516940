import React, { useEffect, useRef } from 'react';
import { Button, useNotify, useRefresh, useUpdateLoading } from 'react-admin';
import isEmpty from 'is-empty-typed';
import { Avatar, Paper } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/AccountCircle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import ChatMessage from '../../../../commonComponents/ChatMessage';
import { SendMessage } from '../sendMessage/SendMessage';

import ChatCompanionInfo from '../../../../commonComponents/ChatCompanionInfo';
import styles from './TicketsMessages.module.scss';
import httpClient from '../../../../core/httpClient';
import { serverApiUrl, ticketStatuses } from '../../../../config/constants';

export const TicketsMessages = ({ messages, selectedTicket }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const { startLoading, stopLoading } = useUpdateLoading();
  const scrollContainer = useRef();
  const { id, user, status } = selectedTicket;

  useEffect(() => {
    scrollContainer.current.scrollTop = scrollContainer.current.scrollHeight;
  });

  const getMessageParams = () => {
    const url = `${serverApiUrl}/admin/tickets`;
    const data = {
      id,
      user_id: user.id,
    };
    const options = { method: 'PUT', body: JSON.stringify(data) };

    return { url, options };
  };

  const completeTicket = async (formData) => {
    const { url, options } = getMessageParams(formData);

    try {
      startLoading();
      await httpClient(url, options);
      refresh();
    } catch (e) {
      notify("Error: Status wasn't updated", 'warning');
      console.error(e);
    } finally {
      stopLoading();
    }
  };

  const companionFullName = `${user.name} ${user.surname}`;

  return (
    <div className={styles.ticketsMessages}>
      <Paper className={styles.paper}>
        <div className={styles.companionInfo}>
          <Avatar className={styles.avatar}>
            <PersonIcon />
          </Avatar>
          <ChatCompanionInfo companion={user} />
        </div>

        {status !== ticketStatuses.COMPLETED && (
          <Button label="Complete ticket" onClick={completeTicket}>
            <CheckCircleIcon />
          </Button>
        )}
      </Paper>
      <div ref={scrollContainer} className={styles.historyContainer}>
        {!isEmpty(messages) &&
          messages.map((message) => (
            <ChatMessage key={message.created_at} record={message} companionFullName={companionFullName} />
          ))}
      </div>
      <SendMessage ticketId={id} companionId={user.id} />
    </div>
  );
};

import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';

import { userPerformanceStatuses } from '../../../config/constants';

const useStyles = makeStyles({
  statusBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '36px',
    padding: '0 30px',
    borderRadius: '8px',
  },
  statusTitle: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 700,
    fontSize: '14px',
    color: 'white',
  },
});

const UserStatus = ({ status }) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <div className={classes.statusBlock} style={{ backgroundColor: userPerformanceStatuses[status].color }}>
      <span className={classes.statusTitle}>{translate(userPerformanceStatuses[status].title)}</span>
    </div>
  );
};

export default UserStatus;

import React, { useState, useEffect } from 'react';
import { useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import CardWithDiagram from '../CardWithDiagram';
import {
  serverApiUrl,
  usersDiagramsColorScheme,
  APP_TYPES,
  linearDiagramFields,
  CAUCASUS_TYPES,
} from '../../../../../config/constants';
import httpClient from '../../../../../core/httpClient';

const ENV_APP_TYPE = process.env.REACT_APP_APP_TYPE;

const useStyles = makeStyles({
  cardsContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
});

const getBAData = async () => {
  const url = `${serverApiUrl}/admin/users/manager/stats`;
  const options = { method: 'GET' };

  return httpClient(url, options);
};

const BACardSet = () => {
  const notify = useNotify();
  const classes = useStyles();
  const [selectedItems, setSelectedItems] = useState([]);
  const [managerData, setManagerData] = useState({ name: '', data: [] });
  const [regionData, setRegionData] = useState({ data: [] });

  if ((ENV_APP_TYPE === APP_TYPES.HUNGARY || CAUCASUS_TYPES.includes(ENV_APP_TYPE)) && managerData.data.length) {
    managerData.data = managerData.data.filter((item) => item.name !== linearDiagramFields.activeUsers);
  }

  if ((ENV_APP_TYPE === APP_TYPES.HUNGARY || CAUCASUS_TYPES.includes(ENV_APP_TYPE)) && regionData.data.length) {
    regionData.data = regionData.data.filter((item) => item.name !== linearDiagramFields.activeUsers);
  }

  useEffect(() => {
    getBAData()
      .then((response) => {
        setManagerData(response.json.manager_data);
        setRegionData(response.json.region_data);
      })
      .catch((e) => {
        notify(`GET BA data error: ${e.message}`, 'warning');
      });
  }, [notify]);

  const handleClick = (node, nodeIndex) => {
    const index = selectedItems.findIndex((x) => x.name === node.name);
    if (index === -1) {
      setSelectedItems(selectedItems.slice().concat({ name: node.name, value: node.value, index: nodeIndex }));
    } else {
      setSelectedItems(selectedItems.slice(0, index).concat(selectedItems.slice(index + 1)));
    }
  };

  const handleClickOutside = () => {
    setSelectedItems([]);
  };

  return (
    <div className={classes.cardsContainer}>
      <CardWithDiagram
        title={managerData.name}
        data={managerData.data}
        colors={usersDiagramsColorScheme}
        selectedItems={selectedItems}
        handleClick={handleClick}
        handleClickOutside={handleClickOutside}
      />
      <CardWithDiagram
        title="Regional statistics"
        data={regionData.data}
        colors={usersDiagramsColorScheme}
        selectedItems={selectedItems}
        handleClick={handleClick}
        handleClickOutside={handleClickOutside}
      />
    </div>
  );
};

export default BACardSet;

import React from 'react';
import { Create, NumberInput, required, SimpleForm, TextInput } from 'react-admin';
import UploadFileInput from '../../../commonComponents/UploadInputs/UploadFileInput';

const TierCreate = (props) => (
  <Create title="Tiers Create" {...props}>
    <SimpleForm redirect="list">
      <TextInput label="Name" source="name" validate={required()} />
      <NumberInput source="reward_coefficient" label="Coefficient per content" validate={required()} />
      <NumberInput source="coins_per_currency" label="1 euro convertation" validate={required()} />
      <UploadFileInput fileInputSource="account_codes_file" label="Account codes" />
    </SimpleForm>
  </Create>
);

export default TierCreate;

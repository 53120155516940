import React from 'react';
import { Edit, NumberInput, SimpleForm, TextInput } from 'react-admin';
import { ColorInput } from 'react-admin-color-input';
import ImageInput from '../../../commonComponents/UploadInputs/ImageInput';
import { s3FilePaths } from '../../../config/constants';

const BoosterEdit = (props) => (
  <Edit title="Edit Booster" undoable={false} {...props}>
    <SimpleForm>
      <TextInput label="ID" source="id" disabled />
      <TextInput label="Type" source="type" disabled />
      <TextInput source="name" />
      <TextInput source="description" />
      <NumberInput source="price" />
      <ColorInput source="text_color" />
      <ColorInput source="main_color" />
      <ImageInput imageInputSource="icon" imagePath={s3FilePaths.allUploads} />
      <ImageInput imageInputSource="disabled_icon" imagePath={s3FilePaths.allUploads} />
      <ImageInput imageInputSource="banner" imagePath={s3FilePaths.allUploads} />
    </SimpleForm>
  </Edit>
);

export default BoosterEdit;

import React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { RewardCheckbox } from './RewardCheckbox';

export const Sellers = ({ sellers, cpaProgramId, isCheckboxShown, isCheckboxDisabled }) => {
  const columns = [
    {
      field: 'should_rewarded',
      headerName: ' ',
      flex: 1,
      hide: !isCheckboxShown,
      sortable: false,
      renderCell: (params) => (
        <RewardCheckbox params={params} cpaProgramId={cpaProgramId} isDisabled={isCheckboxDisabled} />
      ),
    },
    { field: 'id', headerName: 'ID', flex: 1, sortable: false },
    { field: 'name', headerName: 'Name', flex: 1, sortable: false },
    { field: 'surname', headerName: 'Surname', flex: 1, sortable: false },
    { field: 'phone', headerName: 'Phone number', flex: 1, sortable: false },
    { field: 'last_transaction_date', headerName: 'Last activity date', flex: 1, sortable: false, type: 'date' },
  ];

  const rows = sellers.map((seller) => ({
    ...seller,
    last_transaction_date: seller.last_transaction_date ? new Date(seller.last_transaction_date) : '',
    isSelected: true,
  }));

  return (
    <div className="row">
      <div class="col-sm-5" style={{ backgroundColor: 'white', height: '100%' }}>
        <div className="mt-2" style={{ width: '100%' }}>
          <DataGrid
            rows={rows}
            hideFooterSelectedRowCount
            columns={columns}
            disableColumnMenu
            autoHeight
            hideFooterPagination
          />
        </div>
      </div>
    </div>
  );
};

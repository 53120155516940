import React, { useEffect, useState } from 'react';
import { Button, useNotify, useRefresh, useUpdateLoading } from 'react-admin';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@material-ui/core';
import IconClose from '@material-ui/icons/Close';
import IconDelete from '@material-ui/icons/Delete';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import TextFormatIcon from '@material-ui/icons/TextFormat';
import { serverApiUrl } from '../../../../../config/constants';
import httpClient from '../../../../../core/httpClient';

const AnswersForUser = ({ questId, userId, showButton, statuses, tasks }) => {
  const notify = useNotify();
  const [isOpen, setOpenStatus] = useState(false);
  const [userAnswers, setUserAnswers] = useState([]);
  const { startLoading, stopLoading } = useUpdateLoading();
  const refresh = useRefresh();

  useEffect(() => {
    if (isOpen && userAnswers.length === 0) {
      const url = `${serverApiUrl}/admin/quests/user-answers?user_id=${userId}&quest_id=${questId}`;
      const options = {
        method: 'GET',
      };
      httpClient(url, options)
        .then((res) => {
          setUserAnswers(res.json);
          if (res.json.length === 0) {
            setOpenStatus(false);
          }
        })
        .catch(() => {
          notify("Answers weren't downloaded successfully", 'warning');
        });
    }
  }, [isOpen, userAnswers, userId, questId, notify, tasks]);

  const handleOpenModal = (e) => {
    e.stopPropagation();
    setOpenStatus(true);
  };

  const handleModalClose = () => {
    setOpenStatus(false);
  };

  const handleDeleteAnswer = async (i) => {
    const url = `${serverApiUrl}/admin/quests/user-answers?user_id=${userId}&quest_id=${questId}`;
    const options = {
      method: 'DELETE',
      body: JSON.stringify({
        attach_text: userAnswers[i].attach_text,
      }),
    };

    startLoading();
    await httpClient(url, options)
      .then(() => {
        notify('Answer was deleted successfully', 'success');
        setUserAnswers([]);
        refresh();
      })
      .catch(() => {
        notify("Answer wasn't deleted successfully", 'warning');
      })
      .finally(() => {
        stopLoading();
      });
  };

  const handleAnswerStatus = async (status) => {
    const customTask = tasks.find((task) => task.type_id === 24);
    const url = `${serverApiUrl}/admin/quests/approval?user_id=${userId}&quest_id=${questId}`;
    const options = {
      method: 'PUT',
      body: JSON.stringify({
        status,
        taskType: 'CUSTOM_TEXT_MANUAL',
        taskId: customTask && customTask.id,
      }),
    };

    customTask &&
      (await httpClient(url, options)
        .then(() => {
          notify('Answer status was changed successfully', 'success');
        })
        .then(() => {
          refresh();
        })
        .catch(() => {
          notify("Answer status wasn't changed successfully", 'warning');
        }));
  };

  return (
    <>
      {showButton && (
        <Button onClick={handleOpenModal}>
          <TextFormatIcon />
        </Button>
      )}
      <Dialog open={isOpen} onClick={(e) => e.stopPropagation()} onClose={handleModalClose}>
        <DialogTitle>User Answers</DialogTitle>
        <DialogContent>
          <Grid container direction="row" justify="center" alignItems="center">
            {userAnswers.map((answer, i) => (
              <Grid key={`grid-${i}`} container direction="row" justify="space-between" alignItems="center">
                <Grid item>
                  <span style={{ fontSize: '20px', marginRight: '20px' }}>{answer.attach_text}</span>
                </Grid>
                <Grid item>
                  <IconButton onClick={() => handleAnswerStatus(statuses.accept)} color="secondary">
                    <CheckIcon />
                  </IconButton>
                  <IconButton onClick={() => handleAnswerStatus(statuses.decline)} color="secondary">
                    <ClearIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteAnswer(i)} color="secondary">
                    <IconDelete />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button label="ra.action.close" onClick={handleModalClose}>
            <IconClose />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AnswersForUser;

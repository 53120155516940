import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import { CAUCASUS_TYPES, roles } from '../../../config/constants';
import BonusShopTransfersReportsList from './components/BonusShopTransfersReportsList';

const options = {
  appTypes: CAUCASUS_TYPES,
  allowedRoles: [roles.ADMIN, roles.JTI_TEAM],
  label: 'Phone Transfers',
  subMenuName: 'reports',
};

export default {
  list: BonusShopTransfersReportsList,
  icon: PhoneAndroidIcon,
  name: 'admin/reports/bonus-shop/transfers',
  options,
};

import AchievementIcon from '@material-ui/icons/Stars';
import { APP_TYPES, roles } from '../../config/constants';
import AchievementsList from './components/AchievementsList';
import AchievementCreate from './components/AchievementCreate';
import AchievementEdit from './components/AchievementEdit';

const options = {
  appTypes: [APP_TYPES.HUNGARY],
  allowedRoles: [roles.ADMIN],
  label: 'Achievements',
  subMenuName: 'content',
};

export default {
  create: AchievementCreate,
  list: AchievementsList,
  edit: AchievementEdit,
  icon: AchievementIcon,
  name: 'admin/achievements',
  options,
};

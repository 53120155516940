import React from 'react';
import { Datagrid, List, ReferenceField, TextField } from 'react-admin';
import { CustomDateField } from '../../../../commonComponents/DateField';
import CustomReportListActions from '../../../../commonComponents/ListActions/CustomReportListActions';
import { APP_TYPES } from '../../../../config/constants';

import DigitalProductReportsFilter from './DigitalProductReportsFilter';

const DigitalProductReportsList = (props) => (
  <List
    title="Digital products reports"
    actions={<CustomReportListActions />}
    filters={<DigitalProductReportsFilter />}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField label="Coupon ID" source="id" />
      <TextField label="PDF Link" source="pdf_link" />
      <TextField label="Code" source="code" />
      <CustomDateField label="Created at" source="created_at" />
      <CustomDateField label="Activated at" source="activated_at" />
      <CustomDateField label="Expired at" source="expired_at" />
      <TextField label="User ID" source="user_id" />
      <TextField label="Product ID" source="product.id" />
      <TextField label="description" source="product.description" />
      <TextField label="nominal_price" source="product.nominal_price" />
      <TextField label="price" source="product.price" />
      <ReferenceField label="Provider id" source="product.provider_id" reference="admin/bonus-shop/providers">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Type" source="product.type" reference="admin/reports/orders/types" link={false}>
        <TextField source="name" />
      </ReferenceField>
      {process.env.REACT_APP_APP_TYPE === APP_TYPES.BELARUS && (
        <TextField label="Внешний код торговой точки" source="user.account_code" />
      )}
    </Datagrid>
  </List>
);

export default DigitalProductReportsList;

import React from 'react';
import { Datagrid, DeleteButton, EditButton, List, TextField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  listTruncatedFields: {
    width: '10rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  listIdField: {
    width: '3rem',
  },
});

const ThemeList = (props) => {
  const classes = useStyles();
  return (
    <List title="Themes" exporter={false} bulkActionButtons={false} sort={{ field: 'id', order: 'DESC' }} {...props}>
      <Datagrid rowClick="edit">
        <TextField label="ID" source="id" className={classes.listIdField} />
        <TextField label="Name" source="name" className={classes.listTruncatedFields} />
        <EditButton />
        <DeleteButton undoable={false} />
      </Datagrid>
    </List>
  );
};

export default ThemeList;

import LensIcon from '@material-ui/icons/Lens';
import QuickTipsList from './components/QuickTipsList';
import QuickTipsCreate from './components/QuickTipsCreate';
import QuickTipsEdit from './components/QuickTipsEdit';
import { roles, APP_TYPES } from '../../config/constants';

const options = {
  appTypes: [APP_TYPES.IRELAND],
  allowedRoles: [roles.ADMIN],
  label: 'Quick Tips',
  subMenuName: 'content',
};

export default {
  list: QuickTipsList,
  create: QuickTipsCreate,
  edit: QuickTipsEdit,
  icon: LensIcon,
  name: 'admin/quick-tips',
  options,
};

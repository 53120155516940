import React from 'react';
import { Filter, ReferenceInput, SelectInput } from 'react-admin';
import UserAutocompleteInput from '../../../../commonComponents/UserAutocompleteInput';

const ChanceReportsFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput label="Chance" source="lottery_id" reference="admin/lotteries" alwaysOn>
      <SelectInput optionText={lotteriesOptionText} />
    </ReferenceInput>
    <UserAutocompleteInput label="User ID" source="user_id" alwaysOn />
  </Filter>
);

const lotteriesOptionText = (lottery) => `${lottery.name}(${lottery.id})`;

export default ChanceReportsFilter;

import React from 'react';
import { Filter, ReferenceInput, SelectInput } from 'react-admin';
import UserAutocompleteInput from '../../../../commonComponents/UserAutocompleteInput';

const ChallengeReportsFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      label="Challenge Admin Panel Name"
      source="quest_id"
      reference="admin/quests"
      allowEmpty={false}
      alwaysOn
    >
      <SelectInput optionText="admin_panel_name" />
    </ReferenceInput>
    <ReferenceInput label="Challenge" source="quest_id" reference="admin/quests" allowEmpty={false} alwaysOn>
      <SelectInput optionText={challengeOptionText} />
    </ReferenceInput>
    <UserAutocompleteInput label="User ID" source="user_id" alwaysOn />
  </Filter>
);

const challengeOptionText = (challenge) => `${challenge.name}(${challenge.id})`;

export default ChallengeReportsFilter;

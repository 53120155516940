import React, { useCallback, useEffect, useState } from 'react';
import { useNotify } from 'react-admin';

import httpClient from '../../core/httpClient';
import { serverApiUrl } from '../../config/constants';

import { TicketMessagesContext } from './context';
import { TicketsLayout } from './components/ticketsLayout/TicketsLayout';

const Tickets = (props) => {
  const notify = useNotify();
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [messages, setMessages] = useState([]);

  const refreshChatMessages = useCallback(() => {
    const chatId = `1-${selectedTicket.user.id}`;
    const url = `${serverApiUrl}/admin/messages?ticket_id=${selectedTicket.id}&chat_id=${chatId}&socket=false`;
    const options = { method: 'GET' };

    httpClient(url, options)
      .then((res) => {
        setMessages(res.json.messages.reverse());
      })
      .catch((err) => {
        notify(err.message, 'warning');
      });
  }, [notify, selectedTicket, setMessages]);

  useEffect(() => {
    if (!selectedTicket) return;
    refreshChatMessages();
  }, [selectedTicket, refreshChatMessages]);

  const pushNewMessage = (newMessage) => {
    newMessage && setMessages((oldMessages) => [...oldMessages, newMessage]);
  };

  const handleTicketClick = (ticket) => {
    if (ticket.id === selectedTicket?.id) {
      setSelectedTicket(null);
      setMessages([]);
    } else {
      setSelectedTicket(ticket);
    }
  };

  return (
    <TicketMessagesContext.Provider value={{ messages, pushNewMessage }}>
      <TicketsLayout {...props} onTicketClick={handleTicketClick} messages={messages} selectedTicket={selectedTicket} />
    </TicketMessagesContext.Provider>
  );
};

export default Tickets;

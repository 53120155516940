import React from 'react';
import {
  ArrayInput,
  BooleanInput,
  Create,
  FormDataConsumer,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';
import ImageInput from '../../../../commonComponents/UploadInputs/ImageInput';

import styles from './Brand.module.scss';
import { s3FilePaths } from '../../../../config/constants';

const BrandCreate = (props) => (
  <Create title="Create Brand" {...props}>
    <SimpleForm redirect="list">
      <TextInput label="Name" source="name" />
      <TextInput label="Priority" source="priority" />
      <BooleanInput label="Visible" source="visible" />
      <BooleanInput label="JTI Brand" source="is_jti" />
      {/* <FormDataConsumer> */}
      {/*   {({ formData }) => { */}
      {/*     if (formData?.show_in_activity_report || isHungary()) { */}
      {/*       return <BooleanInput label="JTI Brand" source="is_jti" />; */}
      {/*     } */}
      {/*     return null; */}
      {/*   }} */}
      {/* </FormDataConsumer> */}
      <FormDataConsumer>
        {({ formData }) => {
          if (!formData?.is_jti) {
            return (
              <ReferenceInput label="Brand" source="brand_id" reference="admin/brands" filter={{ positive: true }}>
                <SelectInput optionText="name" validate={required()} />
              </ReferenceInput>
            );
          }
          return null;
        }}
      </FormDataConsumer>
      <>
        <ArrayInput label="Slider" source="slider_images" className={`${styles.slider}`}>
          <SimpleFormIterator>
            <FormDataConsumer>
              {({ getSource }) => (
                <ImageInput label="Brand info" imagePath={s3FilePaths.brand} imageInputSource={getSource('image')} />
              )}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
        <ImageInput imageInputSource="background" imagePath={s3FilePaths.brand} />
        <ImageInput imageInputSource="logo" imagePath={s3FilePaths.brand} />
        <TextInput label="Brand description" source="brand_description" />
      </>
    </SimpleForm>
  </Create>
);

export default BrandCreate;

import React, { useState } from 'react';
import { useNotify, FormWithRedirect, Button, SaveButton, SelectInput, TextInput, required } from 'react-admin';
import IconEdit from '@material-ui/icons/Edit';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import httpClient from '../../../../core/httpClient';
import { serverApiUrl } from '../../../../config/constants';
import { attachmentsStatusChoices } from '../../config/constants';

const ChangeStatusButton = ({ record, refreshListCallback }) => {
  const notify = useNotify();
  const [isOpen, setOpenStatus] = useState(false);

  const handleOpenModal = (e) => {
    e.stopPropagation();
    setOpenStatus(true);
  };

  const handleClose = () => {
    setOpenStatus(false);
  };

  const handleSubmit = ({ id, status, user_id }) => {
    const url = `${serverApiUrl}/admin/mystery-shopper/attachments`;
    const payload = { id, status, user_id };
    const options = { method: 'PUT', body: JSON.stringify(payload) };

    httpClient(url, options)
      .then(() => {
        handleClose();
        refreshListCallback();
      })
      .catch(() => {
        notify("Error: Couldn't change attachment status", 'warning');
      });
  };

  return (
    <>
      <Button label="ra.action.edit" onClick={handleOpenModal}>
        <IconEdit />
      </Button>
      <Dialog open={isOpen} onClick={(e) => e.stopPropagation()} maxWidth="xs">
        <DialogTitle>User attachment moderation</DialogTitle>
        <FormWithRedirect
          resource="admin/mystery-shopper/attachments"
          save={handleSubmit}
          record={record}
          render={({ handleSubmitWithRedirect }) => (
            <>
              <DialogContent dividers>
                <TextInput label="Attachment ID" source="id" disabled fullWidth />
                <SelectInput
                  source="status"
                  optionText="name"
                  choices={attachmentsStatusChoices}
                  validate={required()}
                  fullWidth
                />
              </DialogContent>
              <DialogActions>
                <SaveButton handleSubmitWithRedirect={handleSubmitWithRedirect} />
                <Button label="ra.action.cancel" onClick={handleClose}>
                  <IconCancel />
                </Button>
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

export default ChangeStatusButton;

export const independentItemEntityTypes = {
  quiz: 'QUIZ',
  dailyQuestion: 'DAILY_QUESTION',
  quest: 'QUEST',
  lottery: 'LOTTERY',
  kbArticle: 'KB_ARTICLE',
};

export const independentItemTypeChoices = [
  { id: independentItemEntityTypes.quiz, name: 'Quiz' },
  { id: independentItemEntityTypes.dailyQuestion, name: 'Daily question' },
  { id: independentItemEntityTypes.quest, name: 'Quest' },
  { id: independentItemEntityTypes.lottery, name: 'Lottery' },
  { id: independentItemEntityTypes.kbArticle, name: 'KB Entity' },
];

export const quizSubtypes = {
  generalQuiz: 'GENERAL_QUIZ',
  specialQuiz: 'SPECIAL_QUIZ',
  multibrandQuiz: 'MULTIBRAND_QUIZ',
  survey: 'SURVEY',
};

export const quizActivityTypesChoice = [
  { id: quizSubtypes.generalQuiz, name: 'General quiz' },
  { id: quizSubtypes.specialQuiz, name: 'Special quiz' },
  { id: quizSubtypes.multibrandQuiz, name: 'Multibrand quiz' },
  { id: quizSubtypes.survey, name: 'Survey' },
];

import DigitalProductIcon from '@material-ui/icons/Bookmark';
import DigitalProductReportsList from './components/DigitalProductReportsList';
import { roles, APP_TYPES } from '../../../config/constants';

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: [roles.ADMIN, roles.JTI_TEAM],
  label: 'Digital Products',
  subMenuName: 'reports',
};

export default {
  list: DigitalProductReportsList,
  icon: DigitalProductIcon,
  name: 'admin/reports/digital-products',
  options,
};

import React from 'react';
import { BooleanInput, Create, NumberInput, required, SimpleForm, TextInput } from 'react-admin';
import { ColorInput } from 'react-admin-color-input';
import { APP_TYPES, CAUCASUS_TYPES, s3FilePaths } from '../../../config/constants';
import ImageInput from '../../../commonComponents/UploadInputs/ImageInput';

const ENV_APP_TYPE = process.env.REACT_APP_APP_TYPE;

const BrandCreate = (props) => (
  <Create undoable={false} {...props}>
    <SimpleForm redirect="list">
      <TextInput label="Name" source="name" validate={required()} />
      <NumberInput label="Win coins" source="win_coins" />
      <NumberInput label="Lose coins" source="lose_coins" />
      <NumberInput label="Draw coins" source="draw_coins" />
      <NumberInput label="Special quiz coins" source="special_quiz_coins" />
      <NumberInput label="Solo mode coins" source="solo_mode_coins" />
      <NumberInput label="Special quiz extra coins" source="special_quiz_extra_coins" />
      <TextInput label="Description" source="description" />
      <TextInput label="Tag line" source="quiz_tagline" validate={required()} />
      {ENV_APP_TYPE === APP_TYPES.IRELAND && (
        <ImageInput label="Disclaimer" imageInputSource="disclaimer" imagePath={s3FilePaths.brand} />
      )}
      {[...CAUCASUS_TYPES, APP_TYPES.BELARUS, APP_TYPES.HUNGARY].includes(ENV_APP_TYPE) && (
        <BooleanInput label="Double quiz points" source="double_points" />
      )}
      <NumberInput label="Priority" source="priority" />
      <BooleanInput label="Visible" source="active" />
      <ColorInput source="text_color" validate={required()} />
      <ColorInput source="quiz_element_color" validate={required()} />
      <ColorInput source="triangle_color" validate={required()} />
      <ColorInput source="special_quiz_border_color" validate={required()} />
      <ImageInput label="Image" imageInputSource="image" imagePath={s3FilePaths.brand} />
      <ImageInput label="Logo" imageInputSource="logo" imagePath={s3FilePaths.brand} />
      <ImageInput label="Statistics logo" imageInputSource="statistics_logo" imagePath={s3FilePaths.brand} />
      <ImageInput label="Background image" imageInputSource="background_image" imagePath={s3FilePaths.brand} />
      <ImageInput label="Card image" imageInputSource="card_image" imagePath={s3FilePaths.brand} />
      <ImageInput label="Image top" imageInputSource="image_top" imagePath={s3FilePaths.brand} />
    </SimpleForm>
  </Create>
);

export default BrandCreate;

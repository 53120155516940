import SpecialQuizIcon from '@material-ui/icons/VideogameAsset';
import SingleModeReportsList from './components/SingleModeReportsList';
import { APP_TYPES, roles } from '../../../config/constants';

const options = {
  appTypes: [APP_TYPES.HUNGARY],
  allowedRoles: [roles.ADMIN, roles.JTI_TEAM],
  label: 'Single mode',
  subMenuName: 'reports',
};

export default {
  list: SingleModeReportsList,
  icon: SpecialQuizIcon,
  name: 'admin/reports/single-mode',
  options,
};

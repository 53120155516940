import React from 'react';
import { Filter } from 'react-admin';
import { CustomDateInput } from '../../../../commonComponents/CustomDateInput';

const BotsReportsFilter = (props) => (
  <Filter {...props}>
    <CustomDateInput label="Choose start date" source="start_date" start alwaysOn />
    <CustomDateInput label="Choose end date" source="end_date" end alwaysOn />
  </Filter>
);

export default BotsReportsFilter;

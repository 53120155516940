import React from 'react';
import { Datagrid, DeleteButton, EditButton, List, TextField, DateField } from 'react-admin';
import DuplicateButton from '../../../commonComponents/DuplicateButton';
import CustomListActions from '../../../commonComponents/ListActions/CustomListActions';
import StatusField from '../../../commonComponents/StatusField';
import { duplicateRecord } from '../../../helpers/duplicateQuiz';

const MultibrandQuizList = (props) => (
  <List
    title="Multibrand quizzes"
    exporter={false}
    actions={<CustomListActions />}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <TextField label="Name" source="name" sortable={false} />
      <TextField label="Topic" source="brand.name" sortable={false} />
      <StatusField label="Status" source="status" sortable={false} />
      <DateField label="Publish date" source="published_at" />
      <TextField label="Price" source="price" />
      <DuplicateButton copyRecord={duplicateRecord} />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);

export default MultibrandQuizList;

import React, { Suspense } from 'react';
import { Loading } from 'react-admin';
import { APP_TYPES } from '../../../config/constants';

const ArmeniaUserCreate = React.lazy(() => import('./CreateForms/ArmeniaUserCreate'));
const AzerbaijanUserCreate = React.lazy(() => import('./CreateForms/AzerbaijanUserCreate'));
const BelarusUserCreate = React.lazy(() => import('./CreateForms/BelarusUserCreate'));
const FallbackUserCreate = React.lazy(() => import('./CreateForms/FallbackUserCreate'));
const GeorgiaUserCreate = React.lazy(() => import('./CreateForms/GeorgiaUserCreate'));
const HungaryUserCreate = React.lazy(() => import('./CreateForms/HungaryUserCreate'));
const IrelandUserCreate = React.lazy(() => import('./CreateForms/IrelandUserCreate'));

const UserCreate = (props) => {
  const forms = {
    [APP_TYPES.HUNGARY]: <HungaryUserCreate {...props} />,
    [APP_TYPES.IRELAND]: <IrelandUserCreate {...props} />,
    [APP_TYPES.BELARUS]: <BelarusUserCreate {...props} />,
    [APP_TYPES.GEORGIA]: <GeorgiaUserCreate {...props} />,
    [APP_TYPES.ARMENIA]: <ArmeniaUserCreate {...props} />,
    [APP_TYPES.AZERBAIJAN]: <AzerbaijanUserCreate {...props} />,
  };

  return (
    <Suspense fallback={<Loading />}>
      {forms[process.env.REACT_APP_APP_TYPE] || <FallbackUserCreate {...props} />}
    </Suspense>
  );
};

export default UserCreate;

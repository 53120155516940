import { Box, FormControl, InputLabel, Select, TextField } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';
import MenuItem from '@material-ui/core/MenuItem';

import { statuses } from '../../config/constants';
import CustomExportButton from '../../../../commonComponents/ListActions/CustomExportButton';

const useStyles = makeStyles({
  filtersBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '150px 0 40px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 30,
  },
  title: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 700,
    fontSize: '22px',
  },
  menuItem: {
    height: '20px',
  },
  input: {
    marginRight: '15px',
  },
  blocks: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

const OutletUsersFilters = ({
  name,
  setNameFilter,
  surname,
  setSurnameFilter,
  username,
  setUsernameFilter,
  performance_status,
  setStatusFilter,
  outletId,
}) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <div className={classes.filtersBlock}>
      <div className={classes.header}>
        <span className={classes.title}>{translate('Filter by:')}</span>
        <CustomExportButton
          filter={{
            outlet_address_id: outletId,
            name,
            surname,
            username,
            performance_status,
          }}
          path={`/admin/outlets-users/users`}
          sort={{
            field: 'id',
            order: 'DESC',
          }}
          filterValues={{ outlet_address_id: 7301 }}
          resource={'admin/outlets-users/users'}
        />
      </div>
      <Box
        sx={{
          '& > :not(style)': { m: 1, width: '30ch' },
        }}
      >
        <TextField
          label={translate('Name')}
          variant="filled"
          color="primary"
          onChange={(event) => setNameFilter(event.target.value)}
          value={name}
          className={classes.input}
        />
        <TextField
          label={translate('Surname')}
          variant="filled"
          color="primary"
          onChange={(event) => setSurnameFilter(event.target.value)}
          value={surname}
          className={classes.input}
        />
        <TextField
          label={translate('Profile Name')}
          variant="filled"
          color="primary"
          onChange={(event) => setUsernameFilter(event.target.value)}
          value={username}
          className={classes.input}
        />
        <FormControl variant="filled">
          <InputLabel>{translate('Performance Status')}</InputLabel>
          <Select
            label={translate('Performance Status')}
            variant="filled"
            color="primary"
            onChange={(event) => setStatusFilter(event.target.value)}
            value={performance_status}
            className={classes.selectInput}
          >
            {statuses.map((status, i) => (
              <MenuItem key={i} value={status.value}>
                <div className={classes.menuItem}>{translate(status.title)}</div>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </div>
  );
};

export default OutletUsersFilters;

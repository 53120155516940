import React from 'react';
import { Create, NumberInput, ReferenceInput, SelectInput, TextInput } from 'react-admin';
import { CustomDateInput } from '../../../commonComponents/CustomDateInput';
import QuizForm from '../../../commonComponents/QuizForm';
import ImageInput from '../../../commonComponents/UploadInputs/ImageInput';
import { s3FilePaths, statuses } from '../../../config/constants';

const SpecialQuizCreate = (props) => (
  <Create title="Create Special Quiz" {...props}>
    <QuizForm redirect="list">
      <TextInput label="Name" source="name" />
      <ReferenceInput label="Topic" source="brand_id" reference="services/brands">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <SelectInput source="status" optionText="name" choices={statuses.slice(0, 2)} />
      <NumberInput label="Price" source="price" />
      <CustomDateInput label="Publish date" source="published_at" />
      <ImageInput imageInputSource="image" imagePath={s3FilePaths.quiz} />
    </QuizForm>
  </Create>
);

export default SpecialQuizCreate;

import React from 'react';
import { List, Datagrid, TextField, EditButton, DeleteButton, DateField } from 'react-admin';

import { CustomDateField } from '../../../commonComponents/DateField';
import ImageField from '../../../commonComponents/ImageField';

const SalesTipsList = (props) => (
  <List title="Sales tips" exporter={false} bulkActionButtons={false} sort={{ field: 'id', order: 'DESC' }} {...props}>
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <TextField label="Name" source="name" />
      <ImageField label="Incomplete image" source="incomplete_image" />
      <ImageField label="Complete image" source="complete_image" />
      <CustomDateField label="Date created" source="created_at" />
      <DateField label="Publish date" source="published_at" />
      <EditButton />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
);

export default SalesTipsList;

import React, { useEffect } from 'react';
import { SelectInput } from 'react-admin';

const CategorySelectInput = ({ categoriesList, setCategoriesList, ...rest }) => {
  const { choices } = rest;
  useEffect(() => {
    !categoriesList && setCategoriesList(choices);
  }, [choices, setCategoriesList, categoriesList]);

  return <SelectInput {...rest} />;
};

export default CategorySelectInput;

import React from 'react';
import { Checkbox, LinearProgress, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles({
  componentContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  diagramTitle: {
    marginLeft: 48,
    marginBottom: -10,
    fontSize: 12,
    lineHeight: '14px',
    position: 'relative',
  },
  linearProgress: {
    width: '100%',
    height: 16,
  },
  colorPrimary: {
    backgroundColor: '#F2F2F2',
  },

  infoOutlineIcon: {
    color: '#30D5C8',
    fontSize: '19px',
    marginLeft: '5px',
    position: 'absolute',
    top: '-2px',
  },
});

const LinearDiagramWithCheckbox = (props) => {
  const classes = useStyles();
  const {
    title,
    value = 0,
    id,
    onCheckboxChange,
    barColorClass,
    checkboxCheckedClass,
    customClassName,
    checkboxProps,
    linearProgressProps,
    infoTitle,
  } = props;

  const handleCheckboxChange = (event) => {
    if (onCheckboxChange) {
      onCheckboxChange(event);
    }
  };

  return (
    <div className={`${classes.componentContainer} ${customClassName}`}>
      <span className={classes.diagramTitle}>
        {title}
        <Tooltip title={infoTitle} placement="top">
          <InfoIcon className={classes.infoOutlineIcon} />
        </Tooltip>
      </span>
      <div className={classes.contentContainer}>
        <Checkbox
          id={id}
          color="default"
          onChange={handleCheckboxChange}
          classes={{
            checked: checkboxCheckedClass,
          }}
          {...checkboxProps}
        />
        <LinearProgress
          className={classes.linearProgress}
          variant="determinate"
          value={value}
          classes={{
            colorPrimary: classes.colorPrimary,
            barColorPrimary: barColorClass,
          }}
          {...linearProgressProps}
        />
      </div>
    </div>
  );
};

export default LinearDiagramWithCheckbox;

import React, { useEffect, useState } from 'react';
import { Datagrid, ListContextProvider, TextField, Title } from 'react-admin';
import { io } from 'socket.io-client';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const OnlineUsersList = () => {
  const [onlineData, setOnlineData] = useState({ ids: [], data: {} });

  const joinUserDeviceTokens = (devices, typeId) => {
    const joinedTokens =
      devices && devices.length
        ? devices
            .filter((i) => i.type === typeId && i.identifier)
            .map(
              ({ identifier: token }) =>
                `${token.substring(0, 5)}...${token.substring(token.length - 5, token.length)}`,
            )
            .join(', ')
        : null;

    return joinedTokens;
  };

  useEffect(() => {
    const socket = io(process.env.REACT_APP_WS_URL, {
      query: { token: localStorage.getItem('token') },
    });

    socket.on('sendOnlineUsers', (data) => {
      const userIds = data.map((user) => user.id);
      const tableData = {};

      userIds.forEach((id) => {
        const user = data.find((user) => user.id === id);
        const iosPushTokens = joinUserDeviceTokens(user.devices, 1);
        const androidPushTokens = joinUserDeviceTokens(user.devices, 2);

        tableData[id] = { ...user, iosPushTokens, androidPushTokens };
      });

      setOnlineData({ ids: userIds, data: tableData });
    });

    return () => {
      socket.close();
    };
  }, []);

  return (
    <Card>
      <Title title="My Page" />
      <CardContent>
        {onlineData.ids.length ? (
          <ListContextProvider
            value={{
              data: onlineData.data,
              ids: onlineData.ids,
              total: onlineData.data.length,
              currentSort: { field: 'id', order: 'DESC' },
              resource: 'admin/online-users',
              selectedIds: [],
            }}
          >
            <Datagrid>
              <TextField label="Id" source="id" />
              <TextField label="Name" source="name" sortable={false} />
              <TextField label="Surname" source="surname" sortable={false} />
              <TextField label="IOS" source="iosPushTokens" sortable={false} />
              <TextField label="Android" source="androidPushTokens" sortable={false} />
            </Datagrid>
          </ListContextProvider>
        ) : (
          <Typography style={{ textAlign: 'center' }}>No data to display</Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default OnlineUsersList;

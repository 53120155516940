import React from 'react';
import { Datagrid, List, NumberField, TextField } from 'react-admin';
import { CustomDateField } from '../../../../commonComponents/DateField';
import CustomReportListActions from '../../../../commonComponents/ListActions/CustomReportListActions';
import AchievementsReportsFilter from './AchievementsReportsFilter';

const AchievementsReportsList = (props) => (
  <List
    title="Achievements reports"
    actions={<CustomReportListActions />}
    filters={<AchievementsReportsFilter />}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField source="user_id" />
      <TextField source="achievement_name" />
      <NumberField source="achievement_level" />
      <NumberField source="leaf_sum" />
      <CustomDateField source="level_completed_date" />
    </Datagrid>
  </List>
);

export default AchievementsReportsList;

import React from 'react';
import { Datagrid, List, TextField } from 'react-admin';
import { CustomDateField } from '../../../../commonComponents/DateField';
import CustomReportListActions from '../../../../commonComponents/ListActions/CustomReportListActions';

import ChanceReportsFilter from './ChanceReportsFilter';

const ChanceReportsList = (props) => (
  <List
    title="Chance reports"
    actions={<CustomReportListActions />}
    filters={<ChanceReportsFilter />}
    bulkActionButtons={false}
    sort={{ field: 'created_at', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField label="User ID" source="user_id" />
      <TextField label="Name" source="name" />
      <TextField label="User phone" source="user_phone" sortable={false} />
      <TextField label="Status" source="status" sortable={false} />
      <CustomDateField label="Date" source="date" sortable={false} />
    </Datagrid>
  </List>
);

export default ChanceReportsList;

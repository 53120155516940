import React from 'react';
import {
  BooleanInput,
  Create,
  email,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  regex,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import ManagerAutocompleteInput from '../../../commonComponents/ManagerAutocompleteInput';
import { APP_TYPES, CAUCASUS_TYPES, s3FilePaths, phoneCodes, roles, statuses } from '../../../config/constants';
import { confirmedPasswordValidation, disallowSingleUserRole } from '../../../helpers/validators';
import ImageInput from '../../../commonComponents/UploadInputs/ImageInput';

const ENV_APP_TYPE = process.env.REACT_APP_APP_TYPE;

const isCaucasus = CAUCASUS_TYPES.includes(ENV_APP_TYPE);

const managerRoles = [
  roles.BA,
  roles.JTI_TEAM,
  roles.USER,
  ...([APP_TYPES.HUNGARY, APP_TYPES.IRELAND, ...CAUCASUS_TYPES].includes(ENV_APP_TYPE) ? [roles.RSM] : []),
].map((x) => ({ id: x }));

const ManagerCreate = (props) => (
  <Create title="Create Manager" {...props}>
    <SimpleForm redirect="list">
      <TextInput label="Profile name" source="username" />
      <TextInput
        label="Name"
        source="name"
        validate={[
          required(),
          regex(/^[a-zA-ZА-Яа-я0-9ÁáÉéÍíÓóÖöŐőÚúÜüŰ\-_]+$/, 'Must contain only letters, numbers and -_'),
        ]}
      />
      <TextInput
        label="Surname"
        source="surname"
        validate={[
          required(),
          regex(/^[a-zA-ZА-Яа-я0-9ÁáÉéÍíÓóÖöŐőÚúÜüŰ\-_]+$/, 'Must contain only letters, numbers and -_'),
        ]}
      />
      <TextInput label={`Phone (${phoneCodes[process.env.REACT_APP_APP_TYPE]})`} source="phone" />
      {ENV_APP_TYPE !== APP_TYPES.IRELAND && ENV_APP_TYPE !== APP_TYPES.HUNGARY && (
        <TextInput label="Account codes" source="account_codes" />
      )}
      <TextInput label="Email" source="email" validate={[required(), email()]} />
      <TextInput label="Password" source="password" type="password" validate={required()} />
      <TextInput
        label="Confirm Password"
        source="confirm_password"
        validate={confirmedPasswordValidation}
        type="password"
      />
      {(ENV_APP_TYPE === APP_TYPES.IRELAND || ENV_APP_TYPE === APP_TYPES.HUNGARY) && (
        <ReferenceInput source="region_id" reference="admin/services/regions">
          <SelectInput optionText="name" />
        </ReferenceInput>
      )}
      {ENV_APP_TYPE !== APP_TYPES.HUNGARY && (
        <ReferenceInput label="City" source="city_id" reference="admin/cities" perPage={10000} allowEmpty>
          <SelectArrayInput />
        </ReferenceInput>
      )}
      <SelectArrayInput source="roles" optionText="id" choices={managerRoles} validate={disallowSingleUserRole} />
      <FormDataConsumer>
        {({ formData }) =>
          // prettier-ignore
          ENV_APP_TYPE === APP_TYPES.BELARUS && formData?.roles?.includes(roles.BA) && (
            <NumberInput label="Users Limit" source="users_limit" defaultValue={150} validate={required()} />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) =>
          // prettier-ignore
          isCaucasus && formData?.roles?.includes(roles.BA) && (
            <ManagerAutocompleteInput
              label="RSM"
              validate={required()}
              filter={{ roles: [roles.RSM] }}
            />
          )
        }
      </FormDataConsumer>
      <SelectInput source="status" optionText="name" choices={statuses.slice(0, 3)} />
      <BooleanInput label="Hide Phone" source="hide_phone" />
      <ImageInput imageInputSource="photo" imagePath={s3FilePaths.managerImage} />
    </SimpleForm>
  </Create>
);

export default ManagerCreate;

import React from 'react';
import { List, Datagrid, TextField, FunctionField, DeleteButton } from 'react-admin';

const BotChancesList = (props) => (
  <List
    title="Bot Chances"
    exporter={false}
    bulkActionButtons={false}
    sort={{ field: 'percentage_min', order: 'ASC' }}
    {...props}
  >
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <TextField label="Min win rate (%)" source="percentage_min" sortable={true} />
      <TextField label="Max win rate (%)" source="percentage_max" sortable={true} />
      <FunctionField
        label="Answer probabilities (%)"
        render={(record) => `
      ${record.one_answer_chance} -
      ${record.two_answers_chance} -
      ${record.three_answers_chance} -
      ${record.four_answers_chance} -
      ${record.five_answers_chance}
      `}
      />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
);

export default BotChancesList;

import ProviderIcon from '@material-ui/icons/TransferWithinAStation';
import ProviderEdit from './components/ProviderEdit';
import ProvidersList from './components/ProvidersList';
import { APP_TYPES, roles } from '../../../config/constants';

const options = {
  appTypes: [APP_TYPES.IRELAND],
  allowedRoles: [roles.ADMIN, roles.JTI_TEAM],
  label: 'Providers',
  subMenuName: 'winWinBonusShop',
};

export default {
  list: ProvidersList,
  edit: ProviderEdit,
  icon: ProviderIcon,
  name: 'admin/products/providers',
  options,
};

import React, { useState } from 'react';
import { BooleanInput, NumberInput, SimpleForm, TextInput } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { ColorInput } from 'react-admin-color-input';
import CustomArrayInput from '../../../commonComponents/CustomArrayInput';
import FormIterator from '../../../core/FormIterator';
import { mainScreenSectionTypes, mainScreenSectionTypeToggleOptions } from '../../../config/constants';
import BoxComponentForm from './BoxComponent/BoxComponentForm';
import FocusComponentForm from './FocusComponent/FocusComponentForm';
import ItemComponentForm from './ItemComponent/ItemComponentForm';
import ModuleComponentForm from './ModuleComponent/ModuleComponentForm';
import FadingBlockComponentForm from './FadingBlockComponent/FadingBlockComponentForm';
import TargetGroupInput from '../../../commonComponents/TargetGroupInput';

const useStyles = makeStyles({
  sectionWrapper: {
    width: 350,
    border: '2px solid black',
    borderRadius: 4,
    padding: 30,
  },
});

const ScreenForm = ({ children, ...rest }) => {
  const classes = useStyles();
  const [toggleOptions, setToggleOptions] = useState(mainScreenSectionTypeToggleOptions);

  const getFieldName = (member, name) => {
    if (!member) {
      return name;
    }

    return `${member}.${name}`;
  };

  const pushNewField = ({ fields, nextFormType }) => {
    fields.push({ type: { id: nextFormType } });
  };

  const removeField = (field) => {
    const deletedField = mainScreenSectionTypeToggleOptions.find((x) => x.value === field.type.id);
    setToggleOptions(toggleOptions.concat([deletedField]));
  };

  const renderForm = ({ field, member }) => {
    if (!(field && field.type && field.type.id)) {
      return null;
    }

    const itemIndex = toggleOptions.findIndex((x) => x.value === field.type.id);
    if (itemIndex !== -1) {
      setToggleOptions(toggleOptions.slice(0, itemIndex).concat(toggleOptions.slice(itemIndex + 1)));
    }

    let componentForm;
    let sectionTitle;
    // All components below are wrapped in React.memo()
    // Without that, nested FormIterators go into infinite re-renders for some reason
    switch (field.type.id) {
      case mainScreenSectionTypes.box:
        componentForm = <BoxComponentForm member={member} />;
        sectionTitle = 'Box Section';
        break;
      case mainScreenSectionTypes.focus:
        componentForm = <FocusComponentForm member={member} />;
        sectionTitle = 'Focus Section';
        break;
      case mainScreenSectionTypes.items:
        componentForm = <ItemComponentForm member={member} />;
        sectionTitle = 'Item Section';
        break;
      case mainScreenSectionTypes.modules:
        componentForm = <ModuleComponentForm member={member} />;
        sectionTitle = 'Module Section';
        break;
      case mainScreenSectionTypes.fading_block:
        componentForm = <FadingBlockComponentForm member={member} />;
        sectionTitle = 'Fading Block Section';
        break;
      default:
        componentForm = null;
    }

    return componentForm ? (
      <div className={classes.sectionWrapper}>
        <Typography variant="h4">{sectionTitle}</Typography>
        <NumberInput label="Priority" source={getFieldName(member, 'priority')} />
        <TargetGroupInput source={getFieldName(member, 'target_group_id')} />
        <BooleanInput label="Visible" source={getFieldName(member, 'visible')} />
        {field.type.id === mainScreenSectionTypes.modules && (
          <>
            <TextInput label={'Headline'} source={getFieldName(member, 'headline')} />
            <ColorInput label={'Headline Text Color'} source={getFieldName(member, 'headline_text_color')} />
          </>
        )}
        {componentForm}
      </div>
    ) : null;
  };

  return (
    <SimpleForm {...rest}>
      {children}
      {
        <CustomArrayInput source="sections" defaultValue={[]}>
          <FormIterator
            formTypeTogglerLabel="Screen component type"
            formTypeTogglerOptions={toggleOptions}
            pushNewField={pushNewField}
            removeField={removeField}
            renderForm={renderForm}
            disableAdd={!toggleOptions.length}
          />
        </CustomArrayInput>
      }
    </SimpleForm>
  );
};

export default ScreenForm;

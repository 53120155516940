import OrderIcon from '@material-ui/icons/Note';
import CurrentProductsReportsList from './components/CurrentProductsReportsList';
import { APP_TYPES, roles } from '../../../config/constants';

const options = {
  appTypes: [APP_TYPES.BELARUS],
  allowedRoles: [roles.ADMIN, roles.JTI_TEAM],
  label: 'Current products',
  subMenuName: 'reports',
};

export default {
  list: CurrentProductsReportsList,
  icon: OrderIcon,
  name: 'admin/reports/current-products',
  options,
};

import React from 'react';
import { SelectInput, FormDataConsumer, ReferenceInput, required } from 'react-admin';
import {
  independentItemEntityTypes,
  independentItemTypeChoices,
  quizActivityTypesChoice,
  quizSubtypes,
} from '../config/constants';

const quizReferenceBySubtype = {
  [quizSubtypes.generalQuiz]: 'admin/quizzes',
  [quizSubtypes.specialQuiz]: 'admin/special-quizzes',
  [quizSubtypes.multibrandQuiz]: 'admin/multibrand-quizzes',
  [quizSubtypes.survey]: 'admin/surveys',
};

const activitiesReferenceByType = {
  [independentItemEntityTypes.quest]: 'admin/quests',
  [independentItemEntityTypes.lottery]: 'admin/lotteries',
  [independentItemEntityTypes.dailyQuestion]: 'admin/daily-questions',
};

const getEntitySubtypeSelect = ({ formData }) => {
  switch (formData.entity_type) {
    case independentItemEntityTypes.kbArticle:
      return (
        <ReferenceInput label="KB Category" source="entity_subtype" reference="admin/knowledge-bank/categories">
          <SelectInput optionText="name" validate={required()} />
        </ReferenceInput>
      );
    case independentItemEntityTypes.quiz:
      return <SelectInput choices={quizActivityTypesChoice} source="entity_subtype" validate={required()} />;
    default:
      return null;
  }
};

const getEntityReferenceInput = ({ formData }) => {
  switch (formData.entity_type) {
    case independentItemEntityTypes.kbArticle:
      return (
        <ReferenceInput
          reference="admin/knowledge-bank/articles"
          source="entity_id"
          filter={{ category_id: formData.entity_subtype }}
        >
          <SelectInput optionText="name" validate={required()} />
        </ReferenceInput>
      );

    case independentItemEntityTypes.quiz:
      return (
        <ReferenceInput reference={quizReferenceBySubtype[formData.entity_subtype]} source="entity_id">
          <SelectInput optionText="name" validate={required()} />
        </ReferenceInput>
      );
    case independentItemEntityTypes.quest:
    case independentItemEntityTypes.lottery:
    case independentItemEntityTypes.dailyQuestion:
      return (
        <ReferenceInput reference={activitiesReferenceByType[formData.entity_type]} source="entity_id">
          <SelectInput
            optionText={formData.entity_type === independentItemEntityTypes.dailyQuestion ? 'text' : 'name'}
            validate={required()}
          />
        </ReferenceInput>
      );
    default:
      return null;
  }
};

const IndependentItemCreateFields = () => (
  <>
    <SelectInput choices={independentItemTypeChoices} source="entity_type" /> <br />
    <FormDataConsumer>{getEntitySubtypeSelect}</FormDataConsumer> <br />
    <FormDataConsumer>{getEntityReferenceInput}</FormDataConsumer> <br />
  </>
);

export default IndependentItemCreateFields;

import React from 'react';
import { Datagrid, List, TextField } from 'react-admin';
import CustomReportListActions from '../../../../commonComponents/ListActions/CustomReportListActions';

import TaxesReportsFilter from './TaxesReportsFilter';

const TaxesReportsList = (props) => (
  <List
    title="Taxes reports"
    actions={<CustomReportListActions />}
    filters={<TaxesReportsFilter />}
    filterDefaultValues={{ formula: 0 }}
    bulkActionButtons={false}
    sort={{}}
    {...props}
  >
    <Datagrid>
      <TextField label="Total cost" source="total" sortable={false} />
      <TextField label="Total Minus Tax Rate" source="totalMinusN" sortable={false} />
      <TextField label="Tax cost" source="tax" sortable={false} />
      <TextField label="User ID" source="id" sortable={false} />
      <TextField label="Name" source="name" sortable={false} />
      <TextField label="Surname" source="surname" sortable={false} />
      <TextField label="Username" source="username" sortable={false} />
      <TextField label="Phone" source="phone" sortable={false} />
    </Datagrid>
  </List>
);

export default TaxesReportsList;

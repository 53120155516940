import React from 'react';
import {
  BooleanInput,
  Create,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
} from 'react-admin';

import UploadFileInput from '../../../../commonComponents/UploadInputs/UploadFileInput';
import UserAutocompleteInput from '../../../../commonComponents/UserAutocompleteInput';
import { APP_TYPES } from '../../../../config/constants';

const amountOfUsersChoices = [
  { id: 'single', name: 'Transaction for a single user' },
  { id: 'all', name: 'Transaction for all users' },
  { id: 'group', name: 'Transaction for a group of users' },
  { id: 'custom', name: 'Transaction for a group of users with custom points' },
];

const TransactionCreate = (props) => (
  <Create title="Create Transaction" {...props}>
    <SimpleForm redirect="list">
      <SelectInput source="amountOfUsers" optionText="name" choices={amountOfUsersChoices} />
      <FormDataConsumer>
        {({ formData }) => (
          <>
            {formData.amountOfUsers === 'single' && <UserAutocompleteInput source="id" />}
            {(formData.amountOfUsers === 'group' || formData.amountOfUsers === 'custom') && (
              <UploadFileInput label="File with users" fileInputSource="file_key" validate={required()} />
            )}

            {formData.amountOfUsers && (
              <ReferenceInput source="type" reference="admin/transactions/types" perPage={100}>
                <SelectInput optionText="name" />
              </ReferenceInput>
            )}
            <br />
            {formData.amountOfUsers && formData.amountOfUsers !== 'custom' && <NumberInput source="coins" />}
            <br />
            {formData.amountOfUsers && (
              <BooleanInput defaultValue={true} label="Monthly earned increase" source="shouldIncreaseMonthlyEarned" />
            )}
            {process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY && formData.amountOfUsers && (
              <NumberInput source="leaf" />
            )}
          </>
        )}
      </FormDataConsumer>
    </SimpleForm>
  </Create>
);

export default TransactionCreate;

import React from 'react';
import { Filter, ReferenceInput, TextInput, SelectInput } from 'react-admin';

const ManagersFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Profile name" source="username" alwaysOn />
    <TextInput label="Name" source="name" alwaysOn />
    <TextInput label="Surname" source="surname" alwaysOn />
    <ReferenceInput label="City" source="city_id" reference="admin/cities" perPage={10000} alwaysOn>
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export default ManagersFilter;

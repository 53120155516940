import React, { useEffect } from 'react';
import { Create, SelectInput, SimpleForm, required, BooleanInput } from 'react-admin';
import { useField, useForm } from 'react-final-form';
import { CustomDateInput } from '../../../commonComponents/CustomDateInput';
import Question from '../../../commonComponents/Question';
import { formTypeTogglerOptions, quizzesQuestionTypes } from '../../../config/constants';
import { isIreland } from '../../../helpers/appTypeHelper';

const SingleDailyQuestion = () => {
  const form = useForm();
  const {
    input: { value: questionType },
  } = useField('type_id');
  const answerAmount = {
    [quizzesQuestionTypes.commonText]: 4,
    [quizzesQuestionTypes.picture]: 4,
    [quizzesQuestionTypes.trueFalse]: 2,
  };

  useEffect(() => {
    form.change('type_id', 1);
  }, [form]);

  useEffect(() => {
    let answerCounter = answerAmount[questionType] || 0;
    // eslint-disable-next-line no-plusplus
    while (answerCounter-- > 0) {
      form.mutators.push('answers', {});
    }

    return () => {
      form.change('answers', []);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionType]);

  return <Question questionFormType={questionType} />;
};

const DailyQuestionCreate = (props) => (
  <Create title="Create question of the day" {...props}>
    <SimpleForm redirect="list">
      <SelectInput source="type_id" optionValue="value" optionText="label" choices={formTypeTogglerOptions} />
      <SingleDailyQuestion />
      {isIreland() && (
        <BooleanInput
          style={{ width: '300px' }}
          label="Included in the Progress bar"
          source="progress_bar_enable"
          defaultValue={true}
        />
      )}
      <CustomDateInput label="Publish date" source="active_at" validate={required()} />
    </SimpleForm>
  </Create>
);

export default DailyQuestionCreate;

import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import Tickets from './Tickets';
import { roles, APP_TYPES } from '../../config/constants';

const options = {
  appTypes: [APP_TYPES.IRELAND],
  allowedRoles: [roles.ADMIN],
  label: 'Tickets',
};

export default {
  list: Tickets,
  icon: LiveHelpIcon,
  name: 'admin/tickets',
  options,
};

import React from 'react';
import { Create, TextInput } from 'react-admin';
import RulesForm from './RulesForm';

const TargetGroupCreate = (props) => (
  <Create {...props}>
    <RulesForm redirect="list">
      <TextInput source="name" />
    </RulesForm>
  </Create>
);

export default TargetGroupCreate;

import React from 'react';
import { useForm } from 'react-final-form';

import ImageInput from '../../../commonComponents/UploadInputs/ImageInput';

const ConditionalImageUploader = ({ ...otherProps }) => {
  const form = useForm();
  const hasUrl = form.getFieldState('url')?.value;

  return hasUrl ? <ImageInput {...otherProps} /> : null;
};

export default ConditionalImageUploader;

import React from 'react';
import { Datagrid, DeleteButton, EditButton, TextField, List } from 'react-admin';
import CustomListActions from '../../../commonComponents/ListActions/CustomListActions';

const TiersList = (props) => (
  <List
    title="Tiers"
    bulkActionButtons={false}
    actions={<CustomListActions />}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField label="ID" source="id" />
      <TextField label="Name" source="name" />
      <EditButton />
      <DeleteButton mutationMode="optimistic" />
    </Datagrid>
  </List>
);

export default TiersList;

import React from 'react';
import { Filter, SelectInput, TextField } from 'react-admin';
import { CustomDateInput } from '../../../../commonComponents/CustomDateInput';
import UserAutocompleteInput from '../../../../commonComponents/UserAutocompleteInput';
import { shopsByAppType } from '../../../../config/constants';

const shops = shopsByAppType[process.env.REACT_APP_APP_TYPE];

const TransfersReportsFilter = (props) => (
  <Filter {...props}>
    <CustomDateInput label="Choose start date" source="start_date" start alwaysOn />
    <CustomDateInput label="Choose end date" source="end_date" end alwaysOn />
    <TextField source="traffic_code" />
    <UserAutocompleteInput label="User ID" source="user_id" alwaysOn />
    <SelectInput source="shop" optionText="name" choices={shops} alwaysOn />
  </Filter>
);

export default TransfersReportsFilter;

import React from 'react';
import { NumberInput, required, SimpleForm } from 'react-admin';
import { Typography } from '@material-ui/core/';

const BotChancesForm = (props) => (
  <SimpleForm {...props}>
    <NumberInput label="ID" source="id" disabled />
    <Typography variant="subtitle2">Win rate (%)</Typography>
    <NumberInput
      label="Min"
      source="percentage_min"
      style={{ width: 118, float: 'left', marginRight: 20 }}
      validate={required()}
    />
    <NumberInput label="Max" source="percentage_max" style={{ width: 118 }} validate={required()} />
    <Typography variant="subtitle2">Probability of correct Bot answers (%)</Typography>
    <NumberInput label="One correct answer" source="one_answer_chance" validate={required()} />
    <NumberInput label="Two correct answers" source="two_answers_chance" validate={required()} />
    <NumberInput label="Three correct answers" source="three_answers_chance" validate={required()} />
    <NumberInput label="Four correct answers" source="four_answers_chance" validate={required()} />
    <NumberInput label="Five correct answers" source="five_answers_chance" validate={required()} />
  </SimpleForm>
);

export default BotChancesForm;

import React from 'react';
import { Filter, NumberInput, TextInput, SelectInput } from 'react-admin';
import { CustomDateInput } from '../../../../commonComponents/CustomDateInput';
import UserAutocompleteInput from '../../../../commonComponents/UserAutocompleteInput';
import { booleanFilterChoices } from '../../../../config/constants';

const NewDownloadsFilter = (props) => (
  <Filter {...props}>
    <NumberInput label="Week number" source="week_number" alwaysOn />
    <CustomDateInput
      label="Year"
      source="year"
      views={['year']}
      labelFunc={(date) => (date ? date.year() : '')}
      alwaysOn
    />
    <TextInput label="Account code" source="traffic_code" alwaysOn />
    <UserAutocompleteInput label="User ID" source="user_id" alwaysOn />
    <SelectInput source="downloaded" choices={booleanFilterChoices} allowNull alwaysOn />
  </Filter>
);

export default NewDownloadsFilter;

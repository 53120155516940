import React from 'react';
import { BooleanField, Datagrid, List, TextField } from 'react-admin';

import ProductQuestionsReportsFilter from './ProductQuestionsReportsFilter';
import CustomReportListActions from '../../../../commonComponents/ListActions/CustomReportListActions';
import { CustomDateField } from '../../../../commonComponents/DateField';

const ProductQuestionsReportsList = (props) => (
  <List
    title="Product question quiz report"
    actions={<CustomReportListActions />}
    filters={<ProductQuestionsReportsFilter />}
    filterDefaultValues={{ type_ids: [3] }}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField label="User ID" source="user_id" sortable={false} />
      <TextField label="Brand ID" source="brand_name" sortable={false} />
      <TextField label="Quiz ID" source="pack_id" sortable={false} />
      <TextField label="Question" source="question_text" sortable={false} />
      <TextField label="Correct answer" source="correct_answer" sortable={false} />
      <TextField label="User answer" source="user_answer" sortable={false} />
      <BooleanField label="Correct" source="is_correct" sortable={false} />
      <CustomDateField label="Date" source="date" sortable={false} />
    </Datagrid>
  </List>
);

export default ProductQuestionsReportsList;

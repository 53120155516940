import React from 'react';
import { SimpleForm, Edit, NumberInput, BooleanInput } from 'react-admin';
import IndependentItemCreateFields from './independentItemCreateFields';
import { isIreland } from '../../../helpers/appTypeHelper';

const IndependentItemEdit = (props) => (
  <Edit title="Edit independent item" undoable={false} {...props}>
    <SimpleForm>
      <NumberInput source="id" disabled />
      <IndependentItemCreateFields />
      {isIreland() && (
        <BooleanInput style={{ width: '300px' }} label="Included in the Progress bar" source="progress_bar_enable" />
      )}
    </SimpleForm>
  </Edit>
);

export default IndependentItemEdit;

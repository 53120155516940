import React, { useEffect, useState } from 'react';
import { Button } from 'react-admin';

import httpClient from '../../../../core/httpClient';
import { serverApiUrl } from '../../../../config/constants';
import UsersOverview from './usersOverview/UsersOverview';
import UserCard from './userCard/UserCard';
import OutletUsersFilters from './OutletUsersFilters';
import OutletUsersSorting from './OutletUsersSorting';

const OutletUsers = ({ match, history }) => {
  const outletId = match.params.id;

  const [users, setUsers] = useState([]);

  const [sortBy, setSortBy] = useState({ field: 'id', order: 'DESC' });

  const [nameFilter, setNameFilter] = useState('');
  const [surnameFilter, setSurnameFilter] = useState('');
  const [usernameFilter, setUsernameFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');

  const getOutletUsers = () => {
    const url = `${serverApiUrl}/admin/outlets-users/users?outlet_address_id=${outletId}&name=${nameFilter}&surname=${surnameFilter}&username=${usernameFilter}&performance_status=${statusFilter}&sort=${sortBy.field}&order=${sortBy.order}`;
    const options = { method: 'GET' };

    httpClient(url, options)
      .then(({ json: { data } }) => {
        setUsers(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOutletUsers();
  }, [nameFilter, surnameFilter, usernameFilter, statusFilter, sortBy]);

  const handleClick = () => {
    history.goBack();
  };

  return (
    <div>
      <UsersOverview outletId={outletId} />

      <OutletUsersFilters
        name={nameFilter}
        setNameFilter={setNameFilter}
        surname={surnameFilter}
        setSurnameFilter={setSurnameFilter}
        username={usernameFilter}
        setUsernameFilter={setUsernameFilter}
        performance_status={statusFilter}
        setStatusFilter={setStatusFilter}
        outletId={outletId}
      />

      <OutletUsersSorting sortBy={sortBy} setSortBy={setSortBy} />

      {users.map(
        ({
          brand_knowledge,
          coins,
          earned_credits,
          last_transaction_date,
          performance_status,
          spent_credits,
          leaderboard,
          name,
          surname,
          photo,
        }) => (
          <UserCard
            key={name + last_transaction_date}
            name={name}
            surname={surname}
            lastActivity={last_transaction_date}
            balance={coins}
            earnedCredits={earned_credits}
            spentCredits={spent_credits}
            leaderboardPosition={leaderboard}
            brandKnowledge={brand_knowledge}
            status={performance_status}
            photo={photo}
          />
        ),
      )}
      <Button style={{ margin: '20px 0', width: 100, border: '1px solid' }} onClick={handleClick}>
        <span style={{ fontWeight: 400, fontSize: 15 }}>Back</span>
      </Button>
    </div>
  );
};

export default OutletUsers;

import React from 'react';
import { Create, SimpleForm } from 'react-admin';

import RewardForm from './RewardForm';

const RewardCreate = (props) => (
  <Create title="Create Reward" {...props}>
    <SimpleForm redirect="list">
      <RewardForm />
    </SimpleForm>
  </Create>
);

export default RewardCreate;

import SalesTipsIcon from '@material-ui/icons/CameraAlt';
import ARPacksList from './components/ARPacksList';
import SalesTipCreate from './components/ARPacksCreate';
import ARPacksEdit from './components/ARPacksEdit';
import { roles, APP_TYPES } from '../../config/constants';

const options = {
  appTypes: [APP_TYPES.HUNGARY],
  allowedRoles: [roles.ADMIN],
  label: 'AR Packs',
  subMenuName: 'content',
};

export default {
  list: ARPacksList,
  create: SalesTipCreate,
  edit: ARPacksEdit,
  icon: SalesTipsIcon,
  name: 'admin/pack-recognition',
  options,
};

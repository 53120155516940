import BrandIcon from '@material-ui/icons/Vibration';
import { APP_TYPES, roles } from '../../config/constants';
import BrandCreate from './components/BrandCreate';
import BrandEdit from './components/BrandEdit';
import BrandsList from './components/BrandsList';

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: [roles.ADMIN],
  label: 'Brands',
  subMenuName: 'manage',
};

export default {
  create: BrandCreate,
  list: BrandsList,
  edit: BrandEdit,
  icon: BrandIcon,
  name: 'admin/brands',
  options,
};

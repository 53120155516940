import KnowledgeBankArticleIcon from '@material-ui/icons/FormatAlignLeft';
import ArticlesList from './components/ArticlesList';
import ArticleCreate from './components/ArticleCreate';
import ArticleEdit from './components/ArticleEdit';
import { roles, APP_TYPES } from '../../../config/constants';

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: [roles.ADMIN],
  label: 'Articles',
  subMenuName: 'knowledgeBank',
};

export default {
  list: ArticlesList,
  create: ArticleCreate,
  edit: ArticleEdit,
  icon: KnowledgeBankArticleIcon,
  name: 'admin/knowledge-bank/articles',
  options,
};

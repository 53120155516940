import React from 'react';
import { SimpleFormIterator, ArrayInput, TextInput, required, BooleanInput, NumberInput } from 'react-admin';

const MultipleAnswersFields = ({ getSource, isDropdownIndexShown }) => (
  <ArrayInput label="Answers" source={getSource('answers')}>
    <SimpleFormIterator>
      {isDropdownIndexShown && <NumberInput label="Dropdown Index" source="dropdown_index" min={1} max={5} />}
      <TextInput label="Answer" source="answer" validate={required()} />
      <BooleanInput label="Correct" source="correct" />
    </SimpleFormIterator>
  </ArrayInput>
);

export default MultipleAnswersFields;

import CategoryIcon from '@material-ui/icons/Bookmark';
import CategoriesList from './components/CategoriesList';
import CategoryCreate from './components/CategoryCreate';
import CategoryEdit from './components/CategoryEdit';
import { APP_TYPES, roles } from '../../../config/constants';

const options = {
  appTypes: [APP_TYPES.IRELAND],
  allowedRoles: [roles.ADMIN, roles.JTI_TEAM],
  label: 'Categories',
  subMenuName: 'winWinBonusShop',
};

export default {
  list: CategoriesList,
  create: CategoryCreate,
  edit: CategoryEdit,
  icon: CategoryIcon,
  name: 'admin/products/categories',
  options,
};

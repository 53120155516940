import React from 'react';
import { BooleanInput, required, TextInput } from 'react-admin';

const SingleAnswerFields = ({ getSource }) => (
  <>
    <TextInput label="Answer" source={getSource('answers[0].answer')} validate={required()} />
    <BooleanInput label="Correct" source={getSource('answers[0].correct')} defaultValue disabled />
  </>
);

export default SingleAnswerFields;

import React from 'react';
import { Collapse, List, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMore from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles({
  subMenuIcon: {
    minWidth: '40px',
  },
  listItemText: {
    '& *': { fontSize: '1rem' },
    margin: 0,
    overflow: 'hidden',
  },
  sidebarIsOpen: {
    paddingLeft: 25,
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
  sidebarIsClosed: {
    paddingLeft: 0,
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
});

const SubMenu = ({ handleToggle, sidebarIsOpen, isOpen, name, icon, children }) => {
  const classes = useStyles();

  return (
    <>
      <MenuItem onClick={handleToggle}>
        <ListItemIcon className={classes.subMenuIcon}>{isOpen ? <ExpandMore /> : icon}</ListItemIcon>
        <ListItemText primary={isOpen ? name : ''} secondary={isOpen ? '' : name} className={classes.listItemText} />
      </MenuItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          className={sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed}
        >
          {children}
        </List>
      </Collapse>
    </>
  );
};

export default SubMenu;

import React from 'react';
import { Datagrid, List, TextField } from 'react-admin';
import { CustomDateField } from '../../../../commonComponents/DateField';
import CustomReportListActions from '../../../../commonComponents/ListActions/CustomReportListActions';

import SingleModeReportsFilter from './SingleModeReportsFilter';

const SingleModeReportsList = (props) => (
  <List
    title="Single mode reports"
    actions={<CustomReportListActions />}
    filters={<SingleModeReportsFilter />}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField label="Game ID" source="id" />
      <TextField label="Quiz ID" source="pack.id" />
      <TextField label="Quiz name" source="pack.name" />
      <TextField label="User ID" source="user_id" />
      <TextField label="User name" source="user.name" />
      <TextField label="User phone" source="user.phone" />
      <CustomDateField label="Date" source="created_at" />
      <TextField label="Points" source="transactions[0].coins" sortable={false} />
    </Datagrid>
  </List>
);

export default SingleModeReportsList;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {
  ArrayInput,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  SimpleFormIterator,
  ReferenceInput,
  SelectInput,
} from 'react-admin';

const useStyles = makeStyles({
  hideFormLabel: {
    '& li > p': {
      display: 'none',
    },
    width: 256,
  },
});

const SimulatorScenarioCreate = (props) => {
  const classes = useStyles();

  return (
    <Create title="Create sales simulator" undoable={false} {...props}>
      <SimpleForm redirect="list">
        <Typography color="error">Changes saving not implemented yet. Import instead.</Typography>
        <TextInput label="Name" source="name" />
        <ArrayInput source="questions" className={classes.hideFormLabel}>
          <SimpleFormIterator>
            <TextInput label="ID" source="id" disable />
            <NumberInput label="Number" source="number" />
            <TextInput label="Step" source="step" />
            <TextInput label="Text" source="text" multiline />
            <ReferenceInput label="Type" source="reaction_type_id" reference="admin/reactions/types">
              <SelectInput optionText="id" />
            </ReferenceInput>
            {/* <ArrayInput source="questions" className={classes.hideFormLabel}> */}
            {/*  <SimpleFormIterator> */}
            {/*    <TextInput label="ID" source="id" disabled /> */}
            {/*    <TextInput label="Text" source="text" multiline /> */}
            {/*    <TextInput label="Comment" source="comment" multiline /> */}
            {/*    <ReferenceInput label="Type" source="type_id" reference="admin/reactions/types"> */}
            {/*      <SelectInput optionText="id" /> */}
            {/*    </ReferenceInput> */}
            {/*  </SimpleFormIterator> */}
            {/* </ArrayInput> */}
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

export default SimulatorScenarioCreate;

import LimitationIcon from '@material-ui/icons/PanoramaFishEye';
import LimitationsList from './components/LimitationsList';
import LimitationsEdit from './components/LimitationsEdit';
import LimitationsCreate from './components/LimitationsCreate';
import { roles, APP_TYPES } from '../../config/constants';

const options = {
  appTypes: [APP_TYPES.IRELAND, APP_TYPES.DEMO, APP_TYPES.HUNGARY],
  allowedRoles: [roles.ADMIN, roles.JTI_TEAM],
  label: 'Monthly Limitation',
  subMenuName: 'manage',
};

export default {
  list: LimitationsList,
  create: LimitationsCreate,
  edit: LimitationsEdit,
  icon: LimitationIcon,
  name: 'admin/monthly-limitations',
  options,
};

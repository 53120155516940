import React from 'react';
import { TextInput, BooleanInput, SelectArrayInput, SelectInput, ReferenceInput, required } from 'react-admin';
import { FieldArray } from 'react-final-form-arrays';
import { makeStyles } from '@material-ui/core/styles';

import ImageInput from './UploadInputs/ImageInput';
import { quizzesQuestionTypes as questionTypes, quizPackTypes, APP_TYPES, s3FilePaths } from '../config/constants';

const useStyles = makeStyles({
  customFormField: {
    width: 256,
  },
});

const textAnswersList = ({ fields, classes }) => {
  const answersList = (
    <>
      {fields.map((member) => (
        <div key={`${member}.answer`}>
          <TextInput
            key={member}
            source={`${member}.answer`}
            label="Answer for question"
            className={classes.customFormField}
            validate={required()}
          />
        </div>
      ))}
    </>
  );

  return answersList;
};

const imageAnswersList = ({ fields, classes }) => {
  const answersList = (
    <>
      {fields.map((member, index) => (
        <div key={`${member}.answer`}>
          <ImageInput
            key={member}
            imagePath={s3FilePaths.quiz}
            imageInputSource={`${member}.answer`}
            label={`Answer ${index + 1}`}
            validate={required()}
          />
        </div>
      ))}
    </>
  );

  return answersList;
};

const determineQuestionSettings = (questionFormType) => {
  const answersType = questionFormType === questionTypes.picture ? 'image' : 'text';
  const isMultipleCorrectAnswersChoice = questionFormType !== questionTypes.trueFalse;
  let correctAnswers = [
    { id: 1, name: '1' },
    { id: 2, name: '2' },
    { id: 3, name: '3' },
    { id: 4, name: '4' },
  ];

  correctAnswers = questionFormType === questionTypes.trueFalse ? correctAnswers.slice(0, 2) : correctAnswers;

  const settings = {
    answersType,
    isMultipleCorrectAnswersChoice,
    correctAnswers,
  };

  return settings;
};

const getFieldName = (member, name) => {
  if (!member) {
    return name;
  }

  return `${member}.${name}`;
};

const brandValidator = (value, allValues) => {
  const isBrandRequired = allValues.show_disclaimer || allValues.product_question;
  return !value && isBrandRequired && 'Brand required';
};

const Question = ({ member, questionFormType, quizType, index }) => {
  const classes = useStyles();
  const { answersType, isMultipleCorrectAnswersChoice, correctAnswers } = determineQuestionSettings(questionFormType);
  const isAnswersTypeText = answersType === 'text';
  const isMultibrandQuestion = quizType === quizPackTypes.multibrand;
  const answersComponent = isAnswersTypeText ? textAnswersList : imageAnswersList;
  const isMultipleAllowed = quizType === 'survey' && questionFormType !== 2;
  const ENV_APP_TYPE = process.env.REACT_APP_APP_TYPE;

  return (
    <>
      <TextInput
        source={getFieldName(member, 'text')}
        label="Question"
        className={classes.customFormField}
        validate={required()}
      />
      <br />
      <FieldArray name={getFieldName(member, 'answers')} classes={classes}>
        {answersComponent}
      </FieldArray>
      {isMultipleCorrectAnswersChoice ? (
        <SelectArrayInput
          label="Correct answers"
          source={getFieldName(member, 'correct_answers')}
          choices={correctAnswers}
          className={classes.customFormField}
        />
      ) : (
        <SelectInput
          label="Correct answers"
          source={getFieldName(member, 'correct_answers')}
          choices={correctAnswers}
          format={(value) => (value ? value[0] : '')}
          parse={(value) => [value]}
          className={classes.customFormField}
        />
      )}
      {isMultibrandQuestion ? (
        <>
          <BooleanInput label="Brand question" source={getFieldName(member, 'is_brand_question')} />
          <ReferenceInput
            label="Topic"
            source={getFieldName(member, 'brand_id')}
            reference="services/brands"
            className={classes.customFormField}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        </>
      ) : (
        <BooleanInput label="Show price disclaimer" source={getFieldName(member, 'show_disclaimer')} />
      )}
      <BooleanInput label="Product question" source={getFieldName(member, 'product_question')} />
      {ENV_APP_TYPE === APP_TYPES.HUNGARY && isMultipleAllowed && (
        <BooleanInput label="Multiple survey" source={getFieldName(member, 'is_multiple')} />
      )}
      {[APP_TYPES.IRELAND, APP_TYPES.HUNGARY].includes(ENV_APP_TYPE) && !Number.isInteger(index) && (
        <ReferenceInput
          label="Brand"
          reference="services/brands"
          source={getFieldName(member, 'brand_id')}
          allowEmpty={true}
          validate={brandValidator}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      )}
      {isAnswersTypeText && (
        <ImageInput
          imagePath={s3FilePaths.quiz}
          imageInputSource={getFieldName(member, 'image')}
          validate={required()}
        />
      )}
    </>
  );
};

export default Question;

import SalesTipsIcon from '@material-ui/icons/Help';
import SalesTipsReportsList from './components/SalesTipsReportsList';
import { APP_TYPES, roles } from '../../../config/constants';

const options = {
  appTypes: [APP_TYPES.BELARUS, APP_TYPES.HUNGARY],
  allowedRoles: [roles.ADMIN],
  label: 'Sales tips',
  subMenuName: 'reports',
};

export default {
  list: SalesTipsReportsList,
  icon: SalesTipsIcon,
  name: 'admin/reports/sales-tips',
  options,
};

import SurveyIcon from '@material-ui/icons/AssignmentTurnedIn';
import SurveyReportsList from './components/SurveyReportsList';
import { APP_TYPES, roles } from '../../../config/constants';

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: [roles.ADMIN, roles.JTI_TEAM],
  label: 'Surveys',
  subMenuName: 'reports',
};

export default {
  list: SurveyReportsList,
  icon: SurveyIcon,
  name: 'admin/reports/surveys',
  options,
};

import React from 'react';
import { BooleanField, Datagrid, List, ReferenceField, TextField } from 'react-admin';
import { CustomDateField } from '../../../../commonComponents/DateField';
import CustomReportListActions from '../../../../commonComponents/ListActions/CustomReportListActions';

import GamesReportsFilter from './GamesReportsFilter';

const GamesReportsList = (props) => (
  <List
    title="Games reports"
    actions={<CustomReportListActions />}
    filters={<GamesReportsFilter />}
    bulkActionButtons={false}
    sort={{ field: 'created_at', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField source="id" />
      <TextField label="User ID" source="user_id" />
      <TextField label="User 2 ID" source="user2_id" />
      <TextField source="pack_id" />
      <ReferenceField reference="admin/brands" source="brand_id">
        <TextField source="name" />
      </ReferenceField>
      <TextField label="User points" source="user_points" />
      <TextField label="User 2 points" source="user2_points" />
      <TextField label="User kudos" source="user_points" />
      <TextField source="winner_id" />
      <BooleanField source="bonus" sortable={false} />
      <CustomDateField source="created_at" />
    </Datagrid>
  </List>
);

export default GamesReportsList;

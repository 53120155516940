import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';

const CityCreate = (props) => (
  <Create title="Create City" {...props}>
    <SimpleForm redirect="list">
      <TextInput label="Name" source="name" />
    </SimpleForm>
  </Create>
);

export default CityCreate;

import React, { useState, useEffect } from 'react';
import { useNotify, useTranslate } from 'react-admin';

import CardWithDiagram from '../CardWithDiagram';
import httpClient from '../../../../../core/httpClient';
import {
  serverApiUrl,
  linearDiagramDefaultRegion,
  linearDiagramRegionFilterOptionTypes,
  APP_TYPES,
  CAUCASUS_TYPES,
  roles,
} from '../../../../../config/constants';
import checkUserRole from '../../../../../helpers/checkUserRole';

const ENV_APP_TYPE = process.env.REACT_APP_APP_TYPE;

const isRSM = checkUserRole(roles.RSM);
const isCaucasus = CAUCASUS_TYPES.includes(ENV_APP_TYPE);

const getAdminData = async (query = '') => {
  const url = `${serverApiUrl}/admin/users/admin/stats${query}`;
  const options = { method: 'GET' };

  return httpClient(url, options);
};

const AdminLinearDiagram = (props) => {
  const notify = useNotify();
  const translate = useTranslate();

  const {
    onPeriodChange,
    onCustomFilterChange,
    onCheckboxChange,
    selectedFilters,
    selectedCustomFilterOption,
    selectedPeriod,
  } = props;

  const [summaryData, setSummaryData] = useState([]);
  const [regionsData, setRegionsData] = useState([]);

  useEffect(() => {
    getAdminData()
      .then((response) => {
        setSummaryData(response.json.national_data.data);
        setRegionsData(response.json.regions_data);
      })
      .catch((e) => {
        notify(`GET Admin data error: ${e.message}`, 'warning');
      });
  }, [notify]);

  useEffect(() => {
    if (!isRSM || isCaucasus || !regionsData.length) {
      return;
    }

    const rsmRegionId = regionsData[0]?.id;
    const options = getRegionOptions();
    const regionOption = options.find((x) => x.id === rsmRegionId);

    if (regionOption && onCustomFilterChange) {
      onCustomFilterChange(regionOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionsData]);

  useEffect(() => {
    const getQuery = () => {
      let query = '?';
      if (!selectedPeriod && !selectedCustomFilterOption) {
        return query;
      }
      if (selectedPeriod) {
        query += `period_type=${selectedPeriod}&`;
      }
      if (selectedCustomFilterOption) {
        query += `custom_option_id=${selectedCustomFilterOption.id}&custom_option_type_id=${selectedCustomFilterOption.type}`;
      }
      return query;
    };

    const query = getQuery();

    getAdminData(query)
      .then((response) => {
        setSummaryData(response.json.national_data.data);
      })
      .catch((e) => {
        notify(`GET Admin data error: ${e.message}`, 'warning');
      });
  }, [selectedPeriod, selectedCustomFilterOption, notify]);

  const handlePeriodChange = (event) => {
    if (onPeriodChange) {
      onPeriodChange(event);
    }
  };

  const handleCustomFilterChange = (event) => {
    const options = getRegionOptions();
    const selectedOption = options.find((x) => x.id === event.target.value);
    if (onCustomFilterChange) {
      onCustomFilterChange(selectedOption);
    }
  };

  const handleCheckboxChange = (event) => {
    if (onCheckboxChange) {
      onCheckboxChange(event);
    }
  };

  const getRegionOptions = () => {
    const regions = regionsData.map((region) => {
      const managers = region.managers.map((manager) => {
        const name =
          ENV_APP_TYPE === APP_TYPES.HUNGARY || CAUCASUS_TYPES.includes(ENV_APP_TYPE)
            ? manager.fullName
            : manager.username;
        return {
          id: manager.id,
          name,
          type: linearDiagramRegionFilterOptionTypes.MANAGER,
        };
      });

      return [
        {
          id: region.id,
          name: translate('root.statistics.region_entry', { name: region.name }),
          type: linearDiagramRegionFilterOptionTypes.REGION,
        },
        ...managers,
      ];
    });

    const options = regions.flat();

    options.unshift({
      id: linearDiagramDefaultRegion.id,
      name: translate(linearDiagramDefaultRegion.name),
      type: linearDiagramRegionFilterOptionTypes.REGION,
    });

    if (isCaucasus && options.length) {
      options.splice(
        options.findIndex((e) => e.id === regionsData[0]?.id),
        1,
      );
    }

    return options;
  };

  const getCardTitle = () => {
    const region = getRegionOptions().find(
      (x) => x.id === selectedCustomFilterOption.id && x.type === selectedCustomFilterOption.type,
    );
    return translate('root.statistics.region_summary', { name: region?.name });
  };

  return (
    <CardWithDiagram
      data={summaryData}
      onPeriodChange={handlePeriodChange}
      onCustomFilterChange={handleCustomFilterChange}
      onCheckboxChange={handleCheckboxChange}
      regionsOptions={getRegionOptions()}
      showRegionFilter={true}
      title={getCardTitle()}
      selectedFilters={selectedFilters}
      selectedCustomFilterOptionId={selectedCustomFilterOption.id}
      selectedPeriod={selectedPeriod}
    />
  );
};

export default AdminLinearDiagram;

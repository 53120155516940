import React, { useState, useEffect } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import CardWithDiagram from '../CardWithDiagram';
import {
  serverApiUrl,
  usersStatusesDiagramColorScheme,
  assignedUsersDiagramColorScheme,
  outletSellersStatsColorScheme,
  outletStatusesDiagramColorScheme,
} from '../../../../../config/constants';
import httpClient from '../../../../../core/httpClient';

const useStyles = makeStyles({
  cardsContainer: {
    display: 'grid',
    width: '100%',
    justifyContent: 'stretch',
    gridTemplateColumns: '1fr 1fr',
  },
});

const getBAData = async () => {
  const url = `${serverApiUrl}/admin/users/manager/stats`;
  const options = { method: 'GET' };

  return httpClient(url, options);
};

const BACardSet = () => {
  const notify = useNotify();
  const classes = useStyles();
  const [selectedItems, setSelectedItems] = useState([[], [], [], []]);

  const [assignedUsersData, setAssignedUsersData] = useState({ name: '', data: [] });
  const [assignedUsersStatusesData, setAssignedUsersStatusesData] = useState({ name: '', data: [] });
  const [outletSellersData, setOutletSellersData] = useState({ name: '', data: [] });
  const [outletActivity, setOutletActivity] = useState({ name: '', data: [] });
  const translate = useTranslate();

  useEffect(() => {
    getBAData()
      .then((response) => {
        setAssignedUsersData(response.json.assigned_users_data);
        setAssignedUsersStatusesData(response.json.assigned_users_statuses_data);
        setOutletSellersData(response.json.outlet_sellers_data);
        setOutletActivity(response.json.outlet_activity_data);
      })
      .catch((e) => {
        notify(`GET BA data error: ${e.message}`, 'warning');
      });
  }, [notify]);

  const getHandlers = () => {
    const handlers = [];
    for (let i = 0; i < selectedItems.length; i += 1) {
      handlers.push({
        handleClick: (node, nodeIndex) => {
          const index = selectedItems[i].findIndex((x) => x.name === node.name);
          if (index === -1) {
            selectedItems[i] = selectedItems[i]
              .slice()
              .concat({ name: node.name, value: node.value, index: nodeIndex });
            setSelectedItems([...selectedItems]);
          } else {
            selectedItems[i] = selectedItems[i].slice(0, index).concat(selectedItems[i].slice(index + 1));
            setSelectedItems([...selectedItems]);
          }
        },
        handleClickOutside: () => {
          selectedItems[i] = [];
          setSelectedItems([...selectedItems]);
        },
      });
    }
    return handlers;
  };

  const handlers = getHandlers();

  return (
    <div className={classes.cardsContainer}>
      <CardWithDiagram
        title={outletSellersData.name}
        data={outletSellersData.data}
        colors={outletSellersStatsColorScheme}
        selectedItems={selectedItems[2]}
        handleClick={handlers[2].handleClick}
        handleClickOutside={handlers[2].handleClickOutside}
        grid={true}
        totalFieldName={translate('root.statistics.summary')}
      />
      <CardWithDiagram
        title={outletActivity.name}
        data={outletActivity.data}
        colors={outletStatusesDiagramColorScheme}
        selectedItems={selectedItems[3]}
        handleClick={handlers[3].handleClick}
        handleClickOutside={handlers[3].handleClickOutside}
        grid={true}
        totalFieldName={translate('root.statistics.summary')}
      />
      <CardWithDiagram
        title={assignedUsersData.name}
        data={assignedUsersData.data}
        colors={assignedUsersDiagramColorScheme}
        selectedItems={selectedItems[0]}
        handleClick={handlers[0].handleClick}
        handleClickOutside={handlers[0].handleClickOutside}
        grid={true}
        totalFieldName={translate('root.statistics.available')}
      />
      <CardWithDiagram
        title={assignedUsersStatusesData.name}
        data={assignedUsersStatusesData.data}
        colors={usersStatusesDiagramColorScheme}
        selectedItems={selectedItems[1]}
        handleClick={handlers[1].handleClick}
        handleClickOutside={handlers[1].handleClickOutside}
        grid={true}
        totalFieldName={translate('root.statistics.summary')}
      />
    </div>
  );
};

export default BACardSet;

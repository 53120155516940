import React from 'react';

import CustomArrayInput from '../../../../commonComponents/CustomArrayInput';
import FormIterator from '../../../../core/FormIterator';
import { mainScreenFocusComponentTypeToggleOptions } from '../../../../config/constants';
import FocusComponent from './FocusComponent';

const pushNewField = ({ fields, nextFormType }) => {
  fields.push({ type: { id: nextFormType } });
};

const renderForm = ({ field, member }) => <FocusComponent member={member} type={field.type.id} />;

const FocusComponentForm = React.memo(({ member }) => (
  <CustomArrayInput source={`${member}.focus_components`} label="Focus components" defaultValue={[]}>
    <FormIterator
      formTypeTogglerLabel="Focus component type"
      formTypeTogglerOptions={mainScreenFocusComponentTypeToggleOptions}
      pushNewField={pushNewField}
      renderForm={renderForm}
      maxLength={1}
    />
  </CustomArrayInput>
));

export default FocusComponentForm;

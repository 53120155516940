import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SendIcon from '@material-ui/icons/Send';
import React, { useContext } from 'react';
import { BooleanInput, Button, TextInput, useNotify, usePermissions, useRefresh, useUpdateLoading } from 'react-admin';
import { Form } from 'react-final-form';

import TargetGroupInput from '../../../commonComponents/TargetGroupInput';
import ImageInput from '../../../commonComponents/UploadInputs/ImageInput';
import { APP_TYPES, availableMessageTemplates, s3FilePaths, serverApiUrl } from '../../../config/constants';
import httpClient from '../../../core/httpClient';
import { ChatMessagesContext } from '../context';

const useStyles = makeStyles({
  newMessageSection: {
    backgroundColor: '#f5f3f3',
    padding: '15px',
  },
  newMessageForm: {
    '& > div': {
      paddingBottom: '0 !important',
    },
  },
  newMessageSectionBottomWrapper: {
    display: 'flex',
    width: '100%',
  },
  bottomInputSectionsWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  bottomInputSections: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  bottomInputs: {
    display: 'flex',
    flex: 2,
  },
  sendToAllInput: {
    flex: 1,
  },
  hiddenInputs: {
    margin: 0,
    marginTop: -10,
  },
  saveButtonContainer: {
    textAlign: 'right',
  },
});

const NewMessage = ({ companionId }) => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const { startLoading, stopLoading } = useUpdateLoading();
  const { pushNewMessage } = useContext(ChatMessagesContext);
  const { permissions: userRoles } = usePermissions();
  const isBA = userRoles && userRoles.includes('BA');
  const isIreland = process.env.REACT_APP_APP_TYPE === APP_TYPES.IRELAND;

  const getTemplateMessageParams = ({ messageText, caption, sendAll, image, link, target_group_id }) => {
    const url = `${serverApiUrl}/admin/messages/send/template`;
    const data = {
      message: {
        admin: 1,
        bottom_text: '',
        brand_id: 0,
        image: image || null,
        link: link || '',
        text: messageText,
        caption: caption || '',
      },
      target_group_id: target_group_id || null,
      sendAll,
      type: isIreland || sendAll || target_group_id ? 19 : 4,
      user_id: companionId,
    };
    const options = { method: 'POST', body: JSON.stringify(data) };

    return { url, options };
  };

  const getMessageParams = ({ messageText, sendAll, image, link, target_group_id, caption }) => {
    const targetGroupUrlPart = '/target-group';
    let messageDestinationUrlPart = '';

    if (sendAll) {
      messageDestinationUrlPart = '/all';
    } else if (target_group_id) {
      messageDestinationUrlPart = targetGroupUrlPart;
    }

    const url = `${serverApiUrl}/admin/messages/send${messageDestinationUrlPart}`;
    const data = {
      message: {
        admin: 1,
        bottom_text: '',
        brand_id: 0,
        image: image || null,
        link: link || '',
        text: messageText,
        caption: caption || '',
      },
      target_group_id: target_group_id || null,
      type: isIreland || sendAll || target_group_id ? 19 : 4,
      user_id: companionId,
    };
    const options = { method: 'POST', body: JSON.stringify(data) };

    return { url, options };
  };

  const handleSubmit = async (formData) => {
    const hasTemplate = availableMessageTemplates.some((t) => formData.messageText.includes(t));
    const { url, options } = hasTemplate ? getTemplateMessageParams(formData) : getMessageParams(formData);

    try {
      startLoading();
      const { json } = await httpClient(url, options);
      pushNewMessage(json);
      refresh();
    } catch (e) {
      notify("Error: Message wasn't sent", 'warning');
      console.error(e);
    } finally {
      stopLoading();
    }
  };

  return (
    <Paper classes={{ root: classes.newMessageSection }}>
      <Form
        onSubmit={handleSubmit}
        className={classes.newMessageForm}
        render={({ form, handleSubmit, pristine, submitting, values }) => (
          <form onSubmit={(event) => handleSubmit(event).then(form.reset)}>
            <TextInput
              label="Message"
              fullWidth
              multiline
              helperText={`Available replacements: ${availableMessageTemplates.join(', ')}`}
              name="messageText"
              placeholder="Type a message..."
            />
            <div style={{ marginTop: -8 }} className={classes.newMessageSectionBottomWrapper}>
              <div className={classes.bottomInputSectionsWrapper}>
                <div className={classes.bottomInputSections}>
                  <div className={classes.bottomInputSections}>
                    <BooleanInput
                      className={classes.sendToAllInput}
                      label="Send to all"
                      source="sendAll"
                      helperText={false}
                      onChange={() => form.change('sendToTargetGroup', false)}
                    />
                  </div>
                  <div className={classes.bottomInputSections}>
                    <ImageInput
                      imageInputSource="image"
                      imagePreviewSource="url"
                      helperText={false}
                      imagePath={s3FilePaths.chat}
                      noLabel
                    />
                  </div>
                  <div className={classes.bottomInputs}>
                    {!isBA && (
                      <BooleanInput
                        label="Send to target group"
                        source="sendToTargetGroup"
                        helperText={false}
                        onChange={() => form.change('sendAll', false)}
                      />
                    )}
                    {values.sendToTargetGroup && (
                      <TargetGroupInput className={classes.hiddenInputs} helperText={false} />
                    )}
                  </div>
                  <div className={classes.saveButtonContainer}>
                    <Button
                      label="Send"
                      type="submit"
                      alignIcon="right"
                      size="medium"
                      variant="contained"
                      disabled={submitting || pristine || !values.messageText}
                    >
                      <SendIcon />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      />
    </Paper>
  );
};

export default NewMessage;

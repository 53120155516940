import React from 'react';
import { List, Datagrid, TextField, EditButton } from 'react-admin';

const CitiesList = (props) => (
  <List title="Cities" exporter={false} bulkActionButtons={false} sort={{ field: 'id', order: 'DESC' }} {...props}>
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" sortable={false} />
      <TextField label="Name" source="name" sortable={false} />
      <EditButton />
    </Datagrid>
  </List>
);

export default CitiesList;

import React from 'react';
import { Datagrid, List, NumberField, TextField } from 'react-admin';
import { CustomDateField } from '../../../../commonComponents/DateField';
import CustomReportListActions from '../../../../commonComponents/ListActions/CustomReportListActions';

import TransfersReportsFilter from './TransfersReportsFilter';

const BoostersReportsList = (props) => (
  <List
    title="Transfers"
    actions={<CustomReportListActions />}
    filters={<TransfersReportsFilter />}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField source="user_id" />
      <TextField source="name" sortable={false} />
      <TextField source="surname" sortable={false} />
      <TextField source="phone" sortable={false} />
      <CustomDateField label="Date created" source="created_at" />
      <TextField source="account_code" />
      <NumberField source="total" />
      <TextField source="status" sortable={false} />
      <NumberField source="wallet_number" />
      <NumberField source="personal_number" />
      <TextField source="transaction_id" sortable={false} />
      <TextField source="shop" sortable={false} />
    </Datagrid>
  </List>
);

export default BoostersReportsList;

import { roles } from '../config/constants';

export const questionsValidate = (values) => {
  const errors = {};
  if (!values || !values.length) {
    errors.questions = { _error: 'At least one question must be entered' };
  } else {
    const questionsArrayErrors = [];
    values.forEach((question, questionIndex) => {
      const questionErrors = {};
      if (!question || !question.text) {
        questionErrors.text = 'Required';
        questionsArrayErrors[questionIndex] = questionErrors;
      }
      if (!question || !question.image) {
        questionErrors.image = 'Required';
        questionsArrayErrors[questionIndex] = questionErrors;
      }
      if (!question || !question.answers || !question.answers.length) {
        questionErrors.answers = { _error: 'At least one answer must be entered' };
        questionsArrayErrors[questionIndex] = questionErrors;
      }
      if (!question || !question.correct_answers || !question.correct_answers.length) {
        questionErrors.correct_answers = { _error: 'At least one correct answer must be entered' };
        questionsArrayErrors[questionIndex] = questionErrors;
      }
    });
    if (questionsArrayErrors.length) {
      errors.questions = questionsArrayErrors;
    }
  }

  if (!errors.questions) {
    return undefined;
  }

  return errors;
};

export const confirmedPasswordValidation = (confirmedPassword, allValues) =>
  confirmedPassword !== allValues.password ? "Password doesn't match" : undefined;

export const numberLengthValidator =
  (requiredLength = 7, message = `Account number must have ${requiredLength} digits`) =>
  (value) => {
    if (Array.isArray(requiredLength)) {
      // [5, 6] => both inclusive
      // [5, 8] => range 5-8, both inclusive
      const [left, right] = requiredLength;
      const range = Array.from({ length: right - left + 1 }, (_, i) => i + left);
      return !range.includes(value.toString().length) ? message : undefined;
    }
    return value.toString().trim().length !== requiredLength ? message : undefined;
  };

export const overSpecifiedAge =
  (requiredAge = 18, message = `Must be over ${requiredAge} years old`) =>
  (value) => {
    const today = new Date();
    const birthday = new Date(value);
    let age = today.getFullYear() - birthday.getFullYear();
    const month = today.getMonth() - birthday.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthday.getDate())) {
      age -= 1;
    }
    return age >= requiredAge ? undefined : message;
  };

export const disallowSingleUserRole = (userRoles) =>
  userRoles && userRoles.length === 1 && userRoles[0] === roles.USER
    ? "You cannot create used with only 'User' role"
    : undefined;

export const mysteryShopperSingleQuestionValidate = (answers) => {
  if (answers) {
    const correctAnswers = answers.filter((answer) => {
      if (!answer) return;

      return answer.is_correct;
    });

    if (answers.length > 15) {
      return '15 is the maximum number of possible options';
    }

    return correctAnswers.length > 1 ? 'There must be only one correct answer' : undefined;
  }
};

export const mysteryShopperMultipleQuestionValidate = (answers) => {
  if (answers) {
    const correctAnswers = answers.filter((answer) => {
      if (!answer) return;

      return answer.is_correct;
    });

    if (answers.length > 15) {
      return '15 is the maximum number of possible options';
    }

    return correctAnswers.length < 2 ? 'There must be at least two correct answer' : undefined;
  }
};

import React from 'react';
import { List, Datagrid, TextField, EditButton, DeleteButton } from 'react-admin';
import CustomListActions from '../../../commonComponents/ListActions/CustomListActions';

const LevelsPackagesList = (props) => (
  <List
    title="Levels Packages"
    exporter={false}
    bulkActionButtons={false}
    actions={<CustomListActions />}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField label="ID" source="id" />
      <TextField label="Name" source="name" />
      <EditButton />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
);

export default LevelsPackagesList;

import { ReferenceInput, SelectInput } from 'react-admin';
import React from 'react';

function ThemeSelector(props) {
  return (
    <ReferenceInput
      label="Theme"
      source="theme_id"
      reference="admin/themes"
      perPage={5000}
      allowEmpty
      emptyValue={null}
      {...props}
    >
      <SelectInput optionText={(theme) => theme.name} />
    </ReferenceInput>
  );
}

export default ThemeSelector;

import React, { useState } from 'react';
import { useNotify } from 'react-admin';
import { Button, Typography } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';

import httpClient from '../../../core/httpClient';
import { serverApiUrl } from '../../../config/constants';

const useStyles = makeStyles({
  collectingStatsContainer: {
    maxWidth: '41%',
    padding: '16px 24px',
  },
  button: {
    minWidth: 88,
    marginLeft: 20,
    padding: '0 16px',
    textTransform: 'none',
    lineHeight: '36px',
  },
  actionButtonSection: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  statsLink: {
    marginTop: 10,
  },
});

const CollectingStats = () => {
  const classes = useStyles();
  const notify = useNotify();
  const [statsLink, setStatsLink] = useState('');

  const performRequest =
    ({ endpointPathName, method }) =>
    () => {
      const url = `${serverApiUrl}${endpointPathName}`;
      const options = { method };

      httpClient(url, options)
        .then((res) => {
          if (method === 'GET') {
            setStatsLink(res.json);
          } else {
            notify('Success: Operation was performed');
          }
        })
        .catch((err) => {
          notify(`Error: ${err.message}`, 'warning');
        });
    };

  return (
    <div className={classes.collectingStatsContainer}>
      <Typography variant="h6" gutterBottom>
        Statistics of users who deleted the app
      </Typography>
      <div className={classes.actionButtonSection}>
        <Typography>Initialize collecting users stats:</Typography>
        <Button
          onClick={performRequest({ endpointPathName: '/admin/stats/init', method: 'POST' })}
          variant="contained"
          color="primary"
          className={classes.button}
        >
          Start
        </Button>
      </div>

      <div className={classes.actionButtonSection}>
        <Typography>Reset collected users stats:</Typography>
        <Button
          onClick={performRequest({ endpointPathName: '/admin/stats/reset', method: 'POST' })}
          variant="contained"
          color="primary"
          className={classes.button}
        >
          Reset
        </Button>
      </div>

      <div className={classes.actionButtonSection}>
        <Typography>Get current stats:</Typography>
        <Button
          onClick={performRequest({ endpointPathName: '/admin/stats', method: 'GET' })}
          variant="contained"
          color="primary"
          className={classes.button}
        >
          Get stats
        </Button>
      </div>

      <div className={classes.statsLink}>
        <Typography>Stats link:</Typography>
        {statsLink ? <a href={statsLink}>{statsLink}</a> : ''}
      </div>
      <br />
      <Typography variant="h6" gutterBottom>
        Privacy policy\Terms&Conditions
      </Typography>
      <div className={classes.actionButtonSection}>
        <Typography>Notify users about policy changes:</Typography>
        <Button
          onClick={performRequest({
            endpointPathName: '/admin/users/notice/legal-documents-change',
            method: 'POST',
          })}
          variant="contained"
          color="primary"
          className={classes.button}
        >
          Notify
        </Button>
      </div>
    </div>
  );
};

export default CollectingStats;

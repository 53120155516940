import ReadArticleIcon from '@material-ui/icons/ChromeReaderMode';
import UsersKnowledgeBankArticlesList from './components/UsersKnowledgeBankArticlesList';
import { APP_TYPES, roles } from '../../../config/constants';

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: [roles.ADMIN, roles.JTI_TEAM],
  label: 'KB Articles',
  subMenuName: 'reports',
};

export default {
  list: UsersKnowledgeBankArticlesList,
  icon: ReadArticleIcon,
  name: 'admin/reports/users-knowledge-bank-articles',
  options,
};

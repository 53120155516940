import React from 'react';
import { Edit, ReferenceInput, TextInput, SelectInput, NumberInput, BooleanInput } from 'react-admin';
import { statuses } from '../../../config/constants';
import QuizForm from '../../../commonComponents/QuizForm';
import { CustomDateInput } from '../../../commonComponents/CustomDateInput';
import { isIreland } from '../../../helpers/appTypeHelper';

const QuizEdit = (props) => (
  <Edit title="Edit Quiz" undoable={false} {...props}>
    <QuizForm>
      <TextInput label="ID" source="id" disabled />
      <TextInput label="Name" source="name" />
      <NumberInput label="Price" source="price" />
      <CustomDateInput label="Publish date" source="published_at" />
      <ReferenceInput label="Topic" source="brand_id" reference="services/brands">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <SelectInput source="status" optionText="name" choices={statuses.slice(0, 2)} />
      {isIreland() && (
        <BooleanInput style={{ width: '300px' }} label="Included in the Progress bar" source="progress_bar_enable" />
      )}
    </QuizForm>
  </Edit>
);

export default QuizEdit;

import { Filter, ReferenceInput, SelectInput, TextInput, usePermissions } from 'react-admin';
import React from 'react';
import ManagerAutocompleteInput from '../../../commonComponents/ManagerAutocompleteInput';
import { roles } from '../../../config/constants';
import CityAutocompleteInput from '../../../commonComponents/CityAutocompleteInput';

const OutletsAndUsersFilter = (props) => {
  const { permissions: userRoles } = usePermissions();

  const isBA = userRoles && userRoles.includes(roles.BA);
  const isJTI = userRoles && userRoles.includes(roles.JTI_TEAM);

  return (
    <Filter {...props}>
      <ReferenceInput
        label={'Legal Entity'}
        source="legal_entity_id"
        reference="admin/legal-entities"
        perPage={10000}
        alwaysOn
      >
        <SelectInput source="name" optionText={(le) => `(${le.id}) ${le.name}`} />
      </ReferenceInput>
      <TextInput label="Outlet code" source="outlet_code" alwaysOn />
      <TextInput label="Address" source="street" alwaysOn />
      <CityAutocompleteInput label="City" source="city_id" alwaysOn />
      {!isBA && <ManagerAutocompleteInput label="TM" alwaysOn />}
      {isJTI && (
        <ReferenceInput label="Region" source="region_id" reference="admin/services/regions" alwaysOn>
          <SelectInput optionText="name" />
        </ReferenceInput>
      )}
    </Filter>
  );
};

export default OutletsAndUsersFilter;

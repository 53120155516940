import BotChancesIcon from '@material-ui/icons/Casino';
import BotChancesCreate from './components/BotChancesCreate';
import BotChancesEdit from './components/BotChancesEdit';
import BotChancesList from './components/BotChancesList';
import { APP_TYPES, roles } from '../../config/constants';

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: [roles.ADMIN],
  label: 'Bot Chances',
  subMenuName: 'manage',
};

export default {
  create: BotChancesCreate,
  list: BotChancesList,
  edit: BotChancesEdit,
  icon: BotChancesIcon,
  name: 'admin/bot-chances',
  options,
};

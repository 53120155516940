import React from 'react';
import { List, Datagrid, TextField, BooleanField, EditButton, DeleteButton } from 'react-admin';
import ManagersFilter from './ManagersFilter';
import CustomListActions from '../../../commonComponents/ListActions/CustomListActions';

const ManagersList = (props) => (
  <List
    title="Managers"
    filters={<ManagersFilter />}
    actions={<CustomListActions importer={false} />}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <TextField label="Profile name" source="username" sortable={false} />
      <TextField label="Name" source="name" sortable={false} />
      <TextField label="Surname" source="surname" sortable={false} />
      <TextField label="Email" source="email" sortable={false} />
      <BooleanField label="Hide Phone" source="hide_phone" sortable={false} />
      <EditButton />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
);

export default ManagersList;

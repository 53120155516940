import React from 'react';
import {
  Edit,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import TargetGroupInput from '../../../../commonComponents/TargetGroupInput';
import ImageInput from '../../../../commonComponents/UploadInputs/ImageInput';
import { bonusShopCategoryTypes, s3FilePaths } from '../../../../config/constants';

const categoriesWithHeader = [
  bonusShopCategoryTypes.TRANSFER,
  bonusShopCategoryTypes.UMICO,
  bonusShopCategoryTypes.OPPA,
];

const CategoryEdit = (props) => (
  <Edit title="Edit Category" undoable={false} {...props}>
    <SimpleForm>
      <TextInput label="ID" source="id" disabled />
      <TextInput label="Name" source="name" validate={required()} />
      <ImageInput imageInputSource="photo" validate={required()} imagePath={s3FilePaths.allUploads} />
      <NumberInput label="Priority" source="priority" />
      <ReferenceInput
        label="Transaction Type"
        source="transaction_type_id"
        reference="admin/transactions/types"
        perPage={100}
      >
        <SelectInput validate={required()} />
      </ReferenceInput>
      <TargetGroupInput />
      <ReferenceInput label="Type" source="type" reference="admin/bonus-shop/category-types">
        <SelectInput validate={required()} />
      </ReferenceInput>
      <FormDataConsumer>
        {({ formData }) => {
          const { type } = formData;
          return categoriesWithHeader.includes(type) ? (
            <ImageInput
              label="Header Image"
              imageInputSource="header_image"
              validate={required()}
              imagePath={s3FilePaths.allUploads}
            />
          ) : null;
        }}
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
);

export default CategoryEdit;

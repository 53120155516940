import React from 'react';
import { Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Edit, SimpleForm, TextInput, required, BooleanInput } from 'react-admin';
import { CustomDateInput } from '../../../commonComponents/CustomDateInput';
import UploadFileInput from '../../../commonComponents/UploadInputs/UploadFileInput';

const TradeProgramsEdit = (props) => {
  const history = useHistory();

  return (
    <Edit title={'Edit'} undoable={false} onSuccess={() => history.goBack()} {...props}>
      <SimpleForm>
        <Typography variant="h5" component="div">
          Edit trade program
        </Typography>
        <TextInput label="Name" source="name" />
        <UploadFileInput label={'Import:'} fileInputSource="file_link" buttonText={'Upload file'} />
        <BooleanInput label="Paused" source="paused" />
        <Typography variant="h6" component="div">
          Data collecting phase
        </Typography>
        <CustomDateInput label="Phase start date" source="start_collection_phase" validate={required()} />
        <CustomDateInput label="Phase end date" source="end_collection_phase" validate={required()} />
        <Typography variant="h6" component="div">
          Ended
        </Typography>
        <CustomDateInput label="Phase start date" source="start_ended_phase" validate={required()} />
      </SimpleForm>
    </Edit>
  );
};

export default TradeProgramsEdit;

import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  leftIcon: {
    marginRight: theme.spacing(1),
  },
}));

const AddButton = (props) => {
  const { label } = props;
  const classes = useStyles();

  return (
    <Button size="small" {...props}>
      <AddIcon className={classes.leftIcon} />
      {label}
    </Button>
  );
};

export default AddButton;

import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';
import ImageInput from '../../../../commonComponents/UploadInputs/ImageInput';
import { s3FilePaths } from '../../../../config/constants';

const TypeCreate = (props) => (
  <Create title="Create new Type" {...props}>
    <SimpleForm redirect="list">
      <TextInput label="Name" source="name" />
      <TextInput label="Title" source="title" />
      <ImageInput imageInputSource="photo" imagePath={s3FilePaths.bonusShop} />
    </SimpleForm>
  </Create>
);

export default TypeCreate;

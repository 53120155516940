import BatteryChargingFullIcon from '@material-ui/icons/BatteryChargingFull';
import BoostersReportsList from './components/BoostersReportsList';
import { APP_TYPES, roles } from '../../../config/constants';

const options = {
  appTypes: [APP_TYPES.HUNGARY],
  allowedRoles: [roles.ADMIN],
  label: 'Boosters usage',
  subMenuName: 'reports',
};

export default {
  list: BoostersReportsList,
  icon: BatteryChargingFullIcon,
  name: 'admin/reports/boosters',
  options,
};

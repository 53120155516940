import React from 'react';

import { AutocompleteInput, BooleanInput, Create, ReferenceInput, required, SimpleForm, TextInput } from 'react-admin';
import ConditionalImageUploader from './ConditionalImageUploader';
import { ROOT_LEVEL } from '../../../config/constants';

const ProblemCreate = ({ level, ...otherProps }) => (
  <Create title="Create Problem" {...otherProps}>
    <SimpleForm redirect="list">
      <TextInput label="Name" source="name" validate={required()} />
      <ReferenceInput
        source="article_id"
        label="Article"
        reference={`admin/knowledge-bank/articles/autocomplete`}
        perPage={10000}
        allowEmpty
        emptyValue={null}
      >
        <AutocompleteInput />
      </ReferenceInput>

      <TextInput label="Level" source="level" disabled defaultValue={level} />
      {level !== ROOT_LEVEL && (
        <ReferenceInput
          source="parent_id"
          reference={`admin/problems/${level}/problems/autocomplete`}
          perPage={10000}
          validate={required()}
        >
          <AutocompleteInput />
        </ReferenceInput>
      )}

      <ConditionalImageUploader label="Image" imageInputSource="image" />
      <BooleanInput label="Start chat" source="should_start_chat" />
    </SimpleForm>
  </Create>
);

export default ProblemCreate;

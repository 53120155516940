import React from 'react';
import { Datagrid, List, TextField, ReferenceField } from 'react-admin';
import AttachmentImages from '../../../../commonComponents/AttachmentImages';
import CustomReportListActions from '../../../../commonComponents/ListActions/CustomReportListActions';

import LoyaltyProgramsReportsFilter from './LoyaltyProgramsReportsFilter';

const LoyaltyProgramsReportsList = (props) => (
  <List
    title="Loyalty program reports"
    actions={<CustomReportListActions />}
    filters={<LoyaltyProgramsReportsFilter />}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField label="ID" source="id" />
      <ReferenceField label="Program" source="program_id" reference="admin/loyalty-programs">
        <TextField source="name" />
      </ReferenceField>
      <TextField label="User name" source="user.name" />
      <TextField label="User surname" source="user.surname" />
      <TextField label="User phone" source="user.phone" />
      <TextField label="Points" source="total_points" sortable={false} />
      <AttachmentImages label="Attachments" source="attachments" sortable={false} />
    </Datagrid>
  </List>
);

export default LoyaltyProgramsReportsList;

import React from 'react';
import { Datagrid, List, TextField } from 'react-admin';
import { CustomDateField } from '../../../../commonComponents/DateField';
import CustomReportListActions from '../../../../commonComponents/ListActions/CustomReportListActions';
import { APP_TYPES } from '../../../../config/constants';

import InviteCodesFilter from './InviteCodesFilter';

const InviteCodesList = (props) => (
  <List
    title="Users Invite Codes"
    actions={<CustomReportListActions />}
    filters={<InviteCodesFilter />}
    bulkActionButtons={false}
    sort={{}}
    {...props}
  >
    <Datagrid>
      <TextField label="ID" source="id" />
      <TextField label="Owner ID" source="inviter_id" sortable={false} />
      <TextField label="Owner Phone" source="inviter_phone" sortable={false} />
      <TextField label="Owner Name" source="inviter_name" sortable={false} />
      <TextField label="Owner Surname" source="inviter_surname" sortable={false} />
      <TextField label="Referral ID" source="invitee_id" sortable={false} />
      <TextField label="Code" source="code" sortable={false} />
      <TextField label="Referral Phone" source="invitee_phone" sortable={false} />
      <CustomDateField label="Date" source="created_at" sortable={false} />
      {process.env.REACT_APP_APP_TYPE === APP_TYPES.BELARUS && (
        <TextField label="Внешний код торговой точки" source="account_code" />
      )}
    </Datagrid>
  </List>
);

export default InviteCodesList;

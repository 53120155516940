import React, { useState } from 'react';
import { Button, FormWithRedirect, required, SaveButton, SelectInput, TextInput, useNotify } from 'react-admin';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core/';
import IconCancel from '@material-ui/icons/Cancel';
import PeopleIcon from '@material-ui/icons/People';
import { availableMessageTemplates, serverApiUrl } from '../../../config/constants';
import httpClient from '../../../core/httpClient';

import styles from './SendToGroupOfUsers.module.scss';

const SendToGroupOfUsers = ({ endpointUrl, recordKey, record }) => {
  const notify = useNotify();
  const [isOpen, setOpenStatus] = useState(false);
  const [targetGroupsList, setTargetGroupsList] = useState([]);

  const handleOpenModal = (e) => {
    e.stopPropagation();
    const url = `${serverApiUrl}/admin/target-groups?limit=100`;
    const options = { method: 'GET' };

    httpClient(url, options)
      .then((res) => {
        setTargetGroupsList(res.json['target-groups']);
        setOpenStatus(true);
      })
      .catch(() => {
        notify("Error: Can't get target groups list", 'warning');
      });
  };

  const handleCloseModal = () => {
    setOpenStatus(false);
  };

  const handleSendMessage = ({ message, target_group_id, caption }) => {
    const url = `${serverApiUrl}${endpointUrl}`;
    const body = JSON.stringify({ message, caption, target_group_id, [recordKey]: record.id });
    const options = { method: 'POST', body };

    httpClient(url, options)
      .then(() => handleCloseModal())
      .catch(() => notify("Error: Message wasn't sent", 'warning'));
  };

  return (
    <>
      <IconButton onClick={handleOpenModal}>
        <PeopleIcon />
      </IconButton>
      <Dialog open={isOpen} onClick={(e) => e.stopPropagation()} maxWidth="xs">
        <DialogTitle>Send to target group</DialogTitle>
        <FormWithRedirect
          save={handleSendMessage}
          render={({ handleSubmitWithRedirect, submitting, pristine }) => (
            <>
              <DialogContent dividers className={styles.dialogContent}>
                <TextInput label="Caption" source="caption" />
                <TextInput
                  helperText={`Available replacements: ${availableMessageTemplates.join(', ')}`}
                  label="Message"
                  source="message"
                  fullWidth
                  validate={required()}
                />
                <SelectInput
                  source="target_group_id"
                  optionText="name"
                  choices={targetGroupsList}
                  fullWidth
                  validate={required()}
                />
              </DialogContent>
              <DialogActions>
                <SaveButton disabled={submitting || pristine} handleSubmitWithRedirect={handleSubmitWithRedirect} />
                <Button label="ra.action.cancel" onClick={handleCloseModal}>
                  <IconCancel />
                </Button>
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

export default SendToGroupOfUsers;

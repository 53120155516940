import React from 'react';
import { CheckboxGroupInput, Filter, ReferenceInput, SelectInput, TextInput } from 'react-admin';
import PhoneInput from '../../../../commonComponents/PhoneInput';

const LepCodeFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput label="LEP" source="competition_id" reference="admin/lep" alwaysOn>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <PhoneInput source="phone" isRequired={false} alwaysOn />
    <TextInput source="code" alwaysOn />
    <CheckboxGroupInput source="hide_empty" choices={[{ id: true, name: 'Hide not assigned' }]} alwaysOn />
  </Filter>
);

export default LepCodeFilter;

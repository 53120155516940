import React from 'react';
import { NumberInput, ReferenceInput, required, SelectInput } from 'react-admin';

import { mainScreenFocusComponentTypes, s3FilePaths } from '../../../../config/constants';
import ImageInput from '../../../../commonComponents/UploadInputs/ImageInput';
import withCardWrapper from '../../hocs/withCardWrapper';

const getFieldName = (member, name) => {
  if (!member) {
    return name;
  }

  return `${member}.${name}`;
};

const getReferenceInput = (member, type) => {
  let fieldLabel = '';
  let fieldSourceName;
  let fieldRequired = true;
  let fieldReference = '';

  switch (type) {
    case mainScreenFocusComponentTypes.solo_quiz:
      fieldLabel = 'Solo Quiz Brand ID';
      fieldSourceName = 'brand_id';
      fieldRequired = false;
      break;
    case mainScreenFocusComponentTypes.quiz_with_user:
      fieldLabel = 'Quiz with user Brand ID';
      fieldSourceName = 'brand_id';
      fieldRequired = false;
      break;
    case mainScreenFocusComponentTypes.kb_article:
      fieldLabel = 'KB Article ID';
      fieldSourceName = 'knowledge_bank_article_id';
      break;
    case mainScreenFocusComponentTypes.special_quiz:
      fieldLabel = 'Special quiz ID';
      fieldSourceName = 'pack_id';
      break;
    case mainScreenFocusComponentTypes.kb_game:
      fieldLabel = 'KB game ID';
      fieldSourceName = 'knowledge_bank_article_id';
      break;
    case mainScreenFocusComponentTypes.survey:
      fieldLabel = 'KB Survey ID';
      fieldSourceName = 'knowledge_bank_article_id';
      break;
    case mainScreenFocusComponentTypes.multibrand_quiz:
      fieldLabel = 'Multibrand quiz ID';
      fieldSourceName = 'pack_id';
      break;
    case mainScreenFocusComponentTypes.loyalty_program:
      fieldLabel = 'Loyalty program ID';
      fieldSourceName = 'program_id';
      break;
    case mainScreenFocusComponentTypes.kb_category:
      fieldLabel = 'KB Category ID';
      fieldSourceName = 'knowledge_bank_category_id';
      fieldReference = 'admin/knowledge-bank/categories';
      break;
    case mainScreenFocusComponentTypes.achievement:
      fieldLabel = 'Achievement';
      fieldSourceName = 'achievement_id';
      fieldReference = 'admin/achievements';
      break;
    case mainScreenFocusComponentTypes.check_in:
    case mainScreenFocusComponentTypes.question_of_the_day:
    case mainScreenFocusComponentTypes.event_mode:
    case mainScreenFocusComponentTypes.single_mode:
    case mainScreenFocusComponentTypes.invite_code:
    case mainScreenFocusComponentTypes.achievements:
    case mainScreenFocusComponentTypes.lep:
    case mainScreenFocusComponentTypes.pack_recognition:
    case mainScreenFocusComponentTypes.lottery:
      return null;
    default:
      throw new Error(`Unsupported type for Focus component(TypeId = ${type}).`);
  }

  return fieldReference ? (
    <ReferenceInput
      label={fieldLabel}
      source={getFieldName(member, fieldSourceName)}
      reference={fieldReference}
      validate={fieldRequired && required()}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  ) : (
    <NumberInput
      label={fieldLabel}
      source={getFieldName(member, fieldSourceName)}
      validate={fieldRequired && required()}
    />
  );
};

const BoxComponent = ({ member, type }) => (
  <>
    <ImageInput
      label="Image"
      imagePath={s3FilePaths.allUploads}
      imageInputSource={getFieldName(member, 'image')}
      validate={required()}
    />
    {getReferenceInput(member, type)}
  </>
);

export default withCardWrapper(BoxComponent);

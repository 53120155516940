import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  ArrayInput,
  Edit,
  FormDataConsumer,
  NumberInput,
  RadioButtonGroupInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';
import { CustomDateInput } from '../../../commonComponents/CustomDateInput';
import TargetGroupInput from '../../../commonComponents/TargetGroupInput';
import ImageInput from '../../../commonComponents/UploadInputs/ImageInput';
import { CATEGORY_TYPES, s3FilePaths } from '../../../config/constants';
import CategorySelector from '../../../commonComponents/CategorySelector';

const useStyles = makeStyles({
  hideFormLabel: {
    '& li > p': {
      display: 'none',
    },
  },
  rewardField: {
    width: 256,
  },
});

const SalesTipEdit = (props) => {
  const classes = useStyles();

  return (
    <Edit title="Edit sales tip" undoable={false} {...props}>
      <SimpleForm redirect="list">
        <TextInput label="Name" source="name" validate={required()} />
        <TextInput label="Description" source="description" />
        <CategorySelector source={'category_id'} categoryType={CATEGORY_TYPES.SALES_TIPS} />
        <CustomDateInput label="Choose publication date" source="published_at" validate={required()} />
        <TargetGroupInput />
        <ImageInput
          label="Incomplete step image"
          imageInputSource="incomplete_image"
          imagePath={s3FilePaths.salesTips}
        />
        <ImageInput label="Complete step image" imageInputSource="complete_image" imagePath={s3FilePaths.salesTips} />
        <ArrayInput source="steps" className={classes.hideFormLabel}>
          <SimpleFormIterator>
            <TextInput label="Name" source="name" />
            <RadioButtonGroupInput
              label="Step type"
              source="step_type"
              choices={[
                { id: 'url', name: 'URL' },
                { id: 'quiz', name: 'Special quiz ID' },
              ]}
            />
            <FormDataConsumer>
              {({ scopedFormData, getSource }) => {
                // tries to re-render when unmounted and is transitioning out of the view
                if (!scopedFormData) return;
                if (!scopedFormData.step_type) {
                  // eslint-disable-next-line no-param-reassign
                  scopedFormData.step_type = scopedFormData.special_quiz_id ? 'quiz' : 'url';
                }

                // eslint-disable-next-line consistent-return
                return scopedFormData.step_type === 'quiz' ? (
                  <ReferenceInput
                    label="Special quiz ID"
                    className={classes.rewardField}
                    source={getSource('special_quiz_id')}
                    reference="admin/special-quizzes"
                    resource="admin/sales-tips"
                    validate={required()}
                  >
                    <SelectInput optionText="name" />
                  </ReferenceInput>
                ) : (
                  <TextInput
                    label="URL"
                    source={getSource('url')}
                    className={classes.rewardField}
                    validate={required()}
                  />
                );
              }}
            </FormDataConsumer>
            <NumberInput label="Minutes to read" source="minutes_to_read" validate={required()} />
            <NumberInput label="Coins" source="coins" validate={required()} />
            <ReferenceInput
              label="Transaction type"
              source="transaction_type_id"
              reference="admin/transactions/types"
              perPage={100}
              filter={{ name: 'SALES' }}
              sort={{ field: 'id', order: 'ASC' }}
              validate={required()}
            >
              <SelectInput />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export default SalesTipEdit;

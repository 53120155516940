import { Filter, SelectInput } from 'react-admin';
import UserAutocompleteInput from '../../../../commonComponents/UserAutocompleteInput';
import { attachmentsStatusChoices } from '../../config/constants';

const AttachmentsFilter = ({ tasks, ...props }) => (
  <Filter {...props}>
    <UserAutocompleteInput label="User" source="user_id" reference="admin/users/autocomplete" alwaysOn />
    <SelectInput optionText="name" choices={attachmentsStatusChoices} source="status" alwaysOn />
  </Filter>
);

export default AttachmentsFilter;

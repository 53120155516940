import React from 'react';
import { Datagrid, List, TextField } from 'react-admin';
import { CustomDateField } from '../../../../commonComponents/DateField';
import CustomReportListActions from '../../../../commonComponents/ListActions/CustomReportListActions';
import WinstonMemoryGameFilter from './WinstonMemoryGameFilter';

const WinstonMemoryGameList = (props) => (
  <List
    title="Winston Memory Game reports"
    actions={<CustomReportListActions />}
    filters={<WinstonMemoryGameFilter />}
    bulkActionButtons={false}
    sort={{}}
    {...props}
  >
    <Datagrid>
      <TextField label="User ID" source="id" sortable={false} />
      <TextField label="User Phone" source="phone" sortable={false} />
      <CustomDateField label="Date created" source="created_at" sortable={false} />
    </Datagrid>
  </List>
);

export default WinstonMemoryGameList;

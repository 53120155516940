import React from 'react';
import { BooleanField, Datagrid, List, TextField, ArrayField, SingleFieldList, ChipField } from 'react-admin';
import { CustomDateField } from '../../../../commonComponents/DateField';
import CustomReportListActions from '../../../../commonComponents/ListActions/CustomReportListActions';

import ActivitiesFilter from './activitiesFilter';

const ActivitiesList = (props) => (
  <List
    title="Activity"
    actions={<CustomReportListActions />}
    filters={<ActivitiesFilter />}
    bulkActionButtons={false}
    sort={{ field: 'user_id', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField label="User ID" source="user_id" />
      <TextField label="Account code" source="traffic_code" />
      <BooleanField label="Downloaded" source="downloaded" sortable={false} />
      <CustomDateField label="Download Date" source="download_date" />
      <CustomDateField label="Last Transaction Date" source="last_activity_date" />
      <ArrayField label="Brands" source="brands">
        <SingleFieldList>
          <ChipField source="name" disabled={true} />
        </SingleFieldList>
      </ArrayField>
      <TextField label="Activity type" source="activity_type" />
      <TextField label="Number of actions" source="number_of_actions" />
    </Datagrid>
  </List>
);

export default ActivitiesList;

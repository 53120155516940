import { stringify } from 'query-string';
import { showNotification } from 'react-admin';
import httpClient from '../core/httpClient';
import { serverApiUrl } from '../config/constants';

const exporter =
  (filterValues = {}, endpointPathName = `${window.location.pathname}/xlsx`) =>
  (records, fetchRelatedRecords, dispatch) => {
    const filterQueryString = filterValues && Object.keys(filterValues).length ? `?${stringify(filterValues)}` : '';
    const url = `${serverApiUrl}${endpointPathName}${filterQueryString}`;
    const options = { method: 'GET' };

    httpClient(url, options)
      .then((res) => {
        window.location.href = res.json.url;
      })
      .catch(() => {
        dispatch(showNotification("Error: file link wasn't loaded", 'warning'));
      });
  };

export default exporter;

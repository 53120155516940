import React from 'react';
import { Route } from 'react-router-dom';
import OnlineUsersList from '../pages/onlineUsers/components/OnlineUsersList';
import { OutletNewSyncTest } from '../pages/outlets/outlets/components/OutletNewSyncTest';
import CPAProgram from '../pages/cpaProgram/components/CPAProgram/CPAProgram';
import { MediaView } from '../pages/mediaView/MediaView';
import TradeProgramAddresses from '../pages/tradeProgram/components/TradeProgramAdresses/TradeProgramAddresses';
import { UsersTradeProgram } from '../pages/tradeProgram/components/UsersTradeProgram/UsersTradeProgram';
import OutletUsers from '../pages/outletsAndUsers/components/outletUsers/OutletUsersList';

export default [
  <Route exact path="/admin/online-users" component={OnlineUsersList} />,
  <Route exact path="/admin/outlets/new-sync-test" component={OutletNewSyncTest} />,
  <Route exact path="/admin/cpa-program/view/:id/:name" component={CPAProgram} />,
  <Route exact path="/admin/media-view" component={MediaView} />,
  <Route exact path="/admin/trade-program/:id/addresses" component={TradeProgramAddresses} />,
  <Route exact path="/admin/trade-program/:id/addresses/:addressId" component={UsersTradeProgram} />,
  <Route exact path="/admin/outlets-users/:id" component={OutletUsers} />,
];

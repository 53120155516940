import CallToActionIcon from '@material-ui/icons/CallToAction';
import { APP_TYPES, roles } from '../../config/constants';
import PopupCreate from './components/PopupCreate';
import PopupEdit from './components/PopupEdit';
import PopupList from './components/PopupList';

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: [roles.ADMIN],
  label: 'Popup',
  subMenuName: 'manage',
};

export default {
  create: PopupCreate,
  edit: PopupEdit,
  list: PopupList,
  icon: CallToActionIcon,
  name: 'admin/popups',
  options,
};

import React, { useState, useEffect } from 'react';
import MuiSelect from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    backgroundColor: '#01C1B6',
    border: 'none',
    borderRadius: 3,
    fontSize: 12,
    color: '#ffffff',
    padding: '2px 0 2px 10px',
    '&:before': {
      borderBottom: 'unset',
    },
    '&:after': {
      borderBottom: 'unset',
    },
  },
  icon: {
    color: '#ffffff',
    right: 2,
  },
});

const Select = ({ options, defaultValue, onChange, customClassName, passedValue, ...rest }) => {
  const classes = useStyles();

  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(passedValue);
  }, [passedValue]);

  const getMenuItems = () =>
    options.map((option) => (
      <MenuItem key={option.id} value={option.id} name={option.name}>
        {option.name}
      </MenuItem>
    ));

  const handleChange = (event) => {
    setValue(event.target.value);
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <MuiSelect
      value={value}
      className={`${classes.container} ${customClassName}`}
      onChange={handleChange}
      classes={{ icon: classes.icon }}
      disableUnderline={true}
      {...rest}
    >
      {getMenuItems()}
    </MuiSelect>
  );
};

export default Select;

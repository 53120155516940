import React from 'react';
import { Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, required } from 'react-admin';
import { CustomDateInput } from '../../../commonComponents/CustomDateInput';
import TargetGroupInput from '../../../commonComponents/TargetGroupInput';
import ImageInput from '../../../commonComponents/UploadInputs/ImageInput';
import { s3FilePaths } from '../../../config/constants';

const SalesSimulatorEdit = (props) => (
  <Edit title="Edit sales simulator" undoable={false} {...props}>
    <SimpleForm redirect="list">
      <TextInput label="ID" source="id" disabled />
      <TextInput label="Name" source="name" validate={required()} />
      <TextInput label="Coins" source="coins" validate={required()} />
      <CustomDateInput label="Start date" source="start_date" validate={required()} />
      <CustomDateInput label="End date" source="end_date" validate={required()} />
      <TargetGroupInput />
      <ReferenceInput
        label="Reactions"
        source="reaction_id"
        reference="admin/reactions"
        perPage={100}
        allowEmpty
        validate={required()}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ImageInput
        label="Incomplete step image"
        imageInputSource="incomplete_image"
        imagePath={s3FilePaths.salesSimulator}
        validate={required()}
      />
      <ImageInput
        label="Complete step image"
        imageInputSource="complete_image"
        imagePath={s3FilePaths.salesSimulator}
        validate={required()}
      />
    </SimpleForm>
  </Edit>
);

export default SalesSimulatorEdit;

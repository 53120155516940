import React from 'react';
import { BooleanInput, Filter, ReferenceInput, SelectInput, TextInput } from 'react-admin';

const InteractivesReportsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="User id" source="user_id" />
    <ReferenceInput label="Brand" source="brand_id" reference="admin/knowledge-bank/brands">
      <SelectInput optionText="name" />
    </ReferenceInput>
    <BooleanInput label="Moderation" source="moderation" />
  </Filter>
);

export default InteractivesReportsFilter;

import React from 'react';
import { Filter, ReferenceInput, SelectInput, TextInput } from 'react-admin';
import ManagerAutocompleteInput from '../../../commonComponents/ManagerAutocompleteInput';
import { APP_TYPES } from '../../../config/constants';

const AccountNumberFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Account number" source="account_codes" alwaysOn />
    <ManagerAutocompleteInput label="BA" source="user_id" alwaysOn />
    {process.env.REACT_APP_APP_TYPE === APP_TYPES.IRELAND && (
      <ReferenceInput source="tier_id" reference="admin/tiers" alwaysOn>
        <SelectInput source="name" />
      </ReferenceInput>
    )}
  </Filter>
);

export default AccountNumberFilter;

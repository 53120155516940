import React from 'react';
import { Datagrid, DeleteButton, List, TextField, DateField } from 'react-admin';
import DuplicateButton from '../../../commonComponents/DuplicateButton';
import CustomListActions from '../../../commonComponents/ListActions/CustomListActions';
import SendMessageToAllUsers from '../../../commonComponents/SendMessageActions/SendToAllUsers/SendToAllUsers';
import SendMessageToGroupOfUsers from '../../../commonComponents/SendMessageActions/SendToGroupOfUsers/SendToGroupOfUsers';
import SendMessageToSingleUser from '../../../commonComponents/SendMessageActions/SendToSingleUser/SendToSingleUser';
import StatusField from '../../../commonComponents/StatusField';
import { duplicateRecord } from '../../../helpers/duplicateQuiz';

const SpecialQuizzesList = (props) => (
  <List
    title="Special quizzes"
    exporter={false}
    actions={<CustomListActions />}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <TextField label="Name" source="name" sortable={false} />
      <TextField label="Topic" source="brand.name" sortable={false} />
      <StatusField label="Status" source="status" sortable={false} />
      <DateField label="Publish date" source="published_at" />
      <TextField label="Price" source="price" />
      <DuplicateButton copyRecord={duplicateRecord} />
      <DeleteButton undoable={false} />
      <SendMessageToSingleUser recordKey="pack_id" endpointUrl="/admin/special-quizzes/send/user" />
      <SendMessageToGroupOfUsers recordKey="pack_id" endpointUrl="/admin/special-quizzes/send/group" />
      <SendMessageToAllUsers recordKey="pack_id" endpointUrl="/admin/special-quizzes/send/all" />
    </Datagrid>
  </List>
);

export default SpecialQuizzesList;

import React from 'react';
import { Edit, TextInput } from 'react-admin';
import RulesForm from './RulesForm';

const TargetGroupEdit = (props) => (
  <Edit undoable={false} {...props}>
    <RulesForm>
      <TextInput source="id" disabled />
      <TextInput source="name" />
    </RulesForm>
  </Edit>
);

export default TargetGroupEdit;

import React from 'react';
import { ReferenceInput, AutocompleteInput } from 'react-admin';

function formatText(legalEntity) {
  const text = legalEntity?.name ? legalEntity?.name : '';

  if (legalEntity?.id) {
    return `(${legalEntity.id}) ${text}`;
  }

  return text;
}

const LegalEntityAutocompleteInput = (props) => {
  const {
    label = 'Legal Entity',
    source = 'legal_entity_id',
    reference = 'admin/legal-entities/autocomplete',
    ...otherProps
  } = props;

  return (
    <ReferenceInput label={label} source={source} reference={reference} allowEmpty {...otherProps}>
      <AutocompleteInput
        optionText={formatText}
        options={{
          suggestionsContainerProps: {
            style: { zIndex: 1301 },
          },
        }}
      />
    </ReferenceInput>
  );
};

export default LegalEntityAutocompleteInput;

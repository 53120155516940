import React from 'react';
import { Edit, NumberInput, ReferenceInput, required, SelectInput, SimpleForm, TextInput } from 'react-admin';
import CityAutocompleteInput from '../../../../commonComponents/CityAutocompleteInput';
import LegalEntityAutocompleteInput from '../../../../commonComponents/LegalEntityAutocompleteInput';
import ManagerAutocompleteInput from '../../../../commonComponents/ManagerAutocompleteInput';
import {
  outletsActivityStatusesOptions,
  outletStatuses,
  outletsTMRCoverageOptions,
  roles,
} from '../../../../config/constants';
import { isCaucasus, isHungary, isBelarus } from '../../../../helpers/appTypeHelper';

const OutletEdit = (props) => (
  <Edit title="Edit Outlet" undoable={false} {...props}>
    <SimpleForm>
      <TextInput label="ID" source="id" disabled />
      <TextInput label={isCaucasus ? 'Address' : 'Street'} source="street" sortable={false} validate={required()} />
      {!isHungary() && !isCaucasus && (
        <TextInput label="Building Number" source="building_number" sortable={false} validate={required()} />
      )}
      {isCaucasus && <SelectInput label="TMR Coverage" source="tmr_coverage" choices={outletsTMRCoverageOptions} />}
      {isCaucasus && (
        <ManagerAutocompleteInput
          label="Supervisor"
          source="supervisor_id"
          filter={{ roles: [roles.RSM] }}
          validate={required()}
        />
      )}
      {isCaucasus && (
        <SelectInput
          label="Activity Status"
          source="status"
          choices={outletsActivityStatusesOptions}
          validate={required()}
        />
      )}
      {isCaucasus && <NumberInput label="Cluster" source="cluster" />}
      {isCaucasus && <TextInput label="Sub Chanel" source="sub_channel" />}
      {isCaucasus && <TextInput label="Region" source="area" />}
      {!isHungary() && <TextInput label="Longitude" source="longitude" />}
      {!isHungary() && <TextInput label="Latitude" source="latitude" />}
      {isBelarus() && <TextInput label="Область" source="area" />}
      {isBelarus() && <SelectInput source="status" optionText="name" choices={outletStatuses} />}
      <ManagerAutocompleteInput label={isCaucasus ? 'TMR' : 'Manager'} validate={required()} />
      <CityAutocompleteInput validate={required()} />
      <LegalEntityAutocompleteInput label={isCaucasus ? 'LegalName' : undefined} validate={required()} />
      <ReferenceInput source="outlet_type_id" reference="admin/services/outlet-types" validate={required()}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput label="Outlet code" source="outlet_code" />
      <TextInput label={isCaucasus ? 'Fiscal Code' : 'Legal ID'} source="legal_id" />
      {isHungary() && <TextInput label="Zip code" source="zip_code" />}
      {isHungary() && (
        <ReferenceInput label="Tier" source="tier_discount_id" reference="admin/tiers-discounts" validate={required()}>
          <SelectInput optionText="name" />
        </ReferenceInput>
      )}
    </SimpleForm>
  </Edit>
);

export default OutletEdit;

import React from 'react';
import { Filter, TextInput } from 'react-admin';

const WinstonMemoryGameFilter = (props) => (
  <Filter {...props}>
    <TextInput label="User ID" source="user_id" alwaysOn />
  </Filter>
);

export default WinstonMemoryGameFilter;

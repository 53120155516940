import React from 'react';
import { SaveButton, Toolbar } from 'react-admin';

const EditViewToolbarWithoutDelete = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export default EditViewToolbarWithoutDelete;

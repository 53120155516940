import AutolightIcon from '@material-ui/icons/LocalGasStation';
import AutolightReportsList from './components/AutolightReportsList';
import { APP_TYPES, roles } from '../../../config/constants';

const options = {
  appTypes: [APP_TYPES.BELARUS],
  allowedRoles: [roles.ADMIN, roles.JTI_TEAM],
  label: 'Autolight orders',
  subMenuName: 'reports',
};

export default {
  list: AutolightReportsList,
  icon: AutolightIcon,
  name: 'admin/reports/autolight',
  options,
};

import React from 'react';
import { ReferenceInput, SelectInput } from 'react-admin';

const TargetGroupInput = (props) => (
  <ReferenceInput
    label="Target group"
    source="target_group_id"
    reference="admin/target-groups"
    perPage={5000}
    allowEmpty
    emptyValue={null}
    {...props}
  >
    <SelectInput optionText={(x) => `${x.id} - ${x.name}`} />
  </ReferenceInput>
);

export default TargetGroupInput;

import React from 'react';
import { BooleanInput, Edit, NumberInput, ReferenceInput, SelectInput, SimpleForm, TextInput } from 'react-admin';

import { unexpectedRewardsTypes } from '../../../config/constants';

const RewardEdit = (props) => (
  <Edit title="Edit reward" undoable={false} {...props}>
    <SimpleForm>
      <TextInput label="ID" source="id" disabled />
      <TextInput label="Reward name" source="name" />
      <SelectInput source="type_id" optionText="name" choices={unexpectedRewardsTypes} />
      <ReferenceInput label="Frequencies" source="frequency_id" reference="admin/services/frequencies">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput label="Description" source="description" />
      <NumberInput label="Coins" source="coins" />
      <ReferenceInput source="level_id" reference="admin/services/unexpected-rewards-levels">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <NumberInput label="Value" source="value" />
      <BooleanInput label="Is repeatable" source="is_repeated" />
    </SimpleForm>
  </Edit>
);

export default RewardEdit;

import React from 'react';
import { Edit, SelectInput, SimpleForm, required, ReferenceInput, TextInput, BooleanInput } from 'react-admin';
import { CustomDateInput } from '../../../commonComponents/CustomDateInput';
import { quickTipsQuestionTypeSelectOptions, s3FilePaths } from '../../../config/constants';
import QuickTipsQuestion from '../../dailyQuestions/components/QuickTipsQuestion';
import ImageInput from '../../../commonComponents/UploadInputs/ImageInput';
import { isIreland } from '../../../helpers/appTypeHelper';

const QuickTipsCreate = (props) => (
  <Edit title="Create quick tips" {...props}>
    <SimpleForm redirect="list">
      <SelectInput
        source="type"
        optionValue="value"
        optionText="label"
        choices={quickTipsQuestionTypeSelectOptions}
        disabled
      />
      {isIreland() && (
        <BooleanInput style={{ width: '300px' }} label="Included in the Progress bar" source="progress_bar_enable" />
      )}
      <ImageInput imageInputSource="image" validate={required()} imagePath={s3FilePaths.quickTips} />
      <TextInput source="question" validate={required()} />

      <QuickTipsQuestion />
      <ReferenceInput label="Brand" source="brand_id" reference="services/brands" allowEmpty={true}>
        <SelectInput optionText="name" validate={required()} />
      </ReferenceInput>
      <CustomDateInput label="Publish date" source="active_at" validate={required()} />
    </SimpleForm>
  </Edit>
);

export default QuickTipsCreate;

import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';

import LogsFilter from './LogsFilter';
import { CustomDateField } from '../../../commonComponents/DateField';

const LogsList = (props) => (
  <List
    title="Logs"
    filters={<LogsFilter />}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField label="ID" source="id" />
      <TextField label="Admin Id" source="admin_id" />
      <TextField label="Action" source="action" />
      <TextField label="Info" source="info" />
      <CustomDateField label="Date" source="date" />
    </Datagrid>
  </List>
);

export default LogsList;

import LoyaltyProgramIcon from '@material-ui/icons/Extension';
import LepReportsList from './components/LepReportsList';
import { APP_TYPES, roles } from '../../../config/constants';

const options = {
  appTypes: [APP_TYPES.IRELAND],
  allowedRoles: [roles.ADMIN],
  label: 'LEP',
  subMenuName: 'reports',
};

export default {
  list: LepReportsList,
  icon: LoyaltyProgramIcon,
  name: 'admin/reports/lep',
  options,
};

import React, { useCallback } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import { useNotify } from 'react-admin';
import { serverApiUrl } from '../../../../config/constants';

import httpClient from '../../../../core/httpClient';

const WON_STATUS = 1;

const LepCodeCheckbox = ({ record, refresh }) => {
  const notify = useNotify();
  const won = record.status === WON_STATUS;

  const handleCheckBoxOnClick = useCallback(
    (e) => {
      const body = {
        user_id: record.user_id,
        code: record.code,
        competition_id: record.competition_id,
        won: e.target.checked,
      };
      const url = `${serverApiUrl}/admin/lep/codes/mark`;
      const options = { method: 'POST', body: JSON.stringify(body) };
      httpClient(url, options)
        .then(refresh)
        .catch(() => notify('Error occurred', 'warning'));
    },
    [notify, record, refresh],
  );

  return (
    <Tooltip title="Won?" placement="top">
      <Checkbox checked={won} onClick={handleCheckBoxOnClick} disabled={!record.user_id || won} />
    </Tooltip>
  );
};

export default LepCodeCheckbox;

import TouchAppIcon from '@material-ui/icons/TouchApp';
import InteractiveList from './components/InteractiveList';
import InteractiveCreate from './components/InteractiveCreate';
import InteractiveEdit from './components/InteractiveEdit';
import { roles, APP_TYPES } from '../../config/constants';

const options = {
  appTypes: [APP_TYPES.HUNGARY, APP_TYPES.IRELAND],
  allowedRoles: [roles.ADMIN],
  label: 'Interactive',
  subMenuName: 'content',
};

export default {
  list: InteractiveList,
  create: InteractiveCreate,
  edit: InteractiveEdit,
  icon: TouchAppIcon,
  name: 'admin/interactive-articles',
  options,
};

import React from 'react';
import { List, Datagrid, TextField, EditButton } from 'react-admin';

const ProblemList = ({ level, ...otherProps }) => {
  const title = `${level} Level`;

  return (
    <List title={title} exporter={false} bulkActionButtons={false} {...otherProps}>
      <Datagrid rowClick="edit">
        <TextField label="ID" source="id" sortable={false} />
        <TextField label="Name" source="name" sortable={false} />
        <TextField label="Url" source="url" sortable={false} />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default ProblemList;

import { useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import { serverApiUrl } from '../../../../config/constants';
import httpClient from '../../../../core/httpClient';
import OverviewBlock from '../OverviewBlock';

const WatingModerationUsers = () => {
  const translate = useTranslate();
  const [generalInfo, setGeneralInfo] = useState();
  const [additionalInfo, setAdditionalInfo] = useState();

  const updateProgramOutlets = () => {
    const url = `${serverApiUrl}/admin/outlets-users/waiting-moderation-users`;
    const options = { method: 'GET' };

    httpClient(url, options)
      .then(({ json: { waiting_moderation_users, new_waiting_moderation_users } }) => {
        setGeneralInfo(waiting_moderation_users);
        setAdditionalInfo(new_waiting_moderation_users);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    updateProgramOutlets(0);
  }, []);

  return (
    <OverviewBlock
      generalInfo={generalInfo}
      additionalInfo={additionalInfo}
      description={translate('Waiting moderation users')}
      tip={translate('root.statistics.Users who need your moderation to access the App')}
    />
  );
};

export default WatingModerationUsers;

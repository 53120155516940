import ChallengeIcon from '@material-ui/icons/FormatAlignJustify';
import ChallengeReportsList from './components/ChallengeReportsList';
import { APP_TYPES, CAUCASUS_TYPES, roles } from '../../../config/constants';

const appType = process.env.REACT_APP_APP_TYPE;

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: CAUCASUS_TYPES.includes(appType) ? [roles.ADMIN, roles.JTI_TEAM] : [roles.ADMIN],
  label: 'Challenge',
  subMenuName: 'reports',
};

export default {
  list: ChallengeReportsList,
  icon: ChallengeIcon,
  name: 'admin/reports/quests',
  options,
};

import React from 'react';
import { Datagrid, List, TextField } from 'react-admin';
import { CustomDateField } from '../../../../commonComponents/DateField';
import CustomReportListActions from '../../../../commonComponents/ListActions/CustomReportListActions';
import { APP_TYPES } from '../../../../config/constants';
import { isHungary, isIreland } from '../../../../helpers/appTypeHelper';

import UserReportsFilter from './UserReportsFilter';

const UserReportsList = (props) => (
  <List
    title="User reports"
    actions={<CustomReportListActions />}
    filters={<UserReportsFilter />}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField label="User ID" source="id" />
      <TextField label="Name" source="user_name" sortable={false} />
      <TextField label="Surname" source="user_surname" sortable={false} />
      <TextField label="Username" source="user_username" sortable={false} />
      <TextField label="Phone" source="user_phone" sortable={false} />
      <TextField label="Email" source="user_email" sortable={false} />
      <TextField label="City" source="user_city" sortable={false} />
      <CustomDateField label="Date" source="user_created_at" sortable={false} />
      <TextField label="Shop id" source="user_shop_id" sortable={false} />
      <TextField label="Points" source="points" sortable={false} />
      <TextField label="Coins" source="coins" sortable={false} />
      <TextField label="Win" source="count_win" sortable={false} />
      <TextField label="Draw" source="count_draw" sortable={false} />
      <TextField label="Lose" source="count_lose" sortable={false} />
      <TextField label="Win rate (%)" source="win_rate" sortable={true} />
      {(isHungary() || isIreland()) && <TextField label="DG Checkpoints" source="count_daily_games" sortable={true} />}
      {process.env.REACT_APP_APP_TYPE === APP_TYPES.BELARUS && (
        <TextField label="Внешний код торговой точки" source="account_code" />
      )}
      {process.env.REACT_APP_APP_TYPE === APP_TYPES.BELARUS && (
        <TextField label="Внешний код продавца" source="seller_code" />
      )}
    </Datagrid>
  </List>
);

export default UserReportsList;

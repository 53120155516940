import React from 'react';
import { DateField } from 'react-admin';

import { timeZones } from '../config/constants';

const appTimeZone = timeZones[process.env.REACT_APP_APP_TYPE];

export const CustomDateField = (props) => (
  <DateField options={{ timeZone: appTimeZone }} showTime locales="ru" {...props} />
);

import React from 'react';
import { List, Datagrid, TextField, EditButton, DeleteButton, SelectField, DateField } from 'react-admin';

import CustomListActions from '../../../commonComponents/ListActions/CustomListActions';
import DuplicateButton from '../../../commonComponents/DuplicateButton';
import { quickTipsTypeConst } from '../config/consts';

const QuickTipsList = (props) => {
  const duplicateRecord = (record) => {
    const { id, ...mappedRecord } = record;
    const answers = mappedRecord.answers
      .sort((a, b) => a.id - b.id)
      .map((answer) => ({ answer: answer.answer, correct: answer.correct }));
    mappedRecord.answers = answers;

    return mappedRecord;
  };

  return (
    <List
      title="Quick tips"
      exporter={false}
      bulkActionButtons={false}
      actions={<CustomListActions />}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}
    >
      <Datagrid rowClick="edit">
        <TextField label="ID" source="id" />
        <TextField label="Question text" source="question" />
        <DateField label="Active at" source="active_at" />
        <SelectField label="Question type" source="type" choices={quickTipsTypeConst} />
        <EditButton />
        <DuplicateButton copyRecord={duplicateRecord} />
        <DeleteButton undoable={true} />
      </Datagrid>
    </List>
  );
};

export default QuickTipsList;

import React from 'react';
import { useLocation } from 'react-router-dom';
import { Button, useDataProvider, useNotify, useTranslate } from 'react-admin';
import Icon from '@material-ui/icons/CloudUpload';
import queryString from 'query-string';

const CustomImportButton = ({ label, resource, record }) => {
  const notify = useNotify();
  const location = useLocation();
  const dataProvider = useDataProvider();
  const fileInputRef = React.useRef();
  const translate = useTranslate();

  const handleFileUpload = (e) => {
    e.stopPropagation();
    const searchParams = queryString.parse(location.search);
    const search = searchParams.filter ? queryString.stringify(JSON.parse(searchParams.filter)) : '';
    const data = new FormData();
    record && data.append('id', record.id);
    data.append('file', fileInputRef.current.files[0], 'file');
    dataProvider
      .create(`${resource}/import?${search}`, { data })
      .then((res) => {
        if (res.data.id && res.data.id.startsWith && res.data.id.startsWith('http')) {
          notify('Error while importing data', 'warning');
          window.location.href = res.data.id;
        } else {
          notify('Data imported', 'success');
        }
        fileInputRef.current.value = null;
      })
      .catch((error) => {
        notify(error.message || 'Error while importing data', 'warning');
        fileInputRef.current.value = null;
      });
  };

  return (
    <>
      <Button label={label || translate('root.listToolBar.import')} onClick={() => fileInputRef.current.click()}>
        <Icon />
      </Button>
      <input ref={fileInputRef} hidden type="file" onChange={handleFileUpload} />
    </>
  );
};

export default CustomImportButton;

import React from 'react';
import { Filter, ReferenceInput, SelectInput, TextInput } from 'react-admin';
import { CustomDateInput } from '../../../../commonComponents/CustomDateInput';
import UserAutocompleteInput from '../../../../commonComponents/UserAutocompleteInput';

const MultibrandQuizReportsFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput label="Multibrand quizzes" source="pack_id" reference="admin/multibrand-quizzes" alwaysOn>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <UserAutocompleteInput label="User ID" source="user_id" reference="admin/users/autocomplete" alwaysOn />
    <TextInput label="Account code" source="traffic_code" alwaysOn />
    <CustomDateInput label="Choose start date" source="start_date" start alwaysOn />
    <CustomDateInput label="Choose end date" source="end_date" end alwaysOn />
  </Filter>
);

export default MultibrandQuizReportsFilter;

import React from 'react';
import { List, Datagrid, TextField, EditButton, DeleteButton, BooleanField } from 'react-admin';
import ImageField from '../../../../commonComponents/ImageField';

const BrandsList = (props) => (
  <List
    title="Knowledge Bank Brands"
    exporter={false}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <TextField label="Name" source="name" />
      <ImageField label="Icon" source="icon" />
      <TextField label="Priority" source="priority" />
      <BooleanField label="Visible" source="visible" />
      <EditButton />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
);

export default BrandsList;

import React from 'react';
import { List, Datagrid, TextField, EditButton } from 'react-admin';

const StepsList = (props) => (
  <List
    title="Daily Rewards"
    exporter={false}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <TextField label="Step number" source="step_number" />
      <EditButton />
    </Datagrid>
  </List>
);

export default StepsList;

import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';
import RewardForm from './RewardForm';

const RewardEdit = (props) => (
  <Edit title="Edit Reward" undoable={false} {...props}>
    <SimpleForm redirect="list">
      <TextInput label="ID" source="id" disabled />
      <RewardForm {...props} />
    </SimpleForm>
  </Edit>
);

export default RewardEdit;

import React from 'react';
import { Create, TextInput, SelectInput, NumberInput } from 'react-admin';
import { statuses, quizPackTypes } from '../../../config/constants';
import QuizForm from '../../../commonComponents/QuizForm';
import { CustomDateInput } from '../../../commonComponents/CustomDateInput';

const MultibrandQuizCreate = (props) => (
  <Create title="Create Multibrand Quiz" {...props}>
    <QuizForm redirect="list" quizType={quizPackTypes.multibrand}>
      <TextInput label="Name" source="name" />
      <SelectInput source="status" optionText="name" choices={statuses.slice(0, 2)} />
      <NumberInput label="Price" source="price" />
      <CustomDateInput label="Publish date" source="published_at" />
    </QuizForm>
  </Create>
);

export default MultibrandQuizCreate;

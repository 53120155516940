import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';

const LegalEntityEdit = (props) => (
  <Edit title="Edit Legal Entity" undoable={false} {...props}>
    <SimpleForm>
      <TextInput label="ID" source="id" disabled />
      <TextInput label="Name" source="name" />
      <TextInput label="Type" source="type" />
    </SimpleForm>
  </Edit>
);

export default LegalEntityEdit;

import React from 'react';
import {
  BooleanInput,
  Edit,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import ImageInput from '../../../../commonComponents/UploadInputs/ImageInput';
import { APP_TYPES, knowledgeBankCategorySections, s3FilePaths } from '../../../../config/constants';
import CategoryTypeSelector from './CategoryTypeSelector';

const CategoriesEdit = (props) => (
  <Edit title="Edit Category" undoable={false} {...props}>
    <SimpleForm redirect="list">
      <TextInput label="Name" source="name" validate={required()} />
      <CategoryTypeSelector />
      <NumberInput label="Priority" source="priority" />
      <ImageInput imageInputSource="icon" validate={required()} imagePath={s3FilePaths.allUploads} />
      <ReferenceInput
        label="Transaction Type"
        source="transaction_type_id"
        reference="admin/transactions/types"
        perPage={100}
      >
        <SelectInput optionText="name" validate={required()} />
      </ReferenceInput>
      <ReferenceInput
        label="Target group"
        source="target_group_id"
        reference="admin/target-groups"
        perPage={100}
        allowEmpty
        emptyValue={null}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <SelectInput
        label="Section of Category"
        source={'section'}
        choices={knowledgeBankCategorySections}
        validate={required()}
      />
      <BooleanInput label="Active" source="active" />
      <BooleanInput label="Show Brand" source="show_brand" />
      <BooleanInput label="Show Quiz" source="show_quiz" />
      <BooleanInput label="Show 'Minutes to read'" source="show_minutes_to_read" />
      <BooleanInput label="Show 'Choose a brand'" source="should_choose_brand" />
      <BooleanInput label="Show retail price" source="show_retail_price" />
      <BooleanInput label="Show 'Published at'" source="show_published_at" />
      <BooleanInput label="Show 'New' bubble" source="show_new_bubble" />
      <BooleanInput label="Limit content" source="limited_content" />
      {(process.env.REACT_APP_APP_TYPE === APP_TYPES.IRELAND ||
        process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY) && (
        <BooleanInput label="Push notifications" source="send_push_notification" />
      )}
      <BooleanInput label="Is hidden category?" source="special" />
    </SimpleForm>
  </Edit>
);

export default CategoriesEdit;

import { APP_TYPES } from '../config/constants';

export const isIreland = () => process.env.REACT_APP_APP_TYPE === APP_TYPES.IRELAND;
export const isHungary = () => process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY;
export const isBelarus = () => process.env.REACT_APP_APP_TYPE === APP_TYPES.BELARUS;
export const isAzerbaijan = () => process.env.REACT_APP_APP_TYPE === APP_TYPES.AZERBAIJAN;
export const isArmenia = () => process.env.REACT_APP_APP_TYPE === APP_TYPES.ARMENIA;
export const isGeorgia = () => process.env.REACT_APP_APP_TYPE === APP_TYPES.GEORGIA;
export const isDemo = () => process.env.REACT_APP_APP_TYPE === APP_TYPES.DEMO;

export const isCaucasus = isArmenia() || isAzerbaijan() || isGeorgia();

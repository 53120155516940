import React from 'react';
import { Filter, ReferenceInput, SelectInput, TextInput } from 'react-admin';
import ManagerAutocompleteInput from '../../../../commonComponents/ManagerAutocompleteInput';
import { outletContractedStatuses, outletContractTypes } from '../../../../config/constants';
import { managerLabel } from '../../../users/components/UserLabelConsts';
import { isHungary, isBelarus, isCaucasus } from '../../../../helpers/appTypeHelper';

const OutletFilter = ({ permissions, ...props }) =>
  isHungary() ? (
    <Filter {...props}>
      <TextInput label="Trafik code" source="outlet_code" alwaysOn />
      <ReferenceInput label="City" source="city_id" reference="admin/cities" perPage={10000} alwaysOn>
        <SelectInput source="name" />
      </ReferenceInput>
      <ManagerAutocompleteInput label={managerLabel} alwaysOn />
      <TextInput label="Address" source="street" alwaysOn />
      <SelectInput source="contracted" optionText="name" choices={outletContractedStatuses} alwaysOn />
      <SelectInput source="contract_type" optionText="name" choices={outletContractTypes} alwaysOn />
    </Filter>
  ) : (
    <Filter {...props}>
      {(isBelarus() || isCaucasus) && <TextInput label="Outlet code" source="outlet_code" alwaysOn />}
      <TextInput label={isCaucasus ? 'Address' : 'Street'} source="street" alwaysOn />
      <ReferenceInput label="City" source="city_id" reference="admin/cities" perPage={10000} alwaysOn>
        <SelectInput source="name" />
      </ReferenceInput>
      <ReferenceInput
        label="Legal Entity"
        source="legal_entity_id"
        reference="admin/legal-entities"
        perPage={10000}
        alwaysOn
      >
        <SelectInput source="name" />
      </ReferenceInput>
    </Filter>
  );

export default OutletFilter;

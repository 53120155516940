import { useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import { serverApiUrl } from '../../../../config/constants';
import httpClient from '../../../../core/httpClient';
import OverviewBlock from '../OverviewBlock';

const OutletsCount = () => {
  const translate = useTranslate();
  const [generalInfo, setGeneralInfo] = useState();

  const updateProgramOutlets = () => {
    const url = `${serverApiUrl}/admin/outlets-users/outlets-count`;
    const options = { method: 'GET' };

    httpClient(url, options)
      .then(({ json: { outlets_number } }) => {
        setGeneralInfo(outlets_number);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    updateProgramOutlets(0);
  }, []);

  return (
    <OverviewBlock
      generalInfo={generalInfo}
      description={translate('Outlets number')}
      tip={translate('root.statistics.All outlets to which you are assigned')}
    />
  );
};

export default OutletsCount;

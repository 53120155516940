import React, { useEffect } from 'react';
import { TextInput, SelectInput, BooleanInput } from 'react-admin';
import { useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { makeStyles } from '@material-ui/core/styles';

import ImageInput from '../../../../commonComponents/UploadInputs/ImageInput';
import { APP_TYPES, quizzesQuestionTypes, s3FilePaths } from '../../../../config/constants';

const useStyles = makeStyles({
  questionFormWrapper: {
    width: 276,
    marginTop: 20,
    padding: 10,
    border: '1px solid #0000001f',
  },
});

const correctAnswers = [
  { id: 1, name: '1' },
  { id: 2, name: '2' },
  { id: 3, name: '3' },
  { id: 4, name: '4' },
];

const textAnswersList = ({ fields }) => (
  <>
    {fields.map((member) => (
      <div key={`${member}.answer`}>
        <TextInput key={member} source={`${member}.answer`} label="Answer for question" />
      </div>
    ))}
  </>
);

const ENV_APP_TYPE = process.env.REACT_APP_APP_TYPE;

const QuestionForm = ({ name, populate, ...rest }) => {
  const form = useForm();
  const classes = useStyles();

  useEffect(() => {
    if (!populate) return;
    for (let i = 0; i < 5; i += 1) {
      form.mutators.push(name, { type_id: quizzesQuestionTypes.commonText });
      for (let j = 0; j < 4; j += 1) {
        form.mutators.push(`${name}[${i}].answers`, {});
      }
    }
  });

  return (
    <FieldArray name={name}>
      {({ fields }) =>
        fields.map((field, index) => (
          <div key={field} className={classes.questionFormWrapper}>
            <TextInput source={`${name}[${index}].text`} label="Question" {...rest} />
            <FieldArray name={`${name}[${index}].answers`} component={textAnswersList} />
            <SelectInput
              source={`${name}[${index}].correct_answers`}
              label="Correct answer"
              choices={correctAnswers}
              format={(value) => (value ? value[0] : '')}
              parse={(value) => [value]}
            />
            <BooleanInput label="Show price disclaimer" source={`${name}[${index}].show_disclaimer`} />
            {ENV_APP_TYPE === APP_TYPES.HUNGARY && (
              <BooleanInput label="Product question" source={`${name}[${index}].product_question`} />
            )}
            <ImageInput imagePath={s3FilePaths.quiz} imageInputSource={`${name}[${index}].image`} />
          </div>
        ))
      }
    </FieldArray>
  );
};

export default QuestionForm;

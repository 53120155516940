import AchievementIcon from '@material-ui/icons/Stars';
import { APP_TYPES, roles } from '../../../config/constants';
import AchievementsReportsList from './components/AchievementsReportsList';

const options = {
  appTypes: [APP_TYPES.HUNGARY],
  allowedRoles: [roles.ADMIN],
  label: 'Achievements',
  subMenuName: 'reports',
};

export default {
  list: AchievementsReportsList,
  icon: AchievementIcon,
  name: 'admin/reports/achievements',
  options,
};

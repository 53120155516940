import { BooleanInput, FormDataConsumer } from 'react-admin';
import React from 'react';
import PriceQuestion from './PriceQuestion';
import QuizQuestion from './QuizQuestion';

const QuickTipsQuestion = () => (
  <>
    <FormDataConsumer>
      {({ formData }) => (
        <>
          {(() => {
            switch (formData.type) {
              case 1:
                return <PriceQuestion />;
              case 2:
                return <QuizQuestion />;
              default:
                return <></>;
            }
          })()}
        </>
      )}
    </FormDataConsumer>
    <br />

    <BooleanInput label="Show price disclaimer" source={'show_price_disclaimer'} defaultValue={true} />
  </>
);

export default QuickTipsQuestion;

import MultibrandQuizIcon from '@material-ui/icons/List';
import { roles, APP_TYPES, CAUCASUS_TYPES } from '../../config/constants';
import MultibrandQuizList from './components/MultibrandQuizList';
import MultibrandQuizEdit from './components/MultibrandQuizEdit';
import MultibrandQuizCreate from './components/MultibrandQuizCreate';

const ENV_APP_TYPE = process.env.REACT_APP_APP_TYPE;

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: [APP_TYPES.HUNGARY, APP_TYPES.BELARUS, ...CAUCASUS_TYPES].includes(ENV_APP_TYPE)
    ? [roles.ADMIN]
    : [roles.ADMIN, roles.JTI_TEAM],
  label: 'Multibrand Quizzes',
  subMenuName: 'content',
};

export default {
  list: MultibrandQuizList,
  create: MultibrandQuizCreate,
  edit: MultibrandQuizEdit,
  icon: MultibrandQuizIcon,
  name: 'admin/multibrand-quizzes',
  options,
};

import React from 'react';
import { Datagrid, List, TextField } from 'react-admin';

import { ticketStatusesForComponent } from '../../../../config/constants';
import CustomListActions from '../../../../commonComponents/ListActions/CustomListActions';
import { CustomDateField } from '../../../../commonComponents/DateField';
import StatusField from '../../../../commonComponents/StatusField';

import TicketsFilter from '../ticketsFilter/TicketsFilter';
import styles from './TicketsPreviews.module.scss';

export const TicketsPreviews = ({ onTicketClick, selectedTicket, ...otherProps }) => (
  <div className={styles.ticketsPreviews}>
    <List
      title="Tickets"
      filters={<TicketsFilter />}
      actions={<CustomListActions importer={false} creation={false} />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      {...otherProps}
    >
      <Datagrid
        rowStyle={(record) => (record.id === selectedTicket?.id ? { backgroundColor: '#01c1b6' } : {})}
        rowClick={(id, basePath, record) => onTicketClick(record)}
      >
        <TextField label="ID" source="id" />
        <TextField label="Name" source="problem.name" sortable={false} />
        <TextField label="User ID" source="user.id" sortable={false} />
        <CustomDateField label="Created at" source="created_at" sortable={false} />
        <CustomDateField label="Last message" source="last_message_date" sortable={false} />

        <StatusField source="status" statuses={ticketStatusesForComponent} sortable={false} />
      </Datagrid>
    </List>
  </div>
);

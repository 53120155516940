import React from 'react';
import { stringify } from 'query-string';
import { ExportButton, useNotify } from 'react-admin';
import { serverApiUrl } from '../../config/constants';

const downloadFile = (downloadUrl) => {
  const link = document.createElement('a');
  link.setAttribute('href', downloadUrl);
  link.setAttribute('download', 'reports');
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(link.href);
  document.body.removeChild(link);
};

const CustomExportButton = ({ filter, path = window.location.pathname, sort, filterValues, resource }) => {
  const notify = useNotify();

  const handleClick = async () => {
    const filterQueryString = filter && Object.keys(filter).length ? `?${stringify(filter)}` : '';
    const url = `${serverApiUrl}${path}/xlsx${filterQueryString}`;

    const response = await fetch(url, {
      method: 'GET',
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}`, 'Content-Type': 'application/json' },
    });

    if (!response.ok) {
      return notify('reports.error.download', 'warning');
    }

    let downloadUrl;
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.indexOf('application/zip') !== -1) {
      const blob = await response.blob();
      downloadUrl = URL.createObjectURL(blob);
    } else {
      const json = await response.json();
      downloadUrl = json.url;
    }

    downloadFile(downloadUrl);
  };

  return (
    <ExportButton exporter={handleClick} maxResults={0} sort={sort} filterValues={filterValues} resource={resource} />
  );
};

export default CustomExportButton;

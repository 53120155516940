import React from 'react';
import { Create, SimpleForm, TextInput, NumberInput, ReferenceInput, SelectInput, required } from 'react-admin';
import ImageInput from '../../../../commonComponents/UploadInputs/ImageInput';
import TargetGroupInput from '../../../../commonComponents/TargetGroupInput';
import { APP_TYPES, s3FilePaths } from '../../../../config/constants';

const ProviderCreate = (props) => (
  <Create title="Create Provider" {...props}>
    <SimpleForm redirect="list">
      <TextInput label="Name" source="name" validate={required()} />
      <ImageInput imageInputSource="photo" validate={required()} imagePath={s3FilePaths.bonusShop} />
      <NumberInput label="Priority" source="priority" />
      <ReferenceInput label="Category" source="category_id" reference="admin/bonus-shop/categories/digital">
        <SelectInput optionText="name" validate={required()} />
      </ReferenceInput>
      <TargetGroupInput />
      <TextInput label="Template URL" source="template_url" validate={required()} />
      {process.env.REACT_APP_APP_TYPE === APP_TYPES.BELARUS && (
        <ReferenceInput source="mobile_operator_id" reference="services/mobile-operators">
          <SelectInput source="id" optionText={(x) => `${x.id} ${x.name}`} />
        </ReferenceInput>
      )}
    </SimpleForm>
  </Create>
);

export default ProviderCreate;

import React from 'react';
import { Datagrid, EditButton, List, NumberField, TextField } from 'react-admin';

import ImageField from '../../../../commonComponents/ImageField';
import exporter from '../../../../helpers/xlsxExporter';

const ProvidersList = ({ classes, ...rest }) => (
  <List
    title="Providers"
    bulkActionButtons={false}
    exporter={exporter(null, '/admin/products/csv')}
    perPage={1000}
    pagination={null}
    {...rest}
  >
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" sortable={false} />
      <TextField label="Name" source="name" sortable={false} />
      <ImageField label="Image" source="photo" sortable={false} />
      <TextField label="Category" source="category" sortable={false} />
      <NumberField label="Priority" source="priority" sortable={false} />
      <EditButton />
    </Datagrid>
  </List>
);

export default ProvidersList;

import React from 'react';
import {
  BooleanInput,
  Create,
  NumberInput,
  required,
  SelectInput,
  TextInput,
  FormDataConsumer,
  SimpleForm,
  ArrayInput,
} from 'react-admin';
import RewardInput from '../../../commonComponents/RewardInput';
import TargetGroupInput from '../../../commonComponents/TargetGroupInput';
import ImageInput from '../../../commonComponents/UploadInputs/ImageInput';
import { s3FilePaths, statuses } from '../../../config/constants';
import Reward from './Reward';

const typeOfChance = [
  { id: 1, name: 'Number of Winners' },
  { id: 2, name: 'Probability of Winning' },
];
const rewardInputFields = [
  { inputLabel: 'Prize', inputType: 'string', radioLabel: 'Prizes', source: 'product_name' },
  { inputLabel: 'Coins', inputType: 'number', radioLabel: 'Coins', source: 'coins' },
];

const IrelandChanceCreate = (props) => (
  <Create title="Create Chance" {...props}>
    <SimpleForm redirect="list">
      <SelectInput label="Type of Chance" source="type" optionText="name" choices={typeOfChance} />
      <FormDataConsumer>
        {({ formData }) => (
          <>
            {formData.type && <TextInput label="Name" source="name" validate={required()} />}
            <br />
            {formData.type && <TextInput label="Description" source="description" validate={required()} />}
            <br />
            {formData.type === 1 && <NumberInput label="Total" source="total" />}
            <br />
            {formData.type && <TextInput label="Cost" source="cost" validate={required()} />}
            {formData.type && (
              <BooleanInput
                style={{ width: '300px' }}
                label="Included in the Progress bar"
                source="progress_bar_enable"
                defaultValue={true}
              />
            )}
            {formData.type === 1 && <RewardInput formType="create" inputFields={rewardInputFields} />}
            <br />
            {formData.type && (
              <SelectInput source="status" optionText="name" choices={statuses.slice(0, 2)} validate={required()} />
            )}
            <br />
            {formData.type === 1 && <NumberInput label="Number of winners" source="winner_count" />}
            {formData.type === 2 && (
              <NumberInput label="Consolation Points" source="consolation_points" validate={required()} />
            )}
            {formData.type && (
              <ImageInput imageInputSource="photo" validate={required()} imagePath={s3FilePaths.allUploads} />
            )}
            {formData.type && (
              <ImageInput
                imageInputSource="background_image"
                validate={required()}
                imagePath={s3FilePaths.allUploads}
              />
            )}
            {formData.type && <NumberInput label="Priority" source="priority" />}
            <br />
            {formData.type && <TargetGroupInput />}
            {formData.type === 2 && (
              <ArrayInput label="Rewards of Points" source="reward_of_points">
                <Reward {...props} isEdit={false} />
              </ArrayInput>
            )}
          </>
        )}
      </FormDataConsumer>
    </SimpleForm>
  </Create>
);

export default IrelandChanceCreate;

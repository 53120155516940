import React from 'react';
import { Link } from 'react-router-dom';
import { Datagrid, DeleteButton, EditButton, List, TextField } from 'react-admin';
import { stringify } from 'query-string';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import ListIcon from '@material-ui/icons/FormatListNumbered';

import { CustomDateField } from '../../../commonComponents/DateField';
import ImageField from '../../../commonComponents/ImageField';

const useStyles = makeStyles({
  link: {
    display: 'inline-flex',
    alignItems: 'center',
  },
});

const LinkToScenarios = ({ record, pathname, recordName }) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.link}
      component={Link}
      to={{
        pathname,
        search: stringify({ page: 1, perPage: 10, filter: JSON.stringify({ [recordName]: record.id }) }),
      }}
    >
      <ListIcon />
    </Button>
  );
};

const SalesSimulatorList = (props) => (
  <List title="Sales tips" exporter={false} bulkActionButtons={false} sort={{ field: 'id', order: 'DESC' }} {...props}>
    <Datagrid>
      <TextField label="ID" source="id" />
      <TextField label="Name" source="name" />
      <ImageField label="Incomplete image" source="incomplete_image" />
      <ImageField label="Complete image" source="complete_image" />
      <CustomDateField label="Start date" source="start_date" />
      <CustomDateField label="End date" source="end_date" />
      <LinkToScenarios pathname="/admin/sales-simulators/scenarios" recordName="simulator_id" />
      <EditButton />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
);

export default SalesSimulatorList;

import React from 'react';
import { SimpleForm, TextInput, ReferenceArrayInput, SelectArrayInput, SelectInput, useTranslate } from 'react-admin';
import ArrayField from '../../../commonComponents/ArrayField';

import CustomArrayInput from '../../../commonComponents/CustomArrayInput';
import FormIterator from '../../../core/FormIterator';
import {
  APP_TYPES,
  outletContractedStatuses,
  outletContractTypes,
  roles,
  targetGroupAttributeTypes,
  userVerificationStatuses,
} from '../../../config/constants';
import UploadFileInput from '../../../commonComponents/UploadInputs/UploadFileInput';
import TargetGroupInput from '../../../commonComponents/TargetGroupInput';
import checkUserRole from '../../../helpers/checkUserRole';

const ENV_APP_TYPE = process.env.REACT_APP_APP_TYPE;

const isAdmin = checkUserRole(roles.ADMIN);

const rolesChoices = [roles.BA, roles.JTI_TEAM].map((x) => ({ id: x }));
const inputFieldStyle = {
  width: 256,
};

const pushNewField = ({ fields, nextFormType: ruleTypeId }) => {
  fields.push({ attribute: ruleTypeId });
};

const renderForm = (formTypeTogglerOptions, { field, member, resource, record }) => {
  const selectedOption = formTypeTogglerOptions.find((option) => option.value === field.attribute);
  const { value, placeholder, reference, optionText, perPage } = selectedOption;
  let component = null;

  switch (value) {
    case targetGroupAttributeTypes.ACCOUNT_CODE:
      component = (
        <>
          <UploadFileInput fileInputSource={`${member}.file_url`} label={placeholder} />
          <TextInput label={placeholder} source={`${member}.values`} style={inputFieldStyle} />
        </>
      );
      break;
    case targetGroupAttributeTypes.ROLE:
      component = (
        <SelectArrayInput
          label={placeholder}
          source={`${member}.values`}
          optionText={optionText}
          choices={rolesChoices}
          style={inputFieldStyle}
        />
      );
      break;
    case targetGroupAttributeTypes.OUTLET_CONTRACTED:
      component = (
        <SelectInput
          label={placeholder}
          source={`${member}.values`}
          optionText={optionText}
          choices={outletContractedStatuses}
          style={inputFieldStyle}
        />
      );
      break;
    case targetGroupAttributeTypes.USER_VERIFICATION:
      component = (
        <SelectInput
          label={placeholder}
          source={`${member}.values`}
          optionText={optionText}
          choices={userVerificationStatuses}
          style={inputFieldStyle}
        />
      );
      break;
    case targetGroupAttributeTypes.OUTLET_CONTRACT_TYPE:
      component = (
        <SelectArrayInput
          label={placeholder}
          source={`${member}.values`}
          optionText={optionText}
          choices={outletContractTypes}
          style={inputFieldStyle}
        />
      );
      break;
    case targetGroupAttributeTypes.USER_ID:
      component = (
        <>
          <UploadFileInput fileInputSource={`${member}.file_url`} label="Users" />
          <ArrayField label="User IDs" source={`${member}.values`} record={record} />
        </>
      );
      break;
    case targetGroupAttributeTypes.REVERSED:
      component = <TargetGroupInput label={placeholder} resource={resource} source={`${member}.group_id`} />;
      break;
    default:
      component = (
        <ReferenceArrayInput
          label={placeholder}
          source={`${member}.values`}
          format={(v) => (v ? v.map((i) => +i) : v)}
          reference={reference}
          resource={resource}
          perPage={perPage}
        >
          <SelectArrayInput optionText={optionText} style={inputFieldStyle} />
        </ReferenceArrayInput>
      );
  }

  return component;
};

const RulesForm = ({ children, ...rest }) => {
  const translate = useTranslate();

  const formTypeTogglerOptions = [
    ...(isAdmin
      ? [
          {
            value: targetGroupAttributeTypes.POSITION,
            label: translate(`root.rulesForm.togglerOptions.${targetGroupAttributeTypes.POSITION}.label`),
            placeholder: `root.rulesForm.togglerOptions.${targetGroupAttributeTypes.POSITION}.placeholder`,
            reference: 'services/positions',
            optionText: 'name',
          },
          {
            value: targetGroupAttributeTypes.ACCOUNT_CODE,
            label: translate(`root.rulesForm.togglerOptions.${targetGroupAttributeTypes.ACCOUNT_CODE}.label`),
            placeholder: `root.rulesForm.togglerOptions.${targetGroupAttributeTypes.ACCOUNT_CODE}.placeholder`,
          },
          {
            value: targetGroupAttributeTypes.CITY,
            label: translate(`root.rulesForm.togglerOptions.${targetGroupAttributeTypes.CITY}.label`),
            placeholder: `root.rulesForm.togglerOptions.${targetGroupAttributeTypes.CITY}.placeholder`,
            reference: 'admin/cities',
            perPage: 10000,
            optionText: 'name',
          },
          {
            value: targetGroupAttributeTypes.MANAGER,
            label: translate(`root.rulesForm.togglerOptions.${targetGroupAttributeTypes.MANAGER}.label`),
            placeholder: `root.rulesForm.togglerOptions.${targetGroupAttributeTypes.MANAGER}.placeholder`,
            reference: 'admin/managers',
            optionText: (manager) => (manager ? `${manager.name} ${manager.surname}` : ''),
          },
          {
            value: targetGroupAttributeTypes.USER_ID,
            label: translate(`root.rulesForm.togglerOptions.${targetGroupAttributeTypes.USER_ID}.label`),
          },
          {
            value: targetGroupAttributeTypes.ROLE,
            label: translate(`root.rulesForm.togglerOptions.${targetGroupAttributeTypes.ROLE}.label`),
            placeholder: `root.rulesForm.togglerOptions.${targetGroupAttributeTypes.ROLE}.placeholder`,
            optionText: 'id',
          },
          {
            value: targetGroupAttributeTypes.OUTLET_CONTRACTED,
            label: translate(`root.rulesForm.togglerOptions.${targetGroupAttributeTypes.OUTLET_CONTRACTED}.label`),
            placeholder: `root.rulesForm.togglerOptions.${targetGroupAttributeTypes.OUTLET_CONTRACTED}.placeholder`,
            optionText: 'name',
          },
          {
            value: targetGroupAttributeTypes.OUTLET_CONTRACT_TYPE,
            label: translate(`root.rulesForm.togglerOptions.${targetGroupAttributeTypes.OUTLET_CONTRACT_TYPE}.label`),
            placeholder: `root.rulesForm.togglerOptions.${targetGroupAttributeTypes.OUTLET_CONTRACT_TYPE}.placeholder`,
            optionText: 'name',
          },
          {
            value: targetGroupAttributeTypes.USER_VERIFICATION,
            label: translate(`root.rulesForm.togglerOptions.${targetGroupAttributeTypes.USER_VERIFICATION}.label`),
            placeholder: `root.rulesForm.togglerOptions.${targetGroupAttributeTypes.USER_VERIFICATION}.placeholder`,
          },
          {
            value: targetGroupAttributeTypes.REVERSED,
            label: translate(`root.rulesForm.togglerOptions.${targetGroupAttributeTypes.REVERSED}.label`),
            placeholder: `root.rulesForm.togglerOptions.${targetGroupAttributeTypes.REVERSED}.placeholder`,
          },
        ]
      : []),
    ...(ENV_APP_TYPE === APP_TYPES.HUNGARY
      ? [
          {
            value: targetGroupAttributeTypes.REGISTRATION,
            label: translate(`root.rulesForm.togglerOptions.${targetGroupAttributeTypes.REGISTRATION}.label`),
            placeholder: `root.rulesForm.togglerOptions.${targetGroupAttributeTypes.REGISTRATION}.placeholder`,
            reference: 'admin/users',
            optionText: (user) => (user ? `(${user.id}) ${user.name} ${user.surname}` : ''),
          },
        ]
      : []),
  ];

  if (ENV_APP_TYPE === APP_TYPES.IRELAND) {
    formTypeTogglerOptions.push({
      value: targetGroupAttributeTypes.TIER,
      label: translate(`root.rulesForm.togglerOptions.${targetGroupAttributeTypes.TIER}.label`),
      placeholder: `root.rulesForm.togglerOptions.${targetGroupAttributeTypes.TIER}.placeholder`,
      reference: 'admin/tiers',
      optionText: 'name',
    });
  }

  return (
    <SimpleForm {...rest}>
      {children}
      <CustomArrayInput source="rules">
        <FormIterator
          formTypeTogglerOptions={formTypeTogglerOptions}
          renderForm={(v) => renderForm(formTypeTogglerOptions, v)}
          pushNewField={pushNewField}
          formTypeTogglerLabel={translate(`root.rulesForm.formTypeTogglerLabel`)}
        />
      </CustomArrayInput>
    </SimpleForm>
  );
};

export default RulesForm;

import { Button, CircularProgress, ThemeProvider, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { useNotify } from 'react-admin';
import { useHistory } from 'react-router-dom';
import { createTheme } from '@mui/material/styles';
import moment from 'moment';

import {
  roles,
  serverApiUrl,
  tradeProgramAddressesStatus,
  tradeProgramAddressesStatusOptions,
  tradeProgramPhases,
} from '../../../../config/constants';
import { RewardCheckbox } from './RewardCheckbox';
import styles from './UsersTradeProgram.module.scss';
import httpClient from '../../../../core/httpClient';
import checkUserRole from '../../../../helpers/checkUserRole';
import { CustomModal } from './CustomModal';

const theme = createTheme({
  palette: {
    primary: {
      main: '#01c1b6',
    },
  },
});

export const UsersTradeProgram = ({ match }) => {
  const history = useHistory();
  const notify = useNotify();
  const [showDialog, setShowModal] = useState(false);
  const [tradeProgram, setTradeProgram] = useState(null);
  const [users, setUsers] = useState(null);
  const tradeProgramId = match.params.id;
  const addressTradeProgramId = match.params.addressId;

  useEffect(() => {
    updateUsersTradeProgram();
  }, []);

  const getAddressStatusOptions = () =>
    tradeProgramAddressesStatusOptions.find(
      (tradeProgramAddresses) => tradeProgram?.trade_program_addresses_status === tradeProgramAddresses.id,
    );

  const permissionEdit = () => {
    const isDataCollectionPhase = tradeProgram?.phase === tradeProgramPhases.DataCollection;
    const isAssessmentPhase = tradeProgram?.phase === tradeProgramPhases.Assessment;
    const isPausedPhase = tradeProgram?.phase === tradeProgramPhases.Paused;
    const isEndedPhase = tradeProgram?.phase === tradeProgramPhases.Ended;

    if (isDataCollectionPhase && checkUserRole(roles.RSM)) return false;
    if (isAssessmentPhase && checkUserRole(roles.BA, roles.RSM)) return false;
    if (isPausedPhase && checkUserRole(roles.BA, roles.RSM)) return false;
    if (isEndedPhase && checkUserRole(roles.BA, roles.RSM, roles.JTI_TEAM)) return false;

    return true;
  };

  const handelSaveChanges = () => {
    const selectedUsersIds = [];

    users.forEach((user) => {
      if (user.selected === true) selectedUsersIds.push(user.id);
    });

    if (selectedUsersIds.length === 0) {
      return showModal();
    }

    const url = `${serverApiUrl}/admin/trade-program/${tradeProgramId}/addresses/${addressTradeProgramId}`;

    const body = JSON.stringify({ selected_users: selectedUsersIds });
    const options = { method: 'PUT', body };

    httpClient(url, options)
      .then(({ status }) => {
        notify('Changes saved successfully', 'success');
        updateUsersTradeProgram();
      })
      .catch((err) => {
        notify('Changes saved error', 'error');
        console.log(err);
      });
  };

  const selectCheckbox = (selection) => {
    const newUsers = users.map((user) => {
      if (user.id === selection) {
        user.selected = !user.selected;
      }
      return user;
    });

    setUsers(newUsers);
  };

  const updateUsersTradeProgram = () => {
    setUsers(null);
    const url = `${serverApiUrl}/admin/trade-program/${tradeProgramId}/addresses/${addressTradeProgramId}`;
    const options = { method: 'GET' };

    httpClient(url, options)
      .then(({ json: { users_trade_program, trade_program } }) => {
        setUsers(users_trade_program);
        setTradeProgram(trade_program);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    {
      field: 'selected',
      headerName: ' ',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <RewardCheckbox params={params} handleClick={selectCheckbox} isDisabled={!permissionEdit} />
      ),
    },
    { field: 'id', headerName: 'ID', flex: 1, sortable: false },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      sortable: false,
    },
    { field: 'surname', headerName: 'Surname', flex: 1, sortable: false },
    {
      field: 'phone',
      headerName: 'Phone number',
      flex: 1,
      sortable: false,
    },
    {
      field: 'last_transaction_date',
      headerName: 'Last activity date',
      flex: 1,
      sortable: false,
      renderCell: (params) => moment(params.last_transaction_date).format('YYYY-MM-DD'),
    },
  ];

  const handleSubmit = async ({ reason }) => {
    const url = `${serverApiUrl}/admin/trade-program/${tradeProgramId}/addresses/${addressTradeProgramId}/denied`;
    const body = JSON.stringify({ reason });
    const options = { method: 'PUT', body };

    httpClient(url, options)
      .then(() => {
        hideModal();
        notify('Denied status Reason', 'success');
        updateUsersTradeProgram();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const showModal = () => {
    setShowModal(true);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  const Title = () => (
    <div>
      <Typography variant="h5" className={styles.titleContainer}>
        <span>{tradeProgram?.outlet_code}</span>
        <span>{tradeProgram?.legal_name}</span>
        <span>{tradeProgram?.address}</span>
      </Typography>
      <Typography className={styles.descriptionContainer}>
        <span>Sell out plan: {tradeProgram?.sell_out_plan}</span>
        <span>Sell in plan: {tradeProgram?.sell_in_plan}</span>
        <span>
          Program status:
          <span style={{ color: getAddressStatusOptions()?.color }}> {getAddressStatusOptions()?.name}</span>
        </span>
      </Typography>
      {tradeProgram?.trade_program_addresses_status === tradeProgramAddressesStatus.Denied && (
        <div className={styles.reasonContainer}>
          <Typography variant="h5" className={styles.reasonTitle}>
            Denied status Reason:
          </Typography>
          <p className={styles.reasonDescription}>{tradeProgram?.reason_denied}</p>
        </div>
      )}
    </div>
  );

  return (
    <ThemeProvider theme={theme}>
      <div className={styles.viewContainer}>
        {!users ? (
          <div className={styles.spinnerContainer}>
            <CircularProgress />
          </div>
        ) : (
          <div>
            <Title />
            <div className={styles.datagridContainer}>
              <DataGrid
                rows={users}
                hideFooterSelectedRowCount
                columns={columns}
                disableColumnMenu
                autoHeight
                hideFooterPagination
              />

              <div className={styles.buttonsContainer}>
                <Button variant="outlined" onClick={history.goBack}>
                  Back
                </Button>
                {permissionEdit() && (
                  <Button
                    variant="contained"
                    onClick={handelSaveChanges}
                    style={{ color: '#FFFF', marginLeft: '20px' }}
                  >
                    Save changes
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}
        <CustomModal handleCloseClick={hideModal} handleSubmit={handleSubmit} showDialog={showDialog} />
      </div>
    </ThemeProvider>
  );
};

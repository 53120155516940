import React from 'react';
import { Filter, ReferenceInput, TextInput, SelectInput } from 'react-admin';
import { CustomDateInput } from '../../../../commonComponents/CustomDateInput';
import UserAutocompleteInput from '../../../../commonComponents/UserAutocompleteInput';

const SpecialQuizReportsFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput label="Special quizzes" source="pack_id" reference="admin/special-quizzes" alwaysOn>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput label="Topic" source="brand_id" reference="admin/brands" filter={{ positive: true }} alwaysOn>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <UserAutocompleteInput label="User ID" source="user_id" reference="admin/users/autocomplete" alwaysOn />
    <TextInput label="Account code" source="traffic_code" alwaysOn />
    <CustomDateInput label="Choose start date" source="start_date" start alwaysOn />
    <CustomDateInput label="Choose end date" source="end_date" end alwaysOn />
  </Filter>
);

export default SpecialQuizReportsFilter;

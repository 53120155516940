import QuizIcon from '@material-ui/icons/FormatListNumbered';
import QuizzesList from './components/QuizzesList';
import QuizCreate from './components/QuizCreate';
import QuizEdit from './components/QuizEdit';
import { roles, APP_TYPES, CAUCASUS_TYPES } from '../../config/constants';

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: [...CAUCASUS_TYPES, APP_TYPES.BELARUS, APP_TYPES.HUNGARY].includes(process.env.REACT_APP_APP_TYPE)
    ? [roles.ADMIN]
    : [roles.ADMIN, roles.JTI_TEAM],
  label: 'Quizzes',
  subMenuName: 'content',
};

export default {
  list: QuizzesList,
  create: QuizCreate,
  edit: QuizEdit,
  icon: QuizIcon,
  name: 'admin/quizzes',
  options,
};

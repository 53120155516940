import React from 'react';
import { Datagrid, List, ReferenceField, TextField } from 'react-admin';
import { CustomDateField } from '../../../../commonComponents/DateField';
import CustomReportListActions from '../../../../commonComponents/ListActions/CustomReportListActions';

import SalesSimulatorReportsFilter from './SalesSimulatorReportsFilter';

const SalesSimulatorReportsList = (props) => (
  <List
    title="Sales simulator scenarios"
    actions={<CustomReportListActions />}
    filters={<SalesSimulatorReportsFilter />}
    bulkActionButtons={false}
    sort={{}}
    {...props}
  >
    <Datagrid>
      <TextField label="User ID" source="user_id" />
      <ReferenceField label="Simulator" source="scenario.simulator_id" reference="admin/sales-simulators">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Scenario name" source="scenario.id" reference="admin/sales-simulators/scenarios">
        <TextField source="name" />
      </ReferenceField>
      <TextField label="Points" source="transaction.coins" />
      <CustomDateField label="Created at" source="created_at" />
    </Datagrid>
  </List>
);

export default SalesSimulatorReportsList;

import React from 'react';
import { Typography } from '@mui/material';
import { BooleanInput, Create, required, SimpleForm, TextInput } from 'react-admin';
import { CustomDateInput } from '../../../commonComponents/CustomDateInput';
import UploadFileInput from '../../../commonComponents/UploadInputs/UploadFileInput';

const ProgramCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <Typography variant="h5" component="div">
        Create trade program
      </Typography>
      <TextInput label="Name" source="name" validate={required()} />
      <UploadFileInput label={'Import:'} fileInputSource="file_link" buttonText={'Upload file'} validate={required()} />
      <BooleanInput label="Paused" source="paused" />
      <Typography variant="h6" component="div">
        Data collecting phase
      </Typography>
      <CustomDateInput label="Phase start date" source="start_collection_phase" validate={required()} />
      <CustomDateInput label="Phase end date" source="end_collection_phase" validate={required()} />
      <Typography variant="h6" component="div">
        Ended
      </Typography>
      <CustomDateInput label="Phase start date" source="start_ended_phase" validate={required()} />
    </SimpleForm>
  </Create>
);

export default ProgramCreate;

import React from 'react';
import { Datagrid, DeleteButton, EditButton, List, TextField } from 'react-admin';

import RewardTypeField from './RewardTypeField';

const RewardsList = (props) => (
  <List title="Rewards" exporter={false} bulkActionButtons={false} sort={{ field: 'id', order: 'DESC' }} {...props}>
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <TextField label="Name" source="name" />
      <TextField label="Description" source="description" sortable={false} />
      <RewardTypeField label="Type" source="type_id" />
      <TextField label="Done" source="done" />
      <EditButton />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
);

export default RewardsList;

import React from 'react';
import { BooleanField, Datagrid, List, NumberField, TextField, useRefresh } from 'react-admin';
import AttachmentsFilter from './AttachmentsFilter';
import { CustomDateField } from '../../../../commonComponents/DateField';
import ImageField from '../../../../commonComponents/ImageField';
import StatusField from '../../../../commonComponents/StatusField';
import { loyaltyAttachmentStatuses } from '../../../../config/constants';
import ChangeAttachmentStatusButton from './ChangeAttachmentStatusButton';

const AttachmentsList = (props) => {
  const refresh = useRefresh();

  return (
    <List
      title="Loyalty program attachments"
      filters={<AttachmentsFilter />}
      exporter={false}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}
    >
      <Datagrid>
        <NumberField label="Photo ID" source="id" />
        <TextField label="User ID" source="user_id" />
        <CustomDateField label="Date" source="created_at" />
        <ImageField label="Image" source="attachment" />
        <NumberField label="Quantity" source="product_quantity" />
        <BooleanField label="Rewarded" source="rewarded" />
        <StatusField label="Status" source="status" statuses={loyaltyAttachmentStatuses} />
        <ChangeAttachmentStatusButton refreshListCallback={refresh} />
      </Datagrid>
    </List>
  );
};

export default AttachmentsList;

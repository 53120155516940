import React, { useState } from 'react';
import { NumberInput, TextInput } from 'react-admin';
import { useForm } from 'react-final-form';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import _get from 'lodash.get';

const useStyles = makeStyles({
  rewardTypeToggler: {
    flexDirection: 'row',
  },
  rewardTypeTogglerLabel: {
    marginTop: '30px',
  },
  rewardField: {
    width: 256,
  },
});

const setInitialRewardType = (formType, record, firstField, secondField) => {
  if (formType === 'edit') {
    return _get(record, secondField.source) ? secondField.inputLabel : firstField.inputLabel;
  }

  return firstField.inputLabel;
};

const RewardInput = ({ formType, record, inputFields, isFirstFieldPersist }) => {
  const form = useForm();
  const classes = useStyles();
  const firstField = inputFields[0];
  const secondField = inputFields[1];
  const [rewardType, changeRewardType] = useState(setInitialRewardType(formType, record, firstField, secondField));

  const handleChangeRewardType = (e) => {
    const rewardType = e.target.value;

    changeRewardType(rewardType);
    if (rewardType === firstField.inputLabel) {
      form.change(secondField.source, null);
    } else if (!isFirstFieldPersist) {
      form.change(firstField.source, null);
    }
  };

  const renderField = (fieldOptions) => {
    const field =
      fieldOptions.inputType === 'string' ? (
        <TextInput label={fieldOptions.inputLabel} source={fieldOptions.source} className={classes.rewardField} />
      ) : (
        <NumberInput label={fieldOptions.inputLabel} source={fieldOptions.source} className={classes.rewardField} />
      );

    return field;
  };

  return (
    <>
      <Typography className={classes.rewardTypeTogglerLabel}>Choose type of reward</Typography>
      <RadioGroup value={rewardType} onChange={handleChangeRewardType} className={classes.rewardTypeToggler}>
        <FormControlLabel value={firstField.inputLabel} control={<Radio />} label={firstField.radioLabel} />
        <FormControlLabel value={secondField.inputLabel} control={<Radio />} label={secondField.radioLabel} />
      </RadioGroup>
      {/* eslint-disable-next-line no-nested-ternary */}
      {rewardType === firstField.inputLabel ? (
        renderField(firstField)
      ) : isFirstFieldPersist ? (
        <>
          <div>{renderField(secondField)}</div>
          <div>{renderField(firstField)}</div>
        </>
      ) : (
        renderField(secondField)
      )}
    </>
  );
};

export default RewardInput;

import React from 'react';
import { CheckboxGroupInput, Filter, SelectInput, ReferenceInput, TextInput } from 'react-admin';
import UserAutocompleteInput from '../../../../commonComponents/UserAutocompleteInput';
import { loyaltyAttachmentStatuses } from '../../../../config/constants';

const AttachmentsFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput label="Loyalty program" source="program_id" reference="admin/loyalty-programs" alwaysOn>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <UserAutocompleteInput label="User" source="user_id" reference="admin/users/autocomplete" alwaysOn />
    <TextInput label="Account code" source="traffic_code" alwaysOn />
    <SelectInput source="status" optionText="name" choices={loyaltyAttachmentStatuses} alwaysOn />
    <CheckboxGroupInput source="hide_rewarded" choices={[{ id: true, name: 'Hide rewarded' }]} alwaysOn />
  </Filter>
);

export default AttachmentsFilter;

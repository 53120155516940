import React, { useCallback, useEffect, useState } from 'react';
import { SimpleForm, Title, useNotify, useTranslate, useUpdateLoading } from 'react-admin';
import { Card, CardContent, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import httpClient from '../../../core/httpClient';
import formatNumber from '../../../helpers/numberFormatter';
import { isIreland } from '../../../helpers/appTypeHelper';
import { CustomDateInput } from '../../../commonComponents/CustomDateInput';
import { APP_TYPES, CAUCASUS_TYPES, serverApiUrl } from '../../../config/constants';
import StatisticsItem from './StatisticsItem';

import { ReactComponent as AndroidSVG } from '../../../icons/Android.svg';
import DefaultImage from '../../../icons/Image.svg';
import { ReactComponent as IosSVG } from '../../../icons/IOS.svg';
import { ReactComponent as QuestionSVG } from '../../../icons/Question.svg';

const ENV_APP_TYPE = process.env.REACT_APP_APP_TYPE;
const isHungary = ENV_APP_TYPE === APP_TYPES.HUNGARY;
const isCaucasus = CAUCASUS_TYPES.includes(ENV_APP_TYPE);
const startDates = {
  [APP_TYPES.HUNGARY]: '2018-06-01',
  [APP_TYPES.IRELAND]: '2019-08-22',
  [APP_TYPES.GEORGIA]: '2020-07-13',
  [APP_TYPES.ARMENIA]: '2020-07-20',
};

const getStartDates = () => startDates[ENV_APP_TYPE];

const useStyles = makeStyles({
  container: {
    maxWidth: '100%',
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    borderBottomStyle: 'solid',
    borderBottomColor: '#F2F2F2',
    paddingBottom: '30px',
  },

  marginLeft80: {
    marginLeft: 80,
  },

  sectionTitle: {
    marginTop: '40px',
    fontSize: '22px',
    fontWeight: 'bold',
  },

  correctAverage: {
    fontSize: '22px',
  },

  numbers: {
    fontSize: '22px',
  },

  title: {
    paddingTop: '10px',
    fontSize: '12px',
  },

  description: {
    fontSize: '12px',
    color: '#B1B1B1',
  },

  icons: {
    width: '15px',
    height: '13px',
  },

  paddingLeft5: {
    paddingLeft: '5px',
  },

  paddingLeft10: {
    paddingLeft: '10px',
  },

  paddingLeft15: {
    paddingLeft: '15px',
  },

  marginTop5: {
    marginTop: '5px',
  },

  simpleForm: {
    '& div': {
      display: 'flex',
    },
    display: 'flex',
    justifyContent: 'space-between',
  },

  datesContainer: {
    display: 'flex',
  },

  datePicker: {
    flex: 1,
    margin: '0 10px',
    width: 200,
  },

  images: {
    maxWidth: '74px',
    height: '35px',
    objectFit: 'contain',
  },

  displayInline: {
    display: 'inline',
  },
});

const StatisticsLayout = ({ resource }) => {
  const notify = useNotify();
  const classes = useStyles();
  const translate = useTranslate();
  const { startLoading, stopLoading } = useUpdateLoading();
  const [endDate, setEndDate] = useState(undefined);
  const [startDate, setStartDate] = useState(undefined);
  const [usersData, setUsersData] = useState({ database: {} });
  const [generalData, setGeneralData] = useState({ generalActivity: {} });
  const [brandsData, setBrandsData] = useState({ brandActivity: {} });
  const [brandsKnowledgeData, setBrandsKnowledgeData] = useState({
    brandKnowledge: {
      specialQuizzes: [
        {
          logo: DefaultImage,
          correctAverage: null,
        },
        {
          logo: DefaultImage,
          correctAverage: null,
        },
        {
          logo: DefaultImage,
          correctAverage: null,
        },
        {
          logo: DefaultImage,
          correctAverage: null,
        },
        {
          logo: DefaultImage,
          correctAverage: null,
        },
      ],
      generalQuizzes: [
        {
          logo: DefaultImage,
          correctAverage: null,
        },
        {
          logo: DefaultImage,
          correctAverage: null,
        },
        {
          logo: DefaultImage,
          correctAverage: null,
        },
        {
          logo: DefaultImage,
          correctAverage: null,
        },
        {
          logo: DefaultImage,
          correctAverage: null,
        },
      ],
      quickTips: [
        {
          logo: DefaultImage,
          correctAverage: null,
        },
        {
          logo: DefaultImage,
          correctAverage: null,
        },
        {
          logo: DefaultImage,
          correctAverage: null,
        },
        {
          logo: DefaultImage,
          correctAverage: null,
        },
        {
          logo: DefaultImage,
          correctAverage: null,
        },
      ],
    },
  });
  const [promoCodesData, setPromoCodesData] = useState({ promoCodes: {} });
  const [costsData, setCostsData] = useState({ costs: {} });

  const getDateFilter = useCallback(() => {
    if (startDate && endDate) {
      // prettier-ignore
      return `?start_date=${startDate}&end_date=${endDate}`;
    }
    if (startDate) {
      return `?start_date=${startDate}`;
    }
    if (endDate) {
      return `?end_date=${endDate}`;
    }
    return '';
  }, [startDate, endDate]);

  useEffect(() => {
    let loadedSections = 0;
    const dateFilter = getDateFilter();

    const checkLoadingProgress = (progress) => {
      if (progress >= 6) {
        stopLoading();
      }
    };

    const getStatisticsData = async (part = '') => {
      const url = `${serverApiUrl}/admin/statistics${part}${dateFilter}`;
      const options = { method: 'GET' };

      return httpClient(url, options);
    };

    startLoading();
    getStatisticsData('/users')
      .then((response) => {
        setUsersData(response.json);
        checkLoadingProgress((loadedSections += 1));
      })
      .catch((e) => notify(`GET users data error: ${e.message}`, 'warning'));
    getStatisticsData('/general')
      .then((response) => {
        setGeneralData(response.json);
        checkLoadingProgress((loadedSections += 1));
      })
      .catch((e) => notify(`GET general data error: ${e.message}`, 'warning'));
    getStatisticsData('/brand-knowledge')
      .then((response) => {
        setBrandsKnowledgeData(response.json);
        checkLoadingProgress((loadedSections += 1));
      })
      .catch((e) => notify(`GET brands data error: ${e.message}`, 'warning'));
    getStatisticsData('/brands')
      .then((response) => {
        setBrandsData(response.json);
        checkLoadingProgress((loadedSections += 1));
      })
      .catch((e) => notify(`GET users data error: ${e.message}`, 'warning'));
    getStatisticsData('/promocodes')
      .then((response) => {
        setPromoCodesData(response.json);
        checkLoadingProgress((loadedSections += 1));
      })
      .catch((e) => notify(`GET users data error: ${e.message}`, 'warning'));
    getStatisticsData('/costs')
      .then((response) => {
        setCostsData(response.json);
        checkLoadingProgress((loadedSections += 1));
      })
      .catch((e) => notify(`GET users data error: ${e.message}`, 'warning'));
  }, [startDate, endDate, getDateFilter, notify, startLoading, stopLoading]);

  return (
    <Card>
      <Title title={translate(`resources.${resource}.name`)} />
      <CardContent>
        <div>
          <div>
            <div className={classes.simpleForm}>
              <Typography className={classes.sectionTitle} style={{ color: '#8A4B9E' }}>
                {translate(`root.statistics.Database`)}
              </Typography>
              <SimpleForm toolbar={null} className={classes.datesContainer} resource={resource}>
                <CustomDateInput
                  source="start_date"
                  resource={resource}
                  minDate={getStartDates()}
                  maxDate={endDate}
                  className={classes.datePicker}
                  onChange={(value) => setStartDate(value || undefined)}
                  start
                />
                <CustomDateInput
                  source="end_date"
                  resource={resource}
                  minDate={getStartDates()}
                  className={classes.datePicker}
                  onChange={(value) => setEndDate(value || undefined)}
                  end
                />
              </SimpleForm>
            </div>
            <div className={classes.container}>
              <div>
                <Typography className={classes.numbers}>{formatNumber(usersData.database.totalContacts)}</Typography>
                <Typography className={classes.title}>{translate(`root.statistics.Total contacts`)}</Typography>
              </div>
              <div className={classes.marginLeft80}>
                <Typography className={classes.numbers}>{formatNumber(usersData.database.underModeration)}</Typography>
                <Typography className={classes.title}>{translate(`root.statistics.Under Moderation`)}</Typography>
              </div>
              <div className={classes.marginLeft80}>
                <Typography className={classes.numbers}>{formatNumber(usersData.database.totalDownloads)}</Typography>
                <Typography className={classes.title}>{translate(`root.statistics.Total downloads`)}</Typography>
                <div className={classes.marginTop5}>
                  <Tooltip title="IOS downloads" placement="top">
                    <span>
                      <IosSVG className={classes.icons} />
                      <Typography className={`${classes.paddingLeft5} ${classes.displayInline}`}>
                        {formatNumber(usersData.database.ios)}
                      </Typography>
                    </span>
                  </Tooltip>
                  <Tooltip title="Android downloads" placement="top">
                    <span className={classes.paddingLeft15}>
                      <AndroidSVG className={classes.icons} />
                      <Typography className={`${classes.paddingLeft5} ${classes.displayInline}`}>
                        {formatNumber(usersData.database.android)}
                      </Typography>
                    </span>
                  </Tooltip>
                  <Tooltip title="Unknown downloads" placement="top">
                    <span className={classes.paddingLeft10}>
                      <QuestionSVG className={classes.icons} />
                      <Typography className={classes.displayInline}>
                        {formatNumber(usersData.database.unknownOs)}
                      </Typography>
                    </span>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <Typography className={classes.sectionTitle} style={{ color: '#FFAC30' }}>
                {translate(`root.statistics.General activity`)}
              </Typography>
            </div>
            <div className={classes.container}>
              <div>
                <StatisticsItem
                  data={generalData.generalActivity.completedTasks}
                  title={translate('root.statistics.Challenge')}
                  description={translate('root.statistics.challenge_description')}
                />
              </div>
              {!isCaucasus && (
                <div className={classes.marginLeft80}>
                  <StatisticsItem
                    data={generalData.generalActivity.numberOfBets}
                    title={translate('root.statistics.Chance')}
                    description={translate('root.statistics.chance_description')}
                  />
                </div>
              )}
              {!isCaucasus && (
                <div className={classes.marginLeft80}>
                  <StatisticsItem
                    data={generalData.generalActivity.userQuestions}
                    title={translate('root.statistics.Daily question')}
                    description={translate('root.statistics.daily_question_description')}
                  />
                </div>
              )}
              <div className={classes.marginLeft80}>
                <StatisticsItem
                  data={generalData.generalActivity.head2headQuizzes}
                  title={translate('root.statistics.Head to head quizzes')}
                  description={translate('root.statistics.h2h_quiz_description')}
                />
              </div>
              {!isCaucasus && (
                <div className={classes.marginLeft80}>
                  <StatisticsItem
                    data={generalData.generalActivity.soloQuizzes}
                    title={translate('root.statistics.Solo quizzes')}
                    description={translate('root.statistics.solo_quiz_description')}
                  />
                </div>
              )}
              {isHungary && (
                <>
                  <div className={classes.marginLeft80}>
                    <StatisticsItem
                      data={generalData.generalActivity.singleMode}
                      title={translate('root.statistics.Single mode')}
                      description={translate('root.statistics.single_mode_description')}
                    />
                  </div>
                  <div className={classes.marginLeft80}>
                    <StatisticsItem
                      data={generalData.generalActivity.eventMode}
                      title={translate('root.statistics.Event mode')}
                      description={translate('root.statistics.event_mode_description')}
                    />
                  </div>
                </>
              )}
              {(isHungary || isCaucasus) && (
                <div className={classes.marginLeft80}>
                  <StatisticsItem
                    data={generalData.generalActivity.survey}
                    title={translate('root.statistics.Survey')}
                    description={translate('root.statistics.survey_description')}
                  />
                </div>
              )}
              {/* <div className={classes.marginLeft80}> */}
              {/*  <StatisticsItem */}
              {/*    data={generalData.generalActivity.completedLoyaltyPrograms} */}
              {/*    title="Logic Loyalty program" */}
              {/*    description="times completed" */}
              {/*  /> */}
              {/* </div> */}
            </div>
            <div>
              <div>
                <Typography className={classes.sectionTitle} style={{ color: '#ED8D7D' }}>
                  {translate('root.statistics.Brand activity')}
                </Typography>
              </div>
              <div className={classes.container}>
                <div>
                  <StatisticsItem
                    data={brandsData.brandActivity.articlesRead}
                    title={translate('root.statistics.Articles')}
                    description={translate('root.statistics.articles_description')}
                  />
                </div>
                {!isCaucasus && (
                  <div className={classes.marginLeft80}>
                    <StatisticsItem
                      data={brandsData.brandActivity.gamesPlayed}
                      title={translate('root.statistics.Games')}
                      description={translate('root.statistics.games_description')}
                    />
                  </div>
                )}
                <div className={classes.marginLeft80}>
                  <StatisticsItem
                    data={brandsData.brandActivity.specialQuizzesPlayed}
                    title={translate('root.statistics.Special quizzes')}
                    description={translate('root.statistics.special_quiz_description')}
                  />
                </div>
                {isHungary && (
                  <div className={classes.marginLeft80}>
                    <StatisticsItem
                      data={brandsData.brandActivity.multibrandQuizzesPlayed}
                      title={translate('root.statistics.Multibrand quiz')}
                      description={translate('root.statistics.multibrand_quiz_description')}
                    />
                  </div>
                )}
              </div>
            </div>
            {!isCaucasus && (
              <>
                <div>
                  <div>
                    <Typography className={classes.sectionTitle} style={{ color: '#01C1B6' }}>
                      {translate('root.statistics.Brand knowledge - Special Quizzes')}
                    </Typography>
                  </div>
                  <div className={classes.container}>
                    {brandsKnowledgeData.brandKnowledge.specialQuizzes.map((specialQuiz, index) => (
                      <div className={index === 0 ? '' : classes.marginLeft80} key={`special-${index}`}>
                        <img className={classes.images} src={specialQuiz.logo} alt="Brand logo" />
                        <Typography className={classes.correctAverage}>{`${formatNumber(
                          specialQuiz.correctAverage,
                        )}%`}</Typography>
                        <Typography className={classes.description}>
                          {translate('root.statistics.brand_knowledge_description')}
                        </Typography>
                      </div>
                    ))}
                  </div>
                </div>
                {!isCaucasus && (
                  <div>
                    <div>
                      <Typography className={classes.sectionTitle} style={{ color: '#8A4B9E' }}>
                        {translate('root.statistics.Brand knowledge - General Quizzes')}
                      </Typography>
                    </div>
                    <div className={classes.container}>
                      {brandsKnowledgeData.brandKnowledge.generalQuizzes.map((generalQuiz, index) => (
                        <div className={index === 0 ? '' : classes.marginLeft80} key={`general-${index}`}>
                          <img className={classes.images} src={generalQuiz.logo} alt="Brand logo" />
                          <Typography className={classes.correctAverage}>{`${formatNumber(
                            generalQuiz.correctAverage,
                          )}%`}</Typography>
                          <Typography className={classes.description}>
                            {translate('root.statistics.brand_knowledge_description')}
                          </Typography>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {isIreland() && (
                  <div>
                    <div>
                      <Typography className={classes.sectionTitle} style={{ color: '#01C1B6' }}>
                        {translate('root.statistics.Brand knowledge - Quick Tips')}
                      </Typography>
                    </div>
                    <div className={classes.container}>
                      {brandsKnowledgeData.brandKnowledge.quickTips.map((specialQuiz, index) => (
                        <div className={index === 0 ? '' : classes.marginLeft80} key={`special-${index}`}>
                          <img className={classes.images} src={specialQuiz.logo} alt="Brand logo" />
                          <Typography className={classes.correctAverage}>{`${formatNumber(
                            specialQuiz.correctAverage,
                          )}%`}</Typography>
                          <Typography className={classes.description}>
                            {translate('root.statistics.brand_knowledge_description')}
                          </Typography>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </>
            )}
            <div>
              <div>
                <Typography className={classes.sectionTitle} style={{ color: '#66A3FF' }}>
                  {translate('root.statistics.Costs')}
                </Typography>
              </div>
              <div className={classes.container}>
                <div>
                  <StatisticsItem
                    data={costsData.costs.pointsEarned}
                    title={translate('root.statistics.Credits earned')}
                    description={translate('root.statistics.credits_earned_description')}
                  />
                </div>
                {!isHungary && (
                  <div className={classes.marginLeft80}>
                    <StatisticsItem
                      data={costsData.costs.moneyEarned}
                      title={translate('root.statistics.Currency earned')}
                      description={translate('root.statistics.currency_earned_description')}
                    />
                  </div>
                )}
                <div className={classes.marginLeft80}>
                  <StatisticsItem
                    data={costsData.costs.pointsSpent}
                    title={translate('root.statistics.Credits spent')}
                    description={translate('root.statistics.credits_spent_description')}
                  />
                </div>
                {!isHungary && (
                  <>
                    <div className={classes.marginLeft80}>
                      <StatisticsItem
                        data={costsData.costs.allOrders}
                        title={translate('root.statistics.Orders')}
                        description={translate('root.statistics.orders_description')}
                      />
                    </div>
                    <div className={classes.marginLeft80}>
                      <StatisticsItem
                        data={costsData.costs.ordersCost}
                        title={translate('root.statistics.Orders cost')}
                        description={translate('root.statistics.orders_costs_description')}
                      />
                    </div>
                  </>
                )}
                {isHungary && (
                  <>
                    <div className={classes.marginLeft80}>
                      <StatisticsItem
                        data={costsData.costs.physicalOrders}
                        title={translate('root.statistics.Physical orders')}
                        description={translate('root.statistics.physical_orders_description')}
                      />
                    </div>
                    <div className={classes.marginLeft80}>
                      <StatisticsItem
                        data={costsData.costs.digitalOrders}
                        title={translate('root.statistics.Digital orders')}
                        description={translate('root.statistics.digital_orders_description')}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div>
              <div>
                <Typography className={classes.sectionTitle} style={{ color: '#FFBFBF' }}>
                  {translate(`root.statistics.Promo codes`)}
                </Typography>
              </div>
              <div className={classes.container} style={{ borderBottom: 'none' }}>
                <div>
                  <StatisticsItem
                    data={promoCodesData.promoCodes.promoCodesActivated}
                    title={translate('root.statistics.Promo codes activated')}
                    description={translate('root.statistics.promocode_activated_description')}
                  />
                </div>
                <div className={classes.marginLeft80}>
                  <StatisticsItem
                    data={promoCodesData.promoCodes.promoPointsEarned}
                    title={translate('root.statistics.Promo codes credits earned')}
                    description={translate('root.statistics.promocode_credits_earned_description')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default StatisticsLayout;

export const fileUploadStyles = {
  image: {
    maxHeight: '3rem',
  },
  removeButton: {
    float: 'left',
    display: 'inline-block',
    position: 'relative',
    paddingRight: '48px',
    '& button': {
      top: 8,
      right: 8,
      opacity: 0,
      position: 'absolute',
      minWidth: 16,
    },
    '&:hover button': {
      opacity: 1,
    },
  },
  dropZone: {
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    display: 'inline-block',
    padding: '0.5rem',
    margin: '20px 10px 20px 0',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.24)',
    },
    '& + .previews': {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
  },
  dropZoneNoMargin: {
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    display: 'inline-block',
    padding: '0.5rem',
    margin: 0,
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.24)',
    },
    '& + .previews': {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
  },
};

import React from 'react';
import { AutocompleteInput, ReferenceInput } from 'react-admin';

function formatText(city) {
  const text = city?.name;

  if (city?.id) {
    return `(${city.id}) ${text}`;
  }

  return text;
}

const CityAutocompleteInput = (props) => {
  const { label, source = 'city_id', reference = 'admin/cities/autocomplete', ...otherProps } = props;

  return (
    <ReferenceInput label={label} source={source} reference={reference} allowEmpty {...otherProps}>
      <AutocompleteInput
        optionText={formatText}
        options={{
          suggestionsContainerProps: {
            style: { zIndex: 1301 },
          },
        }}
      />
    </ReferenceInput>
  );
};

export default CityAutocompleteInput;

import SpecialQuizIcon from '@material-ui/icons/FormatAlignCenter';
import ManagersActivityReportsList from './components/ManagersActivityReportsList';
import { APP_TYPES, roles } from '../../../config/constants';

const options = {
  appTypes: [APP_TYPES.HUNGARY],
  allowedRoles: [roles.ADMIN],
  label: 'Activity managers',
  subMenuName: 'reports',
};

export default {
  list: ManagersActivityReportsList,
  icon: SpecialQuizIcon,
  name: 'admin/reports/managers-activity',
  options,
};

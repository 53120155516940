import React from 'react';
import { List, Datagrid, TextField, EditButton, DeleteButton, ReferenceField } from 'react-admin';
import CustomListActions from '../../../commonComponents/ListActions/CustomListActions';
import { independentItemEntityTypes } from '../config/constants';

const EntitySubtypeField = (props) => {
  switch (props.record.entity_type) {
    case independentItemEntityTypes.quiz:
      return <TextField {...props} />;

    case independentItemEntityTypes.kbArticle:
      return (
        <ReferenceField reference="admin/knowledge-bank/categories" {...props}>
          <TextField source="name" />
        </ReferenceField>
      );

    default:
      return null;
  }
};

const IndependentItemsList = (props) => (
  <List
    title="Independent Items"
    bulkActionButtons={false}
    actions={<CustomListActions />}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField source="id" />
      <TextField source="entity_type" />
      <EntitySubtypeField source="entity_subtype" />
      <TextField source="entity_id" label="Entity Id" />
      <EditButton />
      <DeleteButton mutationMode="optimistic" />
    </Datagrid>
  </List>
);

export default IndependentItemsList;

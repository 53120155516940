import React, { useState } from 'react';
import { Datagrid, List, TextField } from 'react-admin';
import CustomListActions from '../../../../commonComponents/ListActions/CustomListActions';

import ImagesForUser from './customActions/ImagesForUser';
import AnswersForUser from './customActions/AnswersForUser';
import TaskStatusForUser from './customActions/TaskStatusForUser';
import { CustomDateField } from '../../../../commonComponents/DateField';
import AttachmentsFilter from './AttachmentsFilter';
import { isHungary, isIreland } from '../../../../helpers/appTypeHelper';

const ImagesForUserWrapper = ({ record, ...props }) => (
  <ImagesForUser questId={record.quest.id} userId={record.userId} showButton={record.withImages} {...props} />
);
const TextForUserWrapper = ({ record, ...props }) => (
  <AnswersForUser
    questId={record.quest.id}
    userId={record.userId}
    showButton={record.withAnswers}
    tasks={record.tasks}
    {...props}
  />
);

const AttachmentsList = (props) => {
  const [tasks, setTasks] = useState(null);

  const hasTeamChallenge = isHungary() || isIreland();

  const statuses = {
    accept: 1,
    decline: 0,
  };

  return (
    <List
      title="Challenge Attachments"
      filters={<AttachmentsFilter tasks={tasks} />}
      bulkActionButtons={false}
      actions={<CustomListActions importer={false} />}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}
    >
      <Datagrid>
        <TextField label="Admin Panel Name" source="quest.admin_panel_name" sortable={false} />
        <TextField label="Name" source="quest.name" sortable={false} />
        <TextField label="User Id" source="userId" sortable={false} />
        <CustomDateField label="Challenge end date" source="quest.end_date" sortable={false} />
        <TaskStatusForUser label="Tasks" tasks={tasks} setTasksForFilter={setTasks} />
        {hasTeamChallenge && <TextForUserWrapper label="Answers" statuses={statuses} />}
        <ImagesForUserWrapper label="Images" source="withImages" sortable={true} statuses={statuses} tasks={tasks} />
      </Datagrid>
    </List>
  );
};

export default AttachmentsList;

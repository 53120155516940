import QuizProductQuestion from '@material-ui/icons/Notes';
import { APP_TYPES, roles } from '../../../config/constants';
import ProductQuestionsReportsList from './components/ProductQuestionsReportsList';

const options = {
  appTypes: [APP_TYPES.IRELAND],
  allowedRoles: [roles.ADMIN, roles.JTI_TEAM],
  label: 'Product Questions',
  subMenuName: 'reports',
};

export default {
  list: ProductQuestionsReportsList,
  icon: QuizProductQuestion,
  name: 'admin/reports/product-questions',
  options,
};

import React from 'react';
import DescriptionIcon from '@material-ui/icons/Description';
import { useForm } from 'react-final-form';
import { FileInput, useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import httpClient from '../../core/httpClient';
import { s3FilePaths, serverApiUrl } from '../../config/constants';
import { fileUploadStyles } from './styles';

const useStyles = makeStyles(fileUploadStyles);

const UploadFileInput = ({
  label = '',
  buttonText = 'Upload File',
  fileInputSource,
  filePreviewSource = 'file',
  filePath = s3FilePaths.files,
  ...rest
}) => {
  const form = useForm();
  const notify = useNotify();
  const classes = useStyles();

  const uploadFile = (file) => {
    const formData = new FormData();
    formData.append('file', file[0]);
    formData.append('file_path', filePath);
    const url = `${serverApiUrl}/admin/services/file`;
    const options = { method: 'POST', body: formData };

    httpClient(url, options)
      .then((res) => {
        form.change(fileInputSource, res.json.file_key);
      })
      .catch(() => {
        notify("Error: File wasn't sent", 'warning');
      });
  };

  return (
    <FileInput
      classes={{ dropZone: classes.dropZone, removeButton: classes.removeButton, root: classes.root }}
      source={fileInputSource}
      label={label}
      placeholder={<span>{buttonText}</span>}
      options={{ onDropAccepted: uploadFile }}
      {...rest}
    >
      <DescriptionIcon style={{ fontSize: 40 }} source={filePreviewSource} />
    </FileInput>
  );
};

export default UploadFileInput;

import React from 'react';
import { Typography } from '@material-ui/core';

const ChatCompanionInfo = ({ companion }) => (
  <div>
    <div>
      <Typography>
        <b>{`${companion.name} ${companion.surname}`}</b>
      </Typography>
    </div>
    <div>
      <Typography variant="body2">{`${companion.phone} (${companion.id})`}</Typography>
    </div>
  </div>
);

export default ChatCompanionInfo;

import React from 'react';
import { List } from 'react-admin';
import CustomReportListActions from '../../../../commonComponents/ListActions/CustomReportListActions';

import TransactionReportsFilter from './TransactionReportsFilter';
import { TransactionReportsDataGrid } from './TransactionReportsDataGrid';

const TransactionReportsList = (props) => (
  <List
    title="Transaction reports"
    actions={<CustomReportListActions />}
    filters={<TransactionReportsFilter />}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <TransactionReportsDataGrid />
  </List>
);

export default TransactionReportsList;

import TaxesIcon from '@material-ui/icons/Toll';
import TaxesReportsList from './components/TaxesReportsList';
import { APP_TYPES, roles } from '../../../config/constants';

const options = {
  appTypes: [APP_TYPES.BELARUS],
  allowedRoles: [roles.ADMIN],
  label: 'Taxes',
  subMenuName: 'reports',
};

export default {
  list: TaxesReportsList,
  icon: TaxesIcon,
  name: 'admin/reports/taxes',
  options,
};

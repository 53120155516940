import React from 'react';
import { ColorInput } from 'react-admin-color-input';
import { Edit, NumberInput, required, SimpleForm, TextInput } from 'react-admin';
import ArrayField from '../../../commonComponents/ArrayField';
import UploadFileInput from '../../../commonComponents/UploadInputs/UploadFileInput';

const TierEdit = (props) => (
  <Edit title="Tiers Edit" undoable={false} {...props}>
    <SimpleForm redirect="list">
      <TextInput label="ID" source="id" validate={required()} disabled />
      <TextInput label="Name" source="name" validate={required()} />
      <NumberInput label="Discount - %" source="discount" validate={required()} />
      <UploadFileInput fileInputSource="outlet_codes_file" label="Outlet codes" />
      <ColorInput source="color" defaultValue="#FFFFFF" validate={required()} />
      <ArrayField label="Outlets" source="outlets" />
    </SimpleForm>
  </Edit>
);

export default TierEdit;

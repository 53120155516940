import React from 'react';
import { Edit, NumberInput, ReferenceInput, SelectInput, SimpleForm, TextInput } from 'react-admin';
import EditViewToolbarWithoutDelete from '../../../../commonComponents/EditViewToolbarWithoutDelete';
import ImageInput from '../../../../commonComponents/UploadInputs/ImageInput';
import { s3FilePaths } from '../../../../config/constants';

const CategoryEdit = (props) => (
  <Edit title="Edit Category" undoable={false} {...props}>
    <SimpleForm toolbar={<EditViewToolbarWithoutDelete />}>
      <TextInput label="ID" source="id" />
      <TextInput label="Name" source="name" />
      <ImageInput imageInputSource="photo" imagePath={s3FilePaths.allUploads} />
      <ReferenceInput label="Transaction Type" source="transaction_type_id" reference="admin/transactions/types">
        <SelectInput />
      </ReferenceInput>
      <NumberInput label="Priority" source="priority" />
    </SimpleForm>
  </Edit>
);

export default CategoryEdit;

import React from 'react';
import CustomExportButton from '../../../../commonComponents/ListActions/CustomExportButton';

const ExportMysteryShopperButton = ({ record }) => {
  const { id } = record;

  return <CustomExportButton filter={{ mystery_shopper_id: id }} />;
};

export default ExportMysteryShopperButton;

import React, { useState, useEffect } from 'react';
import { useNotify } from 'react-admin';

import httpClient from '../../../../core/httpClient';
import { serverApiUrl } from '../../../../config/constants';
import RegisteredUsersLinearDiagram from './RegisteredUsersLinearDiagram';

const getAdminData = async (query = '') => {
  const url = `${serverApiUrl}/admin/outlets-users/registered-users-statistic?period_type=${query}`;
  const options = { method: 'GET' };

  return httpClient(url, options);
};

const LinearDiagramContainer = () => {
  const notify = useNotify();

  const [usersData, setUsersData] = useState([]);
  const [periodType, setPeriodType] = useState();

  useEffect(() => {
    const getQuery = () => {
      let query = 'ALL';
      if (periodType) {
        query = `${periodType}`;
      }
      return query;
    };

    const query = getQuery();

    getAdminData(query)
      .then(({ json }) => {
        setUsersData(json);
      })
      .catch((e) => {
        notify(`GET Admin data error: ${e.message}`, 'warning');
      });
  }, [notify, periodType]);

  const handlePeriodChange = (event) => {
    setPeriodType(event.target.value);
  };

  return <RegisteredUsersLinearDiagram categories={usersData} handlePeriodChange={handlePeriodChange} />;
};

export default LinearDiagramContainer;

import PromoCodesIcon from '@material-ui/icons/AttachMoney';
import PromoCodesList from './components/PromoCodesList';
import { APP_TYPES, roles } from '../../../config/constants';

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: [roles.ADMIN, roles.JTI_TEAM],
  label: 'Promo Codes',
  subMenuName: 'reports',
};

export default {
  list: PromoCodesList,
  icon: PromoCodesIcon,
  name: 'admin/reports/promo-codes',
  options,
};

import React from 'react';
import { Datagrid, List, TextField } from 'react-admin';
import { CustomDateField } from '../../../../commonComponents/DateField';
import CustomReportListActions from '../../../../commonComponents/ListActions/CustomReportListActions';
import { isAzerbaijan, isGeorgia } from '../../../../helpers/appTypeHelper';

import BonusShopTransfersReportsFilter from './BonusShopTransfersReportsFilter';

const areShopsShown = isGeorgia() || isAzerbaijan();

const BonusShopTransfersReportsList = (props) => (
  <List
    title="Bonus shop transfers reports"
    actions={<CustomReportListActions />}
    filters={<BonusShopTransfersReportsFilter />}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField label="Id" source="id" />
      <TextField label="User ID" source="userId" />
      <TextField label="Phone" source="phone" />
      <TextField label="Name" source="name" />
      <TextField label="Surname" source="surname" />
      <TextField label="Msisdn" source="msisdn" />
      <TextField label="Amount" source="amount" />
      <TextField label="Status" source="status" />
      <TextField label="Transactions id" source="transaction_id" />
      <CustomDateField label="Created at" source="created_at" />
      <TextField label="Outlet code" source="outlet_code" />
      {areShopsShown && <TextField label="Shop" source="shop" />}
    </Datagrid>
  </List>
);

export default BonusShopTransfersReportsList;

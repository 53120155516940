import React from 'react';
import { useRefresh } from 'react-admin';
import { Avatar, Card, CardContent, CardHeader, Typography } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/AccountCircle';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import { CustomDateField } from '../../../commonComponents/DateField';
import { serverApiUrl } from '../../../config/constants';
import httpClient from '../../../core/httpClient';

import ChatCompanionInfo from '../../../commonComponents/ChatCompanionInfo';

const cardContentInnerPadding = '8px';
const useStyles = makeStyles({
  root: {
    borderRadius: 0,
    cursor: 'pointer',
    '&:hover': {
      color: 'white',
      backgroundColor: '#428bca',
    },
    '&:hover .chat-card_hovered': {
      color: 'white',
    },
  },
  cardContent: {
    wordBreak: 'break-all',
    '&:last-child': {
      paddingBottom: cardContentInnerPadding,
    },
  },
  selectedChat: {
    backgroundColor: '#dfdfdf',
  },
  cardMarksSection: {
    float: 'right',
    position: 'relative',
    width: 25,
  },
  chatMarkIcon: {
    position: 'absolute',
    top: 10,
    left: 0,
    right: 0,
    margin: 'auto',
    color: '#000',
  },
  markedChat: {
    color: '#01c1b6',
  },
  unMarkedChat: {
    color: '#bdbdbd',
  },
  unreadMessagesMark: {
    width: 15,
    height: 15,
    position: 'absolute',
    top: 40,
    left: 0,
    right: 0,
    margin: 'auto',
    borderRadius: '50%',
  },
  alreadyReadMessageColor: {
    background: '#bdbdbd',
  },
  notReadMessageColor: {
    background: 'red',
  },
  paddingY8: {
    paddingTop: cardContentInnerPadding,
    paddingBottom: cardContentInnerPadding,
  },
});

const chatMessageFormatter = (msg) => {
  const message = JSON.parse(msg);
  const imageText = message.image?.url ? `(Image: ${message.image.url})` : '';
  const linkText = message.link ? `(Link: ${message.link})` : '';
  const messageText = `${
    message.text.length > 30 ? message.text.slice(0, 30).concat('...') : message.text
  } ${imageText} ${linkText}`;

  return messageText;
};

const ChatCard = ({ record, handleChatClick, rememberThatChatWasOpened, chatsWhichWereOpened, selectedChatId }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const dateFormat = {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };
  const isChatSelected = selectedChatId === record.id;
  const isChatHasUnreadMessages = !!record.friend_unread && !chatsWhichWereOpened.includes(record.id);
  const isChatMarkedUnread = record.unread;
  const isUnreadDisplayed = isChatMarkedUnread || isChatHasUnreadMessages;

  const changeReadStatus = () => {
    const url = `${serverApiUrl}/admin/chats/read`;
    const data = {
      chat_id: record.id,
      unread: record.unread ? 0 : 1,
    };
    const options = { method: 'POST', body: JSON.stringify(data) };

    httpClient(url, options)
      .then(() => {
        refresh();
      })
      .catch((err) => {
        console.error('Chat read status change failed', err);
      });
  };

  const handleMarkClick = (e) => {
    e.stopPropagation();
    const url = `${serverApiUrl}/admin/chats/mark`;
    const data = {
      chat_id: record.id,
      marked: record.marked ? 0 : 1,
    };
    const options = { method: 'POST', body: JSON.stringify(data) };

    httpClient(url, options)
      .then(() => {
        refresh();
      })
      .catch((err) => {
        console.error('Chat mark failed', err);
      });
  };

  const handleReadClick = (e) => {
    e.stopPropagation();
    if (!isChatMarkedUnread && isChatHasUnreadMessages) {
      rememberThatChatWasOpened();
    } else {
      changeReadStatus();
    }
  };

  const onChatClick = () => {
    if (record.unread) {
      changeReadStatus();
    }
    rememberThatChatWasOpened();
    handleChatClick();
  };

  return (
    <Card
      onClick={onChatClick}
      raised
      classes={{ root: classes.root }}
      className={isChatSelected ? classes.selectedChat : ''}
    >
      <div className={classes.cardMarksSection}>
        <BookmarkIcon
          onClick={handleMarkClick}
          className={`${classes.chatMarkIcon} ${record.marked ? classes.markedChat : classes.unMarkedChat}`}
        />
        <div
          onClick={handleReadClick}
          className={`${classes.unreadMessagesMark} ${
            isUnreadDisplayed ? classes.notReadMessageColor : classes.alreadyReadMessageColor
          }`}
        />
      </div>
      <CardHeader
        className={classes.paddingY8}
        titleTypographyProps={{ color: 'inherit' }}
        title={<ChatCompanionInfo companion={record.companion} />}
        subheader={
          <CustomDateField
            className="chat-card_hovered"
            record={record}
            source="last_message_date"
            options={dateFormat}
          />
        }
        avatar={
          <Avatar>
            <PersonIcon />
          </Avatar>
        }
      />
      <CardContent className={`${classes.cardContent} ${classes.paddingY8}`}>
        <Typography>{record.last_message ? chatMessageFormatter(record.last_message) : ''}</Typography>
      </CardContent>
    </Card>
  );
};

export default ChatCard;

import React from 'react';
import Typography from '@material-ui/core/Typography';
import { statuses as defaultStatuses, APP_TYPES } from '../config/constants';

const ENV_APP_TYPE = process.env.REACT_APP_APP_TYPE;

const StatusField = ({ record = {}, source, statuses = defaultStatuses }) => {
  const statusId = source.split('.').reduce((o, k) => o && o[k], record);
  const status = statuses.find((i) => i.id === statusId);
  const statusName = (status && status.name) || statuses[0].name;

  const styles = {};
  styles.color =
    status && [APP_TYPES.IRELAND, APP_TYPES.HUNGARY, APP_TYPES.BELARUS].includes(ENV_APP_TYPE) && status.color;

  return (
    <Typography style={styles} variant="body2">
      {statusName}
    </Typography>
  );
};

export default StatusField;

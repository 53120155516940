import React from 'react';
import { Button, useDataProvider, useRefresh } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/NotInterested';

const useStyles = makeStyles({
  disabledButton: {
    color: 'grey',
  },
  activeButtons: {
    color: 'red',
  },
  revertButtonLabel: {
    paddingLeft: '0.5em',
  },
});

const OrderRevertButton = ({ record, resource }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const isOrderRejected = record.status === 4;

  const handleButtonClick = (e) => {
    e.stopPropagation();
    const { id, price } = record;
    const data = { price, order_id: id };
    dataProvider.create(`${resource}/revert`, { data }).then(() => {
      refresh();
    });
  };

  return (
    <Button
      onClick={handleButtonClick}
      disabled={isOrderRejected}
      className={isOrderRejected ? classes.disabledButton : classes.activeButtons}
    >
      <>
        <CancelIcon />
        <div className={classes.revertButtonLabel}>REVERT</div>
      </>
    </Button>
  );
};

export default OrderRevertButton;

import React from 'react';
import { Filter, ReferenceInput, SelectInput } from 'react-admin';
import { APP_TYPES } from '../../../config/constants';

const TargetGroupFilter = (props) => (
  <Filter {...props}>
    {process.env.REACT_APP_APP_TYPE === APP_TYPES.IRELAND && (
      <ReferenceInput source="tier_id" reference="admin/tiers" alwaysOn>
        <SelectInput source="name" />
      </ReferenceInput>
    )}
  </Filter>
);

export default TargetGroupFilter;

import React from 'react';
import { DeleteButton, EditButton, useRecordContext } from 'react-admin';
import { roles, tradeProgramPhases } from '../../../config/constants';
import OpenButton from '../../../commonComponents/OpenButton';
import checkUserRole from '../../../helpers/checkUserRole';

export const RowActionToolbar = ({ openButtonHandel, ...props }) => {
  const record = useRecordContext();

  const isAssessment = record.phase === tradeProgramPhases.Assessment;
  const isPaused = record.phase === tradeProgramPhases.Paused;

  const isOpenButton = !(checkUserRole(roles.BA) && (isAssessment || isPaused));
  const isEditButton = checkUserRole(roles.ADMIN, roles.JTI_TEAM);
  const isDeleteButton = checkUserRole(roles.ADMIN);

  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      {isOpenButton && <OpenButton handleClick={openButtonHandel} {...props} />}
      {isEditButton && <EditButton {...props} />}
      {isDeleteButton && <DeleteButton undoable={false} {...props} />}
    </div>
  );
};

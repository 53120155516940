import React from 'react';
import { CreateButton, TopToolbar } from 'react-admin';
import CustomExportButton from './CustomExportButton';
import CustomImportButton from './CustomImportButton';

const CustomListActions = ({
  bulkActions,
  basePath,
  displayedFilters,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  exporter,
  creation = true,
  importer = true,
}) => (
  <TopToolbar>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    {creation && <CreateButton basePath={basePath} />}
    {exporter && <CustomExportButton filter={filterValues} />}
    {importer && <CustomImportButton resource={resource} />}
  </TopToolbar>
);

export default CustomListActions;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Create, ReferenceInput, required, SelectInput, SimpleForm } from 'react-admin';
import { CustomDateInput } from '../../../../commonComponents/CustomDateInput';
import CouponForm from './CouponForm';
import { couponFormStyles } from './styles';

const useStyles = makeStyles(couponFormStyles);

const CouponCreate = (props) => {
  const classes = useStyles();

  return (
    <Create title="Add Coupon" {...props}>
      <SimpleForm redirect="list">
        <CouponForm />
        <CustomDateInput label="Expired at" source="expired_at" validate={required()} />
        <ReferenceInput
          label="Digital product"
          source="product_id"
          reference="admin/bonus-shop/products"
          className={classes.productInput}
        >
          <SelectInput optionText="description" validate={required()} />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export default CouponCreate;

import React from 'react';
import { Edit } from 'react-admin';

import BotChancesForm from './BotChancesForm';

const BotChancesEdit = (props) => (
  <Edit undoable={false} {...props}>
    <BotChancesForm {...props} redirect="list" />
  </Edit>
);

export default BotChancesEdit;

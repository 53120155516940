import React from 'react';
import { List, Datagrid, TextField, EditButton, required, ReferenceField, NumberField } from 'react-admin';
import { ColorField } from 'react-admin-color-input';

const InteractiveList = (props) => (
  <List
    title="Interactives"
    exporter={false}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <TextField label="Article ID" source="article_id" />

      <ReferenceField
        source="article_id"
        label="Article"
        reference={`admin/knowledge-bank/articles/autocomplete`}
        perPage={10000}
        link={false}
        validate={required()}
      >
        <TextField label="Name" source="name" />
      </ReferenceField>
      <ColorField source="background_color" />

      <NumberField label="Coins" source="coins" />
      <EditButton />
    </Datagrid>
  </List>
);

export default InteractiveList;

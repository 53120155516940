import TradeProgramIcon from '@material-ui/icons/AssignmentTurnedIn';
import TradeProgramsCreate from './components/TradeProgramsCreate';
import TradeProgramsList from './components/TradeProgramsList';
import TradeProgramsEdit from './components/TradeProgramsEdit';
import { allRoles, APP_TYPES } from '../../config/constants';

const options = {
  appTypes: [APP_TYPES.BELARUS, APP_TYPES.ARMENIA, APP_TYPES.AZERBAIJAN, APP_TYPES.GEORGIA],
  allowedRoles: allRoles,
};

export default {
  list: TradeProgramsList,
  create: TradeProgramsCreate,
  edit: TradeProgramsEdit,
  icon: TradeProgramIcon,
  name: 'admin/trade-program',
  options,
};

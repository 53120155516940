import decodeToken from 'jwt-decode';

export default (...roles) => {
  const token = localStorage.getItem('token');
  if (!token) {
    return false;
  }
  const decodedToken = decodeToken(token);

  return decodedToken.roles && roles.some((role) => decodedToken.roles.includes(role));
};

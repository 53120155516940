import React, { useEffect, useState } from 'react';
import { Button, useNotify, useRefresh, useUpdateLoading } from 'react-admin';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@material-ui/core';
import IconClose from '@material-ui/icons/Close';
import IconDelete from '@material-ui/icons/Delete';
import IconImage from '@material-ui/icons/Image';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import ImageField from '../../../../../commonComponents/ImageField';
import { serverApiUrl } from '../../../../../config/constants';
import httpClient from '../../../../../core/httpClient';
import { isHungary, isIreland } from '../../../../../helpers/appTypeHelper';

const ImagesForUser = ({ questId, userId, showButton, statuses, tasks }) => {
  const notify = useNotify();
  const [isOpen, setOpenStatus] = useState(false);
  const [userImages, setUserImages] = useState([]);
  const { startLoading, stopLoading } = useUpdateLoading();
  const refresh = useRefresh();

  const hasTeamChallenge = isHungary() || isIreland();

  useEffect(() => {
    if (isOpen && userImages.length === 0) {
      const url = `${serverApiUrl}/admin/quests/user-images?user_id=${userId}&quest_id=${questId}`;
      const options = {
        method: 'GET',
      };
      httpClient(url, options)
        .then((res) => {
          setUserImages(res.json);
          if (res.json.length === 0) {
            setOpenStatus(false);
          }
        })
        .catch(() => {
          notify("Images weren't downloaded successfully", 'warning');
        });
    }
  }, [isOpen, userImages, userId, questId, notify, tasks]);

  const handleOpenModal = (e) => {
    e.stopPropagation();
    setOpenStatus(true);
  };

  const handleModalClose = () => {
    setOpenStatus(false);
  };

  const handleDeleteImage = async (i) => {
    const url = `${serverApiUrl}/admin/quests/user-images?user_id=${userId}&quest_id=${questId}`;
    const options = {
      method: 'DELETE',
      body: JSON.stringify({
        attach_url: userImages[i].attach_url,
      }),
    };

    startLoading();
    await httpClient(url, options)
      .then(() => {
        notify('Image was deleted successfully', 'success');
        setUserImages([]);
        refresh();
      })
      .catch(() => {
        notify("Image wasn't deleted successfully", 'warning');
      })
      .finally(() => {
        stopLoading();
      });
  };

  const handleAnswerStatus = async (status) => {
    const customTask = tasks.find((task) => task.type === 6);
    const url = `${serverApiUrl}/admin/quests/approval?user_id=${userId}&quest_id=${questId}`;
    const options = {
      method: 'PUT',
      body: JSON.stringify({
        status,
        taskType: 'CUSTOM',
        taskId: customTask.id,
      }),
    };

    await httpClient(url, options)
      .then(() => {
        notify('Answer status was changed successfully', 'success');
      })
      .then(() => {
        refresh();
      })
      .catch(() => {
        notify("Answer status wasn't changed successfully", 'warning');
      });
  };

  return (
    <>
      {showButton && (
        <Button onClick={handleOpenModal}>
          <IconImage />
        </Button>
      )}
      <Dialog open={isOpen} onClick={(e) => e.stopPropagation()} onClose={handleModalClose}>
        <DialogTitle>User Images</DialogTitle>
        <DialogContent>
          <Grid container direction="row" justify="center" alignItems="center">
            {userImages.map((image, i) => (
              <Grid key={`grid-${i}`} container direction="row" justify="space-between" alignItems="center">
                <Grid item>
                  <ImageField src={image.attach_url} />
                </Grid>
                <Grid item>
                  {hasTeamChallenge && (
                    <IconButton onClick={() => handleAnswerStatus(statuses.accept)} color="secondary">
                      <CheckIcon />
                    </IconButton>
                  )}
                  {hasTeamChallenge && (
                    <IconButton onClick={() => handleAnswerStatus(statuses.decline)} color="secondary">
                      <ClearIcon />
                    </IconButton>
                  )}
                  <IconButton onClick={() => handleDeleteImage(i)} color="secondary">
                    <IconDelete />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button label="ra.action.close" onClick={handleModalClose}>
            <IconClose />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ImagesForUser;

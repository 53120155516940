import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles({
  componentContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 48,
    marginBottom: 20,
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  diagramTitle: {
    marginBottom: 10,
    fontSize: 12,
    lineHeight: '14px',
    position: 'relative',
  },
  linearProgress: {
    width: '100%',
    height: 16,
  },
  colorPrimary: {
    backgroundColor: '#F2F2F2',
  },

  infoOutlineIcon: {
    color: '#30D5C8',
    fontSize: '19px',
    marginLeft: '5px',
    position: 'absolute',
    top: '-2px',
  },
});

const LinearDiagram = (props) => {
  const classes = useStyles();
  const { title, value = 0, barColorClass } = props;

  return (
    <div className={`${classes.componentContainer}`}>
      <span className={classes.diagramTitle}>{title}</span>
      <div className={classes.contentContainer}>
        <LinearProgress
          className={classes.linearProgress}
          variant="determinate"
          value={value}
          classes={{
            colorPrimary: classes.colorPrimary,
            barColorPrimary: barColorClass,
          }}
        />
      </div>
    </div>
  );
};

export default LinearDiagram;

import {
  ArrayInput,
  Edit,
  NumberInput,
  ReferenceInput,
  required,
  maxLength,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

import { s3FilePaths } from '../../../config/constants';
import { mysteryShopperStatus } from '../config/constants';
import { CustomDateInput } from '../../../commonComponents/CustomDateInput';
import ImageInput from '../../../commonComponents/UploadInputs/ImageInput';
import Criteria from './Criteria';

const MysteryShopperEdit = (props) => (
  <Edit title="Edit Mystery Shopper" {...props}>
    <SimpleForm redirect="list">
      <TextInput label="Id" source="id" disabled />
      <TextInput label="Name" source="name" validate={[required(), maxLength(39)]} />
      <CustomDateInput label="Start date" source="start_date" validate={required()} start />
      <SelectInput label="Status" source="status" choices={mysteryShopperStatus} validate={required()} />
      <CustomDateInput label="End date" source="end_date" validate={required()} end />
      <ReferenceInput label="Brands" reference={'admin/brands'} source={'brand_id'}>
        <SelectInput optionText="name" validate={required()} />
      </ReferenceInput>
      <NumberInput label="Reward coefficient" source="reward_coefficient" validate={required()} />
      <ReferenceInput
        label="Target group"
        source="target_group_id"
        reference="admin/target-groups"
        perPage={10000}
        allowEmpty={false}
        emptyValue={null}
      >
        <SelectInput optionText="name" validate={required()} />
      </ReferenceInput>
      <ImageInput
        label="Mystery Shopper background"
        imageInputSource="background_image"
        imagePath={s3FilePaths.mysteryShopper}
        validate={required()}
      />
      <CustomDateInput label="Result page shown until" source="result_page_date_to" validate={required()} end />
      <ArrayInput label="Add criteria" source="criterias" validate={required()}>
        <Criteria />
      </ArrayInput>
    </SimpleForm>
  </Edit>
);

export default MysteryShopperEdit;

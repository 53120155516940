import React from 'react';
import { List, Datagrid, TextField, NumberField, BooleanField } from 'react-admin';
import { ColorField } from 'react-admin-color-input';

const BrandsList = (props) => (
  <List title="Brands" exporter={false} bulkActionButtons={false} sort={{ field: 'id', order: 'ASC' }} {...props}>
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <TextField label="Name" source="name" sortable={false} />
      <TextField label="Description" source="description" sortable={false} />
      <NumberField label="Priority" source="priority" />
      <BooleanField label="Visible" source="active" />
      <ColorField source="text_color" />
    </Datagrid>
  </List>
);

export default BrandsList;

import React from 'react';
import { BooleanInput, NumberInput, required, TextInput } from 'react-admin';
import { ColorInput } from 'react-admin-color-input';

import { mainScreenBoxComponentTypes, s3FilePaths } from '../../../../config/constants';
import withCardWrapper from '../../hocs/withCardWrapper';
import ImageInput from '../../../../commonComponents/UploadInputs/ImageInput';

const getFieldName = (member, name) => {
  if (!member) {
    return name;
  }

  return `${member}.${name}`;
};

const getReferenceInput = (member, type) => {
  let fieldLabel = '';
  let fieldSourceName;
  switch (type) {
    case mainScreenBoxComponentTypes.quest:
      return null;
    case mainScreenBoxComponentTypes.lottery:
      return null;
    case mainScreenBoxComponentTypes.kb_article:
      fieldLabel = 'KB Article ID';
      fieldSourceName = 'knowledge_bank_article_id';
      break;
    case mainScreenBoxComponentTypes.special_quiz:
      fieldLabel = 'Special Quiz ID';
      fieldSourceName = 'pack_id';
      break;
    default:
      throw new Error(`Unsupported type for Box component(TypeId = ${type}).`);
  }

  return <NumberInput label={fieldLabel} source={getFieldName(member, fieldSourceName)} validate={required()} />;
};

const BoxComponent = ({ member, type }) => (
  <>
    <TextInput label="Name" source={getFieldName(member, 'name')} validate={required()} />
    <TextInput source={getFieldName(member, 'navigation_title')} label={'Menu title'} />
    <TextInput label="Description" source={getFieldName(member, 'description')} />
    <ColorInput label="Text color" source={getFieldName(member, 'text_color')} validate={required()} />
    <ColorInput label="Background color" source={getFieldName(member, 'background_color')} validate={required()} />
    <ColorInput label="Background color (to)" source={getFieldName(member, 'to_background_color')} />
    <NumberInput
      label={'Background Opacity'}
      source={getFieldName(member, 'from_background_opacity')}
      min={0}
      max={100}
    />
    <NumberInput
      label={'Background Opacity (to)'}
      source={getFieldName(member, 'to_background_opacity')}
      min={0}
      max={100}
    />
    <ColorInput label="Border color" source={getFieldName(member, 'border_color')} />
    {getReferenceInput(member, type)}
    <NumberInput label="Priority" source={getFieldName(member, 'priority')} />
    <BooleanInput label="Visible" source={getFieldName(member, 'visible')} />
    <ImageInput label="Icon" imagePath={s3FilePaths.allUploads} imageInputSource={getFieldName(member, 'icon')} />
  </>
);

export default withCardWrapper(BoxComponent);

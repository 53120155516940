import React from 'react';
import {
  BooleanInput,
  Create,
  FormDataConsumer,
  minValue,
  NumberInput,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
} from 'react-admin';
import { CustomDateInput } from '../../../commonComponents/CustomDateInput';
import { isIreland } from '../../../helpers/appTypeHelper';

const PromoCodeCreateToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton transform={({ custom_code, count, ...data }) => ({ ...data, count: custom_code ? 1 : count })} />
  </Toolbar>
);

const PromoCodeCreate = (props) => (
  <Create title="Create Promo code" {...props}>
    <SimpleForm redirect="list" toolbar={<PromoCodeCreateToolbar />}>
      <BooleanInput label="Custom promo code" source="custom_code" />
      <FormDataConsumer>
        {({ formData }) =>
          formData.custom_code ? (
            <TextInput label="Custom code" source="customCode" />
          ) : (
            <NumberInput label="Number of codes" source="count" defaultValue={1} validate={[minValue(1), required()]} />
          )
        }
      </FormDataConsumer>
      <NumberInput label="Promo code coins" source="coins" validate={required()} />
      <BooleanInput label="Can use multiple times" source="multiply" />
      {isIreland() && (
        <BooleanInput
          style={{ width: '300px' }}
          label="Included in the Progress bar"
          source="progress_bar_enable"
          defaultValue={true}
        />
      )}
      <CustomDateInput label="Expires at" source="expires_at" />
    </SimpleForm>
  </Create>
);

export default PromoCodeCreate;

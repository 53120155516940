import React from 'react';
import { Filter, ReferenceInput, SelectInput } from 'react-admin';

const CouponsFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput label="Product" source="product_id" reference="admin/bonus-shop/products" alwaysOn>
      <SelectInput optionText="description" />
    </ReferenceInput>
  </Filter>
);

export default CouponsFilter;

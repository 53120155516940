import React from 'react';
import { BooleanField, Datagrid, List, TextField } from 'react-admin';
import { CustomDateField } from '../../../../commonComponents/DateField';
import CustomReportListActions from '../../../../commonComponents/ListActions/CustomReportListActions';
import { APP_TYPES } from '../../../../config/constants';

import DailyQuestionsReportsFilter from './DailyQuestionsReportsFilter';

const DailyQuestionsReportsList = (props) => (
  <List
    title="Daily questions reports"
    actions={<CustomReportListActions />}
    filters={<DailyQuestionsReportsFilter />}
    bulkActionButtons={false}
    {...props}
  >
    <Datagrid>
      <TextField label="ID" source="id" />
      <TextField label="Question ID" source="question_id" />
      <TextField label="Question Text" source="question_text" />
      <TextField label="Profile name" source="user_name" sortable={false} />
      <TextField label="User phone" source="user_phone" sortable={false} />
      <BooleanField label="Success" source="success" sortable={false} />
      <CustomDateField label="Date" source="question_created_at" sortable={false} />
      {process.env.REACT_APP_APP_TYPE === APP_TYPES.BELARUS && (
        <TextField label="Внешний код торговой точки" source="account_code" />
      )}
    </Datagrid>
  </List>
);

export default DailyQuestionsReportsList;

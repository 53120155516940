import React from 'react';
import { Datagrid, DeleteButton, EditButton, List, TextField, DateField } from 'react-admin';
import CustomListActions from '../../../commonComponents/ListActions/CustomListActions';
import DuplicateButton from '../../../commonComponents/DuplicateButton';

const DailyQuestionsList = (props) => {
  const duplicateRecord = (record) => {
    const { id, ...mappedRecord } = record;
    const answers = mappedRecord.answers.sort((a, b) => a.id - b.id).map((answer) => ({ answer: answer.answer }));
    mappedRecord.answers = answers;

    return mappedRecord;
  };

  return (
    <List
      title="Questions of the day"
      exporter={false}
      bulkActionButtons={false}
      actions={<CustomListActions />}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}
    >
      <Datagrid rowClick="edit">
        <TextField label="ID" source="id" />
        <TextField label="Question text" source="text" />
        <DateField label="Active at" source="active_at" />
        <EditButton />
        <DuplicateButton copyRecord={duplicateRecord} />
        <DeleteButton undoable={false} />
      </Datagrid>
    </List>
  );
};

export default DailyQuestionsList;

import React, { useContext } from 'react';
import { BooleanInput, Button, TextInput, useNotify, useRefresh, useUpdateLoading } from 'react-admin';
import { Form } from 'react-final-form';
import SendIcon from '@material-ui/icons/Send';

import ImageInput from '../../../../commonComponents/UploadInputs/ImageInput';
import { s3FilePaths, serverApiUrl } from '../../../../config/constants';
import httpClient from '../../../../core/httpClient';
import { TicketMessagesContext } from '../../context';

import styles from './SendMessage.module.scss';

export const SendMessage = ({ companionId, ticketId }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const { startLoading, stopLoading } = useUpdateLoading();
  const { pushNewMessage } = useContext(TicketMessagesContext);

  const getMessageParams = ({ messageText, image, link, caption }) => {
    const url = `${serverApiUrl}/admin/messages/send`;
    const data = {
      message: {
        admin: 1,
        bottom_text: '',
        brand_id: 0,
        image: image || null,
        link: link || '',
        text: messageText,
        caption: caption || '',
      },
      type: 4,
      user_id: companionId,
      ticket_id: ticketId,
    };
    const options = { method: 'POST', body: JSON.stringify(data) };

    return { url, options };
  };

  const handleSubmit = async (formData) => {
    const { url, options } = getMessageParams(formData);

    try {
      startLoading();
      const { json } = await httpClient(url, options);
      pushNewMessage(json);
      refresh();
    } catch (e) {
      notify("Error: Message wasn't sent", 'warning');
      console.error(e);
    } finally {
      stopLoading();
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      render={({ form, handleSubmit, pristine, submitting, values }) => (
        <form onSubmit={(event) => handleSubmit(event).then(form.reset)}>
          <div className={styles.sendMessage}>
            <TextInput label="Caption" fullWidth multiline name="caption" />
            <TextInput label="Message" fullWidth multiline name="messageText" placeholder="Type a message..." />

            <div className={styles.buttonsBlock}>
              <div className={styles.leftBlock}>
                <div className={styles.linkAttachment}>
                  <BooleanInput label="Add link" source="addLink" helperText={false} />
                  {values.addLink && <TextInput label="Link" source="link" helperText={false} />}
                </div>

                <Button
                  className={styles.sendButton}
                  label="Send"
                  type="submit"
                  alignIcon="right"
                  size="medium"
                  variant="contained"
                  disabled={submitting || pristine || !values.messageText}
                >
                  <SendIcon />
                </Button>
              </div>

              <div>
                <ImageInput
                  imageInputSource="image"
                  imagePreviewSource="url"
                  helperText={false}
                  imagePath={s3FilePaths.chat}
                />
              </div>
            </div>
          </div>
        </form>
      )}
    />
  );
};

import React from 'react';
import { BooleanField, Datagrid, List, TextField } from 'react-admin';

import { CustomDateField } from '../../../../commonComponents/DateField';

const TransactionsList = (props) => (
  <List
    title="Transactions"
    exporter={false}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField label="ID" source="id" />
      <TextField label="User Id" source="user_id" />
      <CustomDateField label="Created at" source="created_at" />
      <TextField label="Status" source="status" />
      <TextField label="Type" source="type_model.name" />
      <TextField label="Points" source="coins" />
      <BooleanField label="Monthly earned increase" source="in_monthly_earned" />
    </Datagrid>
  </List>
);

export default TransactionsList;

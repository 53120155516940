import React from 'react';
import { Create, SimpleForm, ReferenceInput, SelectInput, required } from 'react-admin';
import UserAutocompleteInput from '../../../../commonComponents/UserAutocompleteInput';

const presentsOptionText = (present) => {
  const text = `${present.name} (price: ${present.price}, count: ${present.count})`;
  return text;
};

const OrderCreate = (props) => (
  <Create title="Create order" {...props}>
    <SimpleForm redirect="list">
      <ReferenceInput label="Presents" source="product_id" reference="admin/bonus-shop/presents">
        <SelectInput optionText={presentsOptionText} validate={required()} />
      </ReferenceInput>
      <UserAutocompleteInput validate={required()} />
    </SimpleForm>
  </Create>
);

export default OrderCreate;

export const mysteryShopperQuestionTypes = {
  multiplyChoiceQuestion: 1,
  singleChoiceQuestion: 2,
  priceQuestion: 3,
  openQuestion: 4,
  addPhotoQuestion: 5,
};

export const mysteryShopperFormTypeTogglerOptions = [
  { value: mysteryShopperQuestionTypes.multiplyChoiceQuestion, label: 'Multiple choice' },
  { value: mysteryShopperQuestionTypes.singleChoiceQuestion, label: 'Single choice' },
  { value: mysteryShopperQuestionTypes.priceQuestion, label: 'Price question' },
  { value: mysteryShopperQuestionTypes.openQuestion, label: 'Open question' },
  { value: mysteryShopperQuestionTypes.addPhotoQuestion, label: 'Add photo' },
];

export const mysteryShopperStatus = [
  { id: 0, name: 'Not active' },
  { id: 1, name: 'Active' },
];

export const attachmentsStatus = {
  incorrect: 0,
  correct: 1,
  on_moderation: 2,
};

export const attachmentsStatusChoices = [
  { id: attachmentsStatus.incorrect, name: 'Incorrect answer' },
  { id: attachmentsStatus.correct, name: 'Correct answer' },
  { id: attachmentsStatus.on_moderation, name: 'On moderation' },
];

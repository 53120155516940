import PromoCodeIcon from '@material-ui/icons/AttachMoney';
import PromoCodesList from './components/PromoCodesList';
import PromoCodeCreate from './components/PromoCodeCreate';
import PromoCodeEdit from './components/PromoCodeEdit';
import { roles, APP_TYPES, CAUCASUS_TYPES } from '../../config/constants';

const ENV_APP_TYPE = process.env.REACT_APP_APP_TYPE;

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: [APP_TYPES.HUNGARY, APP_TYPES.BELARUS, ...CAUCASUS_TYPES].includes(ENV_APP_TYPE)
    ? [roles.ADMIN]
    : [roles.ADMIN, roles.JTI_TEAM],
  label: 'Promo codes',
  subMenuName: 'content',
};

export default {
  list: PromoCodesList,
  create: PromoCodeCreate,
  edit: PromoCodeEdit,
  icon: PromoCodeIcon,
  name: 'admin/promo-codes',
  options,
};

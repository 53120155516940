import React from 'react';
import ModalImage from 'react-modal-image';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  imageContainer: {
    display: 'inline-block',
    marginRight: '10px',
  },
  imageSmall: {
    maxHeight: ({ height }) => `${height}rem !important`,
  },
});

const ImageField = ({ record, source, src, height = 3 }) => {
  const classes = useStyles({ height });
  const imageSource = src || source.split('.').reduce((o, k) => o && o[k], record);

  return (
    <div key={imageSource} onClick={(e) => e.stopPropagation()} className={classes.imageContainer}>
      <ModalImage
        small={imageSource}
        large={imageSource}
        className={classes.imageSmall}
        imageBackgroundColor="transparent"
        alt=""
      />
    </div>
  );
};

export default ImageField;

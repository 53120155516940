import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import ChatCard from './ChatCard';
import { ChatCardsSectionScrollContext } from '../context';

const styles = {
  chatCards: {
    overflowY: 'auto',
  },
};

// Rewriting this with hooks is pain, further reading
// https://blog.logrocket.com/how-is-getsnapshotbeforeupdate-implemented-with-hooks/
class ChatCards extends React.Component {
  constructor(props) {
    super(props);
    this.chatCardsListRef = React.createRef();
    this.state = {
      chatsWhichWereOpened: [],
    };
  }

  static contextType = ChatCardsSectionScrollContext;

  getSnapshotBeforeUpdate(prevProps, _) {
    if (prevProps.ids[0] !== this.props.ids[0]) {
      const list = this.chatCardsListRef.current;
      return list.scrollTop;
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { chatCardsSectionScrollPosition } = this.context;

    if (chatCardsSectionScrollPosition || snapshot) {
      const list = this.chatCardsListRef.current;
      list.scrollTop = snapshot || chatCardsSectionScrollPosition;
      this.context.keepScrollPosition(null);
    }
  }

  componentWillUnmount() {
    const list = this.chatCardsListRef.current;
    this.context.keepScrollPosition(list.scrollTop);
  }

  rememberThatChatWasOpened = (id) => () => {
    this.setState({ chatsWhichWereOpened: [...this.state.chatsWhichWereOpened, id] });
  };

  render() {
    const { handleChatClick } = this.context;
    const { classes, ids, data, selectedChatId } = this.props;

    return (
      <div ref={this.chatCardsListRef} className={classes.chatCards}>
        {ids.map((id) => (
          <ChatCard
            key={id}
            record={data[id]}
            selectedChatId={selectedChatId}
            rememberThatChatWasOpened={this.rememberThatChatWasOpened(id)}
            handleChatClick={handleChatClick(id)}
            chatsWhichWereOpened={this.state.chatsWhichWereOpened}
          />
        ))}
      </div>
    );
  }
}

export default withStyles(styles)(ChatCards);

import { SimpleForm, TextInput } from 'react-admin';
import React from 'react';
import { ColorInput } from 'react-admin-color-input';
import { s3FilePaths } from '../../../config/constants';
import ImageInput from '../../../commonComponents/UploadInputs/ImageInput';

function UpsertThemeForm({ type, ...props }) {
  const isEdit = type === 'edit';

  return (
    <SimpleForm {...props} redirect="list">
      {isEdit && <TextInput label="ID" source="id" disabled />}
      <TextInput source={'name'} label={'Name'} />
      <ImageInput label="Upload a logo" imageInputSource="logo" imagePath={s3FilePaths.allUploads} />
      <ImageInput
        label="Upload background image"
        imageInputSource="background_image"
        imagePath={s3FilePaths.allUploads}
      />
      <ColorInput source={'background_color'} label={'Background color'} />
      <ColorInput source={'text_color'} label={'Text color'} />
      <ColorInput source={'primary_color'} label={'Primary color'} />
      <ColorInput source={'secondary_color'} label={'Secondary color'} />
      <ColorInput source={'accent_color'} label={'Accent color'} />
    </SimpleForm>
  );
}

export default UpsertThemeForm;

import React from 'react';
import CustomArrayInput from '../../../../commonComponents/CustomArrayInput';
import FormIterator from '../../../../core/FormIterator';
import { mainScreenModuleComponentTypeToggleOptions } from '../../../../config/constants';
import ModuleComponent from './ModuleComponent';

const pushNewField = ({ fields, nextFormType }) => {
  fields.push({ type: { id: nextFormType } });
};

const renderForm = ({ field, member }) => <ModuleComponent member={member} type={field.type.id} />;

const BoxComponentForm = React.memo(({ member }) => (
  <>
    <CustomArrayInput source={`${member}.module_components`} label="Module components" defaultValue={[]}>
      <FormIterator
        formTypeTogglerLabel="Module component type"
        formTypeTogglerOptions={mainScreenModuleComponentTypeToggleOptions}
        pushNewField={pushNewField}
        renderForm={renderForm}
      />
    </CustomArrayInput>
  </>
));

export default BoxComponentForm;

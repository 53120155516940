import LeaderboardIcon from '@material-ui/icons/PlusOne';
import LeaderBoardReportsList from './components/LeaderBoardReportsList';
import { roles, APP_TYPES, CAUCASUS_TYPES } from '../../../config/constants';

const appType = process.env.REACT_APP_APP_TYPE;

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: CAUCASUS_TYPES.includes(appType) ? [roles.ADMIN, roles.JTI_TEAM] : [roles.ADMIN],
  label: 'Leaderboard',
  subMenuName: 'reports',
};

export default {
  list: LeaderBoardReportsList,
  icon: LeaderboardIcon,
  name: 'admin/reports/rating',
  options,
};

import React from 'react';
import { Edit, SimpleForm, BooleanInput, TextInput, SelectInput } from 'react-admin';
import { statuses } from '../../../config/constants';
import { CustomDateInput } from '../../../commonComponents/CustomDateInput';
import { isIreland } from '../../../helpers/appTypeHelper';

const PromoCodeEdit = (props) => (
  <Edit title="Edit Promo code" undoable={false} {...props}>
    <SimpleForm>
      <TextInput label="ID" source="id" disabled />
      <TextInput label="Code" source="code" disabled />
      <TextInput label="Coins" source="coins" />
      <SelectInput source="status" optionText="name" choices={statuses.slice(0, 2)} />
      <BooleanInput label="Can use multiple times" source="multiply" />
      {isIreland() && (
        <BooleanInput style={{ width: '300px' }} label="Included in the Progress bar" source="progress_bar_enable" />
      )}
      <CustomDateInput label="Expires at" source="expires_at" />
    </SimpleForm>
  </Edit>
);

export default PromoCodeEdit;

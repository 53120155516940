import React from 'react';
import {
  ArrayInput,
  BooleanInput,
  Edit,
  FormDataConsumer,
  NumberInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import RewardInput from '../../../commonComponents/RewardInput';
import TargetGroupInput from '../../../commonComponents/TargetGroupInput';
import ImageInput from '../../../commonComponents/UploadInputs/ImageInput';
import { s3FilePaths, statuses } from '../../../config/constants';
import Reward from './Reward';

const typeOfChance = [
  { id: 1, name: 'Number of Winners' },
  { id: 2, name: 'Probability of Winning' },
];

const rewardInputFields = [
  { inputLabel: 'Prize', inputType: 'string', radioLabel: 'Prizes', source: 'product_name' },
  { inputLabel: 'Coins', inputType: 'number', radioLabel: 'Coins', source: 'coins' },
];

const IrelandChanceEdit = (props) => (
  <Edit title="Edit Chance" undoable={false} {...props}>
    <SimpleForm redirect="list">
      <SelectInput label="Type of Chance" source="type" optionText="name" choices={typeOfChance} disabled={true} />
      <FormDataConsumer>
        {({ formData }) => (
          <>
            {formData.type && <TextInput label="Name" source="name" />}
            <br />
            {formData.type && <TextInput label="Description" source="description" />}
            <br />
            {formData.type === 1 && <NumberInput label="Total" source="total" />}
            <br />
            {formData.type && <TextInput label="Cost" source="cost" />}
            {formData.type && (
              <BooleanInput
                style={{ width: '300px' }}
                label="Included in the Progress bar"
                source="progress_bar_enable"
              />
            )}
            {formData.type === 1 && <RewardInput formType="create" inputFields={rewardInputFields} />}
            <br />
            {formData.type && <SelectInput source="status" optionText="name" choices={statuses.slice(0, 2)} />}
            <br />
            {formData.type === 1 && <NumberInput label="Number of winners" source="winner_count" />}
            {formData.type === 2 && (
              <NumberInput label="Consolation Points" source="consolation_points" validate={required()} />
            )}
            {formData.type && (
              <ImageInput imageInputSource="photo" validate={required()} imagePath={s3FilePaths.allUploads} />
            )}
            {formData.type && (
              <ImageInput
                imageInputSource="background_image"
                validate={required()}
                imagePath={s3FilePaths.allUploads}
              />
            )}
            {formData.type && <NumberInput label="Priority" source="priority" />}
            <br />
            {formData.type && <TargetGroupInput />}
            {formData.type === 2 && (
              <ArrayInput label="Rewards of Points" source="reward_of_points">
                <Reward {...props} isEdit={true} />
              </ArrayInput>
            )}
          </>
        )}
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
);

export default IrelandChanceEdit;

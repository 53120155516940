import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from './locales/en';

const i18nProvider = polyglotI18nProvider((locale) => {
  if (locale === 'en') {
    return englishMessages;
  }
  return import(`./locales/${locale}.js`)
    .then((m) => m.default)
    .catch(() => englishMessages);
}, 'en');

export default i18nProvider;

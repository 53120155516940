import CategoryIcon from '@material-ui/icons/Bookmark';
import ProductsList from './components/ProductsList';
import ProductCreate from './components/ProductCreate';
import ProductEdit from './components/ProductEdit';
import { roles, ALL_WITHOUT_IRELAND } from '../../../config/constants';

const options = {
  appTypes: ALL_WITHOUT_IRELAND,
  allowedRoles: [roles.ADMIN],
  label: 'Digital Products',
  subMenuName: 'bonusShop',
};

export default {
  list: ProductsList,
  create: ProductCreate,
  edit: ProductEdit,
  icon: CategoryIcon,
  name: 'admin/bonus-shop/products',
  options,
};

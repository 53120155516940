import LensIcon from '@material-ui/icons/Lens';

import { roles, APP_TYPES } from '../../../config/constants';
import QuickTipsReportsList from './components/QuickTipsReportsList';

const options = {
  appTypes: [APP_TYPES.IRELAND],
  allowedRoles: [roles.ADMIN],
  label: 'Quick Tips',
  subMenuName: 'reports',
};

export default {
  list: QuickTipsReportsList,
  icon: LensIcon,
  name: 'admin/reports/quick-tips',
  options,
};

import React, { useCallback, useEffect, useState } from 'react';
import { List, useNotify } from 'react-admin';
import { chatStatuses, serverApiUrl } from '../../../config/constants';
import httpClient from '../../../core/httpClient';
import { ChatCardsSectionScrollContext, ChatMessagesContext } from '../context';

import ChatLayout from './ChatLayout';
import ChatListPagination from './ChatListPagination';

const Chat = (props) => {
  const notify = useNotify();
  const [selectedChatId, changeSelectedChatId] = useState(null);
  const [messages, changeMessages] = useState([]);
  const [chatCardsSectionScrollPosition, keepScrollPosition] = useState(0);

  const refreshChatMessages = useCallback(() => {
    const url = `${serverApiUrl}/admin/messages?chat_id=${selectedChatId}&socket=false`;
    const options = { method: 'GET' };

    httpClient(url, options)
      .then((res) => {
        changeMessages(res.json.messages.reverse());
      })
      .catch((err) => {
        notify(err.message, 'warning');
      });
  }, [notify, selectedChatId, changeMessages]);

  useEffect(() => {
    if (!selectedChatId) return;
    refreshChatMessages();
  }, [selectedChatId, refreshChatMessages]);

  const handleChatClick = (chatId) => () => {
    changeSelectedChatId(chatId);
  };

  const pushNewMessage = (newMessage) => {
    newMessage && changeMessages((oldMessages) => [...oldMessages, newMessage]);
  };

  return (
    <ChatMessagesContext.Provider value={{ messages, pushNewMessage }}>
      <ChatCardsSectionScrollContext.Provider
        value={{ chatCardsSectionScrollPosition, keepScrollPosition, handleChatClick }}
      >
        <List
          title="Chats"
          actions={null}
          pagination={<ChatListPagination />}
          bulkActionButtons={false}
          perPage={50}
          filterDefaultValues={{ filter: chatStatuses[0].value }}
          {...props}
        >
          <ChatLayout
            selectedChatId={selectedChatId}
            handleChatClick={handleChatClick}
            changeSelectedChatId={changeSelectedChatId}
          />
        </List>
      </ChatCardsSectionScrollContext.Provider>
    </ChatMessagesContext.Provider>
  );
};

export default Chat;

import React from 'react';
import { BooleanInput, Edit, required, TextInput } from 'react-admin';

import ScreenForm from './ScreenForm';
import TargetGroupInput from '../../../commonComponents/TargetGroupInput';
import ThemeSelector from '../../../commonComponents/ThemeSelector';

const ScreenEdit = (props) => (
  <Edit title="Edit screen" undoable={false} {...props}>
    <ScreenForm redirect="list">
      <TextInput label="ID" source="id" disabled />
      <TextInput label="Name" source="name" validate={required()} />
      <TextInput label="Description" source="description" validate={required()} />
      <TargetGroupInput />
      <BooleanInput label="Active" source="active" />
      <ThemeSelector />
      <TextInput source={'navigation_title'} label={'Menu title'} />
    </ScreenForm>
  </Edit>
);

export default ScreenEdit;

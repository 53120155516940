import React from 'react';
import { FormDataConsumer, SelectInput, NumberInput, TextInput, required } from 'react-admin';
import { popupApplicationTypes, popupNavigationTypes } from '../../../config/constants';

const PopupNavigationSelect = () => {
  const getRelativeFormComponent = (rest) => (
    <>
      <SelectInput label="Application type" source="relative_type" choices={popupApplicationTypes} {...rest} />
      <FormDataConsumer>
        {({ formData }) => {
          if (formData.relative_type) {
            const { needID: isIdNeed, contentIDLabel } = popupApplicationTypes.find(
              ({ id }) => id === formData.relative_type,
            );
            return isIdNeed ? (
              <NumberInput
                style={{ marginLeft: '10px' }}
                label={contentIDLabel}
                source="relative_id"
                validate={required()}
                min={1}
              />
            ) : null;
          }
        }}
      </FormDataConsumer>
    </>
  );

  const getFormComponentByFormData = ({ formData, ...rest }) => {
    switch (formData.button_type) {
      case popupNavigationTypes.CLOSE:
        return;
      case popupNavigationTypes.URL:
        return <TextInput label="URL" source="button_url" validate={required()} />;
      case popupNavigationTypes.RELATIVE:
        return getRelativeFormComponent(rest);
      default:
        return null;
    }
  };

  return <FormDataConsumer>{(data) => getFormComponentByFormData(data)}</FormDataConsumer>;
};

export default PopupNavigationSelect;

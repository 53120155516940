import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  FunctionField,
  ReferenceField,
  ArrayField,
  SingleFieldList,
  ChipField,
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import OutletFilter from './OutletFilter';
import CustomListActions from '../../../../commonComponents/ListActions/CustomListActions';
import { isCaucasus, isHungary } from '../../../../helpers/appTypeHelper';

const getManagerFieldLabel = () => {
  if (isHungary()) {
    return 'TM';
  }
  if (isCaucasus) {
    return 'TMR';
  }
  return 'Manager';
};

const ContractedField = ({ record }) => {
  const { contracted } = record;
  return <Typography>{contracted ? 'Contracted' : 'Non-Contracted'}</Typography>;
};

const OutletsList = (props) => (
  <List
    title="Outlets"
    bulkActionButtons={false}
    actions={<CustomListActions />}
    filters={<OutletFilter />}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" sortable={false} />
      <ReferenceField label="City" source="city_id" reference="admin/cities">
        <TextField source="name" />
      </ReferenceField>
      <TextField label={isHungary() || isCaucasus ? 'Address' : 'Street'} source="street" sortable={false} />
      {!isHungary() && !isCaucasus && <TextField label="Building Number" source="building_number" sortable={false} />}
      {!isHungary() && <TextField label="Longitude" source="longitude" sortable={false} />}
      {!isHungary() && <TextField label="Latitude" source="latitude" sortable={false} />}
      <ReferenceField label={getManagerFieldLabel()} source="manager_id" reference="admin/managers" sortable={false}>
        <FunctionField render={(record) => `${record.name} ${record.surname}`} />
      </ReferenceField>
      <ArrayField label={isCaucasus ? 'LegalName' : 'Legal Entity Name'} source="legal_entities" sortable={false}>
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <TextField label={isHungary() ? 'Trafik code' : 'Outlet code'} source="outlet_code" />
      <TextField label={isCaucasus ? 'Fiscal Code' : 'Legal ID'} source="legal_id" />
      {isHungary() && <ContractedField label="Contracted" />}
      {isHungary() && <TextField label="Contract Type" source="contract_type" />}
      {isHungary() && <TextField label="Zip code" source="zip_code" />}
      <EditButton />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
);

export default OutletsList;

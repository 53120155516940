import React from 'react';
import { BooleanInput, NumberInput, SimpleForm, TextInput, useNotify, useRefresh } from 'react-admin';
import { CustomDateInput } from '../../../commonComponents/CustomDateInput';
import { APP_TYPES, serverApiUrl } from '../../../config/constants';
import httpClient from '../../../core/httpClient';

const SettingsForm = ({ loaded, data, ids }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const record = loaded ? data[ids[0]] : {};

  const handleSubmit = (values) => {
    const url = `${serverApiUrl}/admin/settings/update`;
    const data = { ...values, default_theme: 1 };
    const options = { method: 'POST', body: JSON.stringify(data) };

    httpClient(url, options)
      .then(() => refresh())
      .catch(() => notify("Error: Settings weren't updated", 'warning'));
  };

  return (
    <SimpleForm resource="admin/settings" save={handleSubmit} record={record}>
      <NumberInput label="Win coins" source="win_coins" />
      <NumberInput label="Lose coins" source="lose_coins" />
      <NumberInput label="Special coins" source="special_coins" />
      <NumberInput label="Draw coins" source="draw_coins" />
      <NumberInput label="Order limit" source="orders_sum_limit" />
      <CustomDateInput label="Content limit date" source="content_limit_date" />
      <BooleanInput label="Show price disclaimer" source="show_disclaimer" />
      <TextInput label="IOS release version" source="ios_version" />
      <TextInput label="Android release version" source="android_version" />
      <NumberInput label="Question of the day coins" source="daily_question_coins" />
      <NumberInput label="Quiz complexity threshold (games)" source="quiz_complexity_threshold" />
      {process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY && <NumberInput source="leaf_exchange_rate" />}
    </SimpleForm>
  );
};

export default SettingsForm;

import React from 'react';
import { List, Datagrid, TextField, EditButton, DeleteButton, NumberField, SelectField } from 'react-admin';
import CustomListActions from '../../../commonComponents/ListActions/CustomListActions';
import { CustomDateField } from '../../../commonComponents/DateField';
import { booleanFilterChoices } from '../../../config/constants';

const AchievementsList = (props) => (
  <List
    title="Achievements"
    exporter={false}
    bulkActionButtons={false}
    actions={<CustomListActions />}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField label="ID" source="id" />
      <TextField label="Name" source="name" />
      <CustomDateField label="Start date" source="start_date" showTime={false} />
      <CustomDateField label="End date" source="end_date" showTime={false} />
      <NumberField label="Priority" source="priority" />
      <SelectField label="Visible" source="visible" choices={booleanFilterChoices} />
      <EditButton />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
);

export default AchievementsList;

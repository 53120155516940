import AccountNumberIcon from '@material-ui/icons/VpnKey';
import AccountNumberList from './components/AccountNumberList';
import AccountNumberCreate from './components/AccountNumberCreate';
import AccountNumberEdit from './components/AccountNumberEdit';
import { roles, APP_TYPES } from '../../config/constants';

const options = {
  appTypes: [APP_TYPES.IRELAND, APP_TYPES.GEORGIA, APP_TYPES.ARMENIA, APP_TYPES.AZERBAIJAN],
  allowedRoles: [roles.ADMIN],
  label: 'Account numbers',
  subMenuName: 'manage',
};

export default {
  list: AccountNumberList,
  create: AccountNumberCreate,
  edit: AccountNumberEdit,
  icon: AccountNumberIcon,
  name: 'admin/account-numbers',
  options,
};

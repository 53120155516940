import React from 'react';
import { NumberInput, SimpleForm, useNotify } from 'react-admin';
import ImageInput from '../../../commonComponents/UploadInputs/ImageInput';
import { s3FilePaths, serverApiUrl } from '../../../config/constants';

import httpClient from '../../../core/httpClient';

const InviteCode = ({ loaded, data, ids }) => {
  const notify = useNotify();
  const record = loaded ? data[ids[0]] : {};

  const handleSubmit = ({ invite_code_reward, invite_code_background }) => {
    const url = `${serverApiUrl}/admin/settings/invite-code`;
    const body = { invite_code_reward, invite_code_background };
    const options = { method: 'POST', body: JSON.stringify(body) };

    httpClient(url, options)
      .then(() => notify('Invite code settings were update successfully', 'success'))
      .catch(() => notify("Error: Invite code settings weren't update", 'warning'));
  };

  return (
    <SimpleForm resource="admin/settings" onSubmit={handleSubmit} save={handleSubmit} record={record}>
      <ImageInput
        label="Invite code background"
        imagePath={s3FilePaths.allUploads}
        imageInputSource="invite_code_background"
      />
      <NumberInput label="Invite code reward" source="invite_code_reward" />
    </SimpleForm>
  );
};

export default InviteCode;

import React from 'react';
import { Filter, ReferenceInput, SelectInput, TextInput } from 'react-admin';
import { CustomDateInput } from '../../../../commonComponents/CustomDateInput';
import UserAutocompleteInput from '../../../../commonComponents/UserAutocompleteInput';

const GamesReportsFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput source="brand_id" reference="admin/brands" filter={{ positive: true }} alwaysOn>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <TextInput label="User phone" source="user_phone" alwaysOn />
    <UserAutocompleteInput label="User ID" source="user_id" alwaysOn />
    <CustomDateInput label="Choose start date" source="start_date" start alwaysOn />
    <CustomDateInput label="Choose end date" source="end_date" end alwaysOn />
  </Filter>
);

export default GamesReportsFilter;

import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import CustomReportListActions from '../../../../commonComponents/ListActions/CustomReportListActions';
import ChallengeReportsFilter from './ChallengeReportsFilter';
import { APP_TYPES } from '../../../../config/constants';

const ChallengeReportsList = (props) => (
  <List
    title="Challenge reports"
    actions={<CustomReportListActions />}
    filters={<ChallengeReportsFilter />}
    bulkActionButtons={false}
    sort={{ field: 'created_at', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField label="User ID" source="id" />
      <TextField label="Name" source="user_name" sortable={false} />
      <TextField label="Phone" source="user_phone" sortable={false} />
      <TextField label="Games" source="count_games" sortable={false} />
      <TextField label="Win" source="count_win" sortable={false} />
      <TextField label="Games T1" source="count_brand_1" sortable={false} />
      <TextField label="Games T2" source="count_brand_2" sortable={false} />
      <TextField label="Games T3" source="count_brand_3" sortable={false} />
      <TextField label="Points" source="points" sortable={false} />
      <TextField label="Orders" source="count_orders" sortable={false} />
      <TextField label="Articles" source="count_articles" sortable={false} />
      <TextField label="Coin Spent" source="count_coin_spent" sortable={false} />
      <TextField label="Lotteries Played" source="count_lotteries" sortable={false} />
      <TextField label="Special Quizzes" source="count_special_quizzes" sortable={false} />
      <TextField label="Multibrand Quizzes" source="count_multibrand_quizzes" sortable={false} />
      {process.env.REACT_APP_APP_TYPE === APP_TYPES.BELARUS && (
        <TextField label="Внешний код торговой точки" source="account_code" />
      )}
    </Datagrid>
  </List>
);

export default ChallengeReportsList;

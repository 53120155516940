import React from 'react';
import { Button, useDataProvider, useRefresh, useNotify } from 'react-admin';
import FileCopyIcon from '@material-ui/icons/FileCopy';

const DuplicateButton = ({ copyRecord, resource, record }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const handleButtonClick = (e) => {
    e.stopPropagation();
    const data = copyRecord(record);
    dataProvider
      .create(resource, { data })
      .then(() => {
        refresh();
        notify('Successfully created record');
      })
      .catch((e) => {
        notify(`Error duplicating record: ${e.message}`, 'warning');
      });
  };

  return (
    <Button onClick={handleButtonClick} label="Duplicate">
      <FileCopyIcon />
    </Button>
  );
};

export default DuplicateButton;

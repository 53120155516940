import { useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import { serverApiUrl } from '../../../../config/constants';
import httpClient from '../../../../core/httpClient';
import OverviewBlock from '../OverviewBlock';

const RegisteredUsers = () => {
  const translate = useTranslate();
  const [generalInfo, setGeneralInfo] = useState();
  const [additionalInfo, setAdditionalInfo] = useState();

  const updateProgramOutlets = () => {
    const url = `${serverApiUrl}/admin/outlets-users/registered-users`;
    const options = { method: 'GET' };

    httpClient(url, options)
      .then(({ json: { registered_users, new_registered_users } }) => {
        setGeneralInfo(registered_users);
        setAdditionalInfo(new_registered_users);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    updateProgramOutlets(0);
  }, []);

  return (
    <OverviewBlock
      generalInfo={generalInfo}
      additionalInfo={additionalInfo}
      description={translate('Registered users')}
      tip={translate('root.statistics.The total number of users at your outlets')}
    />
  );
};

export default RegisteredUsers;

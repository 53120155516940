import React, { useEffect, useState } from 'react';
import { Avatar, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/AccountCircle';

import ChatCompanionInfo from '../../../commonComponents/ChatCompanionInfo';
import ChatCards from './ChatCards';
import ChatMessagesSection from './ChatMessagesSection';
import ChatsFilter from './ChatsFilter';
import NewMessage from './NewMessage';

const useStyles = makeStyles({
  chatsContainer: {
    border: '1px solid #01c1b6',
    display: 'flex',
    height: '80vh',
  },
  chatCardsWithFilterSection: {
    display: 'flex',
    flexDirection: 'column',
    width: '25%',
    border: '1px solid #01c1b6',
  },
  chatCards: {
    overflowY: 'auto',
  },
  chatsFilter: {
    borderBottom: '1px solid #ddd',
  },
  selectedChatSection: {
    display: 'flex',
    flexDirection: 'column',
    width: '75%',
    border: '1px solid #01c1b6',
  },
  selectedChatCompanionInfoSection: {
    display: 'flex',
    padding: '20px 0 20px 10px',
    backgroundColor: '#f5f3f3',
    fontWeight: 600,
    fontSize: '0.9em',
  },
  selectedChatCompanionAvatar: {
    marginRight: 10,
  },
});

const ChatLayout = ({ ids, data, selectedChatId }) => {
  const classes = useStyles();
  const [selectedChat, changeSelectedChat] = useState(null);

  useEffect(() => {
    if (data[selectedChatId]) {
      changeSelectedChat(data[selectedChatId]);
    }
  }, [data, selectedChatId]);

  return (
    <div className={classes.chatsContainer}>
      <div className={classes.chatCardsWithFilterSection}>
        <div className={classes.chatsFilter}>
          <ChatsFilter />
        </div>
        <ChatCards ids={ids} data={data} selectedChatId={selectedChatId} />
      </div>
      <div className={classes.selectedChatSection}>
        {selectedChatId && selectedChat && (
          <>
            <Paper classes={{ root: classes.selectedChatCompanionInfoSection }}>
              <Avatar classes={{ root: classes.selectedChatCompanionAvatar }}>
                <PersonIcon />
              </Avatar>
              <ChatCompanionInfo companion={selectedChat.companion} />
            </Paper>
            <ChatMessagesSection selectedChatCompanion={selectedChat.companion} selectedChatId={selectedChatId} />
            <NewMessage companionId={selectedChat.companion.id} />
          </>
        )}
      </div>
    </div>
  );
};

export default ChatLayout;

import React from 'react';
import { ColorInput } from 'react-admin-color-input';
import { Create, NumberInput, required, SimpleForm, TextInput } from 'react-admin';
import UploadFileInput from '../../../commonComponents/UploadInputs/UploadFileInput';

const TierCreate = (props) => (
  <Create title="Tiers Create" {...props}>
    <SimpleForm redirect="list">
      <TextInput label="Name" source="name" validate={required()} />
      <NumberInput label="Discount - %" source="discount" min={0} max={100} validate={required()} />
      <UploadFileInput fileInputSource="outlet_codes_file" label="Outlet codes" />
      <ColorInput source="color" defaultValue="#FFFFFF" validate={required()} />
    </SimpleForm>
  </Create>
);

export default TierCreate;

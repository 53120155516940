import React from 'react';
import {
  ArrayInput,
  Create,
  NumberInput,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  minValue,
  maxValue,
  BooleanInput,
  FormDataConsumer,
} from 'react-admin';
import { ColorInput } from 'react-admin-color-input';
import { makeStyles } from '@material-ui/core/styles';
import { CustomDateInput } from '../../../commonComponents/CustomDateInput';
import TargetGroupInput from '../../../commonComponents/TargetGroupInput';
import ImageInput from '../../../commonComponents/UploadInputs/ImageInput';
import { APP_TYPES, s3FilePaths, statuses } from '../../../config/constants';
import { isIreland } from '../../../helpers/appTypeHelper';

const useStyles = makeStyles({
  hideFormLabel: {
    '& li > p': {
      display: 'none',
    },
  },
});

const shouldPushBeVisible =
  process.env.REACT_APP_APP_TYPE === APP_TYPES.IRELAND || process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY;

const LoyaltyProgramsCreate = (props) => {
  const classes = useStyles();

  return (
    <Create title="Create monthly limitation" undoable="false" {...props}>
      <SimpleForm redirect="list" inititalValues={{ push: false, message: '' }}>
        <TextInput label="Name" source="name" defaultValue="Loyalty program" validate={required()} />
        <CustomDateInput label="Start date" source="start_date" start validate={required()} />
        <CustomDateInput label="End date" source="end_date" end validate={required()} />
        <NumberInput label="Points" source="coins" defaultValue={1200} validate={required()} />
        <NumberInput
          label="Products to sell"
          source="sales_requirement"
          defaultValue={6}
          validate={[required(), maxValue(6), minValue(1)]}
        />
        <SelectInput source="status" optionText="name" choices={statuses.slice(0, 2)} validate={required()} />
        {isIreland() && (
          <BooleanInput
            style={{ width: '300px' }}
            label="Included in the Progress bar"
            source="progress_bar_enable"
            defaultValue={true}
          />
        )}
        <TargetGroupInput />
        <ImageInput
          imagePath={s3FilePaths.allUploads}
          label="Background image"
          imageInputSource="background_image"
          validate={required()}
        />
        <ImageInput
          imagePath={s3FilePaths.allUploads}
          label="Product image"
          imageInputSource="product_image"
          validate={required()}
        />
        <ImageInput
          imagePath={s3FilePaths.allUploads}
          label="Cover image 1"
          imageInputSource="cover_image_1"
          validate={required()}
        />
        <ImageInput
          imagePath={s3FilePaths.allUploads}
          label="Cover image 2"
          imageInputSource="cover_image_2"
          validate={required()}
        />
        <ImageInput
          imagePath={s3FilePaths.allUploads}
          label="Cover image 3"
          imageInputSource="cover_image_3"
          validate={required()}
        />
        <ImageInput
          imagePath={s3FilePaths.allUploads}
          label="Cover image 4"
          imageInputSource="cover_image_4"
          validate={required()}
        />
        <ImageInput
          imagePath={s3FilePaths.allUploads}
          label="Cover image 5"
          imageInputSource="cover_image_5"
          validate={required()}
        />
        <ImageInput
          imagePath={s3FilePaths.allUploads}
          label="Cover image 6"
          imageInputSource="cover_image_6"
          validate={required()}
        />
        <ColorInput source="button_text_color" defaultValue="#FFFFFF" validate={required()} />
        <ColorInput source="button_color" defaultValue="#000000" validate={required()} />
        <ColorInput source="button_plus_color" defaultValue="#FAD32A" validate={required()} />
        <ArrayInput source="products" className={classes.hideFormLabel}>
          <SimpleFormIterator>
            <TextInput label="Name" source="name" validate={required()} />
            <TextInput label="Keywords" source="keywords" validate={required()} />
          </SimpleFormIterator>
        </ArrayInput>
        {shouldPushBeVisible && (
          <>
            <BooleanInput label="Push notification" source="push" />
            <FormDataConsumer>
              {({ formData, ...rest }) => formData && formData.push && <TextInput source="message" {...rest} />}
            </FormDataConsumer>
          </>
        )}
      </SimpleForm>
    </Create>
  );
};

export default LoyaltyProgramsCreate;

import QuizIcon from '@material-ui/icons/AssignmentTurnedIn';
import SurveysList from './components/SurveysList';
import SurveyCreate from './components/SurveyCreate';
import SurveyEdit from './components/SurveyEdit';
import { APP_TYPES, CAUCASUS_TYPES, roles } from '../../config/constants';

const ENV_APP_TYPE = process.env.REACT_APP_APP_TYPE;

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: [APP_TYPES.HUNGARY, APP_TYPES.BELARUS, ...CAUCASUS_TYPES].includes(ENV_APP_TYPE)
    ? [roles.ADMIN]
    : [roles.ADMIN, roles.JTI_TEAM],
  label: 'Surveys',
  subMenuName: 'content',
};

export default {
  list: SurveysList,
  create: SurveyCreate,
  edit: SurveyEdit,
  icon: QuizIcon,
  name: 'admin/surveys',
  options,
};

import React, { useState } from 'react';
import { Button, FormWithRedirect, required, SaveButton, TextInput, useNotify } from 'react-admin';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import IconCancel from '@material-ui/icons/Cancel';
import httpClient from '../../../core/httpClient';
import { availableMessageTemplates, serverApiUrl } from '../../../config/constants';

import styles from './SendToAllUsers.module.scss';

const SendToAllUsers = ({ endpointUrl, record, recordKey }) => {
  const notify = useNotify();
  const [isOpen, setOpenStatus] = useState(false);

  const handleOpenModal = (e) => {
    e.stopPropagation();
    setOpenStatus(true);
  };

  const handleCloseModal = () => {
    setOpenStatus(false);
  };

  const handleSendMessage = ({ message, caption }) => {
    const url = `${serverApiUrl}${endpointUrl}`;
    const body = JSON.stringify({ message, caption, [recordKey]: record.id });
    const options = { method: 'POST', body };

    httpClient(url, options)
      .then(() => handleCloseModal())
      .catch(() => notify("Error: Message wasn't sent", 'warning'));
  };

  return (
    <>
      <IconButton onClick={handleOpenModal}>
        <SendIcon />
      </IconButton>
      <Dialog open={isOpen} onClick={(e) => e.stopPropagation()}>
        <DialogTitle>Send to all users</DialogTitle>
        <FormWithRedirect
          save={handleSendMessage}
          render={({ handleSubmitWithRedirect, submitting, pristine }) => (
            <>
              <DialogContent className={styles.dialogContent}>
                <TextInput label="Caption" source="caption" />
                <TextInput
                  helperText={`Available replacements: ${availableMessageTemplates.join(', ')}`}
                  label="Message"
                  source="message"
                  fullWidth
                  validate={required()}
                />
              </DialogContent>
              <DialogActions>
                <SaveButton disabled={submitting || pristine} handleSubmitWithRedirect={handleSubmitWithRedirect} />
                <Button label="ra.action.cancel" onClick={handleCloseModal}>
                  <IconCancel />
                </Button>
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

export default SendToAllUsers;

import CategoryIcon from '@material-ui/icons/Bookmark';
import CategoriesList from './components/CategoriesList';
import CategoryCreate from './components/CategoryCreate';
import CategoryEdit from './components/CategoryEdit';
import { roles, ALL_WITHOUT_IRELAND } from '../../../config/constants';

const options = {
  appTypes: ALL_WITHOUT_IRELAND,
  allowedRoles: [roles.ADMIN],
  label: 'Categories',
  subMenuName: 'bonusShop',
};

export default {
  list: CategoriesList,
  create: CategoryCreate,
  edit: CategoryEdit,
  icon: CategoryIcon,
  name: 'admin/bonus-shop/categories',
  options,
};

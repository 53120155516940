import React from 'react';
import { Filter, TextInput } from 'react-admin';
import { CustomDateInput } from '../../../../commonComponents/CustomDateInput';
import UserAutocompleteInput from '../../../../commonComponents/UserAutocompleteInput';

const InviteCodesFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Owner ID" source="inviter_id" alwaysOn />
    <TextInput label="Owner Phone" source="inviter_phone" alwaysOn />
    <UserAutocompleteInput label="Referral ID" source="invitee_id" alwaysOn />
    <CustomDateInput label="Choose start date" source="start_date" start alwaysOn />
    <CustomDateInput label="Choose end date" source="end_date" end alwaysOn />
  </Filter>
);

export default InviteCodesFilter;

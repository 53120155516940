import DailyQuestionsIcon from '@material-ui/icons/DateRange';
import DailyQuestionsReportsList from './components/DailyQuestionsReportsList';
import { roles, APP_TYPES, CAUCASUS_TYPES } from '../../../config/constants';

const appType = process.env.REACT_APP_APP_TYPE;

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: CAUCASUS_TYPES.includes(appType) ? [roles.ADMIN, roles.JTI_TEAM] : [roles.ADMIN],
  label: 'Daily Questions',
  subMenuName: 'reports',
};

export default {
  list: DailyQuestionsReportsList,
  icon: DailyQuestionsIcon,
  name: 'admin/reports/daily-questions',
  options,
};

import AdministratorIcon from '@material-ui/icons/Group';
import AdministratorsList from './components/AdministratorsList';
import AdministratorCreate from './components/AdministratorCreate';
import AdministratorEdit from './components/AdministratorEdit';
import { roles, APP_TYPES } from '../../config/constants';

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: [roles.ADMIN],
  label: 'Administrators',
};

export default {
  list: AdministratorsList,
  create: AdministratorCreate,
  edit: AdministratorEdit,
  icon: AdministratorIcon,
  name: 'admin/admins',
  options,
};

import React from 'react';
import { List } from 'react-admin';

import SettingsLayout from './SettingsLayout';

const Settings = (props) => (
  <List title="Settings" {...props} actions={null} perPage={100} bulkActionButtons={false} pagination={<></>}>
    <SettingsLayout />
  </List>
);

export default Settings;

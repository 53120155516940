import React from 'react';
import { Edit, TextInput, SelectInput, BooleanInput, required, FormDataConsumer } from 'react-admin';
import { CustomDateInput } from '../../../commonComponents/CustomDateInput';

import TaskForm from './TaskForm';
import { s3FilePaths, statuses } from '../../../config/constants';
import TargetGroupInput from '../../../commonComponents/TargetGroupInput';
import ImageInput from '../../../commonComponents/UploadInputs/ImageInput';
import { isHungary, isIreland } from '../../../helpers/appTypeHelper';
import { challengeTypes, challengeTypesChoices } from '../config/constants';

const hasTeamChallenge = isHungary() || isIreland();

const ChallengeEdit = (props) => (
  <Edit title="Edit Challenge" undoable={false} {...props}>
    <TaskForm type="edit">
      {hasTeamChallenge && (
        <SelectInput
          label="Type"
          source="type"
          optionText="name"
          validate={required()}
          choices={challengeTypesChoices}
        />
      )}
      <TextInput label="Admin Panel Name" source="admin_panel_name" />
      <TextInput label="Name" source="name" />
      <TextInput label="Description" source="description" />
      <SelectInput source="status" optionText="name" choices={statuses.slice(0, 2)} />
      <CustomDateInput label="Choose start Date" source="start_date" start />
      <CustomDateInput label="Choose end Date" source="end_date" end />
      <TargetGroupInput />
      <FormDataConsumer>
        {({ formData }) => {
          const { type } = formData;
          const isDailyGameType = type === challengeTypes.daily_game;

          return (
            <>
              {!isDailyGameType && isIreland() && (
                <BooleanInput
                  style={{ width: '300px' }}
                  label="Included in the Progress bar"
                  source="progress_bar_enable"
                />
              )}
              {type !== challengeTypes.daily_game && hasTeamChallenge && (
                <ImageInput
                  label="Challenges page background"
                  imageInputSource="background_image"
                  imagePath={s3FilePaths.allUploads}
                  validate={required()}
                />
              )}
              {type !== challengeTypes.daily_game && (
                <BooleanInput label="Show attachment button" source="attachments_enabled" />
              )}
            </>
          );
        }}
      </FormDataConsumer>
    </TaskForm>
  </Edit>
);

export default ChallengeEdit;

import { APP_TYPES } from '../../../config/constants';

const managerTitles = {
  IRELAND: 'BA',
  HUNGARY: 'TM',
};

const accountCodeTitles = {
  IRELAND: 'Account',
  HUNGARY: 'Trafik code',
  BELARUS: 'Внешний код торговой точки',
};

const loggedInOrActiveInfoTitles = {
  HUNGARY: 'Downloaded the app',
  GEORGIA: 'Installed the JTI-Club',
  ARMENIA: 'Installed the JTI-Club',
  AZERBAIJAN: 'Installed the JTI-Club',
  IRELAND: 'Installed the JTI Connex',
};

export const loggedInOrActiveInfoLabel =
  loggedInOrActiveInfoTitles[process.env.REACT_APP_APP_TYPE] || 'Downloaded the app';
export const managerLabel = managerTitles[process.env.REACT_APP_APP_TYPE] || 'Manager';
export const accountCodeLabel = accountCodeTitles[process.env.REACT_APP_APP_TYPE] || 'Outlet code';
export const positionLabels = {
  [APP_TYPES.IRELAND]: 'Job Title',
  [APP_TYPES.BELARUS]: 'Должность',
};
export const positionLabel = positionLabels[process.env.REACT_APP_APP_TYPE] || 'Position';

import { createMuiTheme } from '@material-ui/core/styles';

const customTheme = createMuiTheme({
  palette: {
    primary: { main: '#01c1b6' },
    secondary: { main: '#01c1b6' },
  },
  overrides: {
    MuiButton: { containedPrimary: { color: 'white' } },
    MuiAppBar: { root: { color: '#fff !important' } },
    MuiDrawer: { root: { paddingTop: 24 } },
    RaLayout: { content: { paddingTop: 24 } },
    RaSelectInput: { input: { minWidth: 219 } },
    MuiPickersToolbarText: { toolbarBtnSelected: { color: 'white' }, toolbarTxt: { color: 'white' } },
    MuiPickersDay: { daySelected: { color: 'white' } },
  },
});

export default customTheme;

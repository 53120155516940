import React from 'react';
import { Datagrid, List, NumberField, TextField } from 'react-admin';
import { ColorField } from 'react-admin-color-input';
import ImageField from '../../../commonComponents/ImageField';

const BoosterList = (props) => (
  <List title="Boosters" exporter={false} bulkActionButtons={false} sort={{ field: 'id', order: 'ASC' }} {...props}>
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <TextField source="name" />
      <ImageField source="icon" />
      <TextField source="description" />
      <NumberField source="price" />
      <ColorField source="text_color" />
      <ColorField source="main_color" />
      <ImageField source="banner" />
    </Datagrid>
  </List>
);

export default BoosterList;

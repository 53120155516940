import React from 'react';
import { BooleanField, Datagrid, List, TextField } from 'react-admin';
import { CustomDateField } from '../../../../commonComponents/DateField';
import CustomReportListActions from '../../../../commonComponents/ListActions/CustomReportListActions';

import PromoCodesFilter from './PromoCodesFilter';

const PromoCodesList = (props) => (
  <List
    title="Promo codes reports"
    actions={<CustomReportListActions />}
    filters={<PromoCodesFilter />}
    bulkActionButtons={false}
    sort={{ field: 'promo_code_id', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField label="Promo Code ID" source="promo_code_id" />
      <TextField label="User ID" source="user_id" />
      <TextField label="Code" source="promo_code.code" />
      <TextField label="Coins" source="promo_code.coins" />
      <TextField label="Message" source="message_text" />
      <BooleanField label="Activated" source="activated" />
      <CustomDateField label="Activated at" source="activated_at" />
      <CustomDateField label="Created at" source="promo_code.created_at" />
    </Datagrid>
  </List>
);

export default PromoCodesList;

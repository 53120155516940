import React from 'react';
import { Datagrid, DeleteButton, List, ReferenceField, TextField, DateField } from 'react-admin';

import ArticlesFilter from './ArticlesFilter';
import StatusField from '../../../../commonComponents/StatusField';
import SendArticleToSingleUser from '../../../../commonComponents/SendMessageActions/SendToSingleUser/SendToSingleUser';
import SendArticleToGroupOfUsers from '../../../../commonComponents/SendMessageActions/SendToGroupOfUsers/SendToGroupOfUsers';
import SendArticleToAllUsers from '../../../../commonComponents/SendMessageActions/SendToAllUsers/SendToAllUsers';
import PreSignedUrlField from '../../../../commonComponents/PreSignedUrlField';

const ArticlesList = (props) => (
  <List
    title="Knowledge Bank"
    filters={<ArticlesFilter />}
    exporter={false}
    filterDefaultValues={{ category_id: 1 }}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <TextField label="Name" source="name" />
      <PreSignedUrlField label="URL" source="url" />
      <StatusField label="Status" source="status" />
      <DateField label="Publish date" source="published_at" />
      <ReferenceField label="Brand" reference="admin/knowledge-bank/brands" source="brand_id" allowEmpty>
        <TextField source="name" />
      </ReferenceField>
      <TextField label="Special Quiz ID" source="special_quiz_id" />
      <TextField label="Time" source="minutes_to_read" />
      <TextField label="Priority" source="priority" />
      <DeleteButton undoable={false} />
      <SendArticleToSingleUser recordKey="article_id" endpointUrl="/admin/knowledge-bank/articles/send/user" />
      <SendArticleToGroupOfUsers recordKey="article_id" endpointUrl="/admin/knowledge-bank/articles/send/group" />
      <SendArticleToAllUsers recordKey="article_id" endpointUrl="/admin/knowledge-bank/articles/send/all" />
    </Datagrid>
  </List>
);

export default ArticlesList;

import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  CircularProgress,
  TablePagination,
  Typography,
  TextField,
  ThemeProvider,
} from '@mui/material';
import isEmpty from 'is-empty-typed';
import { createTheme } from '@mui/material/styles';
import { useTranslate } from 'react-admin';

import { serverApiUrl } from '../../../../config/constants';
import httpClient from '../../../../core/httpClient';
import { Sellers } from './Sellers';
import styles from './CPAProgram.module.scss';

const INITIAL_ROWS_PER_PAGE = 10;

const theme = createTheme({
  palette: {
    primary: {
      main: '#01c1b6',
    },
  },
});

const CPAProgram = ({ match }) => {
  const translate = useTranslate();
  const [cpaProgram, setCpaProgram] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(INITIAL_ROWS_PER_PAGE);
  const [outletFilter, setOutletFilter] = useState('');
  const [total, setTotal] = useState(0);
  const cpaName = match.params.name;
  const cpaProgramId = match.params.id;

  const updateProgramOutlets = (offset, limit) => {
    setCpaProgram(null);
    const url = `${serverApiUrl}/admin/cpa-program/outlets?id=${cpaProgramId}&outlet_code=${outletFilter}&limit=${limit}&offset=${offset}`;
    const options = { method: 'GET' };

    httpClient(url, options)
      .then(({ json: { cpa_program_outlets, total } }) => {
        setCpaProgram(cpa_program_outlets);
        setTotal(total);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    updateProgramOutlets(0, rowsPerPage);
  }, [outletFilter]);

  return (
    <div className={styles.cpaViewContainer}>
      <ThemeProvider theme={theme}>
        <Typography variant="h5" component="div" className={styles.title}>
          {`CPA *${cpaName}*`}
        </Typography>

        <TextField
          className={styles.cpaOutletFilter}
          label="Outlet code"
          variant="filled"
          color="primary"
          margin="normal"
          onChange={(event) => setOutletFilter(event.target.value)}
        />

        {!cpaProgram ? (
          <div className={styles.spinnerContainer}>
            <CircularProgress />
          </div>
        ) : (
          cpaProgram.address.map((outlet) => {
            const {
              outlet_address,
              outlet_code,
              outlet_city,
              outlet_legal_entity,
              sellers,
              plan_reward,
              sales_plan,
              id,
            } = outlet;

            return (
              <Card key={id} sx={{ minWidth: 275, marginTop: 3, padding: 2 }}>
                <CardContent>
                  <Typography variant="h6" component="div">
                    {`${outlet_code} ${outlet_city} ${outlet_address} ${outlet_legal_entity}`}
                  </Typography>

                  <Typography variant="body2" style={{ marginBottom: 30 }}>
                    {translate('root.cpa.view.plan', { plan: sales_plan, reward: plan_reward })}
                  </Typography>

                  {!isEmpty(sellers) && (
                    <Sellers
                      sellers={sellers}
                      cpaProgramId={cpaProgramId}
                      isCheckboxShown={cpaProgram.allow_grade}
                      isCheckboxDisabled={cpaProgram.status === 3}
                    />
                  )}
                </CardContent>
              </Card>
            );
          })
        )}

        <TablePagination
          component="div"
          count={total}
          page={page}
          onPageChange={(event, newPage) => {
            updateProgramOutlets(newPage * rowsPerPage, rowsPerPage);
            setPage(newPage);
          }}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={({ target }) => {
            updateProgramOutlets(0, target.value);
            setRowsPerPage(target.value);
            setPage(0);
          }}
        />
      </ThemeProvider>
    </div>
  );
};

export default CPAProgram;

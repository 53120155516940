import React, { useState } from 'react';
import { Checkbox } from '@mui/material';
import classNames from 'classnames';
import { serverApiUrl } from '../../../../config/constants';
import httpClient from '../../../../core/httpClient';

import styles from './CPAProgram.module.scss';

export const RewardCheckbox = ({ params, cpaProgramId, isDisabled }) => {
  const { id, value } = params;
  const [isChecked, setIsChecked] = useState(!!value);

  const handleChange = () => {
    updateShouldRewarded(id, !isChecked).then(() => setIsChecked(!isChecked));
  };

  const updateShouldRewarded = (id, value) => {
    const url = `${serverApiUrl}/admin/cpa-program/rewarded`;
    const body = JSON.stringify({ id: cpaProgramId, user_id: id, should_rewarded: value });
    const options = { method: 'POST', body };

    return httpClient(url, options);
  };

  return (
    <Checkbox
      disabled={isDisabled}
      className={classNames(styles.checkbox, { [styles.disabled]: isDisabled })}
      checked={isChecked}
      onChange={handleChange}
    />
  );
};

import React from 'react';
import { Filter, ReferenceInput, SelectInput } from 'react-admin';
import UserAutocompleteInput from '../../../../commonComponents/UserAutocompleteInput';
import { APP_TYPES } from '../../../../config/constants';

const LeaderBoardReportsFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      label="Interval"
      source="type"
      optionText="name"
      choices={[
        { id: 'all', name: 'All time' },
        { id: 'week', name: 'Week' },
      ]}
      allowEmpty={false}
      alwaysOn
    />
    <UserAutocompleteInput label="User ID" source="user_id" alwaysOn />
    <ReferenceInput label="Brand" source="brand_id" reference="services/brands" alwaysOn>
      <SelectInput optionText="name" />
    </ReferenceInput>
    {process.env.REACT_APP_APP_TYPE === APP_TYPES.IRELAND && (
      <ReferenceInput source="tier_id" reference="admin/tiers" alwaysOn>
        <SelectInput source="name" />
      </ReferenceInput>
    )}
  </Filter>
);

export default LeaderBoardReportsFilter;

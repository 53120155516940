import { roles, APP_TYPES } from '../../../config/constants';
import LepCodeList from './components/LepCodeList';

const options = {
  appTypes: [APP_TYPES.IRELAND],
  allowedRoles: [roles.ADMIN],
  label: 'Codes',
};

export default {
  list: LepCodeList,
  name: 'admin/lep/codes',
  options,
};

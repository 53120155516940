import ManagerIcon from '@material-ui/icons/Group';
import ManagersList from './components/ManagersList';
import ManagerCreate from './components/ManagerCreate';
import ManagerEdit from './components/ManagerEdit';
import { roles, APP_TYPES } from '../../config/constants';

const appType = process.env.REACT_APP_APP_TYPE;
const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: appType === APP_TYPES.BELARUS ? [roles.ADMIN, roles.JTI_TEAM] : [roles.ADMIN],
  label: 'Managers',
};

export default {
  list: ManagersList,
  create: ManagerCreate,
  edit: ManagerEdit,
  icon: ManagerIcon,
  name: 'admin/managers',
  options,
};

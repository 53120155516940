import { roles, APP_TYPES } from '../../../config/constants';
import AttachmentsList from './components/AttachmentsList';

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: [roles.ADMIN],
  label: 'Loyalty program codes',
};

export default {
  list: AttachmentsList,
  name: 'admin/loyalty-programs/attachments',
  options,
};

import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import LegalEntitiesList from './components/LegalEntitiesList';
import LegalEntityCreate from './components/LegalEntityCreate';
import LegalEntityEdit from './components/LegalEntityEdit';
import { roles, APP_TYPES, CAUCASUS_TYPES } from '../../../config/constants';

const appType = process.env.REACT_APP_APP_TYPE;
const isBelarus = appType === APP_TYPES.BELARUS;
const isCaucasus = CAUCASUS_TYPES.includes(appType);

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: isBelarus || isCaucasus ? [roles.ADMIN, roles.JTI_TEAM] : [roles.ADMIN],
  label: 'Legal Entities',
  subMenuName: 'outlets',
};

export default {
  list: LegalEntitiesList,
  create: LegalEntityCreate,
  edit: LegalEntityEdit,
  icon: PermIdentityIcon,
  name: 'admin/legal-entities',
  options,
};

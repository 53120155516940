import React from 'react';
import { Create, ReferenceInput, TextInput, NumberInput, SelectInput, BooleanInput } from 'react-admin';
import { statuses } from '../../../config/constants';
import QuizForm from '../../../commonComponents/QuizForm';
import { CustomDateInput } from '../../../commonComponents/CustomDateInput';
import { isIreland } from '../../../helpers/appTypeHelper';

const QuizCreate = (props) => (
  <Create title="Create Quiz" {...props}>
    <QuizForm redirect="list">
      <TextInput label="Name" source="name" />
      <ReferenceInput label="Topic" source="brand_id" reference="services/brands">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <SelectInput source="status" optionText="name" choices={statuses.slice(0, 2)} />
      {isIreland() && (
        <BooleanInput
          style={{ width: '300px' }}
          label="Included in the Progress bar"
          source="progress_bar_enable"
          defaultValue={true}
        />
      )}
      <NumberInput label="Price" source="price" />
      <CustomDateInput label="Publish date" source="published_at" />
    </QuizForm>
  </Create>
);

export default QuizCreate;

import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import InteractiveModerationList from './components/InteractiveModerationList';
import { roles, APP_TYPES } from '../../config/constants';

const options = {
  appTypes: [APP_TYPES.HUNGARY, APP_TYPES.IRELAND],
  allowedRoles: [roles.ADMIN],
  label: 'Interactive Moderation',
  subMenuName: 'content',
};

export default {
  list: InteractiveModerationList,
  icon: ThumbUpIcon,
  name: 'users-answers',
  options,
};

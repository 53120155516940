import React from 'react';
import { Box, Typography } from '@mui/material';
import {
  CreateButton,
  Datagrid,
  DeleteButton,
  EditButton,
  List,
  TextField,
  useListContext,
  usePermissions,
  useTranslate,
} from 'react-admin';
import { CustomDateField } from '../../../commonComponents/DateField';
import StatusField from '../../../commonComponents/StatusField';
import { cpaProgramSelectStatuses } from '../../../config/constants';
import CustomListActions from '../../../commonComponents/ListActions/CustomListActions';

const ProgramsList = ({ history, ...props }) => {
  const { permissions: userRoles } = usePermissions();
  const isBA = userRoles && userRoles.includes('BA');
  const translate = useTranslate();
  const toCPAProgram = ({ id, name }) => {
    history.push(`cpa-program/view/${id}/${name}`);
  };

  const Empty = () => {
    const { basePath } = useListContext();
    return (
      <Box textAlign="center" m={1} marginTop={20}>
        <Typography variant="h4" paragraph>
          {translate('root.cpa.empty.title')}
        </Typography>

        {!isBA && (
          <>
            <Typography marginBottom={3} variant="body1">
              {translate('root.cpa.empty.description')}
            </Typography>
            <CreateButton basePath={basePath} />
          </>
        )}
      </Box>
    );
  };

  return (
    <List
      hasCreate={!isBA}
      title={translate('root.cpa.list.title')}
      exporter={false}
      empty={<Empty />}
      actions={<CustomListActions importer={false} exporter={false} creation={!isBA} />}
      bulkActionButtons={false}
      {...props}
    >
      <Datagrid rowClick={(id, basePath, record) => toCPAProgram(record)}>
        <TextField source="id" sortable={false} />
        <TextField source="name" sortable={false} />
        <CustomDateField source="end_date" sortable={false} showTime={false} />
        <StatusField label="Status" source="status" sortable={false} statuses={cpaProgramSelectStatuses} />

        {!isBA && <EditButton />}
        {!isBA && <DeleteButton undoable={false} />}
      </Datagrid>
    </List>
  );
};

export default ProgramsList;

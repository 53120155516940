import React from 'react';
import { Datagrid, DeleteButton, List, NumberField, ReferenceField, TextField } from 'react-admin';

import ImageField from '../../../../commonComponents/ImageField';

const CategoriesList = (props) => (
  <List title="Categories" exporter={false} bulkActionButtons={false} sort={{ field: 'id', order: 'DESC' }} {...props}>
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <TextField label="Name" source="name" />
      <ImageField label="Image" source="photo" />
      <NumberField label="Priority" source="priority" />
      <ReferenceField
        label="Transaction Type"
        source="transaction_type_id"
        reference="admin/transactions/types"
        perPage={100}
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Type" source="type" reference="admin/bonus-shop/category-types" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
);

export default CategoriesList;

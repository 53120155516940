import React from 'react';
import { Create, NumberInput, required, SelectInput, SimpleForm, TextInput } from 'react-admin';
import RewardInput from '../../../commonComponents/RewardInput';
import TargetGroupInput from '../../../commonComponents/TargetGroupInput';
import ImageInput from '../../../commonComponents/UploadInputs/ImageInput';
import { s3FilePaths, statuses } from '../../../config/constants';

const ChanceCreate = (props) => {
  const rewardInputFields = [
    { inputLabel: 'Prize', inputType: 'string', radioLabel: 'Prizes', source: 'product_name' },
    { inputLabel: 'Coins', inputType: 'number', radioLabel: 'Coins', source: 'coins' },
  ];

  return (
    <Create title="Create Chance" {...props}>
      <SimpleForm redirect="list">
        <TextInput label="Name" source="name" />
        <TextInput label="Description" source="description" />
        <NumberInput label="Total" source="total" />
        <TextInput label="Cost" source="cost" />
        <RewardInput formType="create" inputFields={rewardInputFields} />
        <SelectInput source="status" optionText="name" choices={statuses.slice(0, 2)} />
        <NumberInput label="Number of winners" source="winner_count" />
        <ImageInput imageInputSource="photo" validate={required()} imagePath={s3FilePaths.allUploads} />
        <TargetGroupInput />
      </SimpleForm>
    </Create>
  );
};

export default ChanceCreate;

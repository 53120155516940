import React from 'react';
import { Datagrid, TextField, useListContext, FunctionField } from 'react-admin';
import { isGeorgia } from '../../../../helpers/appTypeHelper';
import { CustomDateField } from '../../../../commonComponents/DateField';

export const TransactionReportsDataGrid = () => {
  const { filterValues } = useListContext();

  const isMobileTopupTransactionType = filterValues.type_id === 17 && isGeorgia();

  if (isMobileTopupTransactionType) {
    const conversion_ratio = -70;
    return (
      <Datagrid>
        <TextField label="User ID" source="user_id" />
        <TextField label="User Name" source="user.name" />
        <TextField label="User Surname" source="user.surname" />
        <TextField label="Coins" source="coins" />
        <FunctionField label="GEL" render={({ coins }) => coins / conversion_ratio} />
        <TextField label="Phone" source="user.phone" />
        <TextField label="Transaction ID" source="id" />
        <CustomDateField label="Created At" source="created_at" />
        <TextField label="Outlet code" source="user.account_code" />
      </Datagrid>
    );
  }

  return (
    <Datagrid>
      <TextField label="ID" source="id" />
      <TextField label="User ID" source="user_id" />
      <TextField label="User Name" source="user.name" />
      <TextField label="User Surname" source="user.surname" />
      <CustomDateField label="Created at" source="created_at" />
      <TextField label="Status" source="status" />
      <TextField label="Type" source="type_model.name" sortable={false} />
      <TextField label="Coins" source="coins" />
    </Datagrid>
  );
};

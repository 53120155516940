import StoreIcon from '@material-ui/icons/Store';
import MysteryShopperList from './components/MysteryShopperList';
import MysteryShopperEdit from './components/MysteryShopperEdit';
import MysteryShopperCreate from './components/MysteryShopperCreate';
import { APP_TYPES, roles } from '../../config/constants';

const options = {
  appTypes: [APP_TYPES.IRELAND],
  allowedRoles: [roles.JTI_TEAM, roles.ADMIN],
  label: 'Mystery Shopper',
  subMenuName: 'content',
};

export default {
  list: MysteryShopperList,
  create: MysteryShopperCreate,
  edit: MysteryShopperEdit,
  icon: StoreIcon,
  name: 'admin/mystery-shopper',
  options,
};

export const registeredUsersLinearDiagramFields = {
  downloadedUsers: 'Downloaded users',
  notDownloadedUsers: 'Not downloaded users',
};

export const linearDiagramPeriodOptions = [
  { id: 'ALL', name: 'All period' },
  { id: 'LAST_WEEK', name: 'Last week' },
  { id: 'LAST_MONTH', name: 'Last month' },
];

export const userPerformanceStatuses = {
  0: { color: '#4CBF8F', title: 'Good performance' },
  1: { color: '#FFAC30', title: 'Need attention' },
  2: { color: '#FF6666', title: 'Low activity' },
};

export const statuses = [
  { title: ' ', value: '' },
  { color: '#4CBF8F', title: 'Good performance', value: 0 },
  { color: '#FFAC30', title: 'Need attention', value: 1 },
  { color: '#FF6666', title: 'Low activity', value: 2 },
];

import React from 'react';
import { Filter, TextInput, ReferenceInput, SelectInput } from 'react-admin';
import { CustomDateInput } from '../../../../commonComponents/CustomDateInput';

const TransactionReportsFilter = (props) => (
  <Filter {...props}>
    <CustomDateInput label="Choose start date" source="from" start alwaysOn />
    <CustomDateInput label="Choose end date" source="to" end alwaysOn />
    <ReferenceInput label="Type" source="type_id" reference="admin/transactions/types" perPage={100} alwaysOn>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <TextInput label="User ID" source="user_id" alwaysOn />
  </Filter>
);

export default TransactionReportsFilter;

import React from 'react';
import { List, Datagrid, TextField, EditButton, DeleteButton, NumberField } from 'react-admin';

import ImageField from '../../../../commonComponents/ImageField';

const RewardsList = ({ classes, ...props }) => (
  <List label="Rewards" bulkActionButtons={false} exporter={false} sort={{ field: 'id', order: 'DESC' }} {...props}>
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" sortable={false} />
      <TextField label="type" source="type.name" sortable={false} />
      <ImageField label="Image" source="image" sortable={false} />
      <NumberField label="Count" source="count" sortable={false} />
      <EditButton />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
);

export default RewardsList;

import { Button } from '@mui/material';
import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DownloadIcon from '@material-ui/icons/GetApp';
import checkUserRole from '../../../../helpers/checkUserRole';
import { roles } from '../../../../config/constants';

export const CustomActions = ({ editButtonHandler, exportButtonHandler }) => (
  <div>
    {!checkUserRole(roles.RSM, roles.BA) && (
      <Button startIcon={<EditIcon />} onClick={editButtonHandler}>
        EDIT
      </Button>
    )}
    <Button startIcon={<DownloadIcon />} onClick={exportButtonHandler}>
      EXPORT
    </Button>
  </div>
);

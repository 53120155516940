import React from 'react';
import { Create, NumberInput, SelectInput, SimpleForm, TextInput } from 'react-admin';
import { CustomDateInput } from '../../../commonComponents/CustomDateInput';
import { statuses } from '../../../config/constants';
import { questionsValidate } from '../../../helpers/validators';
import QuestionForm from './QuestionForm';

const SingleModeCreate = (props) => (
  <Create title="Create Quiz" {...props}>
    <SimpleForm redirect="list">
      <TextInput label="Name" source="name" />
      <SelectInput source="status" optionText="name" choices={statuses.slice(0, 2)} />
      <NumberInput label="First state coins" source="first_stage_coins" />
      <NumberInput label="Second state coins" source="second_stage_coins" />
      <NumberInput label="Third state coins" source="third_stage_coins" />
      <NumberInput label="All stage bonus" source="all_stage_bonus" />
      <CustomDateInput label="Activates at" source="activated_at" />
      <QuestionForm name="questions" validate={questionsValidate} populate />
    </SimpleForm>
  </Create>
);

export default SingleModeCreate;

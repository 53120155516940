import React from 'react';
import { useNotify, useRefresh, useUpdateLoading, Button } from 'react-admin';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import { serverApiUrl } from '../../../config/constants';
import httpClient from '../../../core/httpClient';

const ModerationButton = ({ record, statusToSet }) => {
  const notify = useNotify();
  const { startLoading, stopLoading } = useUpdateLoading();
  const refresh = useRefresh();
  const { id, user, interactive } = record;

  const getMessageParams = () => {
    const url = `${serverApiUrl}/admin/interactive-articles/answer-status`;
    const data = {
      id,
      interactive_article_id: interactive.id,
      user_id: user.id,
      correct: statusToSet,
    };
    const options = { method: 'PUT', body: JSON.stringify(data) };

    return { url, options };
  };

  const setStatus = async (data) => {
    const { url, options } = getMessageParams(data);

    try {
      startLoading();
      await httpClient(url, options);
      refresh();
    } catch (e) {
      notify(`"Error: Answer wasn't ${statusToSet ? 'accepted' : 'failed'}"`, 'warning');
      console.error(e);
    } finally {
      stopLoading();
    }
  };

  return (
    <Button label={statusToSet ? 'Accept' : 'Fail'} onClick={setStatus}>
      {statusToSet ? <CheckCircleIcon /> : <CancelIcon />}
    </Button>
  );
};

export default ModerationButton;

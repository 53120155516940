import React from 'react';
import { ArrayInput, Edit, NumberInput, required, SimpleForm, SimpleFormIterator, TextInput } from 'react-admin';

const LevelsPackageEdit = (props) => (
  <Edit title="Edit Levels Package" undoable={false} {...props}>
    <SimpleForm redirect="list">
      <TextInput label="Name" source="name" validate={required()} />
      <ArrayInput source="levels_rewards">
        <SimpleFormIterator>
          <NumberInput source="start_level" label="Start level" />
          <NumberInput source="final_level" label="Final level" />
          <NumberInput source="task_reward" label="Reward for 1 task" />
          <NumberInput source="category_reward" label="Reward for category" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);

export default LevelsPackageEdit;

import React from 'react';
import { Box, Typography } from '@mui/material';
import { CreateButton, Datagrid, List, TextField, useListContext, DateField } from 'react-admin';

import StatusField from '../../../commonComponents/StatusField';
import CustomListActions from '../../../commonComponents/ListActions/CustomListActions';
import { RowActionToolbar } from './RowActionToolbar';

import checkUserRole from '../../../helpers/checkUserRole';
import { roles, tradeProgramsPhasesOptions } from '../../../config/constants';

const Empty = () => {
  const { basePath } = useListContext();
  return (
    <Box textAlign="center" m={1} marginTop={20}>
      <Typography variant="h4" paragraph>
        No Trade Program yet
      </Typography>

      {checkUserRole(roles.ADMIN, roles.JTI_TEAM) && (
        <>
          <Typography marginBottom={3} variant="body1">
            Do you want to add one?
          </Typography>
          <CreateButton basePath={basePath} />
        </>
      )}
    </Box>
  );
};

const TradeProgram = ({ history, ...props }) => {
  const toTradeProgramAddresses = ({ record }) => {
    history.push(`trade-program/${record.id}/addresses`);
  };

  return (
    <List
      hasCreate={checkUserRole(roles.ADMIN, roles.JTI_TEAM)}
      title={'Trade program'}
      exporter={false}
      empty={<Empty />}
      actions={
        <CustomListActions importer={false} exporter={false} creation={checkUserRole(roles.ADMIN, roles.JTI_TEAM)} />
      }
      bulkActionButtons={false}
      {...props}
    >
      <Datagrid>
        <TextField source="id" sortable={false} />
        <TextField source="name" sortable={false} />
        <StatusField source="phase" statuses={tradeProgramsPhasesOptions} />
        <DateField source="phase_start_date" sortable={false} />
        <DateField source="phase_end_date" sortable={false} />
        <RowActionToolbar openButtonHandel={toTradeProgramAddresses} />
      </Datagrid>
    </List>
  );
};

export default TradeProgram;

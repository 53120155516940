import React from 'react';
import { AutocompleteInput, ReferenceInput } from 'react-admin';
import { APP_TYPES } from '../config/constants';

const formatText = (user) => {
  if (!user?.surname) {
    return '';
  }
  const fullName =
    process.env.REACT_APP_APP_TYPE === APP_TYPES.IRELAND ? `${user.username}` : `${user.name} ${user.surname}`;

  if (user.id) {
    return `(${user.id}) ${fullName}`;
  }

  return fullName;
};
const ManagerAutocompleteInput = ({
  label,
  source = 'manager_id',
  reference = 'admin/managers/autocomplete',
  ...otherProps
}) => (
  <ReferenceInput label={label} source={source} reference={reference} allowEmpty {...otherProps}>
    <AutocompleteInput
      optionText={formatText}
      options={{
        suggestionsContainerProps: {
          style: { zIndex: 1301 },
        },
      }}
    />
  </ReferenceInput>
);

export default ManagerAutocompleteInput;

import QuizIcon from '@material-ui/icons/FormatListNumbered';
import QuizzesList from './components/QuizzesList';
import QuizCreate from './components/QuizCreate';
import QuizEdit from './components/QuizEdit';
import { roles, APP_TYPES } from '../../../config/constants';

const ENV_APP_TYPE = process.env.REACT_APP_APP_TYPE;

const options = {
  appTypes: [APP_TYPES.BELARUS, APP_TYPES.HUNGARY],
  allowedRoles: [APP_TYPES.HUNGARY, APP_TYPES.BELARUS].includes(ENV_APP_TYPE)
    ? [roles.ADMIN]
    : [roles.ADMIN, roles.JTI_TEAM],
  label: 'Event Mode: Quizzes',
  subMenuName: 'content',
};

export default {
  list: QuizzesList,
  create: QuizCreate,
  edit: QuizEdit,
  icon: QuizIcon,
  name: 'admin/event-mode/quizzes',
  options,
};

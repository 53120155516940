import React from 'react';
import {
  ArrayInput,
  NumberInput,
  SelectInput,
  SimpleFormIterator,
  ReferenceInput,
  ReferenceArrayInput,
  SelectArrayInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import ColorCreate from './ColorCreate';
import { APP_TYPES } from '../../../../config/constants';

const useStyles = makeStyles({
  customFormField: {
    width: 256,
  },
  hideFormLabel: {
    '& li > p': {
      display: 'none',
    },
  },
});

const ConditionalColorInput = () => {
  const classes = useStyles();

  return (
    <>
      <ColorCreate />
      <br />
      {process.env.REACT_APP_APP_TYPE === APP_TYPES.BELARUS ? (
        <ArrayInput
          label="Colors"
          className={`${classes.customFormField} ${classes.hideFormLabel}`}
          source="colors_with_count"
          resource="admin/bonus-shop/presents"
        >
          <SimpleFormIterator>
            <ReferenceInput label="Color" source="color_id" reference="admin/colors">
              <SelectInput optionText="name" />
            </ReferenceInput>
            <NumberInput label="Count" source="count" />
          </SimpleFormIterator>
        </ArrayInput>
      ) : (
        <ReferenceArrayInput
          label="Present colors"
          className={classes.customFormField}
          source="colors"
          reference="admin/colors"
          resource="admin/bonus-shop/presents"
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
      )}
    </>
  );
};

export default ConditionalColorInput;

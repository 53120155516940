import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { ImageField, ImageInput, useNotify, useTranslate } from 'react-admin';
import { useForm } from 'react-final-form';
import { s3FilePaths, serverApiUrl } from '../../config/constants';
import httpClient from '../../core/httpClient';
import { fileUploadStyles } from './styles';

const useStyles = makeStyles(fileUploadStyles);

const UploadImageInput = (props) => {
  const {
    label,
    imageInputSource,
    imagePreviewSource = 'photo',
    imagePath = s3FilePaths.images,
    noLabel,
    ...rest
  } = props;

  const form = useForm();
  const translate = useTranslate();
  const notify = useNotify();
  const classes = useStyles();
  const [imagePreview, setImagePreview] = useState();

  useEffect(() => {
    const file_path = form.getFieldState(imageInputSource).value;

    if (file_path) {
      const url = `${serverApiUrl}/services/file?file_key=${file_path}`;
      const options = { method: 'GET' };
      httpClient(url, options).then((res) => {
        setImagePreview(res.json.url);
      });
    }
  }, []);

  const uploadImage = (file) => {
    const formData = new FormData();
    formData.append('file', file[0]);
    formData.append('file_path', imagePath);
    const url = `${serverApiUrl}/admin/services/file`;
    const options = { method: 'POST', body: formData };

    httpClient(url, options)
      .then((res) => {
        form.change(imageInputSource, res.json.file_key);
        setImagePreview(res.json.url);
      })
      .catch(() => {
        notify("Error: Image wasn't sent", 'warning');
      });
  };

  const formatFunc = (value) => {
    if (typeof value === 'string') {
      return { [imagePreviewSource]: imagePreview };
    }

    return value;
  };

  return (
    <ImageInput
      classes={{
        dropZone: props.noMargin ? classes.dropZoneNoMargin : classes.dropZone,
        removeButton: classes.removeButton,
      }}
      accept="image/*"
      source={imageInputSource}
      format={formatFunc}
      label={noLabel ?? label}
      placeholder={<span>{translate('root.uploadImage')}</span>}
      options={{ onDropAccepted: uploadImage }}
      {...rest}
    >
      <ImageField classes={{ image: classes.image }} source={imagePreviewSource} />
    </ImageInput>
  );
};

export default UploadImageInput;

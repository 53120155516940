import React from 'react';
import { Edit, number, required, SimpleForm, TextInput } from 'react-admin';
import ManagerAutocompleteInput from '../../../commonComponents/ManagerAutocompleteInput';
import { ACCOUNT_CODE_LENGTH } from '../../../config/constants';
import { numberLengthValidator } from '../../../helpers/validators';

const AccountNumberEdit = (props) => (
  <Edit title="Edit account number" undoable={false} {...props}>
    <SimpleForm redirect="list">
      <TextInput
        label="Account number"
        source="account_codes"
        validate={[required(), number(), numberLengthValidator(ACCOUNT_CODE_LENGTH[process.env.REACT_APP_APP_TYPE])]}
      />
      <ManagerAutocompleteInput label="BA" source="user_id" validate={required()} />
    </SimpleForm>
  </Edit>
);

export default AccountNumberEdit;

import React from 'react';
import { TextInput, NumberInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import ImageInput from '../../../commonComponents/UploadInputs/ImageInput';
import RewardInput from '../../../commonComponents/RewardInput';
import { challengesRewardTypes } from '../config/constants';
import { s3FilePaths } from '../../../config/constants';

const useStyles = makeStyles({
  customFormField: {
    width: 256,
    display: 'flex',
  },
});

const Reward = ({ member, rewardFormType, formType, record, isUploadBackgroundButtonShown }) => {
  const classes = useStyles();
  const winnersNumberFieldLabel =
    rewardFormType === challengesRewardTypes.completedQuestsNumber
      ? 'Number of tasks to complete'
      : 'Number of winners';
  const winnersNumberFieldSource =
    rewardFormType === challengesRewardTypes.completedQuestsNumber ? 'tasks_to_complete' : 'number_of_winners';
  const rewardInputFields = [
    { inputLabel: 'Reward name', inputType: 'string', radioLabel: 'Prizes', source: `${member}.name` },
    { inputLabel: 'Coins', inputType: 'number', radioLabel: 'Coins', source: `${member}.coins` },
  ];

  return (
    <>
      <NumberInput
        source={`${member}.${winnersNumberFieldSource}`}
        label={winnersNumberFieldLabel}
        className={classes.customFormField}
      />
      <RewardInput record={record} formType={formType} inputFields={rewardInputFields} isFirstFieldPersist={true} />
      <TextInput source={`${member}.description`} label="Reward description" className={classes.customFormField} />
      {isUploadBackgroundButtonShown && (
        <ImageInput imageInputSource={`${member}.photo`} imagePath={s3FilePaths.allUploads} />
      )}
    </>
  );
};

export default Reward;

import React from 'react';
import { SimpleForm, useNotify } from 'react-admin';
import ImageInput from '../../../commonComponents/UploadInputs/ImageInput';
import { s3FilePaths, serverApiUrl } from '../../../config/constants';

import httpClient from '../../../core/httpClient';

const LepSettings = ({ loaded, data, ids }) => {
  const notify = useNotify();
  const record = loaded ? data[ids[0]] : {};

  const handleSubmit = ({ lep_registration_banner, lep_winner_banner }) => {
    const url = `${serverApiUrl}/admin/settings/lep`;
    const body = { lep_registration_banner, lep_winner_banner };
    const options = { method: 'POST', body: JSON.stringify(body) };

    httpClient(url, options)
      .then(() => notify('LEP settings were update successfully', 'success'))
      .catch(() => notify("Error: LEP settings weren't update", 'warning'));
  };

  return (
    <SimpleForm resource="admin/settings" onSubmit={handleSubmit} save={handleSubmit} record={record}>
      <ImageInput
        label="Registration banner"
        imageInputSource="lep_registration_banner"
        imagePath={s3FilePaths.allUploads}
      />
      <ImageInput label="Winner banner" imageInputSource="lep_winner_banner" imagePath={s3FilePaths.allUploads} />
    </SimpleForm>
  );
};

export default LepSettings;

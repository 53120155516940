import React from 'react';
import { List, Datagrid, TextField, EditButton, DeleteButton } from 'react-admin';

const SimulatorReactionsList = (props) => (
  <List
    title="Simulator reactions"
    exporter={false}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <TextField label="Name" source="name" />
      <EditButton />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
);

export default SimulatorReactionsList;

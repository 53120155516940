import React from 'react';
import {
  Edit,
  TabbedForm,
  FormTab,
  Datagrid,
  TextField,
  TextInput,
  NumberInput,
  SelectInput,
  ReferenceInput,
  ReferenceManyField,
} from 'react-admin';
import Button from '@material-ui/core/Button';
import { Link, useLocation } from 'react-router-dom';
import ImageInput from '../../../../commonComponents/UploadInputs/ImageInput';
import EditViewToolbarWithoutDelete from '../../../../commonComponents/EditViewToolbarWithoutDelete';
import { s3FilePaths } from '../../../../config/constants';

const EditProductButton = ({ record }) => {
  const location = useLocation();

  if (!record) {
    return null;
  }

  const urlParts = location.pathname.split('/');
  const provider_id = urlParts[urlParts.length - 2];

  return (
    <Button
      component={Link}
      to={{
        pathname: `/admin/products/${record.id}`,
        state: { provider_id, name: record.name, description: record.description },
      }}
    >
      Edit
    </Button>
  );
};

const ProviderEdit = (props) => (
  <Edit title="Edit Provider" undoable={false} {...props}>
    <TabbedForm toolbar={<EditViewToolbarWithoutDelete />}>
      <FormTab label="Provider">
        <TextInput label="ID" source="id" disabled />
        <TextInput label="Name" source="name" />
        <ReferenceInput label="Category" source="category_id" reference="admin/products/categories">
          <SelectInput />
        </ReferenceInput>
        <NumberInput label="Priority" source="priority" />
        <ImageInput imageInputSource="photo" imagePath={s3FilePaths.bonusShop} />
      </FormTab>
      <FormTab label="Products" path="products">
        <ReferenceManyField addLabel={false} reference="admin/products" target="id">
          <Datagrid>
            <TextField label="ID" source="id" sortable={false} />
            <TextField label="Name" source="name" sortable={false} />
            <TextField label="Price" source="price" sortable={false} />
            <TextField label="Nominal Price" source="nominal_price" sortable={false} />
            <TextField label="Description" source="description" sortable={false} />
            <EditProductButton />
          </Datagrid>
        </ReferenceManyField>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export default ProviderEdit;

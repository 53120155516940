import React from 'react';
import { Filter, TextInput } from 'react-admin';
import { CustomDateInput } from '../../../commonComponents/CustomDateInput';

const LogsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Admin ID" source="id" alwaysOn />
    <CustomDateInput label="Start Date" source="start_date" start />
    <CustomDateInput label="End Date" source="end_date" end />
  </Filter>
);

export default LogsFilter;

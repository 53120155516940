import React from 'react';
import { Edit, SelectInput, TextInput } from 'react-admin';
import { statuses } from '../../../config/constants';
import QuizForm from '../../../commonComponents/QuizForm';

const SurveyEdit = (props) => (
  <Edit title="Edit survey" undoable={false} {...props}>
    <QuizForm quizType="survey">
      <TextInput label="ID" source="id" disabled />
      <TextInput label="Name" source="name" />
      <SelectInput source="status" optionText="name" choices={statuses.slice(0, 2)} />
    </QuizForm>
  </Edit>
);

export default SurveyEdit;

import React from 'react';
import { Datagrid, DeleteButton, List, ReferenceField, TextField } from 'react-admin';
import { CustomDateField } from '../../../../commonComponents/DateField';
import CouponsFilter from './CouponsFilter';
import PreSignedUrlField from '../../../../commonComponents/PreSignedUrlField';

const CouponsList = (props) => (
  <List title="Coupons" filters={<CouponsFilter />} exporter={false} bulkActionButtons={false} {...props}>
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <PreSignedUrlField label="PDF link" source="pdf_link" />
      <TextField label="Code" source="code" />
      <CustomDateField label="Created at" source="created_at" />
      <CustomDateField label="Expired at" source="expired_at" />
      <ReferenceField label="Product ID" source="product_id" reference="admin/bonus-shop/products">
        <TextField source="description" />
      </ReferenceField>
      <CustomDateField label="Created at" source="created_at" />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
);

export default CouponsList;

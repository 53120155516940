import React from 'react';
import { List, Datagrid, TextField, EditButton, DeleteButton } from 'react-admin';
import TargetGroupFilter from './TargetGroupFilter';

const TargetGroupsList = (props) => (
  <List
    exporter={false}
    bulkActionButtons={false}
    filters={<TargetGroupFilter />}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <EditButton />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
);

export default TargetGroupsList;

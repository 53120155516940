import React from 'react';
import { useTranslate } from 'react-admin';
import { Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CircularDiagram from '../../../../../commonComponents/CircularDiagram';
import { APP_TYPES, CAUCASUS_TYPES, linearDiagramFields } from '../../../../../config/constants';

const ENV_APP_TYPE = process.env.REACT_APP_APP_TYPE;

const showOnlySelectedItemsCapture = ![...CAUCASUS_TYPES, APP_TYPES.BELARUS].includes(ENV_APP_TYPE);

const useStyles = makeStyles({
  cardContainer: {
    display: 'flex',
    width: 'calc(50% - 10px)',
    height: 270,
    marginBottom: 20,
  },
  cardContainerInGrid: {
    display: 'flex',
    height: 270,
    marginBottom: 20,
    '&:nth-child(odd)': {
      marginRight: 10,
    },
    '&:nth-child(even)': {
      marginLeft: 10,
    },
  },
  cardDetails: {
    width: '55%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: '60px 0 0 60px',
    fontSize: 16,
    lineHeight: '19px',
  },
  cardDetailsTitle: {
    fontWeight: 'bold',
    fontSize: 22,
    marginBottom: 10,
  },
  diagramContainer: {
    width: '45%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  categoryName: {
    fontWeight: 'bold',
  },
});

const CardWithDiagram = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const {
    title,
    data,
    colors,
    selectedItems = [],
    handleClick,
    handleClickOutside,
    grid = false,
    totalFieldName = null,
  } = props;

  const handleOnContainerClick = () => {
    if (handleClickOutside) {
      handleClickOutside();
    }
  };

  const total = data.length
    ? data.reduce((acc, cur) => {
        if (
          (ENV_APP_TYPE === APP_TYPES.HUNGARY || CAUCASUS_TYPES.includes(ENV_APP_TYPE)) &&
          cur.name === linearDiagramFields.activeUsers
        ) {
          return acc;
        }

        return acc + cur.value;
      }, 0)
    : 0;

  const getItemsCaptureWithValues = () => {
    const getAllItemsWithValues = () => (
      <>
        <div>
          {totalFieldName || translate('root.statistics.allContacts')}: {total}
        </div>
        {data.map((item, index) => (
          <div key={`${item.name}-${index}`} className={classes.category}>
            <span className={classes.categoryName} style={{ color: colors[item.name] }}>
              {translate(`root.statistics.${item.name}`)}
            </span>
            : {data.find((x) => x.name === item.name).value} (
            {((100 * data.find((x) => x.name === item.name).value) / total || 0).toFixed(2)}%)
          </div>
        ))}
      </>
    );

    const getSelectedItemsWithValues = () =>
      selectedItems.length ? (
        selectedItems.map((item, index) => (
          <div key={`${item.name}-${index}`} className={classes.category}>
            <span className={classes.categoryName} style={{ color: colors[item.name] }}>
              {translate(`root.statistics.${item.name}`)}
            </span>
            : {data.find((x) => x.name === item.name).value} (
            {((100 * data.find((x) => x.name === item.name).value) / total || 0).toFixed(2)}%)
          </div>
        ))
      ) : (
        <div>
          {totalFieldName || translate('root.statistics.allContacts')}: {total}
        </div>
      );

    return showOnlySelectedItemsCapture ? getSelectedItemsWithValues() : getAllItemsWithValues();
  };

  return (
    <Card className={grid ? classes.cardContainerInGrid : classes.cardContainer} onClick={handleOnContainerClick}>
      <div className={classes.cardDetails}>
        <div className={classes.cardDetailsTitle}>{title}</div>
        {getItemsCaptureWithValues()}
      </div>
      <div className={classes.diagramContainer}>
        <CircularDiagram handleClick={handleClick} data={data} colors={colors} selectedItems={selectedItems} />
      </div>
    </Card>
  );
};

export default CardWithDiagram;

import React from 'react';
import {
  BooleanField,
  Datagrid,
  DeleteButton,
  EditButton,
  List,
  NumberField,
  ReferenceField,
  TextField,
} from 'react-admin';
import { CustomDateField } from '../../../../commonComponents/DateField';
import ProductsFilter from './ProductsFilter';

const ProductsList = (props) => (
  <List
    title="Digital Products"
    filters={<ProductsFilter />}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <TextField label="Description" source="description" />
      <NumberField label="Price" source="price" />
      <NumberField label="Promo price" source="promo_price" />
      <ReferenceField label="Provider" source="provider_id" reference="admin/bonus-shop/providers">
        <TextField source="name" />
      </ReferenceField>
      <CustomDateField label="Created at" source="created_at" />
      <BooleanField label="Active" source="active" />
      <EditButton />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
);

export default ProductsList;

import React from 'react';
import { Filter, ReferenceInput, SelectInput } from 'react-admin';
import { CustomDateInput } from '../../../../commonComponents/CustomDateInput';
import UserAutocompleteInput from '../../../../commonComponents/UserAutocompleteInput';

const SingleModeReportsFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput label="Single mode quiz" source="pack_id" reference="admin/single-mode" alwaysOn>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <UserAutocompleteInput label="User ID" source="user_id" reference="admin/users/autocomplete" alwaysOn />
    <CustomDateInput label="Choose start date" source="start_date" alwaysOn start />
    <CustomDateInput label="Choose end date" source="end_date" alwaysOn end />
  </Filter>
);

export default SingleModeReportsFilter;

import IndependentItemIcon from '@material-ui/icons/IndeterminateCheckBox';
import { APP_TYPES, roles } from '../../config/constants';
import IndependentItemsList from './components/IndependentItemsList';
import IndependentItemCreate from './components/IndependentItemCreate';
import IndependentItemEdit from './components/IndependentItemEdit';

const options = {
  appTypes: [APP_TYPES.IRELAND],
  allowedRoles: [roles.ADMIN, roles.JTI_TEAM],
  label: 'Independent Items',
  subMenuName: 'content',
};

export default {
  list: IndependentItemsList,
  create: IndependentItemCreate,
  edit: IndependentItemEdit,
  name: 'admin/independent-items',
  icon: IndependentItemIcon,
  options,
};

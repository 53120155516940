import React from 'react';
import { Datagrid, DeleteButton, EditButton, List, TextField } from 'react-admin';
import StatusField from '../../../commonComponents/StatusField';
import { CustomDateField } from '../../../commonComponents/DateField';
import CustomListActions from '../../../commonComponents/ListActions/CustomListActions';

const SingleModeList = (props) => (
  <List
    title="Single mode quizzes"
    bulkActionButtons={false}
    exporter={false}
    actions={<CustomListActions />}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" sortable={false} />
      <TextField label="Name" source="name" sortable={false} />
      <StatusField label="Status" source="status" sortable={false} />
      <CustomDateField label="Activates at" source="activated_at" sortable={false} />
      <EditButton />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
);

export default SingleModeList;

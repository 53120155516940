import React, { useEffect, useState } from 'react';
import { AppBar, useLocale, useSetLocale, useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Menu, MenuItem, Tooltip, Typography } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon, Translate as LanguageIcon } from '@material-ui/icons';
import { APP_TYPES, availableLanguages } from '../../config/constants';

const useStyles = makeStyles((theme) => ({
  language: {
    margin: theme.spacing(0, 0.5, 0, 1),
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

const isBelarus = process.env.REACT_APP_APP_TYPE === APP_TYPES.BELARUS;

const CustomAppBar = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const locale = useLocale();
  const setLocale = useSetLocale();
  const [languageMenu, setLanguageMenu] = useState(null);

  useEffect(() => {
    const locale = localStorage.getItem('locale') || (isBelarus && 'ru');

    if (locale) {
      setLocale(locale);
    }
  }, [setLocale]);

  const handleLanguageIconClick = (event) => {
    setLanguageMenu(event.currentTarget);
  };

  const handleLanguageMenuClose = (language) => {
    setLocale(language);
    localStorage.setItem('locale', language);
    setLanguageMenu(null);
  };

  return (
    <AppBar {...props}>
      <Typography variant="h6" color="inherit" className={classes.title} id="react-admin-title" />
      {[APP_TYPES.HUNGARY, APP_TYPES.BELARUS].includes(process.env.REACT_APP_APP_TYPE) && (
        <>
          <Tooltip title={translate('root.i18n.changeLanguage')} enterDelay={300}>
            <Button color="inherit" onClick={handleLanguageIconClick}>
              <LanguageIcon />
              <span className={classes.language}>{availableLanguages[locale]}</span>
              <ExpandMoreIcon fontSize="small" />
            </Button>
          </Tooltip>
          <Menu anchorEl={languageMenu} open={!!languageMenu} onClose={handleLanguageMenuClose}>
            {Object.entries(availableLanguages).map(([language, languageName]) => (
              <MenuItem key={language} selected={locale === language} onClick={() => handleLanguageMenuClose(language)}>
                {languageName}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </AppBar>
  );
};

export default CustomAppBar;

import React, { useState, useEffect } from 'react';
import { useNotify } from 'react-admin';

import CardWithDiagram from '../CardWithDiagram';
import httpClient from '../../../../../core/httpClient';
import { APP_TYPES, CAUCASUS_TYPES, linearDiagramFields, serverApiUrl } from '../../../../../config/constants';

const ENV_APP_TYPE = process.env.REACT_APP_APP_TYPE;

const getAdminData = async (query = '') => {
  const url = `${serverApiUrl}/admin/users/manager/stats${query}`;
  const options = { method: 'GET' };

  return httpClient(url, options);
};

const BALinearDiagram = (props) => {
  const notify = useNotify();
  const { onPeriodChange, onCheckboxChange, selectedFilters, selectedPeriod } = props;

  const [summaryData, setSummaryData] = useState([]);
  const [periodType, setPeriodType] = useState();

  useEffect(() => {
    getAdminData()
      .then((response) => {
        setSummaryData(response.json.manager_data.data);
      })
      .catch((e) => {
        notify(`GET Admin data error: ${e.message}`, 'warning');
      });
  }, [notify]);

  useEffect(() => {
    const getQuery = () => {
      let query = '?';
      if (periodType) {
        query += `period_type=${periodType}&`;
      }
      return query;
    };

    const query = getQuery();

    getAdminData(query)
      .then((response) => {
        setSummaryData(response.json.manager_data.data);
      })
      .catch((e) => {
        notify(`GET Admin data error: ${e.message}`, 'warning');
      });
  }, [notify, periodType]);

  const handlePeriodChange = (event) => {
    if (onPeriodChange) {
      onPeriodChange(event);
    }
    setPeriodType(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    if (onCheckboxChange) {
      onCheckboxChange(event);
    }
  };

  const getCardTitle = () => {
    const total = summaryData.length
      ? summaryData.reduce((acc, cur) => {
          if (
            (ENV_APP_TYPE === APP_TYPES.HUNGARY || CAUCASUS_TYPES.includes(ENV_APP_TYPE)) &&
            cur.name === linearDiagramFields.activeUsers
          ) {
            return acc;
          }

          return acc + cur.value;
        }, 0)
      : 0;

    return `Your summary: ${total}`;
  };

  return (
    <CardWithDiagram
      title={getCardTitle()}
      data={summaryData}
      onPeriodChange={handlePeriodChange}
      onCheckboxChange={handleCheckboxChange}
      selectedFilters={selectedFilters}
      selectedPeriod={selectedPeriod}
    />
  );
};

export default BALinearDiagram;

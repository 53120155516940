import React from 'react';
import { Filter, TextInput } from 'react-admin';
import { CustomDateInput } from '../../../../commonComponents/CustomDateInput';

const QuizRatesReportsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Quiz id" source="quiz_id" alwaysOn />
    <CustomDateInput label="Choose start date" source="start_date" start alwaysOn />
    <CustomDateInput label="Choose end date" source="end_date" end alwaysOn />
  </Filter>
);

export default QuizRatesReportsFilter;

import React from 'react';
import {
  FormDataConsumer,
  maxValue,
  minValue,
  NumberInput,
  required,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  customFormField: {
    width: 256,
    display: 'flex',
  },
});

const Reward = ({ isEdit, ...props }) => {
  const classes = useStyles();

  return (
    <FormDataConsumer>
      {({ formData }) => {
        const { reward_of_points } = formData;
        const isDisabled = reward_of_points && reward_of_points.length === 3;
        return (
          <SimpleFormIterator disableAdd={isDisabled} {...props}>
            <TextInput source="id" style={{ display: 'none' }} />
            <NumberInput
              source="amount"
              label="Amount"
              className={classes.customFormField}
              min={0}
              validate={required()}
            />
            <NumberInput source="number_of_rewards" label="Number of Rewards" className={classes.customFormField} />
            <TextInput
              source="probability"
              label="Probability"
              className={classes.customFormField}
              validate={[required(), maxValue(1), minValue(0.01)]}
            />
            {isEdit && (
              <NumberInput
                source="remaining_rewards"
                label="Remaining rewards"
                className={classes.customFormField}
                disabled
              />
            )}
          </SimpleFormIterator>
        );
      }}
    </FormDataConsumer>
  );
};

export default Reward;

import { ReferenceInput, SelectInput } from 'react-admin';
import React from 'react';

function CategorySelector({ categoryType, ...props }) {
  return (
    <ReferenceInput
      label="Category"
      source={props.source || 'knowledge_bank_category_id'}
      reference="admin/knowledge-bank/categories"
      perPage={5000}
      allowEmpty
      filter={{ type: categoryType }}
      emptyValue={null}
      {...props}
    >
      <SelectInput optionText={(brand) => brand.name} />
    </ReferenceInput>
  );
}

export default CategorySelector;

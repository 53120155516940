import React from 'react';
import { Filter, SelectInput } from 'react-admin';
import { CustomDateInput } from '../../../../commonComponents/CustomDateInput';
import UserAutocompleteInput from '../../../../commonComponents/UserAutocompleteInput';
import { isGeorgia, isAzerbaijan } from '../../../../helpers/appTypeHelper';
import { shopsByAppType } from '../../../../config/constants';

const shops = shopsByAppType[process.env.REACT_APP_APP_TYPE];

const areShopsShown = isGeorgia() || isAzerbaijan();

const BonusShopTransactionsReportsFilter = (props) => (
  <Filter {...props}>
    <UserAutocompleteInput label="User" source="user_id" reference="admin/users/autocomplete" alwaysOn />
    <CustomDateInput label="Choose start date" source="start_date" start alwaysOn />
    <CustomDateInput label="Choose end date" source="end_date" end alwaysOn />
    {areShopsShown && <SelectInput source="shop" optionText="name" choices={shops} alwaysOn />}
  </Filter>
);

export default BonusShopTransactionsReportsFilter;

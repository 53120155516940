import React from 'react';
import { Edit, NumberInput, ReferenceInput, required, SelectInput, SimpleForm, TextInput } from 'react-admin';
import TargetGroupInput from '../../../../commonComponents/TargetGroupInput';
import ImageInput from '../../../../commonComponents/UploadInputs/ImageInput';
import { APP_TYPES, s3FilePaths } from '../../../../config/constants';

const ProviderEdit = (props) => (
  <Edit title="Edit Provider" {...props}>
    <SimpleForm>
      <TextInput label="ID" source="id" disabled />
      <TextInput label="Name" source="name" validate={required()} />
      <ImageInput imageInputSource="photo" validate={required()} imagePath={s3FilePaths.bonusShop} />
      <NumberInput label="Priority" source="priority" />
      <ReferenceInput label="Category" source="category_id" reference="admin/bonus-shop/categories/digital">
        <SelectInput optionText="name" validate={required()} />
      </ReferenceInput>
      <TargetGroupInput />
      <TextInput label="Template URL" source="template_url" validate={required()} />
      {process.env.REACT_APP_APP_TYPE === APP_TYPES.BELARUS && (
        <ReferenceInput source="mobile_operator_id" reference="services/mobile-operators">
          <SelectInput source="id" optionText={(x) => `${x.id} ${x.name}`} />
        </ReferenceInput>
      )}
    </SimpleForm>
  </Edit>
);

export default ProviderEdit;

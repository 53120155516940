import React from 'react';
import { List, Datagrid, TextField, EditButton, DeleteButton, NumberField } from 'react-admin';

const ARPacksList = (props) => (
  <List title="Packs" exporter={false} bulkActionButtons={false} sort={{ field: 'id', order: 'DESC' }} {...props}>
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" sortable={false} />
      <NumberField source="barcode" sortable={false} />
      <TextField source="model" sortable={false} />
      <TextField source="brand" sortable={false} />
      <NumberField source="price" sortable={false} />
      <EditButton />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
);

export default ARPacksList;

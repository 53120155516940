import React from 'react';
import { Create, TextInput, SelectInput, BooleanInput, required, FormDataConsumer } from 'react-admin';
import { CustomDateInput } from '../../../commonComponents/CustomDateInput';

import { s3FilePaths, statuses } from '../../../config/constants';
import TaskForm from './TaskForm';
import TargetGroupInput from '../../../commonComponents/TargetGroupInput';
import { isHungary, isIreland } from '../../../helpers/appTypeHelper';
import ImageInput from '../../../commonComponents/UploadInputs/ImageInput';
import { challengeTypes, challengeTypesChoices } from '../config/constants';

const hasTeamChallenge = isHungary() || isIreland();

const ChallengeCreate = (props) => (
  <Create title="Create Challenge" {...props}>
    <TaskForm redirect="list" type="create" defa>
      {hasTeamChallenge && (
        <SelectInput
          label="Type"
          source="type"
          optionText="name"
          validate={required()}
          choices={challengeTypesChoices}
          defaultValue={0}
        />
      )}
      <TextInput label="Admin Panel Name" source="admin_panel_name" />
      <TextInput label="Name" source="name" />
      <TextInput label="Description" source="description" />
      <SelectInput source="status" optionText="name" choices={statuses.slice(0, 2)} />
      <CustomDateInput label="Choose start Date" source="start_date" start />
      <CustomDateInput label="Choose end Date" source="end_date" end />
      <TargetGroupInput />
      <FormDataConsumer>
        {({ formData }) => {
          const { type } = formData;
          const isDailyGameType = type === challengeTypes.daily_game;

          return (
            <>
              {!isDailyGameType && isIreland() && (
                <BooleanInput
                  style={{ width: '300px' }}
                  label="Included in the Progress bar"
                  source="progress_bar_enable"
                  defaultValue={true}
                />
              )}
              {!isDailyGameType && hasTeamChallenge && (
                <ImageInput
                  label="Challenges page background"
                  imageInputSource="background_image"
                  imagePath={s3FilePaths.allUploads}
                  validate={required()}
                />
              )}
              {!isDailyGameType && <BooleanInput label="Show attachment button" source="attachments_enabled" />}
            </>
          );
        }}
      </FormDataConsumer>
    </TaskForm>
  </Create>
);

export default ChallengeCreate;

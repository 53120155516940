import TransactionsIcon from '@material-ui/icons/SwapHoriz';
import TransactionReportsList from './components/TransactionReportsList';
import { APP_TYPES, CAUCASUS_TYPES, roles } from '../../../config/constants';
import { isBelarus } from '../../../helpers/appTypeHelper';

const appType = process.env.REACT_APP_APP_TYPE;

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: CAUCASUS_TYPES.includes(appType) || isBelarus() ? [roles.ADMIN, roles.JTI_TEAM] : [roles.ADMIN],
  label: 'Transactions',
  subMenuName: 'reports',
};

export default {
  list: TransactionReportsList,
  icon: TransactionsIcon,
  name: 'admin/reports/transactions',
  options,
};

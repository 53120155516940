import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DeleteButton,
  DateField,
  SingleFieldList,
  ChipField,
  ArrayField,
  EditButton,
} from 'react-admin';
import StatusField from '../../../commonComponents/StatusField';

const BannerList = (props) => (
  <List
    title="Category Banners"
    exporter={false}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <TextField label="Name" source="name" sortable={true} />
      <ArrayField label="Categories" source="kb_categories" sortable={false}>
        <SingleFieldList linkType={false}>
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <DateField label="Publish date" source="published_at" />
      <StatusField label="Status" source="status" sortable={true} />
      <EditButton />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
);

export default BannerList;

import LocationCity from '@material-ui/icons/LocationCity';
import CitiesList from './components/CitiesList';
import CityCreate from './components/CityCreate';
import CityEdit from './components/CityEdit';
import { roles, APP_TYPES, CAUCASUS_TYPES } from '../../../config/constants';

const appType = process.env.REACT_APP_APP_TYPE;
const isBelarus = appType === APP_TYPES.BELARUS;
const isCaucasus = CAUCASUS_TYPES.includes(appType);

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: isBelarus || isCaucasus ? [roles.ADMIN, roles.JTI_TEAM] : [roles.ADMIN],
  label: 'Cities',
  subMenuName: 'outlets',
};

export default {
  list: CitiesList,
  create: CityCreate,
  edit: CityEdit,
  icon: LocationCity,
  name: 'admin/cities',
  options,
};

import React from 'react';
import { Edit, NumberInput, required, SimpleForm, TextInput } from 'react-admin';
import ImageInput from '../../../commonComponents/UploadInputs/ImageInput';
import { s3FilePaths } from '../../../config/constants';

const ARPacksEdit = (props) => (
  <Edit title="Pack edit" undoable={false} {...props}>
    <SimpleForm redirect="list">
      <TextInput label="ID" source="id" disabled />
      <NumberInput source="barcode" validate={required()} />
      <TextInput label="iOS animation url" source="pwa_animation" validate={required()} />
      <TextInput label="Android animation url" source="android_animation" validate={required()} />
      <TextInput label="Article url" source="article_url" />
      <TextInput source="model" validate={required()} />
      <TextInput source="brand" validate={required()} />
      <TextInput label="CO" source="co" validate={required()} />
      <NumberInput source="tar_content" validate={required()} />
      <NumberInput source="nicotine_content" validate={required()} />
      <TextInput source="format" validate={required()} />
      <TextInput source="filter_type" validate={required()} />
      <TextInput source="cuff_paper" validate={required()} />
      <NumberInput source="price" validate={required()} />
      <ImageInput imageInputSource="image" imagePath={s3FilePaths.recognition} validate={required()} />
      <ImageInput
        label="Description background image"
        imageInputSource="background_image"
        imagePath={s3FilePaths.recognition}
        validate={required()}
      />
    </SimpleForm>
  </Edit>
);

export default ARPacksEdit;

import { BooleanField, Datagrid, List, NumberField, TextField } from 'react-admin';
import React, { useEffect, useState } from 'react';
import ModalImage from 'react-modal-image';

import { makeStyles } from '@material-ui/core/styles';
import SendCategoryToGroupOfUsers from '../../../../commonComponents/SendMessageActions/SendToGroupOfUsers/SendToGroupOfUsers';
import SendCategoryToSingleUser from '../../../../commonComponents/SendMessageActions/SendToSingleUser/SendToSingleUser';
import SendCategoryToAllUsers from '../../../../commonComponents/SendMessageActions/SendToAllUsers/SendToAllUsers';
import CategoriesFilter from './CategoriesFilter';
import { serverApiUrl } from '../../../../config/constants';
import httpClient from '../../../../core/httpClient';

const useStyles = makeStyles({
  imageContainer: {
    display: 'inline-block',
    marginRight: '10px',
  },
  imageSmall: {
    maxHeight: ({ height }) => `${height}rem !important`,
  },
});

const ImageFieldRow = (props) => {
  const classes = useStyles({ height: 3 });
  const [imageSource, setImagePreview] = useState();

  useEffect(() => {
    if (props?.record?.icon) {
      const url = `${serverApiUrl}/services/file?file_key=${props.record.icon}`;
      const options = { method: 'GET' };
      httpClient(url, options).then((res) => {
        setImagePreview(res.json.url);
      });
    }
  }, []);

  return (
    <div key={imageSource} onClick={(e) => e.stopPropagation()} className={classes.imageContainer}>
      <ModalImage
        small={imageSource}
        large={imageSource}
        imageBackgroundColor="transparent"
        alt=""
        className={classes.imageSmall}
      />
    </div>
  );
};

const CategoriesList = (props) => (
  <List
    title="Knowledge Bank Categories"
    filters={<CategoriesFilter />}
    exporter={false}
    bulkActionButtons={false}
    filterDefaultValues={{ section: 1 }}
    sort={{ field: 'priority', order: 'DESC' }}
    {...props}
  >
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <TextField label="Name" source="name" />
      <ImageFieldRow />
      <NumberField label="Priority" source="priority" />
      <BooleanField label="Active" source="active" />
      <BooleanField label="Show 'New' bubble" source="show_new_bubble" />
      <BooleanField label="Limit content" source="limited_content" />
      <SendCategoryToSingleUser recordKey="category_id" endpointUrl="/admin/knowledge-bank/categories/send/user" />
      <SendCategoryToGroupOfUsers recordKey="category_id" endpointUrl="/admin/knowledge-bank/categories/send/group" />
      <SendCategoryToAllUsers recordKey="category_id" endpointUrl="/admin/knowledge-bank/categories/send/all" />
    </Datagrid>
  </List>
);

export default CategoriesList;

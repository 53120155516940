import SettingsIcon from '@material-ui/icons/Settings';
import Settings from './components/Settings';
import { roles, APP_TYPES } from '../../config/constants';

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: [roles.ADMIN],
  label: 'Settings',
  subMenuName: 'manage',
};

export default {
  list: Settings,
  icon: SettingsIcon,
  name: 'admin/settings',
  options,
};

import React from 'react';
import { Datagrid, List, TextField } from 'react-admin';

import QuizRatesReportsFilter from './QuizRatesReportsFilter';

const QuizRatesReportsList = (props) => (
  <List title="Quiz rates" filters={<QuizRatesReportsFilter />} bulkActionButtons={false} {...props}>
    <Datagrid>
      <TextField label="ID" source="id" sortable={false} />
      <TextField label="Quiz id" source="quiz_id" sortable={false} />
      <TextField label="Quiz name" source="quiz_name" sortable={false} />
      <TextField label="User id" source="user_id" sortable={false} />
      <TextField label="User answer" source="quiz_mark" sortable={false} />
      <TextField label="Date" source="date" sortable={false} />
    </Datagrid>
  </List>
);

export default QuizRatesReportsList;

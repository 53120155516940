import TargetGroupIcon from '@material-ui/icons/Group';
import TargetGroupsList from './components/TargetGroupsList';
import TargetGroupCreate from './components/TargetGroupCreate';
import TargetGroupEdit from './components/TargetGroupEdit';
import { roles, APP_TYPES } from '../../config/constants';

const ALLOWED_ROLES = {
  [APP_TYPES.HUNGARY]: [roles.ADMIN, roles.RSM, roles.JTI_TEAM],
  [APP_TYPES.IRELAND]: [roles.ADMIN, roles.JTI_TEAM],
};

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: ALLOWED_ROLES[process.env.REACT_APP_APP_TYPE] || [roles.ADMIN],
  subMenuName: 'manage',
};

export default {
  list: TargetGroupsList,
  create: TargetGroupCreate,
  edit: TargetGroupEdit,
  icon: TargetGroupIcon,
  name: 'admin/target-groups',
  options,
};

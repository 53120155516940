import React, { useState } from 'react';
import { Button, FormWithRedirect, required, SaveButton, useNotify } from 'react-admin';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import AttachmentIcon from '@material-ui/icons/AttachFile';
import IconCancel from '@material-ui/icons/Cancel';
import httpClient from '../../../core/httpClient';
import { serverApiUrl } from '../../../config/constants';

import styles from './SendCustomMessagesToUsers.module.scss';
import UploadFileInput from '../../UploadInputs/UploadFileInput';

const SendCustomMessagesToUsers = ({ endpointUrl }) => {
  const notify = useNotify();
  const [isOpen, setOpenStatus] = useState(false);

  const handleOpenModal = (e) => {
    e.stopPropagation();
    setOpenStatus(true);
  };

  const handleCloseModal = () => {
    setOpenStatus(false);
  };

  const handleSendMessage = ({ file_key }) => {
    const url = `${serverApiUrl}${endpointUrl}`;
    const body = JSON.stringify({ file_key });
    const options = { method: 'POST', body };

    httpClient(url, options)
      .then(() => handleCloseModal())
      .catch(() => notify('Error: Wrong File', 'warning'));
  };

  return (
    <>
      <Button label="Custom messages" onClick={handleOpenModal}>
        <AttachmentIcon />
      </Button>
      <Dialog open={isOpen} onClick={(e) => e.stopPropagation()}>
        <DialogTitle>send custom messages to users</DialogTitle>
        <FormWithRedirect
          save={handleSendMessage}
          render={({ handleSubmitWithRedirect, submitting, pristine }) => (
            <>
              <DialogContent className={styles.dialogContent}>
                <UploadFileInput label="Sending messages" fileInputSource="file_key" validate={required()} />
              </DialogContent>
              <DialogActions>
                <SaveButton
                  label="send"
                  disabled={submitting || pristine}
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                />
                <Button label="ra.action.cancel" onClick={handleCloseModal}>
                  <IconCancel />
                </Button>
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

export default SendCustomMessagesToUsers;

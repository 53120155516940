import OrderIcon from '@material-ui/icons/Note';
import OrdersList from './components/OrdersList';
import OrderCreate from './components/OrderCreate';
import OrderEdit from './components/OrderEdit';
import { roles, APP_TYPES, ALL_WITHOUT_IRELAND } from '../../../config/constants';

const ENV_APP_TYPE = process.env.REACT_APP_APP_TYPE;

const options = {
  appTypes: ALL_WITHOUT_IRELAND,
  allowedRoles: [APP_TYPES.HUNGARY, APP_TYPES.BELARUS].includes(ENV_APP_TYPE)
    ? [roles.ADMIN]
    : [roles.ADMIN, roles.JTI_TEAM],
  label: 'Orders',
  subMenuName: 'bonusShop',
};

export default {
  list: OrdersList,
  create: OrderCreate,
  edit: OrderEdit,
  icon: OrderIcon,
  name: 'admin/bonus-shop/orders',
  options,
};

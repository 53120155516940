import { Datagrid, List, TextField, useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import CustomListActions from '../../../commonComponents/ListActions/CustomListActions';
import OutletsAndUsersFilter from './OutletsAndUsersFilter';
import OutletsOverview from './OutletsOverview';
import LinearDiagramContainer from './registeredUsersLinearDiagram/LinearDiagramContainer';

const useStyles = makeStyles({
  listTitle: {
    fonSize: 22,
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
    fontWeight: 700,
    marginBottom: 30,
  },
  headerCell: {
    fontWeight: 700,
  },
});

const OutletsAndUsersList = (props) => {
  const { history } = props;
  const classes = useStyles();
  const translate = useTranslate();

  const onOutletClick = (outlet_id) => {
    history.push(`outlets-users/${outlet_id}`);
  };

  return (
    <>
      <OutletsOverview />
      <LinearDiagramContainer />
      <div className={classes.listTitle}>{translate('Outlets list')}</div>
      <List
        title="Outlets and Users"
        bulkActionButtons={false}
        actions={<CustomListActions exporter={true} importer={false} creation={false} />}
        filters={<OutletsAndUsersFilter />}
        sort={{ field: 'id', order: 'DESC' }}
        {...props}
      >
        <Datagrid rowClick={onOutletClick} classes={{ headerCell: classes.headerCell }}>
          <TextField label="Legal Entity" source="legal_entity_name" />
          <TextField label="Outlet code" source="outlet_code" />
          <TextField label="City" source="city_name" />
          <TextField label="Address" source="address" />
          <TextField label="Download Users" source="download_users" />
          <TextField label="Monthly active users" source="active_users" />
          <TextField label="TM" source="manager" />
          <TextField label="Region" source="region" />
        </Datagrid>
      </List>
    </>
  );
};

export default OutletsAndUsersList;

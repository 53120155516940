import CPAIcon from '@material-ui/icons/AssignmentTurnedIn';
import ProgramsList from './components/ProgramsList';
import ProgramsCreate from './components/ProgramsCreate';
import ProgramsEdit from './components/ProgramsEdit';
import { allRoles, APP_TYPES } from '../../config/constants';

const options = {
  appTypes: [APP_TYPES.BELARUS],
  allowedRoles: allRoles,
};

export default {
  list: ProgramsList,
  create: ProgramsCreate,
  edit: ProgramsEdit,
  icon: CPAIcon,
  name: 'admin/cpa-program',
  options,
};

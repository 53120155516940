import React from 'react';
import Typography from '@material-ui/core/Typography';

const OutletAddressField = ({ record = {} }) => {
  const { street, building_number } = record.address || {};

  return <Typography>{`${street || ''} ${building_number || ''}`.trim()}</Typography>;
};

export default OutletAddressField;
